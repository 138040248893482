<template>
    <div
        class="no_data"
        :style="{
            height: `${height}px`,
        }"
    >
        <div class="center">
            <!-- noData -->
            <GI class="loading" v-if="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>

            <!-- <GI class="noData" name="noData" ws="60" hs="60"></GI> -->
            <template v-else>
                <GI class="noData" name="global_new_404" ws="76" hs="76"></GI>
                <!-- <div class="bg_img_1 bgCover" :style="{ backgroundImage: `url(${bgSrc})`, width: '107px', height: '84px' }"></div> -->

                <span
                    class="fontColor"
                    :style="{
                        fontSize: `${'14'}px`,
                    }"
                >
                    {{ langJson('No data', 'inscdoge.txt.v1.101') }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NuData',
    data() {
        return {
            bgSrc: '/source/uic/noData.png',
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        height: {
            type: [String, Number],
            default: 300,
        },
    },
}
</script>

<style lang="scss" scoped>
.no_data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 36px;
    color: var(--font1);

    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--font1);

        .loading {
            margin-bottom: 20px;
        }
    }

    .fontColor {
        margin-top: 13px;
        /* color: #909399; */
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.41px;
    }
}
</style>
