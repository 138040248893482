import market from "@/api/market"
import personal from "@/api/personal"
const nftModule = {
    state: {
        //NFT交易记录
        nftHistory: []
    },
    getters: {

    },
    mutations: {
        nftHistory(state, nftHistory) {
            state.nftHistory = nftHistory
        },

    },
    actions: {
        // 获取nft交易记录
        async nftHistoryGet(context, params) {
            console.log();
            try {
                let result;

                if (params.id) {
                    // 用户上传的id
                    result = await market.nftHistory(params.id, params);
                } else {
                    result = await personal.personalActivity(id, params);
                }
                result = this.apiResult(result)
                context.commit("nftHistory", result.data.records);
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

    }
};
export default nftModule;
