<template>
    <div class="switch_theme">
        <div :class="['theme_item', isActive && 'theme_active']">
            <GI v-if="isActive" name="light" ws="16" hs="16" :color="svg3"></GI>
            <GI v-else name="light" ws="16" hs="16" @click="setTheme('light')" :color="svg3"></GI>
        </div>
        <div :class="['theme_item', !isActive && 'theme_active']">
            <GI v-if="!isActive" name="dark" ws="16" hs="16" :color="svg2"></GI>
            <GI v-else name="dark" ws="16" hs="16" @click="setTheme('dark')" :color="svg2"></GI>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        isActive() {
            return this.globalTheme == 'light'
        },
    },
    created() {
        this.setTheme(this.globalTheme)
    },
    methods: {
        setTheme(type) {
            this.$store.commit('theme', type)
            if (type == 'dark') {
                // font
                document.documentElement.style.setProperty('--font1', '#ffffff')
                document.documentElement.style.setProperty('--font2', '#9a9a9a')
                document.documentElement.style.setProperty('--font3', '#B6B6B6')
                // bg
                document.documentElement.style.setProperty('--bg1', '-webkit-linear-gradient(left, #123a54, #111f39 30%, #0e0019 70%, #0e0019)')
                document.documentElement.style.setProperty('--bg2', '#1c1c1c')
                document.documentElement.style.setProperty('--bg3', '-webkit-linear-gradient(left, #823f99, #ca6581)')
                document.documentElement.style.setProperty('--bg4', '#56485e')
                document.documentElement.style.setProperty('--bg5', '-webkit-linear-gradient(left, #27192e, #40104d 30%, #600522 70%, #5b0f31)')
                document.documentElement.style.setProperty('--bg6', '-webkit-linear-gradient(top, #5a4e64, #947680)')
                document.documentElement.style.setProperty('--bg7', '-webkit-linear-gradient(left, #5c0667, #3e0a68, #1f0f6a)') // -webkit-linear-gradient(left, #8330b6, #c76073)
                document.documentElement.style.setProperty('--bg8', 'rgba(0, 0, 0, 0.4)')
                document.documentElement.style.setProperty('--bg9', '#1f242e')
                document.documentElement.style.setProperty('--bg10', '#0E1116')
                document.documentElement.style.setProperty('--bg11', '#615a7465')
                document.documentElement.style.setProperty('--bg12', '#666666')
                document.documentElement.style.setProperty('--bg13', '-webkit-linear-gradient(left, #123a54, #111f39 30%, #0e0019 70%, #0e0019)') // head
                document.documentElement.style.setProperty('--bg14', '-webkit-linear-gradient(left, #370445, #2f0646 30%, #290646 70%, #1e0741)') //list

                document.documentElement.style.setProperty('--bg15', '-webkit-linear-gradient(left, #5c0667, #5c0667)') //list-new left
                document.documentElement.style.setProperty('--bg16', '-webkit-linear-gradient(left, #5c0667, #3e0a68, #1f0f6a)') //list-new
                document.documentElement.style.setProperty('--bg17', '-webkit-linear-gradient(left, #1f0f6a, #1f0f6a)') //list-new right
                document.documentElement.style.setProperty('--bg18', '#615A74')

                // border
                document.documentElement.style.setProperty('--border1', '#dedede00')
                document.documentElement.style.setProperty('--border2', '#dedede35')
                document.documentElement.style.setProperty('--border3', '#371D3E')
            } else if (type == 'light') {
                // font
                document.documentElement.style.setProperty('--font1', '#010101')
                document.documentElement.style.setProperty('--font2', '#9a9a9a')
                document.documentElement.style.setProperty('--font3', '#9a9a9a')

                // bg
                document.documentElement.style.setProperty('--bg1', '#ffffff')
                document.documentElement.style.setProperty('--bg2', '#afafaf')
                document.documentElement.style.setProperty('--bg3', '-webkit-linear-gradient(left, #610064, #650070)')
                document.documentElement.style.setProperty('--bg4', '#401b44')
                document.documentElement.style.setProperty('--bg5', '-webkit-linear-gradient(left, #3f0843, #40104d 30%, #600522 70%, #410f46)')
                document.documentElement.style.setProperty('--bg6', '-webkit-linear-gradient(top, #572f5b, #573657)')
                document.documentElement.style.setProperty('--bg7', '-webkit-linear-gradient(top, #640169, #550b59)')
                document.documentElement.style.setProperty('--bg8', 'rgba(255, 255, 255, 0.4)')
                document.documentElement.style.setProperty('--bg9', '#dee1e6')
                document.documentElement.style.setProperty('--bg10', '#ffffff')
                document.documentElement.style.setProperty('--bg11', '#f5f5f5')
                document.documentElement.style.setProperty('--bg12', '#f9f9f9')
                document.documentElement.style.setProperty('--bg13', '')
                document.documentElement.style.setProperty('--bg14', '')

                document.documentElement.style.setProperty('--bg15', '#fff')
                document.documentElement.style.setProperty('--bg16', '#fff')
                document.documentElement.style.setProperty('--bg17', '#fff')
                document.documentElement.style.setProperty('--bg18', '#615A74')
                // border
                document.documentElement.style.setProperty('--border1', '#dedede')
                document.documentElement.style.setProperty('--border2', '#dedede95')
                document.documentElement.style.setProperty('--border2', '#dedede95')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.switch_theme {
    height: 28px;
    width: 54px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg9);
    cursor: pointer;
    .theme_item {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #00000000;
    }
    .theme_active {
        border-radius: 100px;
        border: 2px solid var(--color_green);
        background-color: var(--bg2);
    }
}
</style>
