<template>
    <Swiper :hs="hs" :ws="ws" :list="list">
        <template v-slot:top="{ item, index }"><div></div></template>
        <template v-slot:main="{ item, index }">
            <DivScale :hs="hs" v-for="(item, index) in list" :style="{ 'max-width': ws, backgroundImage: `url(${item.bannerImg})` }" class="item" :class="'page' + index" :key="index" @click.native="openLink(item.bannerLink)">
                <div class="detail">
                    <div class="title fontOver" :title="item.name">{{ item.name }}</div>
                    <div class="desc" :title="item.desc">{{ item.desc }}</div>
                </div>
            </DivScale>
        </template>
        <template v-slot:bottom="{ item, index }"></template>
    </Swiper>
</template>

<script>
export default {
    props: ['ws', 'hs', 'list'],
    data() {
        return {}
    },
    mounted() {},
    methods: {
        openLink(url) {
            console.log('url')
            if (!!url) {
                window.open(url)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.item {
    white-space: wrap;
    word-break: break-all;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.5s;
    background-color: var(--color_white);
    .detail {
        width: calc(100% - 40px);
        height: calc(100% - 42px);
        .title {
            width: 100%;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.5px;
            color: #ffffff;
        }
        .desc {
            height: 30px;
            width: 100%;
            margin-top: 8px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            color: #ffffff;
            word-break: break-all;

            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
