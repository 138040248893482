<template>
    <div class="link_wallet">
        <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        <div class="link_tip">{{ msg ? msg : langJson('请稍等', 'inscdoge.txt.v1.159') }}</div>
    </div>
</template>
<script>
export default {
    props: {
        msg: {
            type: String,
            default: '',
        },
    },
    created() {},
}
</script>
<style lang="scss" scoped>
.link_wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    .link_tip {
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font1);
        margin-top: 40px;
    }
}
</style>
