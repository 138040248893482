import http from "./base";

/**
 *文章类型下的文章列表 
 * @parma typeId
 */
let getArticles = (params = {}) => {
  return http.get(`/article/articleList`, params);
};

/**
 *文章详情 */
let getDetail = (params = {}) => {
  return http.get(`/content/news/detail`, params);
};

/**
 *文章类型 */
let getTypes = (params = {}) => {
  return http.get(`/article/type`, params);
};
/**
 *推荐文章 */
let goods = () => {
  return http.get(`/content/recommend`);
};

// 新 文章列表  1 底部文章 2 帮助中心
let getArticleList = () => {
  return http.get(`/article/list`);
};
// 新 文章详情
let getArticleDetail = id => {
  return http.get(`/article/detail/${id}`);
};


let getLinkList = () => {
  return http.get('/link/list')
}
export default {
  getArticleList,
  getArticleDetail,
  // getHelps,
  getTypes,
  goods,
  getDetail,
  getLinkList,
  getArticles
};
