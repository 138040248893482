import http from './base'
/**
 * 用户信息
 * @param address   钱包地址
 * @param chainName 主链
 */
let personalInformation = (parmas) => {
    return http.get('/user/info', parmas)
}

/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户所属nft列表
 */
let personalCollected = (params) => {
    return http.get('/product/list', params)
}

/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户创建nft列表
 */
let personalCreated = (params) => {
    return http.get('/product/mintOfMeProducts', params)
}
/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户收藏列表
 */
let personalFavorited = (params) => {
    return http.get('/product/collectList', params)
}
/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户推荐列表
 */
let personalRecommend = (params) => {
    return http.get('/product/wayList', params)
}
/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户拍卖获胜记录
 */
let personalWinning = (params) => {
    return http.get('/auction/winningList', params)
}

/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户nft交易记录
 */
let personalActivity = (params) => {
    return http.get('/trade/list', params)
}

/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户收到的出价记录
 */
let personalOffersReceived = (params) => {
    return http.get('/maker/list', params)
}

/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param address  钱包地址
 * 用户发起的出价记录
 */
let personalOffersMade = (params) => {
    return http.get('/maker/myMakerOffer', params)
}


/**
 * @param productId 产品ID
 * @param price 出售价
 * @param symbol 币种名称
 * @param startTime 售卖时间
 * @param endTime 结束时间
 * @param isSpecific 是否指定用户
 * @param specificAddr 指定用户的地址
 * @param coinId  币种id
 * @param retentionPrice  保留价格
 * @param type  售卖方式
 * @param chainId  主链id
 * @param sign 上架签名
 * @param saleNum 上架数量
 * @param isLongTime 是否长期售卖 0-否 1-是
 * 
 * 上架产品
 */
let nftSale = params => {
    return http.postJson(`/product/shelves`, params);
};
/**
 * @param id  项目id
 * 获取版费明细
 */

let getAwards = (parmas = {}) => {
    return http.get("/trade/awardRecord", parmas);
};
export default {
    personalInformation,
    personalCollected,
    personalCreated,
    personalFavorited,
    personalRecommend,
    personalWinning,
    personalActivity,
    personalOffersReceived,
    personalOffersMade,
    nftSale,
    getAwards
}
