import api from '@/api/login'
import tools from '@/mixin/tools'
const loginModule = {
    state: {
        // 用户令牌
        token: '',
        // 登录随机码
        nonce: '',
        // 连接的钱包信息
        walletDetail: {},
    },
    getters: {},
    mutations: {
        token(state, token) {
            tools.setCookie('token', token)
            state.token = token
        },
        nonce(state, nonce) {
            state.nonce = nonce
        },

        walletDetail(state, walletDetail) {
            if (walletDetail) {
                localStorage.setItem('walletDetail', JSON.stringify(walletDetail))
            }
            state.walletDetail = walletDetail
        },
    },
    actions: {
        // 退出登录
        async logout(context) {
            let nowTime = new Date().getTime()
            if (this.state.lockRun + 1500 < nowTime) {
                context.commit('lockRun', nowTime)
                context.commit('token', '')
                context.commit('walletDetail', {})
                context.commit('information', {})

                context.commit('sendSelect', [])
                context.commit('saleSelect', [])
                context.commit('cartSelect', [])
                context.commit('mintSelect', [])

                context.commit('warning', 'Exit Account')
                if (window.provider && window.provider.close) {
                    window.provider.close()
                }
                window.logout() // 回到首页

                // 退出walletConent
                try {
                    if (window.provider && window.provider.disconnect) {
                        window.provider.disconnect()
                    }
                    for (const [key, val] of Object.entries(localStorage)) {
                        if (key.indexOf('wc@2') != -1) {
                            localStorage.removeItem(key)
                        }
                    }
                    localStorage.removeItem('wagmi.store')
                } catch (error) {}
            }
        },
        // 获取登录 nonce
        async nonceGet(context, params) {
            try {
                let result = await api.loginNonce(params)
                result = this.apiResult(result)
                context.commit('nonce', result.data.nonce)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 登录钱包
        async tokenGet(context, params) {
            try {
                let result = await api.loginPost(params)
                result = this.apiResult(result)
                result && result.data && context.commit('token', result.data.token)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default loginModule
