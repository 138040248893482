<template>
    <div class="global_tip">
        <div class="tip_list">
            <div :class="['tip_item', 'animated', time >= item.expire ? 'animate__backOutUp' : 'animate__backInDown', item.type]" v-for="(item, index) in globalTip" :key="item.expire">
                <GI :name="`tip_${item.type}`" ws="20" hs="20"></GI>
                {{ item.message }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: 0,
        }
    },
    mounted() {
        setInterval(() => {
            this.time = new Date().getTime()
        }, 1000)
    },
    computed: {},
    destroyed() {
        this.$store.commit('tips', [])
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.global_tip {
    .tip_list {
        .tip_item {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            border-radius: 0px;
            // margin-bottom: 12px;
            padding: 12px 15px;
            box-sizing: border-box;
            white-space: wrap;
            column-gap: 10px;
            // border: 1px solid var(--border2);
        }
        .error {
            background: var(--danger_color);
            color: #ffffff;
        }
        .danger {
            background: var(--danger_color);
            color: #ffffff;
        }
        .success {
            // background: var(--success_color);
            color: #ffffff;
            background-color: #ff8d01;
        }
        .warning {
            background-color: #000;
            color: #ffffff;
        }
        width: 100%;
    }
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    height: auto;
    pointer-events: none;
    z-index: 30001;
}
@media screen and (max-width: 450px) {
    .global_tip {
        left: 0px !important;
        right: 0px !important;
        top: 0px !important;
    }
}
</style>
