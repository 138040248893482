import api from '@/api/preSale'

const preSaleModule = {
    state: {
        // 项目详情
        projectInfo: {},
        // 是否可以购买
        verifyParam: false,
        // 账户信息
        accountList: {},
        // 
        symbolQuote: {},
    },
    getters: {},
    mutations: {
        projectInfo(state, projectInfo) {
            state.projectInfo = projectInfo
        },
        verifyParam(state, verifyParam) {
            state.verifyParam = verifyParam
        },
        accountList(state, accountList) {
            state.accountList = accountList
        },
        symbolQuote(state, symbolQuote) {
            state.symbolQuote = symbolQuote
        },
    },
    actions: {
        async projectInfo(context, params) {
            try {
                // 请求接口
                let result = await api.projectInfo(params)
                result = this.apiResult(result)
                result.data && context.commit('projectInfo', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async verifyParam(context, params) {
            try {
                // 请求接口
                let result = await api.verifyParam(params)
                result = this.apiResult(result)

                context.commit('verifyParam', result.code == 200 ? true : false)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async accountList(context, params) {
            try {
                // 请求接口
                let result = await api.accountList(params)
                result = this.apiResult(result)

                context.commit('accountList', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async symbolQuote(context, params) {
            try {
                // 请求接口
                let result = await api.symbolQuote(params)
                result = this.apiResult(result)

                result.data && context.commit('symbolQuote', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default preSaleModule