import api from '@/api/myster'
const mysterModule = {
    state: {
        // 最新盲盒项目列表
        newlistMyster: [],
        // 盲盒列表
        mysters: [],
        // 盲盒详情
        detail: {},
        // 盲盒系列
        mysterSeries: [],
        // 推荐
        mysterRecommendList: [],
        // 排行
        mysterList: [],
        //    历史记录
        mysterHistoryList: [],
    },
    getters: {},
    mutations: {
        newlistMyster(state, newlistMyster) {
            state.newlistMyster = newlistMyster
        },
        mysters(state, mysters) {
            state.mysters = mysters
        },
        detail(state, detail) {
            state.detail = detail
        },
        mysterSeries(state, mysterSeries) {
            state.mysterSeries = mysterSeries
        },
        setMysterRecommendList(state, mysterRecommendList) {
            state.mysterRecommendList = mysterRecommendList
        },
        setMysteryList(state, mysterList) {
            state.mysterList = mysterList
        },
        setmysterHistoryList(state, mysterHistoryList) {
            state.mysterHistoryList = mysterHistoryList
        },
    },
    actions: {
        /* 盲盒详情页 */
        // 盲盒详情
        async mysterDetail(context, id) {
            try {
                // 请求接口
                let result = await api.mysterDetail(id)
                // 处理结果
                result = this.apiResult(result)
                context.commit('detail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async mysterSeries(context, params) {
            try {
                let result = await api.mysterSeries(params)
                result = this.apiResult(result)
                context.commit('mysterSeries', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        /* 盲盒首页 */
        // 获取最新盲盒项目列表
        async mysterNewGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'createTime',
                    isAsc: 'desc',
                }
                // 请求接口
                let result = await api.mysterlist(params)
                result = this.apiResult(result)
                this.apiCache('myster', 'newlistMyster', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 盲盒首页 */
        // 获取最新盲盒排行列表
        async getmysterList(context, orderbyFlag) {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'createTime',
                    isAsc: 'desc',
                }
                // 请求接口
                let result = await api.mysterlist(Object.assign(params, orderbyFlag))
                result = this.apiResult(result)
                context.commit('setMysteryList', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 盲盒列表页 */
        // 获取盲盒项目列表
        async mystersProject(context, params) {
            try {
                let result = await api.mysterlist(Object.assign(params))
                result = this.apiResult(result)
                context.commit('mysters', result.rows)
                context.commit('total', result.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 盲盒交易历史记录
        async getMysterHistoryList(context, params) {
            try {
                let result = await api.mysterTrade(params)
                // 判断缓存是否过期
                result = this.apiResult(result)
                // 设置
                // context.commit('setmysterHistoryList', result.data.rows)
                this.apiCache('myster', 'setmysterHistoryList', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
            }
        },
        /* 盲盒轮播图 */
        async getMysterRecommendList(context) {
            try {
                // 请求接口
                let result = await api.getRecommendList({ type: 4 })

                // 处理结果
                result = this.apiResult(result)
                // 设置
                context.commit('setMysterRecommendList', result.data)
                this.apiCache('myster', 'setMysterRecommendList', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default mysterModule
