import api from "@/api/currency"
const currencyModule = {
    state: {
        //支持的法币
        legalSupport: [],
        //支持的虚拟币
        cryptoSupport: [],
        //支持的渠道
        channelSupport: [],

        //获取支付链接
        orderUrl: "",
        // 购买记录
        orderBuy:[]
    },
    getters: {

    },
    mutations: {
        legalSupport(state, legalSupport) {
            state.legalSupport = legalSupport
        },
        cryptoSupport(state, cryptoSupport) {
            state.cryptoSupport = cryptoSupport
        },
        channelSupport(state, channelSupport) {
            state.channelSupport = channelSupport
        },

        orderUrl(state, orderUrl) {
            state.orderUrl = orderUrl
        },
        orderBuy(state, orderBuy) {
            state.orderBuy = orderBuy
        },


    },
    actions: {
        // 获取支持的法币
        async legalGet(context) {
            try {
                let result = await api.legalSupport()
                result = this.apiResult(result)
                result.data && context.commit("legalSupport", result.data);
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的虚拟币
        async cryptoGet(context) {
            try {
                let result = await api.cryptoSupport()
                result = this.apiResult(result)
                result.data && context.commit("cryptoSupport", result.data);
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的渠道
        async channelGet(context,params) {
            try {
                let result = await api.channelSupport(params)
                result = this.apiResult(result)
                result.data && context.commit("channelSupport", result.data);
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取汇率
        async quotedGet(context,params) {
            try {
                let result = await api.quotedChannel(params)
                result = this.apiResult(result)
                return result && result.data
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取订单链接
        async orderCreate(context,params) {
            try {
                let result = await api.orderCreate(params)
                result = this.apiResult(result)
                result.data && context.commit("orderUrl", result.data);
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取购买记录
        async historyBuy(context,params) {
            try {
                let result = await api.orderBuy(params)
                result = this.apiResult(result)
                result.data && result.data.rows && context.commit("orderBuy", result.data.rows);
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

    }
};
export default currencyModule;
