<template>
    <div class="a" :style="{ width: `${ws || '100%'}` }">
        <!-- 等比例缩放容器 -->
        <div class="b" :style="{ 'padding-bottom': `${hs || '100%'}` }">
            <div class="c">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
// 使用  传入 ws宽度 百分比 比如 100% 相对于父元素
//       传入 hs高度 百分比 比如 100% 相对传入的ws
// <DivScale>
//             <img style="width: 100%;height: 100%;object-fit: contain;margin-right:0;margin-bottom:0"   src="https://img1.baidu.com/it/u=2812417321,4100104782&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1692896400&t=51d4c4ce9a8d025ae59fd0e36583085f" />
//           </DivScale>
export default {
    props: ['ws', 'hs'],
    data() {
        return {}
    },
    watch: {},
    computed: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
.a {
    position: relative;

    .b {
        height: 0 !important;
        overflow: hidden;
        .c {
            position: absolute;
            height: 100% !important;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
