import Vue from 'vue'
import VueRouter from 'vue-router'
import tools from '@/mixin/tools'
Vue.use(VueRouter)

// 默认路由
let routes = [
    {
        path: '*',
        name: '*',
        component: () => import('@/page/404.vue'),
        meta: {
            roles: [],
        },
    },
    {
        path: '/',
        name: '/',
        redirect: '/index',
        // component: () => import("@/page/startup/index.vue"),
        meta: {
            roles: [],
        },
    },
]

// 自动读取路由
let map = require.context('../page', true, /\/.+\.vue$/)

map.keys().forEach((item) => {
    let name = item.replace(/^\.\//, '').split('.')[0]
    let path = item.split('.')[1]
    let component = () => import(`@/page${item.slice(1)}`)
    // let component = () => import(/* webpackChunkName:'all_page' */ `@/page${item.slice(1)}`)
    routes.push({
        path,
        name,
        component,
        meta: {
            roles: [],
        },
    })
})

// 重写传参路由
let replacePath = [
    {
        before: '/preSale',
        after: '/preSale/:invitedCode?',
    },
    {
        before: '/index',
        after: '/index/:invitedCode?',
    },
    {
        before: '/introduction',
        after: '/introduction/:id',
    },
    {
        before: '/plan',
        after: '/plan/:id',
    },
    {
        before: '/noticeDetail',
        after: '/noticeDetail/:id',
    },
    {
        before: '/about',
        after: '/about/:id',
    },
    {
        before: '/purchaseHistory',
        after: '/purchaseHistory/:chainId?/:id?',
    },
    {
        before: '/tokenDetail',
        after: '/tokenDetail/:id?',
    },
]

routes = routes.map((item) => {
    let exit = replacePath.find((subItem) => {
        return item.path == subItem.before
    })
    if (exit) {
        item.path = exit.after
    }
    return item
})

// 实例化
let router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
})
//  防止重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
export default router
