<template>
    <div class="step">
        <!-- <div class="point_list" v-if="stepNum">
            <div :class="['point_item', step == index ? 'point_active' : '']" v-for="(item, index) in slotlist" :key="index" :style="wlen"></div>
        </div> -->
        <div :class="['step_item', step == index ? 'show' : '', step != index ? 'hidden' : '']" v-for="(item, index) in slotlist" :key="index">
            <slot :name="item"></slot>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            slotlist: [],
            step: 0,
        }
    },
    props: {
        stepNum: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        wlen() {
            if (this.slotlist.length > 1) {
                return {
                    width: `calc(${Math.floor(100 / this.slotlist.length)}% - 10px)`,
                }
            } else {
                return {
                    width: `100%`,
                }
            }
        },
    },
    mounted() {
        Object.keys(this.$slots).forEach((item) => {
            this.slotlist.push(item)
        })
    },
    methods: {
        // 重置
        reset() {
            this.step = 0
        },
        // 中止
        stop() {
            this.$emit('stop')
            this.reset()
        },
        jump(index) {
            this.step = index
        },
        // 下一步
        next() {
            if (this.step < this.slotlist.length - 1) {
                this.step += 1
            } else {
                this.$emit('finash')
            }
        },
        // 上一步
        prev() {
            if (this.step >= 1) {
                this.step -= 1
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.step {
    .point_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-top: 12px;

        .point_item {
            height: 4px;
            background-color: #e3e3e3df;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
        }

        .point_active {
            background: var(--bg7);
            /* background-color: rgba($color: #5f0464, $alpha: 0.7); */
        }
    }
}

.show {
    animation: innerShow 2s ease;
}

.hidden {
    height: 0;
    overflow: hidden;
}

@keyframes innerShow {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes innerHide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}</style>
