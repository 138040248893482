<template>
    <div class="box">
        <div class="coin"></div>
    </div>
</template>

<script>
export default {
    props: {
        String: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped>
.box {
    perspective: 120px;
}

.coin {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 4px solid #3cefff;
    animation: spin 1.5s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotateY(540deg);
    }
}
</style>
