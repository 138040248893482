// let { saveNum,smallHandle,longHandle,goodNumber } = require('h-token-staking/src/utils')
export default {
    Abbre(value) {
        return value.slice(2, 8).toUpperCase()
    },
    hideAddress(val) {
        if (!val) {
            return val
        }
        val += ''
        let reg = `^(.{${6}}).*(.{${4}})$`
        let patt = new RegExp(reg)
        return val.replace(patt, '$1...$2')
    },
    hideUrl(val) {
        if (!val) {
            return val
        }
        val += ''
        let reg = `^(.{${20}}).*(.{${6}})$`
        let patt = new RegExp(reg)
        return val.replace(patt, '$1...$2')
    },
    hideStr(val, len = 6) {
        if (!val) {
            return val
        }
        val += ''
        let reg = `^(.{${len}}).*(.{${len}})$`
        let patt = new RegExp(reg)
        return val.replace(patt, '$1...$2')
    },
    numberGrap(val) {
        if (val) {
            val = val + ''
        } else {
            return 0
        }
        // val = this.goodNumber(val)

        let float = val.split('.')[1]
        if (float && float.length > 18) {
            float = float.slice(0, 18)
        }
        val = val.split('.')[0]
        val = val.split('')
        val.reverse()
        val = val.join('')
        if (val.length > 3) {
            let last = ''
            if (val.length % 3 != 0) {
                last = val.slice(val.length - (val.length % 3))
                last = last.split('')
                last.reverse()
                last = last.join('')
            }
            let temp = val
                .match(/\w{3}/g)
                .map((item) => {
                    item = item.split('')
                    item.reverse()
                    item = item.join('')
                    return item
                })
                .reverse()
                .join(',')
            if (last) {
                return float ? `${last + ',' + temp}.${float}` : last + ',' + temp
            } else {
                return float ? `${temp}.${float}` : temp
            }
        } else {
            val = val.split('')
            val.reverse()
            return float ? `${val.join('')}.${float}` : val.join('')
        }
    },
    Omit(value, len = 6) {
        if ((value + '').length < 12) {
            return value
        } else {
            return value ? `${value.slice(0, len)}...${value.slice(value.length - (len - 1), value.length)}` : ''
        }
    },
}
