<template>
    <div class="assets">
        <Back :title="article.title"></Back>
        <div class="bg">
            <div class="wait" v-html="article.content"></div>
            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            article: {},
        }
    },
    computed: {},
    async created() {
        await this.init()
    },
    mounted() {},
    methods: {
        async init() {
            try {
                let id = this.$route.params.id
                let { dispatch, state } = this.$store
                await dispatch('articleGet', id)

                this.article = state.foot.article

                if (this.article.externalLinkFlag == 1) {
                    window.open(this.article.externalLink, '_blank')
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .wait {
            padding: 87px 15px 0;
            flex: 1;
            // display: flex;
            // align-items: flex-end;
            // justify-content: center;
            color: #ffffff;
            font-size: 14px;

            /deep/ img {
                max-width: 100%;
            }
        }
        .pageBottom {
            // flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
