<template>
    <div class="assets">
        <Back :title="langJson('视频介绍', 'inscdoge.txt.v5.96')"></Back>
        <div class="bg">
            <div class="list">
                <div class="item" v-for="(item, index) in list" :key="index + 'P'" @click="item.fun()" v-show="item.show">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    computed: {
        list() {
            try {
                return [
                    {
                        name: this.langJson('elon musk- inscribe and dogecoin', 'inscdoge.txt.v5.97'),
                        fun: () => {
                            try {
                                this.openUrl('/source/mp4/desc-EN.mov')
                                // let lang = localStorage.getItem('Language')
                                // if (lang.indexOf('zh') != -1) {
                                //     this.openUrl('/source/mp4/desc-EN.mp4')
                                // } else if (lang.indexOf('id') != -1) {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // } else if (lang.indexOf('ko') != -1) {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // } else {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // }
                            } catch (err) {
                                this.openUrl('/source/mp4/desc-EN.mov')
                            }
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('世界第一狗狗链铭文代币- inscribe', 'inscdoge.txt.v5.98'),
                        fun: () => {
                            try {
                                this.openUrl('/source/mp4/desc-CN.mp4')
                                // let lang = localStorage.getItem('Language')
                                // if (lang.indexOf('zh') != -1) {
                                //     this.openUrl('/source/mp4/desc-CN.mp4')
                                // } else if (lang.indexOf('id') != -1) {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // } else if (lang.indexOf('ko') != -1) {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // } else {
                                //     this.openUrl('/source/mp4/desc-EN.mov')
                                // }
                            } catch (err) {
                                this.openUrl('/source/mp4/desc-CN.mp4')
                            }
                        },
                        show: this.lang.indexOf('zh') != -1,
                    },
                ]
            } catch (error) {
                return []
            }
        },
    },
    watch: {},
    async created() {
        this.lang = localStorage.getItem('Language')
    },
    mounted() {},
    methods: {
        // changeItem(item) {
        //     this.jump_notice_detail(item.articleId)
        // },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            flex: 1;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            max-height: calc(100% - 87px);

            .item {
                width: 100%;
                box-sizing: border-box;
                padding: 10px 16px;
                // padding: 5px;
                display: flex;
                font-size: 14px;
                flex-direction: column;
                background-color: var(--bg11);
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                border-radius: 4px;
                margin-bottom: 12px;
            }
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
