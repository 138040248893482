<template>
    <div class="load-container">
        <div class="loader-bounce">
            <div class="a" :style="{ background: color2 }"></div>
            <div class="b" :style="{ background: color }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '#00adb5',
        },
        color2: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '#000000',
        },
    },
}
</script>

<style lang="scss" scoped>
.load-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: -16px;
    margin-left: -12px;
}

.loader-bounce {
    width: 50px;
    height: 50px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.a {
    width: 50px;
    height: 5px;
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
}
.b {
    width: 50px;
    height: 50px;
    animation: animate 0.5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow {
    0%,
    100% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1);
    }
}
</style>
