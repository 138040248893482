import http from "./base";

/**
 * 获取项目详情（预售项目ID=1）
 * 
 */
let projectInfo = id => {
    return http.get(`/project/info/${id}`);
};


/**
 * 判断 是否 可以购买
 * projectId=1&amount=666
 */
let verifyParam = (params) => {
    return http.post('/project/verifyParam', params);
};

/**
 * 账户信息
 * 用户账户列表，1-正常账户，2-锁仓账户，3-收益账户
 */
let accountList = (params) => {
    return http.get('/user/account', params);
};


/**
 * 
 * 
 */
let symbolQuote = (params) => {
    return http.get('/symbol/insQuote', params);
};


export default {
    projectInfo,
    verifyParam,
    accountList,
    symbolQuote
}