import api from '@/api/personal'
import tools from '@/mixin/tools'
const personalModule = {
    state: {
        // 非当前用户的用户信息
        visitDetail: {},
        // 用户信息
        information: {},
        // 用户所属nft列表
        collected: [],
        // 用户创建nft列表
        created: [],
        // 用户收藏列表
        favorited: [],
        // 用户推荐列表
        recommend: [],
        // 用户拍卖获胜记录
        winning: [],
        // 用户nft交易记录
        activity: [],
        // 用户收到的出价记录
        offersReceived: [],
        // 用户发起的出价记录
        offersMade: [],
        // 版税记录
        royalty: [],
    },
    getters: {},
    mutations: {
        offersMade(state, offersMade) {
            state.offersMade = offersMade
        },
        offersReceived(state, offersReceived) {
            state.offersReceived = offersReceived
        },
        activity(state, activity) {
            state.activity = activity
        },
        winning(state, winning) {
            state.winning = winning
        },
        recommend(state, recommend) {
            state.recommend = recommend
        },
        favorited(state, favorited) {
            state.favorited = favorited
        },
        created(state, created) {
            state.created = created
        },
        collected(state, collected) {
            state.collected = collected
        },
        information(state, information) {
            if (information) {
                localStorage.setItem('information', JSON.stringify(information))
            }
            state.information = information
        },
        visitDetail(state, visitDetail) {
            state.visitDetail = visitDetail
        },
        setRoyalties(state, royalty) {
            state.royalty = royalty
        },
    },
    actions: {
        async getAwardsAction(context, params) {
            try {
                let result = await api.getAwards(params)
                result = this.apiResult(result)
                context.commit('setRoyalties', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                return false
            }
        },
        // 上架nft
        async nftSale(context, params) {
            try {
                // 请求接口
                let result = await api.nftSale(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户发起的出价记录
        async offersMadeGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalOffersMade(params)
                result = this.apiResult(result)
                context.commit('offersMade', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户收到的出价记录
        async offersReceivedGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalOffersReceived(params)
                result = this.apiResult(result)
                context.commit('offersReceived', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户nft交易记录
        async activityGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalActivity(params)
                result = this.apiResult(result)
                context.commit('activity', result.data.records)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户拍卖获胜记录
        async winningGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalWinning(params)
                result = this.apiResult(result)
                context.commit('winning', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户收藏列表
        async recommendGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalRecommend(params)
                result = this.apiResult(result)
                context.commit('recommend', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 用户收藏列表
        async favoritedGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalFavorited(params)
                result = this.apiResult(result)
                context.commit('favorited', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取用户创建nft列表
        async createdGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalCreated(params)
                result = this.apiResult(result)
                context.commit('created', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取用户所属nft列表
        async collectedGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalCollected(params)
                result = this.apiResult(result)
                context.commit('collected', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取用户信息
        async personalGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalInformation(params)
                result = this.apiResult(result)
                if (result&&result.data) {
                    context.commit('information', result.data)
                }

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 个人中心通过地址获取用户信息
        async visitGet(context, params) {
            try {
                // 请求接口
                let result = await api.personalInformation(params)
                result = this.apiResult(result)
                result.data && context.commit('visitDetail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default personalModule
