import axios from 'axios'
import Qs from 'qs'
import { getCookie, removeCookie, setCookie } from './cookie'
let langs = localStorage.getItem('Language')

let getParams = () => {
        let temp = window.location.href
        let obj = {}
        if (temp.indexOf('?') != -1) {
            let str = temp.split('?')[temp.split('?').length - 1]
            if (!str) {
                return
            }
            str = str.split('&')

            str.forEach((item) => {
                let tempStr = item.split('=')
                obj[tempStr[0]] = tempStr[1]
            })
        }
        return obj
    }
    // 请求头
const formatHeaders = (acHeaders) => {
    let headers = {}
    headers.Language = getParams().lang ? getParams().lang : langs
    headers['Authorization'] = getCookie('token') ? `Bearer ${getCookie('token')}` : ''

    if (acHeaders) {
        headers = {...headers, ...acHeaders }
    }
    return headers
}

let lock = {
    url: '',
    time: 0,
}
const http = ({ url, headers, params, method, hostType, isJson }, cancelToken) => {
    let prefix = ''
    switch (hostType) {
        case 'otc':
            prefix = '/fe-otc-api'
            break
        case 'co':
            prefix = '/fe-co-api'
            break
        case 'upload':
            prefix = '/fe-upload-api'
            break
        case 'def':
            prefix = ''
            break
        case 'exchange':
            prefix = '/web'
            break
        default:
            prefix = '/api' // 默认
    }
    const qsParams = method.toUpperCase() === 'POST' ? (isJson ? JSON.stringify(params) : Qs.stringify(params)) : params
    return new Promise((resolve, reject) => {
        axios(
                method.toUpperCase() === 'POST' ? {
                    url: `${prefix}${url}`,
                    headers: formatHeaders(headers),
                    data: qsParams,

                    method: method || 'post',
                    cancelToken: cancelToken,
                } : {
                    url: `${prefix}${url}`,
                    headers: formatHeaders(headers),

                    params: qsParams,
                    method: method || 'post',
                    cancelToken: cancelToken,
                }
            )
            .then((data) => {
                resolve(data.data)
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    // 处理错误
                    reject(err)
                    throw new Error(`Error:${err}`)
                }
            })
    })
}







// const http = async ({ url, headers, params, method, hostType, isJson }, cancelToken) => {
//     let prefix = ''
//     switch (hostType) {
//         case 'otc':
//             prefix = '/fe-otc-api'
//             break
//         case 'co':
//             prefix = '/fe-co-api'
//             break
//         case 'upload':
//             prefix = '/fe-upload-api'
//             break
//         case 'def':
//             prefix = ''
//             break
//         case 'exchange':
//             prefix = '/web'
//             break
//         default:
//             prefix = '/api' // 默认
//     }
//     try{
//         await loadScript('qs')
//         await loadScript('axios')
//         const qsParams = method.toUpperCase() === 'POST' ? (isJson ? JSON.stringify(params) : Qs.stringify(params)) : params

//         let result = await axios(method.toUpperCase() === 'POST' ? {
//                 url: `${prefix}${url}`,
//                 headers: formatHeaders(headers),
//                 data: qsParams,

//                 method: method || 'post',
//                 cancelToken: cancelToken,
//             } : {
//                 url: `${prefix}${url}`,
//                 headers: formatHeaders(headers),

//                 params: qsParams,
//                 method: method || 'post',
//                 cancelToken: cancelToken,
//             }
//         )
//         return result.data
//     }catch(err){
//         if (axios.isCancel(err)) {
//             console.log('Request canceled', err.message)
//         } else {
//             // 处理错误
//             console.log(err)
//             throw new Error(`Error:${err}`)
//         }
//     }


// }
export default http