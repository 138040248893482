<template>
    <div>
        <div v-for="(item, index) in list" :key="item.id" :class="['filter_item']">
            <TreeItem :item="item" @click.stop.native="typeFilter(item, index)" />
            <div class="item_main" v-show="item.show">
                <template v-if="item.children && item.children.length">
                    <TreeList :list="item.children" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TreeList',
    props: ['list'],
    data() {
        return {}
    },
    created() {},
    methods: {
        async init(item) {
            try {
                this.$set(item, 'loading', true)
                let { dispatch } = this.$store
                let res = await dispatch('inviteTree', { parentId: item.id })
                let list = res.data
                this.$set(item, 'loading', false)
                this.$set(item, 'children', list)
            } catch (err) {
                console.log(err)
            }
        },
        typeFilter(item) {
            if (!(item.directNum > 0)) {
                return
            }
            if (!item.show && !(item.children && item.children.length) && item.directNum > 0) {
                this.init(item)
            }
            this.$set(item, 'show', !item.show)
        },
    },
}
</script>
<style lang="scss" scoped>
.filter_item {
    .item_main {
        height: auto;
        overflow: visible;
        padding: 0px 32px;
        padding-right: 0;

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 32px;

            .item_title {
                color: var(--font2);
                font-size: 14px;

                line-height: 16px;
            }
            .item_check {
                width: 12px;
                height: 12px;
                border-radius: 2px;
                border: 1px solid var(--border2);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.5;
            }
        }
    }
    &:last-child {
        .item_main {
            border-bottom: none;
        }
    }
}
</style>
