<template>
    <Model v-model="show" @mask="close()">
        <div class="model_main" slot="main">
            <div class="title">{{ langJson('Withdraw', 'inscdoge.txt.v3.1') }}</div>
            <div class="line"></div>

            <div class="tab">
                <div class="t">
                    {{ langJson('提现币种', 'inscdoge.txt.v5.75') }}
                </div>
                <Tab :step="step" :list="tabList" @change="tabHand" />
            </div>

            <div class="bar">
                <div class="key">
                    {{ langJson('可用余额', 'inscdoge.txt.v1.121') }}
                </div>
                <div class="val" @click="maxHand()">
                    {{ saveNum(max || '0', insPrecision) | numberGrap }}
                </div>
            </div>

            <!--  :class="step == 0 && 'select'" -->
            <div class="information_item SelectDown">
                <input v-model="form.amount" type="text" :placeholder="langJson('Amount', 'inscdoge.txt.v3.13')" />

                <!-- v-show="step == 0" -->
                <div class="select">
                    <!-- <div class="selectBg"></div> -->
                    <SelectDown bgc="#000000" ref="SelectCoin" :list="trx_coin_l" @select_detail="select_detail" height="36px" />
                </div>
                <!-- <div class="INS">{{ currency_details.name }}</div> -->
            </div>
            <div class="red" v-show="this.form.amount * 1 > this.max * 1">{{ langJson('余额不足', 'inscdoge.txt.v5.61') }}</div>

            <div class="list_rate">
                <div class="item">
                    <div class="key">{{ langJson('Exchange Rate', 'inscdoge.txt.v5.50') }}</div>
                    <div class="val">
                        <!-- 1.trx时 两种 币种对USDT 的汇率不一样 -->
                        <!-- 2.doge时 ins 的汇率为 1:1 -->
                        <template>1 {{ currency_details.name }} ≈ {{ price | numberGrap }} {{ getCoinName }}</template>
                    </div>
                </div>

                <div class="item" v-show="(step == 0 && currency_details.name == 'INS') || step == 2">
                    <div class="key">{{ langJson('总计', 'inscdoge.txt.v5.74') }}</div>
                    <div class="val rate">{{ totalPrice }}</div>
                </div>

                <div class="item" v-show="step == 1">
                    <div class="key">{{ langJson('Handlng fee', 'inscdoge.txt.v1.10') }}</div>
                    <div class="val rate">{{ step == 1 ? withdrawFee.withdrawFeeRate : withdrawFee.withdrawFeeTrxRate || 0 }}%</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Fee Amount', 'inscdoge.txt.v5.51') }}</div>
                    <div class="val">
                        {{ feeAmount | numberGrap }} {{ getCoinName }}
                        <!-- currency_details.name -->
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Receive Amount', 'inscdoge.txt.v5.52') }}</div>
                    <div class="val">{{ getAmount | numberGrap }} {{ getCoinName }}</div>
                </div>
            </div>

            <input v-model="form.address" type="text" class="information_item" :placeholder="step == 1 ? langJson('Withdrawal address', 'inscdoge.txt.v3.29') : langJson('请输入 Trxchain 钱包地址', 'inscdoge.txt.v5.53')" :disabled="step == 0 || step == 2" />

            <!-- <div class="desc">{{ langJson('Withdrawal fee = ', 'inscdoge.txt.v3.30') }}{{ step == 1 ? withdrawFee.withdrawFeeRate : withdrawFee.withdrawFeeTrxRate || 0 }}% {{ langJson('/transaction Withdrawals will arrive within 24~48 hours', 'inscdoge.txt.v3.31') }}</div> -->
            <div class="desc_t">
                <template v-if="step == 0 || step == 2">{{ langJson('请确认 Trxchain 钱包地址，地址错误资产将无法找回', 'inscdoge.txt.v5.56') }}</template>
                <template v-if="step == 1">{{ langJson('请确认 Dogechain 钱包地址，地址错误资产将无法找回', 'inscdoge.txt.v5.57') }}</template>
            </div>

            <BTN class="model_btns" type="orange" :disable="!pass" @click="withdrawHand" :load="loading">{{ langJson('Withdraw', 'inscdoge.txt.v3.1') }}</BTN>
        </div>
    </Model>
</template>

<script>
import tools from '@/mixin/tools'
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ins_usdt: false, // ins 转 usdt 开关

            currency_details: {},
            temp_address_dog: '',
            temp_address_trx: '',
            insPrecision: '6',
            loading: false,
            form: {
                coinId: '',
                amount: '',
                address: '',
                type: 0, // 0提现到doge上的ins 1提现到trx上的ins
                //type 0:本币种到本币种 1：本币种到USDT 2：本币种到TRX
            },
            step: 0,
            tabList: [{ name: 'USDT' }, { name: 'INS' }, { name: 'TRX' }],
            // tabList: [{ name: this.langJson('Trxchain', 'inscdoge.txt.v5.55') }, { name: this.langJson('Dogechain', 'inscdoge.txt.v5.54') }],
        }
    },
    computed: {
        getCoinName() {
            try {
                if (this.step == 0) {
                    return 'USDT'
                }
                if (this.step == 1) {
                    return 'INS'
                }
                if (this.step == 2) {
                    return 'TRX'
                }
                return ''
            } catch (err) {
                console.log(err)
                return 'USDT'
            }
        },
        getAmount() {
            try {
                //
                let temp = this.reduce(this.form.amount || 0, this.feeAmount)
                let num = this.saveNum(this.multiplication(temp, this.price), this.insPrecision)

                if (this.step == 0 && this.currency_details.name == 'INS') {
                    // this.symbolQuote.rate
                    let res = this.multiplication(this.feeAmount || 0, this.symbolQuote.rate || 0)
                    res = this.reduce(this.form.amount || 0, res || 0)
                    num = this.division(res || 0, this.symbolQuote.rate || 0)
                    // num = this.reduce(num || 0, this.feeAmount)
                }

                if (this.step == 2) {
                    if (this.currency_details.name == 'USDT') {
                        let res = this.multiplication(this.feeAmount || 0, this.symbolQuote.trxUsdtRate || 0) // usdt

                        res = this.reduce(this.form.amount || 0, res || 0)

                        num = this.division(res || 0, this.symbolQuote.trxUsdtRate || 0)

                        // let trxAmount = this.division(this.form.amount || 0, this.symbolQuote.trxUsdtRate || 0)
                        // num = this.reduce(trxAmount || 0, this.withdrawFee.withdrawFeeTrx || 0)
                    }
                    if (this.currency_details.name == 'INS') {
                        let res = this.multiplication(this.feeAmount || 0, this.symbolQuote.trxInsRate || 0) // 得到 ins

                        res = this.reduce(this.form.amount || 0, res || 0)
                        // console.log('扣 ins,得到 ins', JSON.stringify(res))
                        num = this.division(res || 0, this.symbolQuote.trxInsRate || 0) // 得到 usdt

                        //
                        // let usdtAmount = this.division(this.form.amount || 0, this.symbolQuote.rate || 0)
                        // let trxAmount = this.division(usdtAmount || 0, this.symbolQuote.trxUsdtRate || 0)
                        // num = this.reduce(trxAmount || 0, this.withdrawFee.withdrawFeeTrx || 0)
                    }
                }

                if (num * 1 < 0) {
                    num = 0
                }
                return this.saveNum(num, this.insPrecision)
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        feeAmount() {
            try {
                let num
                if (this.step == 1) {
                    let temp = this.division(this.withdrawFee.withdrawFeeRate || 0, 100)
                    num = this.multiplication(this.form.amount || 0, temp)
                }
                if (this.step == 0) {
                    num = this.withdrawFee.withdrawFeeTrxRate
                }
                if (this.step == 2) {
                    let trxFee = this.division(this.withdrawFee.withdrawFeeTrx || 0, this.symbolQuote.trxUsdtRate || 0)
                    num = trxFee
                }
                return this.saveNum(num, this.insPrecision)
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        // 总计
        totalPrice() {
            try {
                if (this.step == 0) {
                    if (this.currency_details.name == 'USDT') {
                        return 1
                    }
                    if (this.currency_details.name == 'INS') {
                        return `${this.saveNum(this.division(this.form.amount || 0, this.symbolQuote.rate || 0))} USDT`
                    }
                    return 0
                }
                if (this.step == 2) {
                    if (this.currency_details.name == 'USDT') {
                        let trxAmount = this.division(this.form.amount || 0, this.symbolQuote.trxUsdtRate || 0)
                        return `${this.saveNum(trxAmount)} TRX`
                    }
                    if (this.currency_details.name == 'INS') {
                        let trxAmount = this.division(this.form.amount || 0, this.symbolQuote.trxInsRate || 0)
                        return `${this.saveNum(trxAmount)} TRX`
                    }
                }
                return 0
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        // 1xx = 价格
        price() {
            try {
                if (this.step == 1) {
                    return 1
                }
                if (this.step == 0) {
                    if (this.currency_details.name == 'USDT') {
                        return 1
                    }
                    if (this.currency_details.name == 'INS') {
                        return this.saveNum(this.setAccuracy(this.division(1, this.symbolQuote.rate), 'USDT'))
                    }
                    return 0
                }
                if (this.step == 2) {
                    if (this.currency_details.name == 'USDT') {
                        let trxRate = this.division(1, this.symbolQuote.trxUsdtRate || 0)
                        return this.saveNum(trxRate)
                    }
                    //  1ins=        1usdt = 1/0.14
                    if (this.currency_details.name == 'INS') {
                        let trxRate = this.division(1, this.symbolQuote.trxInsRate || 0)
                        return this.saveNum(trxRate)
                    }
                }
                return 0
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        trx_coin_l() {
            try {
                let res = this.chainSymbol.map((item) => {
                    return { ...item, name: item.name, key: item.id }
                })
                res = res.filter((item) => {
                    if (this.step == 0) {
                        if (this.withdrawFee.withdrawInsUsdtSwitch && this.withdrawFee.withdrawUsdtSwitch) {
                            return true
                        }
                        if (this.withdrawFee.withdrawUsdtSwitch) {
                            return item.name == 'USDT'
                        }
                        if (this.withdrawFee.withdrawInsUsdtSwitch) {
                            return item.name == 'INS'
                        }
                        return false
                    }

                    if (this.step == 1) {
                        // this.withdrawFee.withdrawInsSwitch
                        return item.name == 'INS'
                    }
                    if (this.step == 2) {
                        // this.withdrawFee.withdrawInsSwitch
                        if (this.withdrawFee.withdrawInsTrxSwitch && this.withdrawFee.withdrawUsdtTrxSwitch) {
                            return true
                        }
                        if (this.withdrawFee.withdrawUsdtTrxSwitch) {
                            return item.name == 'USDT'
                        }
                        if (this.withdrawFee.withdrawInsTrxSwitch) {
                            return item.name == 'INS'
                        }
                        return false
                    }
                })
                // if (this.step == 0) {
                res = res.sort((item, item2) => {
                    if (this.withdrawFee.withdrawInsUsdtSwitch && this.withdrawFee.withdrawUsdtSwitch) {
                        return item.name[0].localeCompare(item2.name[0])
                    } else if (this.withdrawFee.withdrawInsUsdtSwitch) {
                        // console.log(1)
                        return item.name == 'INS'
                    } else if (this.withdrawFee.withdrawUsdtSwitch) {
                        // console.log(2)
                        return item.name == 'USDT'
                    }
                })
                // }
                // console.log('res', res)
                return res
            } catch (err) {
                console.log(err)
                return []
            }
        },
        max() {
            try {
                let max
                if (this.currency_details.name == 'USDT') {
                    max = this.pledgeAssets.usdtAccount
                }
                if (this.currency_details.name == 'INS') {
                    max = this.pledgeAssets.incomeAccount
                }

                return max
            } catch (err) {
                console.log('err', err)
                return '0'
            }
        },
        pass() {
            try {
                return this.checkPass(this.form.amount, ['unull'], false) && this.checkPass(this.form.address, ['unull'], false) && this.getAmount > 0 && this.form.amount * 1 <= this.max * 1
            } catch (err) {
                console.log('err', err)
                return false
            }
        },
    },
    watch: {
        'form.amount'() {
            //
            try {
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    if (this.form.amount != '') {
                        this.$set(this.form, 'amount', this.saveNum(this.form.amount, 6))
                    }
                    clearTimeout(this.timer)
                }, 300)
            } catch (err) {
                console.log('err', err)
                // return false
            }
        },
        show(a) {
            if (a) {
                // 提交登录
                this.$set(this.form, 'address', this.walletDetail.address)
                tools.setCookie('withdrawAddressTrx', this.form.address)

                if (this.withdrawFee.withdrawUsdtSwitch || this.withdrawFee.withdrawInsUsdtSwitch) {
                    this.step = 0
                    return
                    // let symbols = this.chainSymbol.map((item) => {
                    //     return { ...item, name: item.name, key: item.id }
                    // })
                    // let usdt = symbols.find((item) => {
                    //     return item.name == 'USDT'
                    // })
                    // let ins = symbols.find((item) => {
                    //     return item.name == 'INS'
                    // })
                    // if (this.withdrawFee.withdrawInsUsdtSwitch && ins) {
                    //     this.$refs.SelectCoin.select(ins)
                    // } else if (this.withdrawFee.withdrawUsdtSwitch && usdt) {
                    //     this.$refs.SelectCoin.select(usdt)
                    // }
                }
                if (this.withdrawFee.withdrawInsSwitch) {
                    this.step = 1
                    return
                }
                if (this.withdrawFee.withdrawInsTrxSwitch || this.withdrawFee.withdrawUsdtTrxSwitch) {
                    this.step = 2
                    return
                }
            }
        },
    },
    created() {},
    mounted() {
        this.$bus.$off('ins_usdt_sure')
        this.$bus.$on('ins_usdt_sure', () => {
            this.sureHand()
        })
    },
    methods: {
        maxHand() {
            this.$set(this.form, 'amount', this.max)
        },
        select_detail(select) {
            // if (!this.withdrawFee.withdrawUsdtSwitch && this.step == 0 && select.name == 'USDT') {
            //     this.pushMsg('warning', this.langJson('暂未开启 usdt=>usdt', 'inscdoge.txt.v5.70'))

            //     let symbols = this.chainSymbol.map((item) => {
            //         return { ...item, name: item.name, key: item.id }
            //     })
            //     let ins = symbols.find((item) => {
            //         return item.name == 'INS'
            //     })
            //     if (this.step == 0 && ins) {
            //         this.$refs.SelectCoin.select(ins)
            //     }
            //     return
            // }

            // if (!this.withdrawFee.withdrawInsUsdtSwitch && this.step == 0 && select.name == 'INS') {
            //     this.pushMsg('warning', this.langJson('暂未开启 ins=>usdt', 'inscdoge.txt.v5.72'))

            //     let symbols = this.chainSymbol.map((item) => {
            //         return { ...item, name: item.name, key: item.id }
            //     })
            //     let usdt = symbols.find((item) => {
            //         return item.name == 'USDT'
            //     })

            //     if (this.step == 0 && usdt) {
            //         this.$refs.SelectCoin.select(usdt)
            //     }
            //     return
            // }
            if (select.key != this.form.coinId) {
                this.currency_details = select
                this.$set(this.form, 'coinId', select.key)
            }
        },
        setAccuracy(value, name, long = 4) {
            let len = String(value).split('.')
            let text

            if (len.length != 0 && len.length == 2) {
                text = len[1]

                let item = this.chainSymbol.find((item) => {
                    return item.name == name
                })
                let length = item && item.precision ? item.precision : long

                len[1] = text.slice(0, length)
                text = len.join('.')
                return text
            }
            return value
        },
        tabHand(step) {
            if (!this.withdrawFee.withdrawInsUsdtSwitch && !this.withdrawFee.withdrawUsdtSwitch && step == 0) {
                // this.pushMsg('warning', this.langJson('暂未开启 usdt=>usdt 和 ins=>usdt', 'inscdoge.txt.v5.73'))
                return
            }
            if (!this.withdrawFee.withdrawInsSwitch && step == 1) {
                // this.pushMsg('warning', this.langJson('暂未开启 ins=>ins', 'inscdoge.txt.v5.69'))
                return
            }

            if (!this.withdrawFee.withdrawInsTrxSwitch && !this.withdrawFee.withdrawUsdtTrxSwitch && step == 2) {
                // this.pushMsg('warning', this.langJson('暂未开启 usdt=>usdt 和 ins=>usdt', 'inscdoge.txt.v5.73'))
                return
            }

            // if (!this.withdrawFee.withdrawUsdtSwitch && step == 0) {
            //     this.pushMsg('warning', this.langJson('暂未开启 usdt=>usdt', 'inscdoge.txt.v5.70'))
            //     return
            // }
            // if (!this.withdrawFee.withdrawInsUsdtSwitch && step == 0) {
            //     this.pushMsg('warning', this.langJson('暂未开启 ins=>usdt', 'inscdoge.txt.v5.72'))
            //     return
            // }
            // if (this.step == 0 && step == 1 && this.withdrawFee.withdrawInsSwitch) {
            //     if (this.currency_details.name == 'USDT') {
            //         this.pushMsg('warning', this.langJson('USDT 不支持提现至 INS', 'inscdoge.txt.v5.59'))
            //         return
            //     }
            // }
            let symbols = this.chainSymbol.map((item) => {
                return { ...item, name: item.name, key: item.id }
            })
            let usdt = symbols.find((item) => {
                return item.name == 'USDT'
            })
            let ins = symbols.find((item) => {
                return item.name == 'INS'
            })
            this.step = step

            if (step == 0) {
                if (this.withdrawFee.withdrawInsUsdtSwitch && ins) {
                    this.$refs.SelectCoin.select(ins)
                } else if (this.withdrawFee.withdrawUsdtSwitch && usdt) {
                    this.$refs.SelectCoin.select(usdt)
                }
            } else if (step == 1 && ins) {
                this.$refs.SelectCoin.select(ins)
            } else if (step == 2) {
                if (this.withdrawFee.withdrawInsTrxSwitch && ins) {
                    this.$refs.SelectCoin.select(ins)
                } else if (this.withdrawFee.withdrawUsdtTrxSwitch && usdt) {
                    this.$refs.SelectCoin.select(usdt)
                }
            }

            let address
            if (this.step == 1) {
                address = tools.getCookie('withdrawAddress')
                this.temp_address_trx = this.form.address
            }
            if (this.step == 0 || this.step == 2) {
                address = tools.getCookie('withdrawAddressTrx')
                this.temp_address_dog = this.form.address
            }
            if (address) {
                this.$set(this.form, 'address', address)
            } else {
                if (this.step == 1 || this.step == 2) {
                    this.$set(this.form, 'address', this.temp_address_dog)
                }
                if (this.step == 0) {
                    this.$set(this.form, 'address', this.temp_address_trx)
                }
            }
        },
        close() {
            this.$set(this.form, 'coinId', '')
            this.$set(this.form, 'amount', '')
            this.$set(this.form, 'address', '')
            this.temp_address_dog = ''
            this.temp_address_trx = ''
            this.$store.commit('withdrawModel', false)
            this.step = 0
        },
        async withdrawHand() {
            if (this.step == 0 && this.currency_details.name == 'USDT') {
                if (this.form.amount * 1 < this.withdrawFee.withdrawUsdtMin * 1) {
                    this.pushMsg('warning', this.langJson('提USDT不能小于', 'inscdoge.txt.v5.71') + this.withdrawFee.withdrawUsdtMin)
                    return
                }
            }
            if (this.step == 0 && this.currency_details.name == 'INS') {
                if (this.division(this.form.amount || 0, this.symbolQuote.rate || 0) < this.withdrawFee.withdrawUsdtMin * 1) {
                    this.pushMsg('warning', this.langJson('提USDT不能小于', 'inscdoge.txt.v5.71') + this.withdrawFee.withdrawUsdtMin)
                    return
                }
            }

            if (this.step == 2 && this.currency_details.name == 'USDT') {
                // 算出总计
                let trxAmount = this.division(this.form.amount || 0, this.symbolQuote.trxUsdtRate || 0)

                // this.symbolQuote.trxUsdtRate

                let withdrawTrxMin = this.division(this.withdrawFee.withdrawTrxMin || 0, this.symbolQuote.trxUsdtRate || 0)

                if (trxAmount < withdrawTrxMin * 1) {
                    this.pushMsg('warning', this.langJson('提TRX不能小于', 'inscdoge.txt.v5.80') + this.withdrawFee.withdrawTrxMin + 'USDT' + '≈(' + this.saveNum(withdrawTrxMin) + 'TRX)')
                    return
                }
            }
            if (this.step == 2 && this.currency_details.name == 'INS') {
                // 算出总计
                let trxAmount = this.division(this.form.amount || 0, this.symbolQuote.trxInsRate || 0)

                let withdrawTrxMin = this.division(this.withdrawFee.withdrawTrxMin || 0, this.symbolQuote.trxUsdtRate || 0)

                if (trxAmount < withdrawTrxMin * 1) {
                    this.pushMsg('warning', this.langJson('提TRX不能小于', 'inscdoge.txt.v5.80') + this.withdrawFee.withdrawTrxMin + 'USDT' + '≈(' + this.saveNum(withdrawTrxMin) + 'TRX)')
                    return
                }
            }

            // if (this.form.amount * 1 > this.pledgeAssets.normalAccount * 1) {
            //     this.pushMsg('warning', this.langJson('Insufficient quantity', 'inscdoge.txt.v5.29'))
            //     return
            // }
            console.log('this.pass', this.pass)
            if (this.pass) {
                // coinId amount address

                try {
                    // console.log('this.walletDetail.chainDetail.id', this.walletDetail.chainDetail.id)
                    // console.log('chainSymbol', this.chainSymbol)
                    // let INS = this.chainSymbol.find((item) => {
                    //     return item.name == 'INS'
                    // })
                    // console.log('INS', INS)
                    // if (this.step == 1) {
                    //     this.form.coinId = INS.id
                    // }

                    // 得到usdt (trx链)
                    if (this.step == 0) {
                        if (this.currency_details.name == 'INS') {
                            this.form.type = 1
                        }
                        if (this.currency_details.name == 'USDT') {
                            this.form.type = 2
                        }
                    }
                    // 得到ins (dot链)
                    if (this.step == 1) {
                        this.form.type = 0
                    }
                    // 得到trx (trx链)
                    if (this.step == 2) {
                        if (this.currency_details.name == 'INS') {
                            this.form.type = 3
                        }
                        if (this.currency_details.name == 'USDT') {
                            this.form.type = 4
                        }
                    }

                    if (this.form.type == 1) {
                        this.$bus.$emit('ins_usdt_tip', { amount: this.form.amount + ' INS', usdt: this.saveNum(this.division(this.form.amount || 0, this.symbolQuote.rate || 0)) + ' USDT', rate: `1 ${this.currency_details.name} ≈ ${this.numberGrapNow(this.price)} ${this.getCoinName}`, fee: `${this.numberGrapNow(this.feeAmount)} ${this.getCoinName}`, getAmount: `${this.numberGrapNow(this.getAmount)} ${this.getCoinName}`, address: `${this.form.address}` })
                        this.$store.commit('withdrawModel_ins_usdt_tip', true)
                        return
                    }
                    await this.sureHand()
                } catch (err) {
                    console.log(err)
                }
            }
        },
        async sureHand() {
            try {
                this.loading = true
                let { dispatch } = this.$store

                let trimStr = this.form.address.trim()
                this.$set(this.form, 'address', trimStr)

                this.$set(this.form, 'amount', this.saveNum(this.form.amount, 6))

                let result = await dispatch('withdraw_apply', this.form)
                this.loading = false
                if (result) {
                    this.pushMsg('success', this.langJson('Withdrawal application submitted successfully', 'inscdoge.txt.v3.32'))
                    if (this.step == 1) {
                        tools.setCookie('withdrawAddress', this.form.address)
                    }
                    this.close()
                    await dispatch('pledgeAssets')
                }
            } catch (error) {}
        },
        numberGrapNow(val) {
            if (val) {
                val = val + ''
            } else {
                return 0
            }
            // val = this.goodNumber(val)

            let float = val.split('.')[1]
            if (float && float.length > 18) {
                float = float.slice(0, 18)
            }
            val = val.split('.')[0]
            val = val.split('')
            val.reverse()
            val = val.join('')
            if (val.length > 3) {
                let last = ''
                if (val.length % 3 != 0) {
                    last = val.slice(val.length - (val.length % 3))
                    last = last.split('')
                    last.reverse()
                    last = last.join('')
                }
                let temp = val
                    .match(/\w{3}/g)
                    .map((item) => {
                        item = item.split('')
                        item.reverse()
                        item = item.join('')
                        return item
                    })
                    .reverse()
                    .join(',')
                if (last) {
                    return float ? `${last + ',' + temp}.${float}` : last + ',' + temp
                } else {
                    return float ? `${temp}.${float}` : temp
                }
            } else {
                val = val.split('')
                val.reverse()
                return float ? `${val.join('')}.${float}` : val.join('')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.select {
    /* position: relative; */
    /* margin: 0 auto; */
    width: 90px;
    height: 100%;
    height: 36px;
    /* margin-top: 27px; */

    .selectBg {
        left: 4px;
        top: -2.5px;
        position: absolute;
        width: 20.81px;
        height: 16.51px;
        /* background-image: url('/public/source/svg/discord_black.svg'); */
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    /deep/ {
        .NFTSortBtn {
            .NFTSortBtnOpen {
                .selectName {
                    text-align: right;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 800;
                    line-height: 20px;
                    background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .NFTSortBtnList {
                .NFTSortBtnList2 {
                    .listItem {
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 800;
                        /* background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent; */
                    }
                }
            }
        }
    }
}

.model_main {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;

    .title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        text-align: center;
    }

    .line {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        background-color: #2a2a2a;
    }

    .bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        /* margin-bottom: 11px; */
        .key {
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #ffffffcc;
            /* color: #ffffff90; */
        }

        .val {
            text-align: right;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #878e95ee;
            /* color: #ffffff90; */
        }
    }

    .information_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;
        padding: 0 16px;
        width: 100%;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        height: 36px;
        /* padding: 8px, 16px; */
        border-radius: 10px;
        background-color: #000000;
        color: #ffffff;
        /* margin-top: 6px; */
        margin-top: 15px;
        transition: all 0s !important;

        &.SelectDown {
            padding-right: 0px;
            margin-top: 8px;
        }

        &::placeholder {
            color: #727272;
        }

        input {
            flex: 1;
            color: #ffffff;
            height: 100%;
            padding-right: 16px;
        }

        .INS {
            /* position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0%, -50%); */

            //styleName: XB 12;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 800;
            line-height: 20px;
            background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .tab {
        margin-top: 15px;
        .t {
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #ffffffcc;
            margin-bottom: 15px;
            /* color: #ffffff90; */
        }
    }

    .list_rate {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;

            .key {
                font-size: 12px;
                color: #878e95;
            }

            .val {
                font-size: 12px;
                color: #878e95;

                &.rate {
                    color: #24ae8f;
                }
            }
        }
    }

    .red {
        margin: 0 auto;
        margin-top: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        /* color: #cccccc; */
        color: red;
    }

    .desc_t {
        margin: 0 auto;
        margin-top: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        /* color: #cccccc; */
        color: red;
    }

    .desc {
        margin: 0 auto;
        margin-top: 15px;
        width: 68%;
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;

        color: #cccccc;
    }

    .model_btns {
        margin-top: 15px;
    }
}

@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
