import { BigNumber } from 'bignumber.js'
// let { saveNum, smallHandle, longHandle, goodNumber } = require('h-token-staking/src/utils')
export default {
    openLink(url) {
        window.open(url)
    },
    //月份加减
    computeMonths(yearMonthDay, monthNum) {
        try {
            let isSlash = yearMonthDay.indexOf('/') != -1
            let arr
            isSlash ? (arr = yearMonthDay.split('/')) : (arr = yearMonthDay.split('-'))
            let year = parseInt(arr[0])
            let month = parseInt(arr[1])
            let day = parseInt(arr[2])
            month = month * 1 + monthNum * 1
            let yearNum
            if (month > 12) {
                yearNum = parseInt((month - 1) / 12)
                month = month % 12 == 0 ? 12 : month % 12
                year += yearNum
            } else if (month <= 0) {
                month = Math.abs(month)
                yearNum = parseInt((month + 12) / 12)
                year -= yearNum
            }
            month = month < 10 ? '0' + month : month
            let temp = new Date(year, month, 0).getTime()
            let time = this.timeStr(temp).slice(0, 10)
            let newArr = time.split('/')
            let newDay = parseInt(newArr[2])
            if (day < newDay) {
                newArr[2] = day
                time = newArr.join('/')
            }
            return time
        } catch (error) {
            console.log(error)
        }
    },
    toSmall(amount, precision) {
        return this.division(amount, Math.pow(10, precision))
    },
    saveNum(n, l = 6) {
        n = this.goodNumber(n)
        n = '' + n
        if (n.indexOf('.') == -1) {
            // n = n + '.' + new Array(l).fill('').join('')
        } else {
            let h = n.split('.')[0]
            let e = n.split('.')[1]
            if (e.length >= l) {
                e = e.substr(0, l)
            } else {
                e = e + new Array(l - e.length).fill('').join('')
                // e = e + new Array(l - e.length).fill(0).join('')
            }
            n = h + '.' + e
        }

        return n
        // try {
        //     //去零
        //     let num = n - 0 // 会变成科学计数法
        //     var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
        //     m = num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
        //     m = this.goodNumber(m)
        //     return m
        //     //       n = this.goodNumber(n)
        // } catch (e) {
        //     console.log('e')
        //     return n
        // }
    },
    smallHandle(n, l = 18) {
        try {
            return smallHandle(n, l)
        } catch (err) {
            console.log(err)
        }
    },
    longHandle(n, l = 18) {
        return longHandle(n, l)
    },
    // error success warning
    pushMsg(type, message) {
        this.$store.commit(type, message)
    },
    // 检查数据是否合规
    checkPass(source, rules = [], isTip = true) {
        return !rules.some((item) => {
            let pass = true
            if (item in this.rules) {
                pass = this.rules[item].match.test(source)
            } else {
                console.log('Not Support')
            }
            if (pass) {
                return false
            } else {
                isTip && this.pushMsg('warning', this.rules[item].tip)
                return true
            }
        })
    },
    driveWidth() {
        let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        return browserWidth
    },
    SubTH(parentNode) {
        try {
            let nodes = parentNode.childNodes
            let foot = 0
            for (let i = 0; i < nodes.length; i++) {
                let { bottom } = nodes[i].getBoundingClientRect()
                if (bottom) {
                    foot = bottom
                }
            }
            let { top } = nodes[0].getBoundingClientRect()
            return Math.abs(foot - top)
        } catch (error) {}
    },
    isEnd(datetimeTo) {
        if (datetimeTo) {
            if (!(typeof datetimeTo == 'number')) {
                datetimeTo = new Date(datetimeTo.replace(/-/g, '/'))
            } else {
                datetimeTo = new Date(datetimeTo)
            }

            return new Date().valueOf() < datetimeTo
        }
    },
    remainTime(datetimeTo) {
        let result
        if (datetimeTo) {
            if (!(typeof datetimeTo == 'number')) {
                datetimeTo = new Date(datetimeTo.replace(/-/g, '/'))
            } else {
                datetimeTo = new Date(datetimeTo)
            }
            let time1 = new Date(datetimeTo).getTime()
            let time2 = new Date().getTime()
            let mss = time1 - time2
            // 倒计时结束
            if (mss < 0) {
                result = {
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                }
                return result
            }
            // 将时间差（毫秒）格式为：天时分秒
            let days = parseInt(mss / (1000 * 60 * 60 * 24))
            let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = parseInt((mss % (1000 * 60)) / 1000)
            result = {
                days: days < 10 ? '0' + days : '' + days,
                hours: hours < 10 ? '0' + hours : '' + hours,
                minutes: minutes < 10 ? '0' + minutes : '' + minutes,
                seconds: seconds < 10 ? '0' + seconds : '' + seconds,
            }
        } else {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            }
        }

        return result
    },
    langJson(str, key) {
        if (key in this.langSource) {
            return this.langSource[key]
        } else {
            return str
        }
    },
    plus(x, y) {
        let base = BigNumber(x)
        return this.goodNumber(base.plus(y).toString())
    },
    reduce(x, y) {
        let base = BigNumber(x)
        return this.goodNumber(base.minus(y).toString())
    },
    multiplication(x, y) {
        let base = BigNumber(x)
        return this.goodNumber(base.multipliedBy(y).toString())
    },
    division(x, y) {
        let base = BigNumber(x)
        let quote = BigNumber(y)
        return this.goodNumber(base.dividedBy(quote).toString())
    },
    timeStr(timestamp) {
        let dateObj = new Date(parseInt(timestamp))
        let y = dateObj.getFullYear()
        let m = dateObj.getMonth() + 1
        let d = dateObj.getDate()
        let H = dateObj.getHours()
        let mm = dateObj.getMinutes()
        let ss = dateObj.getSeconds()
        m = m < 10 ? '0' + m : m
        d = d < 10 ? '0' + d : d
        H = H < 10 ? '0' + H : H

        mm = mm < 10 ? '0' + mm : mm
        ss = ss < 10 ? '0' + ss : ss
        return y + '/' + m + '/' + d + ' ' + H + ':' + mm + ':' + ss
    },
    timestampGet(str) {
        if (!(typeof datetimeTo == 'number')) {
            if (str.indexOf('/') == -1) {
                str = str.replace(/-/g, '/')
            }
            return new Date(str).getTime()
        } else {
            return str
        }
    },
    async copy(text) {
        console.log('text', text)
        await loadScript('HClipboard')
        let clipboard = new HClipboard()
        let result = clipboard.copy(text)
        if (result) {
            this.pushMsg('success', this.langJson('Replicating Success', 'inscdoge.txt.v1.90'))
        } else {
            this.pushMsg('error', this.langJson('Copy failed', 'inscdoge.txt.v1.91'))
        }
    },
    openAddress(address, chainId) {
        let chainDetail = this.findChain('id', chainId)
        let baseUrl = chainDetail.browserAddress
        if (window.uni_app == 'uni_app') {
            window.openH5Url(baseUrl + address)
            return
        } else {
            window.open(baseUrl + address, '_blank')
        }
    },
    openUrl(link) {
        if (!link) return
        if (window.uni_app == 'uni_app') {
            window.openH5Url(link)
            return
        }
        window.open(link, '_blank')
    },
    openTx(hash, chainId) {
        let chainDetail = this.findChain('id', chainId)
        let baseUrl = chainDetail.browserTx

        if (window.uni_app == 'uni_app') {
            window.openH5Url(baseUrl + hash)
            return
        } else {
            window.open(baseUrl + hash, '_blank')
        }
    },
    setCookie(cname, cvalue, hours = 2) {
        // 默认 2小时过期  = 0.08333333
        var d = new Date()
        d.setTime(d.getTime() + hours * 60 * 60 * 1000)
        var expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/'
    },
    getCookie(name) {
        let arrd = null
        const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`)
        if (document.cookie.match(reg)) {
            arrd = document.cookie.match(reg)

            return unescape(arrd[2])
        }
        return null
    },
    /* 科学计数法转字符串 */
    goodNumber(n) {
        n = n + ''
        n = n.toLocaleLowerCase()
        let r = n.split('e')[0]
        let l = n.split('e')[1] ? n.split('e')[1] : ''
        if (l) {
            let zl
            let zs = ''
            let rf = 0
            if (/^\+\d+$/.test(l)) {
                zl = l.split('+')[1]
                for (let i = 0; i < zl; i++) {
                    zs += '0'
                }
                if (r.split('.')[1]) {
                    rf = r.split('.')[1].length
                    r = r.split('.')[0] + r.split('.')[1]
                }
                zs = zs.slice(0, zs.length - rf)
                return r + zs
            } else if (/^\-\d+$/.test(l)) {
                zl = l.split('-')[1]
                for (let i = 0; i < zl; i++) {
                    zs += '0'
                }
                rf = r.split('.')[0].length
                if (r.split('.')[1]) {
                    r = r.split('.')[0] + r.split('.')[1]
                }
                zs = zs.slice(0, zs.length - rf)
                return '0.' + zs + r
            }
        } else {
            return r
        }
    },
    findStakingContract(detail) {
        try {
            let result = this.platformContract.filter((item) => {
                return item.chainId == detail.chainId
            })
            let obj = {}
            result.forEach((item) => {
                obj[item.name] = item.address
            })
            return obj && obj.staking_contract ? obj.staking_contract : ''
        } catch (error) {
            console.log('error', error)
            return '--'
        }
    },
    debounce(func, delay) {
        let timer = null
        return function (...args) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        }
    },

    // 生成随机色
    getRandomColor() {
        let rgb = []
        for (let i = 0; i < 3; ++i) {
            let temp = Math.floor(Math.random() * 256)
            if (temp < 120) {
                temp += 120
            }
            let color = temp.toString(16)
            color = color.length == 1 ? '0' + color : color
            rgb.push(color)
        }
        return '#' + rgb.join('')
    },
    // 数字加逗号
    formatNumber(num) {
        return Number(num).toLocaleString()
    },
    remainTime(datetimeTo) {
        let result
        if (datetimeTo) {
            if (!(typeof datetimeTo == 'number')) {
                datetimeTo = new Date(datetimeTo.replace(/-/g, '/'))
            } else {
                datetimeTo = new Date(datetimeTo)
            }
            let time1 = new Date(datetimeTo).getTime()
            let time2 = new Date().getTime()
            let mss = time1 - time2
            // 倒计时结束
            if (mss < 0) {
                result = {
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                }
                return result
            }
            // 将时间差（毫秒）格式为：天时分秒
            let days = parseInt(mss / (1000 * 60 * 60 * 24))
            let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = parseInt((mss % (1000 * 60)) / 1000)
            result = {
                days: days < 10 ? '0' + days : '' + days,
                hours: hours < 10 ? '0' + hours : '' + hours,
                minutes: minutes < 10 ? '0' + minutes : '' + minutes,
                seconds: seconds < 10 ? '0' + seconds : '' + seconds,
            }
        } else {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            }
        }

        return result
    },
}
