import api from '@/api/staking'
const staking = {
    state: {
        newlistStaking: [],
        endStakingInList: [],
        stakingDetail: {},
        stakingRecommendList: [],
        // 我进行的staking记录
        myStaked: [],
        // 对应项目的nft资产
        nftAssets: [],
    },
    getters: {},
    mutations: {
        setNewinStakingList(state, newlistStaking) {
            state.newlistStaking = newlistStaking
        },
        setEndStakingList(state, endStakingInList) {
            state.endStakingInList = endStakingInList
        },
        setStakingDetail(state, stakingDetail) {
            state.stakingDetail = stakingDetail
        },
        setStakingRecommendList(state, stakingRecommendList) {
            state.stakingRecommendList = stakingRecommendList
        },
        myStaked(state, myStaked) {
            state.myStaked = myStaked
        },
        nftAssets(state, nftAssets) {
            state.nftAssets = nftAssets
        },
    },
    actions: {
        /* staking最新的 */
        async stakingNewGet(context) {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'createTime',
                    isAsc: 'desc',
                }
                // 请求接口
                let result = await api.getStakingProject(params)
                // 处理结果
                result = this.apiResult(result)

                context.commit('setNewinStakingList', result.data.rows)
                this.apiCache('staking', 'setNewinStakingList', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* staking快结束的 */
        async stakingEndGet(context) {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'endTime',
                    isAsc: 'desc',
                }
                // 请求接口
                let result = await api.getStakingProject(params)
                // 处理结果
                result = this.apiResult(result)
                context.commit('setEndStakingList', result.data.rows)
                this.apiCache('staking', 'setEndStakingList', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* staking市场 */
        async stakingMarket(context, params) {
            try {
                // 请求接口
                let result = await api.getStakingProject(params)
                // 处理结果
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* staking详情 */
        async getStakingDetail(context, id) {
            try {
                // 请求接口
                let result = await api.stakingDetail(id)
                // 处理结果
                result = this.apiResult(result)
                context.commit('setStakingDetail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* staking轮播图 */
        async getStakingRecommendList(context) {
            try {
                // 请求接口
                let result = await api.getRecommendList({ type: 3 })

                // 处理结果
                result = this.apiResult(result)
                // 设置
                context.commit('setStakingRecommendList', result.data)
                this.apiCache('staking', 'setStakingRecommendList', result.data)
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 用户staking 记录 */
        async getStakings(context, params) {
            try {
                // 请求接口
                let result = await api.getStakings(params)
                // 处理结果
                result = this.apiResult(result)
                context.commit('myStaked', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 用户 nft资产 */
        async getAssets(context, params) {
            try {
                // 请求接口
                let result = await api.getAssets(params)
                // 处理结果
                result = this.apiResult(result)
                context.commit('nftAssets', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default staking
