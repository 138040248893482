<template>
    <div :id="components_id" class="select">
        <div
            :class="['select_btn', bgColor]"
            @click="openPopup"
            :style="{
                width: size ? `${size}px` : `100%`,
            }"
            @mouseleave="leave"
            @mouseenter="openPopup"
        >
            <div class="btn_text">{{ current.lable }}</div>
            <GI class="icon" name="swap_down_1" ws="24" hs="24" @click="openPopup"></GI>
        </div>
        <div
            class="select_popup"
            :id="popup_id"
            :style="{
                top: `${top}px`,
                width: ws ? `${ws}px` : `auto`,
                right: ws ? `` : `0px`,
            }"
            @mouseenter="openPopup"
            @mouseleave="leave"
        >
            <slot name="top"></slot>
            <div class="popup_list">
                <div class="popup_item" v-for="(item, index) in list" :key="index" @click="selectItem(item.value)">
                    {{ item.lable }}
                </div>
            </div>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    data() {
        return {
            top: 0,
            offset: 10,
            timer: null,
            popup_id: '',
        }
    },
    model: {
        prop: 'value',
        event: 'update',
    },
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: [Number, String],
            default: 200,
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: 'bg',
        },
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'select_' + code
        code = this.regCode()
        this.popup_id = 'select_popup_' + code
    },
    mounted() {
        let { height } = document.getElementById(this.components_id).getBoundingClientRect()
        this.top = this.offset + height

        move.defaults = {
            duration: 500,
        }
    },
    computed: {
        getStyle() {
            return this.ws && this.top
                ? {
                      top: `${this.top}px`,
                      width: `${this.ws}px`,
                  }
                : {
                      top: `${this.top}px`,
                      right: `0px`,
                  }
        },
        current() {
            let result = this.list.find((item) => {
                return item.value == this.value
            })
            return result
                ? result
                : {
                      lable: this.placeholder ? this.placeholder : this.langJson('全部', 'inscdoge.txt.v1.109'),
                      value: '',
                  }
        },
    },
    methods: {
        selectItem(val = '') {
            this.$emit('update', val)
            this.$emit('change', val)
            this.closePopup()
        },
        openPopup() {
            clearTimeout(this.timer)
            let node = document.getElementById(this.popup_id)
            let result = this.SubTH(node)
            result = result > 300 ? 300 : result + 12

            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(180).end()

                // 打开选项
                move(`#${this.components_id} .select_popup`).set('height', `${result}px`).set('opacity', 1).end()
            }
        },
        leave() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.closePopup()
            }, 1000)
        },

        closePopup() {
            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(0).end()

                // 收起选项
                move(`#${this.components_id} .select_popup`).set('height', `0px`).set('opacity', 0).end()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.select {
    position: relative;
    .select_popup {
        height: 0px;
        overflow: auto;
        opacity: 0;
        position: absolute;
        background-color: var(--bg_item4);
        left: 0;
        z-index: 2000000;
        border-radius: 6px;
        .popup_list {
            padding: 12px;
            padding-top: 0px;
            .popup_item {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--font_desc2);
                margin-top: 12px;
                cursor: pointer;
                padding: 8px 12px;
                box-sizing: border-box;
                transition: all 0.5s;
            }
            .popup_item:hover {
                color: var(--font_main);
                background: var(--bth_2_bg);
                border-radius: 6px;
            }
        }
    }
    .select_btn {
        padding: 0 22px;
        height: 44px;
        box-sizing: border-box;
        border-radius: 6px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .btn_text {
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            color: var(--font_modif_color) !important;
            margin-right: 10px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .bg {
        background: var(--bg_item4);
    }
    .white {
        background: var(--bg_item4);
    }
}
</style>
