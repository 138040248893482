import http from "./base";
/**
 * 支持法币列表
 */
let legalSupport = () => {
    return http.post("/exchange/support/legal");
};

/**
 * 支持虚拟币列表
 */
let cryptoSupport = () => {
    return http.post("/exchange/crypto/list");
};

/**
 * 支持的渠道列表
 * @param cryptoSymbol 虚拟币名称
 * @param legalSymbol 法币名称
 */
let channelSupport = (params) => {
    return http.post("/exchange/channel/list", params);
};

/**
 * 获取总报价
 * @param cryptoSymbol 虚拟币名称
 * @param legalSymbol 法币名称
 * @param serverName 渠道名称
 */
let quotedChannel = (params) => {
    return http.post("/exchange/channel/quoted/price", params);
};

/**
 * 获取总报价
 * @param cryptoSymbol 虚拟币名称
 * @param legalSymbol 法币名称
 * @param serverName 渠道名称
 * @param email 邮箱
 * @param quoteId //渠道名称为simplex时必传，其他渠道不传，从报价接口获取
 * @param totalPrice
 */
let orderCreate = (params) => {
    return http.postJson("/exchange/createBuyOrderUrl", params);
};

/**
 * 充值订单记录
 * @param pageNum 
 * @param pageSize 
 * @param status 状态 0:处理中 1:已完成 2:已取消 3:失败 9:异常
 */
 let orderBuy = (params) => {
    return http.get("/exchange/order/record", params);
};

export default {
    legalSupport,
    cryptoSupport,
    channelSupport,
    quotedChannel,
    orderCreate,
    orderBuy
}