import http from "./base";

/**
 * @param chainName
 * 获取nft staking 项目
 */
let getStakingProject = params => {
  return http.get("/staking/projectList", params);
};
/**
 * 是否显示staking tab
 */
let showStakingTab = params => {
  return http.get("/staking/stakingTab", params);
};
/**
 * staking 详情
 */
let stakingDetail = id => {
  return http.get(`/staking/detail/${id}`);
};

/**
 * NFT资产关于项目
 * @param id 项目ID
 * @param address 钱包地址
 */
 let getAssets = params => {
  return http.get(`/staking/conditionAssets/${params.id}`, params);
};
/**
 * staking 记录
 * @param projectId 项目ID
 * @param address 钱包地址
 * @param chainName 链名
 * @param pageNum 第几页
 * @param pageSize 每页数量
 */
 let getStakings = params => {
  return http.get(`/staking/stakingRecord`, params);
};
// 获取页面轮播图
let getRecommendList=(params={})=>{
  return http.get('/index/recommend/list',params)
}
export default {
  getStakingProject,
  showStakingTab,
  stakingDetail,
  getAssets,
  getStakings,
  getRecommendList
};
  