<template>
    <div class="input_style">
        <GI class="input_icon" v-if="icon && showIcon" :name="icon" ws="24" hs="24"></GI>

        <input v-if="!openPwd" v-model="value" class="input_main" :type="type" :placeholder="placeholder" :disabled="disabled" @input="compare" />
        <input v-else v-model="value" class="input_main" type="text" :placeholder="placeholder" :disabled="disabled" @input="compare" />

        <div class="input_append">
            <GI v-if="type == 'password' && !openPwd" name="global_eye_on" ws="24" hs="24" @click="review(true)"></GI>
            <GI v-else-if="type == 'password'" name="global_eye_close" ws="24" hs="24" @click="review(false)"></GI>

            <slot name="append"></slot>
        </div>
        <div class="input_tip" v-if="tip">{{ tip }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: '',
            openPwd: false,

            isInit: false,
        }
    },
    model: {
        prop: 'keywork',
        event: 'change',
    },
    props: {
        keywork: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'nav_search',
        },
        matchs: {
            type: Array,
            default: () => {
                return []
            },
        },
        max: {
            type: [Number, String],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        numberInput: {
            type: Boolean,
            default: false,
        },
        maxValue: {
            type: String | Number,
            default: '',
        },
        minValue: {
            type: String | Number,
            default: '',
        },
        isTip: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tip() {
            if (this.isTip && !!this.value) {
                if (!isNaN(this.value)) {
                    if (!this.maxValue && this.minValue) {
                        // 只有最小
                        if (Number(this.minValue) > Number(this.value)) {
                            return this.langJson('min:', 'inscdoge.txt.v1.100') + this.minValue
                        }
                    } else if (this.maxValue && !this.minValue) {
                        // 只有最大
                        if (Number(this.maxValue) < Number(this.value)) {
                            return this.langJson('max:', 'inscdoge.txt.v1.99') + this.maxValue
                        }
                    } else if (!!this.maxValue && !!this.minValue) {
                        // 最小 最大
                        if (Number(this.maxValue) < Number(this.value)) {
                            return this.langJson('max:', 'inscdoge.txt.v1.99') + this.maxValue
                        } else if (Number(this.minValue) > Number(this.value)) {
                            return this.langJson('min:', 'inscdoge.txt.v1.100') + this.minValue
                        }
                    }

                    return ''
                }
            }
            // 是否有输入
            if (!this.isInit) {
                return ''
            }
            try {
                let result = this.matchs.find((item) => {
                    if (item in this.rules) {
                        return !this.rules[item].match.test(this.value)
                    } else {
                        console.log('Not Support')
                    }
                })
                if (result) {
                    return this.rules[result].tip
                } else {
                    return ''
                }
            } catch (err) {
                console.log(err)
            }
            return ''
        },
    },
    created() {
        this.value = this.keywork
    },
    watch: {
        keywork(n) {
            this.value = n
        },
        value(n) {
            if (this.numberInput) {
                this.$emit('change', Number(n))
            } else {
                this.$emit('change', n)
            }
        },
    },
    methods: {
        compare(event) {
            this.$emit('input')
            this.isInit = true
            try {
                if (this.max !== '') {
                    let current = parseFloat(event.target.value)
                    let max = parseFloat(this.max)
                    if (current > max) {
                        this.value = this.max
                    }
                }
            } catch (err) {
                console.log(err)
                this.value = ''
            }
        },
        review(val) {
            this.openPwd = val
        },
    },
}
</script>
<style lang="scss" scoped>
.input_style {
    .input_tip {
        position: absolute;
        bottom: -20px;
        right: 0px;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ce4115;
        white-space: nowrap;
    }
    .input_append {
        margin-left: 22px;
        display: flex;
        align-items: center;
    }
    .input_icon {
        margin-right: 22px;
    }
    .input_main {
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        height: 16px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        flex: 1;

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        min-width: 0px;
        overflow: hidden;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    background: #1c1c1c;
}
</style>
