let TRONLINK

export default {
    async getTRONLINK() {
        if (TRONLINK) {
            return TRONLINK
        }
        await loadScript('h-unielon-ins')
        console.log('UnielonIns', UnielonIns)
        TRONLINK = new UnielonIns()
        return TRONLINK
    },
    async connect() {
        let res = await this.getTRONLINK()
        console.log('res', res)
        let conentWallet = await res.connect()
        let { address, chainId } = conentWallet.data
        // console.log('链接钱包', { address, chainId })

        if (!chainId) {
            this.$store.commit('warning', this.langJson('Please connect to TRX wallet','inscdoge.txt.v1.92'))
            return false
        }
        // 不支持当前主链请切换到xx主链

        let is = this.chainMapping.some((item) => {
            return item.chainId == chainId
        })
        if (!is) {
            if (this.chainMapping[0]) {
                this.$store.commit('warning', this.langJson('The current main chain is not supported. Please switch to the ','inscdoge.txt.v1.93') + this.chainMapping[0].chainName)
            } else {
                this.$store.commit('warning', this.langJson('The current main chain is not supported.','inscdoge.txt.v1.94'))
            }
            return false
        }

        // 保存钱包和主链
        let walletObj = await this.linkUpdate({ address, chainId })
        return walletObj
    },
    async walletSign(params) {
        let res = await this.getTRONLINK()
        console.log('res', res)
        console.log('params', params)
        let conentWallet = await res.sign_message(params)

        console.log('钱包签名', conentWallet.data)

        return conentWallet.data
    },
}


