import http from './base'

/**
 * 广告
 */
let getBannerList = (params) => {
    return http.get('/bannerAdvert/list', params)
}
/**
 * 项目列表
 *
 */
let getProList = (params) => {
    return http.get('/project/list', params)
}
/**
 * 项目详情
 * @param id
 *
 */
let getProDetail = (id) => {
    return http.get(`/pledge/project/info/${id}`)
}

/**
 * 公告列表
 *
 */
let getArticleList = (params) => {
    return http.get('/article/list', params)
}

/**
 * 获取筛选币种列表
 * @param id
 *  /api/config/coinList
 */
let getCoinList = (params) => {
    return http.get(`/config/coinList`, params)
}

/**
 * 增加邀请人
 * @param invitedCode 邀请码
 */
let invitePost = (params) => {
    return http.post(`/user/invite/update`, params)
}
/**
 * 邀请关系树
 * @param parentId 懒加载
 * @param directNum 子推荐人数
 */
let inviteTree = (params) => {
    return http.get(`/user/invite/list`, params)
}

/**
 * 邀请关系树 下级
 * @param parentId 懒加载
 * @param username 用户名
 */
let inviteTreeChild = (params) => {
    return http.get(`/user/invite/child`, params)
}

/**
 * 返佣记录 累计可提取
 *
 */
let brokerRecordAll = () => {
    return http.get(`/record/brokerRecord/statistics`)
}

/**
 * 邀请返佣记录
 * @param pageSize
 * @param pageNum
 *
 */
let brokerRecord = (params) => {
    return http.get(`/record/brokerRecord/list`, params)
}

/**
 * 投资记录
 * @param pageSize
 * @param pageNum
 *
 */
let pledgeRecord = (params) => {
    return http.get(`/record/pledgeRecord/list`, params)
}

/**
 * 收益记录
 * @param pageSize
 * @param pageNum
 *
 */
let incomeRecord = (params) => {
    return http.get(`/record/incomeRecord/list`, params)
}

/**
 * 提取记录
 * @param pageSize
 * @param pageNum
 *
 */
let extractRecord = (params) => {
    return http.get(`/record/extractRecord/list`, params)
}

/**
 * 文章详情
 *
 */
let articleDetail = (id) => {
    return http.get(`/article/detail/${id}`)
}

let hashInvest = (params) => {
    return http.post(`/project/pledgeRecord/add`, params)
}
let hashExtract = (params) => {
    return http.post(`/withdraw/notify`, params)
}

let withdrawSign = (params) => {
    return http.get(`/withdraw/sign`, params)
}

// tokenAddress  withdrawAmount  chainId
let rewardSign = (params) => {
    return http.get(`/withdraw/broker/sign`, params)
}

// chainId    hash
let rewardHash = (params) => {
    return http.post(`/withdraw/broker/notify`, params)
}

let rewardProject = (params) => {
    return http.get(`/withdraw/broker/coinToProject`, params)
}

let agentInfo = (params = {}) => {
    return http.get(`/user/agentInfo`, params)
}

let teamInfo = (params = {}) => {
    return http.get(`/user/teamInfo`, params)
}

let cycleGet = (params = {}) => {
    return http.get(`/project/cycle`, params)
}

let feeGet = (params = {}) => {
    return http.get(`/withdraw/config`, params)
}

let yourself = (params = {}) => {
    return http.get(`/user/invite/yourself`, params)
}

let assetsTotal = (params = {}) => {
    return http.get(`/project/getAssets`, params)
}

let inSign = (params = {}) => {
    return http.get(`/sign`, params)
}

let inHash = (params = {}) => {
    return http.post(`/project/verifyTx`, params)
}

let checkTicket = (params = {}) => {
    return http.get(`/user/checkTicket`, params)
}

let pledgelist = (params = {}) => {
    return http.get(`/pledge/list`, params)
}

let pledgeAssets = (params = {}) => {
    return http.get(`/user/account/list`, params)
}

// coinId amount address
let withdraw_apply = (params = {}) => {
    return http.post(`/withdraw/apply`, params)
}
// coinId amount
let withdraw_transfer = (params = {}) => {
    return http.post(`/withdraw/transfer`, params)
}

// coinId amount key(用户名/钱包地址)
let transfer_apply = (params = {}) => {
    return http.post(`/transfer/apply`, params)
}

let withdrawSignGet = (params = {}) => {
    return http.get(`/withdraw/sign`, params)
}

let withdrawlist = (params = {}) => {
    return http.get(`/withdraw/list`, params)
}

let earningslist = (params = {}) => {
    return http.get(`/earnings/list`, params)
}

let brokerlist = (params = {}) => {
    return http.get(`/broker/list`, params)
}

let pledgeStatistic = (params = {}) => {
    return http.get(`/pledge/assets`, params)
}

let configParams = (params = {}) => {
    return http.get(`/config/params/list`, params)
}

// 账户信息 chainId
let get_chargeUp_account = (params = {}) => {
    return http.get(`/chargeUp/account`, params)
}

// 释放记录
let get_release_record = (params = {}) => {
    return http.get(`/release/record`, params)
}

// 早期特级 3个NFT
let get_earlyProject_list = (params = {}) => {
    return http.get(`/earlyProject/list`, params)
}

// 早期特级  NFT 详情
let get_earlyProject_info = (id) => {
    return http.get(`/earlyProject/info/${id}`)
}

// 早期项目收益记录 chainId  projectId  pageNum  pageSize
let get_early_earnings_record = (params = {}) => {
    return http.get(`/early/earnings/record`, params)
}

// 早期项目购买记录  chainId   projectId(非必填)  pageNum  pageSize
let get_early_record = (params = {}) => {
    params = {
        ...params,
        type: 1,
    }
    return http.get(`/project/purchase/record`, params)
}

// 流水记录 pageNum  pageSize
let get_flow_list = (params = {}) => {
    params = {
        ...params,
    }
    return http.get(`/flow/list`, params)
}
// 流水记录 pageNum  pageSize
let get_record_list = (params = {}, cancelToken) => {
    params = {
        ...params,
    }
    return http.get(`/record/list`, params, cancelToken)
}

// 普通项目 7个 chainId   pageNum  pageSize
let get_generalProject_list = (params = {}) => {
    return http.get(`/generalProject/list`, params)
}

// 普通项目    详情
let get_generalProject_info = (id) => {
    return http.get(`/generalProject/info/${id}`)
}

// 两个项目总 的  总收益   chainId
let get_earnings = (params = {}) => {
    return http.get(`/earnings`, params)
}

//  普通项目收益汇总   chainId
let get_general_earnings = (params = {}) => {
    return http.get(`/general/earnings`, params)
}

// 普通项目 收益记录
let get_general_earnings_record = (params = {}) => {
    return http.get(`/general/earnings/record`, params)
}
// 普通项目 购买记录 预售购买记录
let get_general_record = (params = {}) => {
    params = {
        ...params,
        // type: 2,
    }
    return http.get(`/project/purchase/record`, params)
}

let get_card_record = (params = {}) => {
    params = {
        ...params,
        type: 3,
    }
    return http.get(`/project/purchase/record`, params)
}

// 普通项目 修改复利方式 参数   recordId  type(1-进取型  2-平衡型  3-保守型)
let get_general_update = (params = {}) => {
    return http.post(`/general/update`, params)
}

let freedlist = (params = {}) => {
    return http.get(`/release/list`, params)
}

let freedDetail = (params = {}) => {
    return http.get(`/release/detail/list`, params)
}

let powerRecord = (params = {}) => {
    return http.get(`/power/record`, params)
}

// 身份卡
let get_identityCard_list = (params = {}) => {
    return http.get(`/identityCardProject/list`, params)
}
// 身份卡    详情
let get_ientityCard_info = (id) => {
    return http.get(`/identityCardProject/info/${id}`)
}

// 购买的项目 type(1-早期项目 2-一般项目 3-信用卡项目)
let get_project_validList = (params = {}) => {
    return http.get(`/project/validList`, params)
}

// 收益记录
let projectEarning = (params = {}) => {
    return http.get(`/project/earnings/record`, params)
}

// 释放记录
let releaseEarning = (params = {}) => {
    return http.get(`/project/release/record`, params)
}

// 创世节点购买记录
let genesisNodeRecord = (params = {}) => {
    return http.get(`/lately/genesis/node/record`, params)
}

// 获取签到配置
let getConfig = (params = {}) => {
    return http.get(`/checkIn/config`, params)
}
// 获取最近七天签到数据
let getSignRecord = (params = {}) => {
    return http.get(`/checkIn/signRecord`, params)
}
// 签到
let checkInSign = (params = {}) => {
    if (params.invitedCode) {
        return http.post(`/checkIn/sign?invitedCode=${params.invitedCode}`)
    } else {
        return http.post(`/checkIn/sign`)
    }
}
// 积分列表
let checkInTotalPoints = (params = {}) => {
    return http.get(`/checkIn/totalPoints`, params)
}
// 积分记录详情
let checkInPointList = (params = {}) => {
    return http.get(`/checkIn/pointList`, params)
}

// 用户详情
let financeInfo = (params = {}) => {
    return http.get(`/user/financeInfo`, params)
}

export default {
    getArticleList,
    withdraw_transfer,
    withdraw_apply,

    get_general_update,
    get_general_record,
    projectEarning,
    releaseEarning,
    get_card_record,
    get_earnings,
    get_general_earnings,
    get_general_earnings_record,

    get_generalProject_info,
    get_generalProject_list,

    get_release_record,
    get_chargeUp_account,
    get_earlyProject_list,
    get_earlyProject_info,
    get_early_earnings_record,
    get_early_record,

    get_flow_list,
    get_record_list,

    pledgeStatistic,
    getBannerList,
    getProList,
    getProDetail,
    getCoinList,
    invitePost,
    inviteTree,
    inviteTreeChild,
    pledgeRecord,
    incomeRecord,
    extractRecord,
    brokerRecordAll,
    brokerRecord,

    articleDetail,
    hashInvest,
    withdrawSign,
    hashExtract,

    rewardSign,
    rewardHash,
    rewardProject,
    agentInfo,
    cycleGet,
    feeGet,
    teamInfo,
    yourself,
    assetsTotal,

    inSign,
    inHash,
    checkTicket,
    pledgelist,
    pledgeAssets,
    withdrawSignGet,
    withdrawlist,
    earningslist,
    brokerlist,
    configParams,

    freedlist,
    freedDetail,
    powerRecord,

    get_identityCard_list,
    get_ientityCard_info,
    get_project_validList,
    genesisNodeRecord,

    getSignRecord,
    getConfig,
    checkInSign,
    checkInTotalPoints,
    checkInPointList,

    financeInfo,
    transfer_apply,
}
