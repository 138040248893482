import api from '@/api/mint'
const mintModule = {
    state: {
        //所属的nft集合
        myslefGroup: [],
    },
    getters: {},
    mutations: {
        myslefGroup(state, myslefGroup) {
            state.myslefGroup = myslefGroup
        },
    },
    actions: {
        // 获取所属的nft集合
        async myslefGroupGet(context, params) {
            try {
                let result = await api.belongGroup(params)
                result = this.apiResult(result)
                context.commit('myslefGroup', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 创建集合
        async groupCreatePost(context, params) {
            try {
                let result = await api.groupCreate(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 合集详情

        async getGroupDetail(context, params) {
            try {
                let result = await api.getGroupDetail(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 合集修改

        async editGroup(context, params) {
            try {
                let result = await api.editGroup(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 创建nft
        async nftMintPost(context, params) {
            try {
                let result = await api.nftMint(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 创建签名
        async mintSignPost(context, params) {
            try {
                let result = await api.mintSign(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // NFT详情
        async getNftDetail(context, params) {
            console.log(params);
            try {
                let result = await api.getArtDetail(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        //修改NFT详情
        async editNft(context, params) {
            try {
                let result = await api.editProject(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 删除NFT
        async delNFT(context, params) {
            try {
                let result = await api.delProject(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default mintModule
