// svg色
export default {
    svg1() {
        // 白 黑
        return this.determineColor('#ffffff', '#191b1c')
    },
    svg2() {
        //  浅绿 白
        return this.determineColor('#1fc7d4', '#ffffff')
    },
    svg3() {
        //  黑  浅绿
        return this.determineColor('#08090d', '#1fc7d4')
    },
    svg4() {
        //  浅灰  浅灰
        return this.determineColor('#5b0760', '#5b0760')
    },
    svg5() {
        //  白  浅蓝
        return this.determineColor('#ffffff', '#0bcbe1')
    },
    
}
