<template>
    <div
        :class="['input_main', imgSrc ? 'mint' : type]"
        :style="
            imgSrc && {
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: `cover`,
            }
        "
    >
        <div class="upload_icon" v-if="!imgSrc" @click="upload">
            <GI name="global_upload" ws="24" hs="24" @click="upload"></GI>
            <div class="main_title">{{ langJson('上传文件', 'inscdoge.txt.v1.105') }}</div>
            <div class="main_tip">{{ tip ? tip : langJson('(文件支持格式: JPEG, JPG, PNG.)', 'inscdoge.txt.v1.106') }}</div>
        </div>
        <div class="mask" v-show="loading">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
        </div>
        <div class="mask replay" v-show="imgSrc">
            <GI name="global_upload" ws="24" hs="24" @click="reUpload"></GI>
        </div>
        <input @change="uploadFile" :id="components_id" class="file_real userLogoInput" type="file" name="myfile" />
    </div>
</template>
<script>
import api from '@/api/config'
export default {
    data() {
        return {
            loading: false,
            components_id: '',
            imgSrc: '',
        }
    },
    inject: ['regCode'],
    props: {
        fileUrl: {
            type: String,
            default: '',
        },
        tip: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'small',
        },
        support: {
            type: Array,
            default: () => {
                return ['image']
            },
        },
        size: {
            type: [Number, String],
            default: 40,
        },
    },
    model: {
        prop: 'fileUrl',
        event: 'change',
    },
    watch: {
        fileUrl() {
            this.imgSrc = this.fileUrl
        },
    },
    created() {
        let code = this.regCode()
        this.components_id = 'upload_' + code
        this.imgSrc = this.fileUrl
    },
    methods: {
        clearUpload() {
            this.imgSrc = ''
            document.getElementById(this.components_id).value = ''
        },
        reUpload() {
            this.imgSrc = ''
            this.upload()
        },
        upload() {
            document.getElementById(this.components_id).value = ''
            document.getElementById(this.components_id).click()
        },
        async uploadFile() {
            let file = document.getElementById(this.components_id).files[0]
            var typelimit = this.support.some((item) => {
                return file.type.indexOf(item) != -1
            })
            const sizelimit = file.size / 1024 / 1024 <= this.size
            // 限制上传类型
            if (!typelimit) {
                this.pushMsg('error', this.langJson('上传的文件类型不支持', 'inscdoge.txt.v1.107'))
                return
            }
            // 限制上传大小
            if (!sizelimit) {
                this.pushMsg('error', this.langJson('上传的文件大小不能大于', 'inscdoge.txt.v1.108') + this.size + 'M!')
                return
            }

            this.loading = true
            this.$emit('uploadLoad', true)
            try {
                let fileSource = document.getElementById(this.components_id).files[0]
                // console.log('fileSource', fileSource)
                this.$emit('source', fileSource)
                let result = await api.uploadFile(fileSource)
                let success = result && result.code == 200
                console.log('标记1', result)
                if (success) {
                    this.imgSrc = result.data
                    this.$emit('change', result.data.url)
                } else {
                    if ((result && result.code == '403') || (result && result.code == '401') || (result && result.code == '1007')) {
                        if (this.$store.state.login.token) {
                            this.$store.dispatch('logout')
                        } else {
                            this.$store.commit('walletShow', true)
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
            this.loading = false
            this.$emit('uploadLoad', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.input_main {
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 32px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .replay {
        opacity: 0;
    }
    .replay:hover {
        opacity: 1;
    }
    .upload_icon {
        .main_tip {
            font-size: 14px;
            line-height: 20px;
            color: var(--font_desc2);
            margin-top: 4px;
        }
        .main_title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--font_main);
            margin-top: 8px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-radius: 32px;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    position: relative;
    transition: all 1s;
    height: 100%;
    border-radius: 16px;
}
.small {
    background-image: url(@/assets/image/global_upload_border.png);
    height: 220px;
    max-width: 403px;
    width: 100%;
}
.big {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 220px;
    width: 100%;
}

.nft_mint {
    background-image: url(@/assets/image/global_upload_border_full.png);
    height: 240px;
    width: 500px;
}

.mint {
    height: 240px;
    width: 500px;
}

.file_real {
    display: none;
}
</style>
