<template>
    <div class="main">
        <GI class="icon_unfind" name="global_new_404" ws="162.8" hs="162.8"></GI>
        <div class="tip">
            {{ langJson('PAGE NOT FOUND', 'inscdoge.txt.v1.3') }}
        </div>
        <div class="back_index" @click="jump_root">
            {{ langJson('HomePage', 'inscdoge.txt.v1.4') }}
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    inject: ['showHead', 'showFoot'],
    created() {
        this.showHead(false)
        this.showFoot(false)
    },
    beforeDestroy() {
        this.showHead(true)
        this.showFoot(true)
    },
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .back_index {
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: var(--font1);
        font-size: 16px;
        cursor: pointer;
    }

    .tip {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 64px;
        color: var(--font1);
    }

    .icon_unfind {
        width: 40% !important;
        max-width: 240px;
    }
}
</style>
