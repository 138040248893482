<template>
    <div class="banner">
        <slot name="top" :item="list[current]" :index="current">
            <div class="btn-wrap">
                <button class="next" @click="nextPage">nextPage</button>
                <button class="prev" @click="prePage">prePage</button>
            </div>
        </slot>
        <div class="conent">
            <div class="slide" ref="slide">
                <div class="main">
                    <slot name="main" :item="list[current]" :index="current"></slot>
                </div>
            </div>
        </div>
        <slot name="bottom" :item="list[current]" :index="current">
            <div class="list">
                <span class="item" v-for="num in list.length" :key="num + 'P'" :class="{ active: current === num - 1 }"></span>
            </div>
        </slot>
    </div>
</template>

<script>
// import BScroll from '@better-scroll/core'
// import Slide from '@better-scroll/slide'

// BScroll.use(Slide)

export default {
    props: ['ws', 'hs', 'list'],
    data() {
        return {
            load: false,
            current: 0,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.slide && this.slide.refresh()
                clearTimeout(this.timer)
            }, 100)
        })
        this.init()
    },
    beforeDestroy() {
        this.slide && this.slide.destroy()
    },
    methods: {
        kkl() {
            console.log('大闪避')
        },
        async init() {
            await loadScript('better-scroll')
            this.$refs.slide.style.width = this.ws
            this.slide = new BetterScroll.createBScroll(this.$refs.slide, {
                // this.slide = new BScroll(this.$refs.slide, {
                scrollX: true,
                scrollY: false,
                slide: true,
                momentum: false,
                bounce: false,
                probeType: 3,
                click: true,
            })

            this.slide.on('slideWillChange', (page) => {
                this.current = page.pageX
            })
        },

        nextPage() {
            this.slide && this.slide.next()
        },
        prePage() {
            this.load = true
            this.slide && this.slide.prev()
        },
    },
}
// https://jsonformatter.org/stylus-to-css
</script>

<style lang="scss" scoped>
/* 动画会影响实例重载间隔的耗时 */
* {
    transition: all 0s;
}
// css 转换 https://blog.csdn.net/my_study_everyday/article/details/124964368
.banner {
    .conent {
        position: relative;
    }

    .slide {
        border-radius: 6px;
        margin: 0 auto;
        min-height: 1px;
        overflow: hidden;
    }

    .main {
        white-space: nowrap;
        font-size: 0;
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        .item {
            display: inline-block;
            margin: 0 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            background: #26242e;
            /* background: #1fc7d4; */
            transition: all 0.4s;

            &.active {
                background: var(--bg11);
                background: #ff6821;
            }
        }
    }

    .btn-wrap {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        button {
            margin: 0 10px;
            padding: 10px;
            color: #fff;
            border-radius: 4px;
            background-color: #666;
        }
    }
}
</style>
