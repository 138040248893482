<template>
    <div
        class="no_data"
        :style="{
            height: `${height}px`,
        }"
    >
        <div class="center">
            <!-- noData -->
            <GI class="loading" v-if="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>

            <!-- <GI class="noData" name="noData" ws="60" hs="60"></GI> -->
            <div class="bg_img_1 bgCover" :style="{ backgroundImage: `url(${bgSrc})`, width: '47px', height: '27px' }"></div>

            <span
                class="fontColor"
                :style="{
                    fontSize: `${'14'}px`,
                }"
            >
                {{ langJson('No data', 'inscdoge.txt.v1.102') }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NuData',
    data() {
        return {
            bgSrc: '/source/uic/no_data1.png',
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        height: {
            type: [String, Number],
            default: 300,
        },
    },
}
</script>

<style lang="scss" scoped>
.no_data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 36px;
    color: var(--font1);

    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--font1);

        .loading {
            margin-bottom: 20px;
        }

        .bg_img_1 {
            background-size: 100% 100%;
        }
    }

    .fontColor {
        margin-top: 11px;
        color: #39bbc4;
        text-align: center;
        font-family: 'MicrosoftYaHei';
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.41px;
    }
}
</style>
