<template>
    <SvgBase64 v-if="type == 'svg' && src == '' && !invariant && color" :color="color" :name="name" :ws="ws" :hs="hs" :noClickStop="noClickStop" @click="$emit('click')"></SvgBase64>
    <div
        v-else
        class="conent"
        :style="{
            width: ws ? `${ws}px` : '100%',
            height: hs ? `${hs}px` : 'auto',
            lineHeight: hs ? `${hs}px` : 'auto',
        }"
        @click="handSvg()"
    >
        <template v-if="lazyload">
            <img
                v-if="!noClickStop"
                :style="{
                    width: ws ? `${ws}px` : '100%',
                    height: hs ? `${hs}px` : 'auto',
                    lineHeight: hs ? `${hs}px` : 'auto',
                }"
                :class="['icon lazyload', rotate ? 'rotate' : '']"
                src="/source/svg/default_icon.svg"
                :data-src="src ? src : `/source/${type}/${name}.${type}`"
                @click.stop="$emit('click')"
            />

            <img
                v-else
                :style="{
                    width: ws ? `${ws}px` : '100%',
                    height: hs ? `${hs}px` : 'auto',
                }"
                :class="['icon lazyload', rotate ? 'rotate' : '']"
                src="/source/svg/default_icon.svg"
                :data-src="src ? src : `/source/${type}/${name}.${type}`"
                @click="$emit('click')"
            />
        </template>
        <template v-else>
            <img
                v-if="!noClickStop"
                :style="{
                    width: ws ? `${ws}px` : '100%',
                    height: hs ? `${hs}px` : 'auto',
                    lineHeight: hs ? `${hs}px` : 'auto',
                }"
                :class="['icon', rotate ? 'rotate' : '']"
                :src="src ? src : `/source/${type}/${name}.${type}`"
                @click.stop="$emit('click')"
            />

            <img
                v-else
                :style="{
                    width: ws ? `${ws}px` : '100%',
                    height: hs ? `${hs}px` : 'auto',
                }"
                :class="['icon', rotate ? 'rotate' : '']"
                :src="src ? src : `/source/${type}/${name}.${type}`"
                @click="$emit('click')"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'GlobalSvg',

    data() {
        return {}
    },
    props: {
        type: {
            type: String,
            default: 'svg',
        },
        name: {
            type: String,
            default: '',
        },
        src: {
            type: String,
            default: '',
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        hs: {
            type: [Number, String],
            default: 0,
        },
        rotate: {
            type: Boolean,
            default: false,
        },
        noClickStop: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '',
        },
        // 不变
        invariant: {
            type: Boolean,
            default: false,
        },
        // 是否懒加载
        lazyload: {
            type: Boolean,
            default: true, // 是
        },
    },
    methods: {
        handSvg() {
            this.$emit('click')
        },
    },
}
</script>

<style lang="scss" scoped>
// .imgGI {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.conent {
    /* display: inline-flex !important; */
    /* align-items: center;
    justify-content: center; */
    /* overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important; */
    flex-shrink: 0 !important;
    transition: all 0s !important;
}
.icon {
    flex-shrink: 0 !important;
    width: 100%;
    height: 100%;
    transition: all 0s !important;
}

@keyframes rotateHandle {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotate {
    animation: rotateHandle 1s linear forwards infinite;
}
</style>
