<template>
    <svg :width="`${ws}px`" :viewBox="`${ws / 2} ${ws / 2} ${ws} ${ws}`">
        <circle :cx="ws" :cy="ws" :r="ws / 2.5" :stroke="color" :stroke-width="ws / 25"></circle>
    </svg>
</template>

<script>
export default {
    props: {
        start: {
            type: Boolean,
            default: true,
        },
        ws: {
            type: [String, Number],
            default: '28',
        },
        color: {
            type: String,
            default: '#000000',
        },
    },
}
</script>

<style lang="scss" scoped>
svg {
    transform-origin: center;
    animation: rotate 2s linear infinite;
}

circle {
    fill: none;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}
</style>
