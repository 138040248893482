<template>
    <div :class="['form_item', `form_${type}`]">
        <div :class="[typeConfig('titleStyle') ? typeConfig('titleStyle') : 'title']">
            {{ title }}
            <span class="optional" v-if="optional">{{ langJson('(可选)', 'inscdoge.txt.v1.134') }}</span>
            <span class="required" v-if="!!matchs.length">*</span>
            <div class="fileDesc" v-if="type == 'file'">{{ fileDesc }}</div>
        </div>
        <div class="input_content">
            <UploadImg ref="UploadImg" v-if="type == 'image'" :size="size" @change="triggleSource" :tip="uploadTip" v-model="target" :type="typeConfig('type')" @uploadLoad="uploadLoad"></UploadImg>

            <div class="input_text" v-else-if="type == 'text'">
                <IN :placeholder="placeholder" :disabled="disabled" :matchs="matchs" :max="max" v-model="target" icon="">
                    <div class="append_center" slot="append">
                        <slot name="text_append"></slot>
                    </div>
                </IN>
            </div>

            <div class="input_text" v-else-if="type == 'password'">
                <IN type="password" :matchs="matchs" :disabled="disabled" :max="max" :placeholder="placeholder" v-model="target" icon=""></IN>
            </div>

            <div class="input_text" v-else-if="type == 'select'">
                <Sel :list="list" :placeholder="placeholder" :size="0" v-model="target"></Sel>
            </div>

            <div class="input_text" v-else-if="type == 'textarea'">
                <textarea class="input" :style="height && { height }" :placeholder="placeholder" v-model="target" :disabled="disabled"></textarea>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            target: '',
        }
    },
    model: {
        prop: 'value',
        event: 'update',
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Array, Object],
            default: '',
        },
        // text image password select textarea collection properties
        type: {
            type: String,
            default: 'text',
        },
        uploadTip: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        image: {
            type: Object,
            default: () => {
                return {
                    type: 'small',
                    titleStyle: 'title',
                }
            },
        },
        file: {
            type: Object,
            default: () => {
                return {
                    type: 'small',
                    titleStyle: 'title',
                }
            },
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        matchs: {
            type: Array,
            default: () => {
                return []
            },
        },
        max: {
            type: [Number, String],
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: '',
        },
        isDetail: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        size: {
            type: [Number, String],
            default: 40,
        },
        support: {
            type: Array,
            default: () => {
                return ['image', 'video', 'audio', 'glb']
                // 'text' 并不支持 text/html
            },
        },
        fileDesc: {
            type: String,
            default: '',
        },
    },
    created() {
        this.target = this.value
    },
    watch: {
        value(n) {
            this.target = n
        },
        target(n) {
            this.$emit('update', n)
            this.$emit('change', n)
        },
    },
    computed: {
        typeConfig() {
            return (key) => {
                return this[this.type] && key in this[this.type] ? this[this.type][key] : ''
            }
        },
    },
    methods: {
        uploadLoad(uploadLoad) {
            this.$emit('uploadLoad', uploadLoad)
        },
        clearUpload() {
            if (this.type == 'image') {
                this.$refs.UploadImg.clearUpload()
            }
            if (this.type == 'file') {
                this.$refs.UploadFile.clearUpload()
            }
        },
        triggleSource(val) {
            console.log('标记1', 'triggleSource', val)
            return this.$emit('source', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.append_center {
    display: flex;
    align-items: center;
}
.fileDesc {
    font-size: 14px;
    line-height: 20px;
    color: var(--font_desc1);
}
.optional {
    font-size: 14px;
    line-height: 20px;
    color: var(--border_color);
    margin-left: 4px;
}
.required {
    color: rgb(230, 60, 60);

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
}
.form_properties {
    .input_content {
        .input_text {
            .project_list {
                .item_add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                    width: 70px;
                    height: 70px;
                    border-radius: 16px;
                    cursor: pointer;
                }
                .item_tag {
                    margin: 12px 12px 0 0;
                    position: relative;
                    overflow: hidden;
                    .mask {
                        bottom: -100%;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        padding: 4px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba($color: #000, $alpha: 0.2);
                        border-radius: 0 0 16px 16px;
                        cursor: pointer;
                        z-index: -99;
                    }
                    &:hover .mask {
                        z-index: 99;
                        bottom: 0;
                        left: 0;
                    }
                }
                display: flex;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }
    .title {
        font-size: 14px;
        line-height: 20px;
        color: #1b1b2c;
    }
}
.form_collection {
    .input_content {
        .input_text {
            .project_list {
                .item_add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                    width: 70px !important;
                    height: 70px;
                    border-radius: 16px;
                    cursor: pointer;
                }
                .item_tag {
                    .item_logo {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                    .item_name {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: var(--font_color);
                        margin-top: 8px;
                        padding: 0 20px;
                        width: 100%;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: center;
                    }
                    margin: 12px 12px 0 0;
                    background-color: rgba($color: #ccc, $alpha: 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100px;
                    height: 70px;
                    border-radius: 16px;
                    cursor: pointer;
                }
                .tag_active {
                    background-color: #e0f0ff;
                }
                display: flex;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }
    .title {
        font-size: 14px;
        line-height: 20px;
        color: #1b1b2c;
    }
}
.form_image,
.form_file {
    .input_content {
        margin-top: 16px;
        display: flex;
    }
    .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #1b1b2c;
    }
    .title_small {
        font-size: 16px;
        line-height: 24px;
        color: #1b1b2c;
    }
}

.form_password {
    .input_content {
        margin-top: 12px;
        .input_text {
            background-color: var(--font1);
            border-radius: 4px;
            border: 1px solid rgba($color: #fff, $alpha: 1);
        }
    }
    .title {
        font-size: 14px;
        line-height: 20px;
        color: #1b1b2c;
    }
}
.form_text {
    .input_content {
        margin-top: 12px;
        .input_text {
            background-color: var(--bg11);
            border-radius: 4px;
        }
    }
    .title {
        font-size: 14px;
        line-height: 20px;
        color: var(--font1);
    }
}

.form_textarea {
    .input_content {
        margin-top: 12px;
        .input_text {
            .input {
                border: none;
                outline: none;
                background: transparent;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #1b1b2c;
                width: 100%;
                padding: 10px 24px;
                border-radius: 6px;
                box-sizing: border-box;
                height: 160px;
            }
            background-color: var(--font1);
            border-radius: 4px;
            border: 1px solid rgba($color: #fff, $alpha: 1);
        }
    }
    .title {
        font-size: 14px;
        line-height: 20px;
        color: #1b1b2c;
    }
}

.form_select {
    .input_content {
        margin-top: 12px;
        .input_text {
            background-color: rgba($color: #fff, $alpha: 0.1);
            border-radius: 4px;
            border: 1px solid var(--border2);
        }
    }
    .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1b1b2c;
    }
}
@media screen and (max-width: 500px) {
    .form_properties {
        .input_content {
            .input_text {
                .project_list {
                    .item_add {
                    }
                    .item_tag {
                        margin: 12px 12px 0 0;
                        position: relative;
                        overflow: hidden;
                        .mask {
                            bottom: 0;
                            left: 0;
                            position: absolute;
                            width: 100%;
                            padding: 4px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba($color: #000, $alpha: 0.2);
                            border-radius: 0 0 16px 16px;
                            cursor: pointer;
                            z-index: 99;
                        }
                    }
                }
            }
        }
    }
}
</style>
