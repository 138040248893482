<template>
    <div :class="['global_assets', classUpdate]">
        <!-- :style="{ top: `${$nav_h}px` }" -->
        <div class="asset_main bgCover">
            <div class="frame_head">
                <div class="left" @click="jump_index">
                    <div class="logo_bg" :style="logoStyle"></div>
                    <GI class="logo_text" name="logo_text" type="svg" ws="89" hs="15" :noClickStop="true"></GI>
                </div>
                <GI class="close" name="close" ws="32" hs="32" :noClickStop="true" @click="$store.commit('SideMenu', false)"></GI>
            </div>

            <div class="person">
                <div class="list">
                    <!-- 新用户才显示的菜单 -->
                    <div class="item" v-for="(item, index) in list" :key="index" @click="item.fun()">{{ item.name }}</div>
                </div>
            </div>

            <div class="links">
                <div v-for="(item, index) in linkList" :key="index" class="link_item bgCover">
                    <!-- logo -->
                    <GI @click="openUrl(item.url)" :src="item.logo" ws="24" hs="24"></GI>
                </div>
            </div>

            <div class="select">
                <div class="selectBg"></div>
                <SelectDown bgc="#000000" ref="SelectDown" :list="listLang" @select="select" height="40px" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            langKey: '',
            langName: '',
            inited: false,

            links: [
                {
                    icon: 'twitter',
                    url: '',
                },
                {
                    icon: 'discord',
                    url: '',
                },
                {
                    icon: 'medium',
                    url: '',
                },
            ],
        }
    },
    mounted() {},
    watch: {
        SideMenu(n) {
            this.inited = true
            if (n) {
                this.init()
            }
        },
    },
    computed: {
        linkList() {
            return this.$store.state.foot.links
        },
        listLang() {
            try {
                let res = this.langTypeList.map((item) => {
                    if (this.langKey == item.langKey) {
                        this.langName = item.language
                        this.$refs.SelectDown.selectvalue = item.language
                        this.$refs.SelectDown.key = item.langKey
                    }
                    return { name: item.language, key: item.langKey }
                })

                return res
            } catch (error) {
                console.log('error', error)
                return []
            }
        },
        logoStyle() {
            let { webLogo } = this.globalImage
            return {
                backgroundImage: `url(${webLogo})`,
                width: `40px`,
                borderRadius: `0px`,
                height: `40px`,
            }
        },
        list() {
            try {
                return [
                    {
                        name: this.langJson('HOME', 'inscdoge.txt.v1.12'),
                        fun: () => {
                            this.jump_root()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Notice', 'inscdoge.txt.v5.46'),
                        fun: () => {
                            this.goNotice()
                        },
                        show: true,
                    },
                    // {
                    //     name: this.langJson('Pre-sale', 'inscdoge.txt.v1.135'),
                    //     fun: () => {
                    //         this.gopreSale()
                    //     },
                    //     show: true,
                    // },
                    {
                        name: this.langJson('Assets', 'inscdoge.txt.v1.136'),
                        fun: () => {
                            this.goAssets()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Team', 'inscdoge.txt.v1.137'),
                        fun: () => {
                            this.goTeam()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Operation process', 'inscdoge.txt.v5.48'),
                        fun: () => {
                            this.goOperate()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Introduction', 'inscdoge.txt.v1.138'),
                        fun: () => {
                            this.goIntroduction()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('视频介绍', 'inscdoge.txt.v5.96'),
                        fun: () => {
                            this.goMp4()
                        },
                        show: true,
                    },

                    {
                        name: this.langJson('Market Plan', 'inscdoge.txt.v1.139'),
                        fun: () => {
                            this.goPlan()
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Connect Us', 'inscdoge.txt.v5.47'),
                        fun: () => {
                            this.goConnectUs()
                        },
                        show: true,
                    },
                ]
            } catch (error) {
                return []
            }
        },
        logoStyle() {
            let { webLogo } = this.globalImage
            return {
                backgroundImage: `url(${webLogo})`,
                width: `40px`,
                borderRadius: `0px`,
                height: `40px`,
            }
        },
        companyName() {
            let { companyName } = this.globalImage
            return companyName
        },
        address() {
            return this.walletDetail.address
        },
        personalImage() {
            try {
                if (this.isLogin()) {
                    return this.information.imageUrl ? this.information.imageUrl : this.globalConfig.defaultConfig.userProfilePhoto
                } else {
                    return this.globalConfig.defaultConfig.userProfilePhoto
                }
            } catch (err) {
                return '/source/png/user.jpeg'
            }
        },

        information() {
            try {
                let result = this.globalPersonal.information
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        oldUser() {
            try {
                return this.information.oldUser ? true : false
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        asset() {
            try {
                let result = this.information.amountStatistics
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        classUpdate() {
            if (this.SideMenu && this.inited) {
                return 'asset_open'
            } else if (this.inited) {
                return 'asset_close'
            }
        },
    },
    created() {
        this.langKey = localStorage.getItem('Language')
    },
    methods: {
        select(select) {
            if (select != localStorage.getItem('Language')) {
                localStorage.setItem('Language', select)
                localStorage.setItem('lang_sourse', '')
                localStorage.setItem('lang_version', '')
                window.location.reload()
            }
        },
        async gopreSale() {
            // await this.loginOf(() => {
            this.jump_preSale()
            // }, false)
        },
        async goAssets() {
            await this.loginOf(() => {
                this.jump_assets()
            }, false)
        },
        async goTeam() {
            await this.loginOf(() => {
                this.jump_team()
            }, false)
        },
        async goNotice() {
            this.jump_notice()
            // await this.loginOf(() => {
            // }, false)
        },
        async goIntroduction() {
            this.jump_introduction()
        },

        async goOperate() {
            this.jump_operationIntroduction()
            // try {
            //     let lang = localStorage.getItem('Language')
            //     if (lang.indexOf('zh') != -1) {
            //         this.openUrl('/ppt/operate-CN.pdf')
            //     } else if (lang.indexOf('id') != -1) {
            //         this.openUrl('/ppt/operate-ID.pdf')
            //     } else if (lang.indexOf('ko') != -1) {
            //         this.openUrl('/ppt/operate-KO.pdf')
            //     } else {
            //         this.openUrl('/ppt/operate-EN.pdf')
            //     }
            // } catch (err) {
            //     this.openUrl('/ppt/operate-EN.pdf')
            // }
        },
        // 只在中文下
        async goMp4() {
            this.jump_perkenalanvideo()
            // try {
            //     let lang = localStorage.getItem('Language')
            //     console.log(lang)
            //     if (lang.indexOf('zh') != -1) {
            //         this.openUrl('/source/mp4/desc-CN.mp4')
            //     } else if (lang.indexOf('id') != -1) {
            //         this.openUrl('/source/mp4/desc-EN.mov')
            //     } else if (lang.indexOf('ko') != -1) {
            //         this.openUrl('/source/mp4/desc-EN.mov')
            //     } else {
            //         this.openUrl('/source/mp4/desc-EN.mov')
            //     }
            // } catch (err) {
            //     this.openUrl('/source/mp4/desc-EN.mp4')
            // }
        },

        async goPlan() {
            try {
                // this.jump_plan()
                let lang = localStorage.getItem('Language')
                console.log(lang)
                if (lang.indexOf('zh') != -1) {
                    this.openUrl('/Inscribe-deck-CN.pdf')
                } else if (lang.indexOf('id') != -1) {
                    this.openUrl('/Inscribe-deck-ID.pdf')
                } else if (lang.indexOf('ko') != -1) {
                    this.openUrl('/Inscribe-deck-KO.pdf')
                } else {
                    this.openUrl('/Inscribe-deck-EN.pdf')
                }
            } catch (err) {
                this.openUrl('/Inscribe-deck-EN.pdf')
            }
        },
        goConnectUs() {
            this.jump_connect_us()
        },
        async init() {
            let { dispatch } = this.$store
            dispatch('getLinkList')
            try {
                // 如果本地有用户信息则更新 information 没有则不请求
                if (Object.keys(this.information).length) {
                    dispatch('personalGet')
                }
            } catch (err) {
                console.log(err)
            }
            this.langKey = localStorage.getItem('Language')
            if (this.langTypeList && !!!this.langTypeList.length) {
                if (!this.langTypeList.length) {
                    this.load_btn = true
                }
                dispatch('getLangType')
            }
            this.load_btn = false
        },

        isLogin() {
            return this.walletDetail && this.walletDetail.address && this.loginToken
        },
        async nameHand() {
            await this.loginOf(() => {
                this.$store.commit('editNameModel', true)
            }, false)
        },
        async editHand() {
            await this.loginOf(() => {
                this.$store.commit('editModel', true)
            }, false)
        },
        close() {
            this.$store.commit('SideMenu', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.select {
    position: relative;
    margin: 0 auto;
    width: 184px;
    height: 40px;
    margin-top: 27px;

    .selectBg {
        left: 4px;
        top: -2.5px;
        position: absolute;
        width: 20.81px;
        height: 16.51px;
        background-image: url('/public/source/svg/discord_black.svg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 27px;
    width: 100%;
    .link_item {
        margin-right: 23px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.list {
    margin-top: 26px;
    position: relative;
    z-index: 99999;
    .item {
        text-transform: lowercase;
        line-height: 52px;
        font-size: 20px;
        color: var(--font1);
        text-align: center;
    }
}

.row1 {
    font-family: 'Title';
    padding: 30px 30px 0;
    text-align: center;
    font-weight: Bold;
    font-size: 32px;
    background-image: var(--bg7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.address {
    margin: 0 5px;
    padding: 0px 10px;
    height: 28px;
    line-height: 28px;

    font-size: 12px;
    background-color: #1fc7d4;
    color: var(--font1);
    border-radius: 40px;
    display: inline-block;
}

.person {
    z-index: 99999;
    position: relative;
    border-radius: 4px;

    .person_head {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgba(#fff, 0.05);
        position: relative;

        /* .editIcon{
            position: absolute;

        } */
        &:first-child {
            border-top: 1px solid transparent;
        }

        .person_icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 16px;
            position: relative;

            .edit {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(27%, -30%);
                width: 22px;
                height: 22px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg1);

                .editBg {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background-color: #eaf0ff;
                }
            }
        }

        .person_name {
            font-size: 22px;

            .name {
                margin-top: -4px;
                color: var(--font1);
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                z-index: 3;
            }

            .desc {
                margin-top: -6px;
                font-size: 16px;

                color: var(--font1);
                position: relative;
                z-index: 2;

                .copyIcon {
                    position: relative;
                    transform: translateY(2.5px);
                }
            }
        }
    }
}

.global_assets {
    // width: 360px;
    width: 100%;
    border-radius: 12px;
    position: fixed;
    // bottom: 20px;
    // right: 20px;
    bottom: 0;
    right: 0;
    top: 0;
    transform: translateX(100vw) scale(1);
    opacity: 0;
    padding: 22px 27px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 2000;
    background: linear-gradient(180deg, #070707 0%, #202020 100%);
    .asset_main {
        box-sizing: border-box;
        flex: 1;
        overflow-y: auto;
        padding-top: 0px;
        position: relative;
        .frame_head {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                .logo_bg {
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: auto 100%;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;
                    display: flex;
                    margin-right: 6.3px;
                }
            }
            .close {
                /* margin: 0 auto; */
            }
        }
    }
}

.asset_open {
    animation: openModel 0.4s ease-out forwards;
}

.asset_close {
    animation: closeModel 0.4s ease-out forwards;
}

@keyframes openModel {
    0% {
        opacity: 0;
        transform: translateY(-100vh) scale(1);
    }

    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

@keyframes closeModel {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translateY(-100vh) scale(1);
    }
}

@media screen and (max-width: 1081px) {
    .global_assets {
        // width: calc(100vw - 40px);
        width: 100vw;
        max-width: 100%;

        left: 0px;
        right: 0px;
        bottom: 0;
        border-radius: 0;
    }
}

.theme {
    /* background-color: var(--color_white); */
    padding: 20px 0;
    padding-bottom: 0px;
    padding-left: 16px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}
</style>
