<template>
    <Model v-model="show">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    <!-- 结账 -->
                    {{ langJson('Settle accounts', 'inscdoge.txt.v1.160') }}
                </div>
                <div class="top_right">
                    <GI name="model_close_1" :color="svg1" ws="24" hs="24" @click="close"></GI>
                </div>
            </div>
            <StepRun ref="step" :stepNum="true">
                <div class="buy_detail" slot="step1">
                    <div class="play_cell">
                        <div class="info_item">
                            <div class="price_left price_item">
                                <!-- 你将支付 -->
                                <div class="price_title">{{ langJson('You will pay', 'inscdoge.txt.v1.161') }}</div>
                                <div class="price_amount">
                                    {{ saveNum(usdtAmount, detail.usdtPrecision) | numberGrap }}&nbsp;
                                    {{ detail.coinName }}
                                </div>
                            </div>
                        </div>
                        <!-- 汇率：{{ symbolQuote.rate }}
                        <hr />
                        {{ plus(1, division(detail.giftRate, 100)) }}
                        <hr />
                        购买：{{ amount }} = {{ multiplication(amount, symbolQuote.rate) }}
                        <hr />
                        赠送：{{ division(multiplication(amount, detail.giftRate), 100) }} = {{ multiplication(division(multiplication(amount, detail.giftRate), 100), symbolQuote.rate) }}
                        <hr />
                        总:{{ plus(multiplication(division(multiplication(amount, detail.giftRate), 100), symbolQuote.rate), multiplication(amount, symbolQuote.rate)) }} -->

                        <!-- type 1预售 2项目列表 -->
                        <template v-if="type == '1'">
                            <div class="account_balance">
                                <!-- 购买数量 -->
                                <div class="balance_title">{{ langJson('Buy Amount', 'inscdoge.txt.v2.44') }}</div>
                                <div class="balance">{{ saveNum(amount, detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                            <div class="account_balance">
                                <!-- 赠送 -->
                                <div class="balance_title">{{ langJson('Gift quantity', 'inscdoge.txt.v2.45') }}</div>
                                <div class="balance">{{ saveNum(division(multiplication(amount, detail.giftRate), 100), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                            <div class="account_balance">
                                <!-- 将会获得 -->
                                <div class="balance_title">{{ langJson('Will get', 'inscdoge.txt.v1.162') }}</div>
                                <div class="balance">{{ saveNum(plus(amount, division(multiplication(amount, detail.giftRate), 100)), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                        </template>

                        <template v-else-if="type == '2'">
                            <div class="account_balance">
                                <!-- 购买数量 -->
                                <div class="balance_title">{{ langJson('Buy Amount', 'inscdoge.txt.v2.44') }}</div>
                                <div class="balance">{{ saveNum(multiplication(amount, symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                            <div class="account_balance" v-show="multiplication(detail.demandGift, symbolQuote.rate) != 0">
                                <!-- 赠送活期数量 -->
                                <div class="balance_title">{{ langJson('Gift current', 'inscdoge.txt.v4.7') }}</div>
                                <div class="balance">{{ saveNum(multiplication(detail.demandGift, symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                            <div class="account_balance" v-show="multiplication(division(multiplication(amount, detail.giftRate), 100), symbolQuote.rate) != 0">
                                <!-- 赠送挖矿数量 -->
                                <div class="balance_title">{{ langJson('Bonus mining amount', 'inscdoge.txt.v5.43') }}({{ detail.giftRate || 0 }}%)</div>
                                <div class="balance">{{ saveNum(multiplication(division(multiplication(amount, detail.giftRate), 100), symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>

                            <div class="account_balance">
                                <!-- 获得锁仓数量 -->
                                <div class="balance_title">{{ langJson('Buy locke amount', 'inscdoge.txt.v4.6') }}</div>
                                <div class="balance">{{ saveNum(multiplication(multiplication(plus(1, division(detail.giftRate, 100)), amount), symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>

                            <div class="account_balance">
                                <!-- 将会获得 -->
                                <div class="balance_title">{{ langJson('Will get', 'inscdoge.txt.v1.162') }}</div>
                                <!-- <div class="balance">{{ saveNum(multiplication(plus(multiplication(plus(1, division(detail.giftRate, 100)), amount), detail.demandGift), symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div> -->
                                <div class="balance">{{ saveNum(plus(multiplication(multiplication(plus(1, division(detail.giftRate, 100)), amount), symbolQuote.rate), multiplication(detail.demandGift, symbolQuote.rate)), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            </div>
                        </template>

                        <!-- <div class="account_balance">
                            购买数量
                            <div class="balance_title" v-if="type == '1'">{{ langJson('Buy Amount', 'inscdoge.txt.v2.44') }}</div>
                            <div class="balance_title" v-else-if="type == '2'">{{ langJson('Buy locke amount', 'inscdoge.txt.v4.6') }}</div>

                            <div class="balance">{{ saveNum(amount, detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            <div class="balance">{{ saveNum(multiplication(multiplication(plus(1, division(detail.giftRate, 100)), amount), symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                        </div>
                        <div class="account_balance">
                            赠送
                            <div class="balance_title" v-if="type == '1'">{{ langJson('Gift quantity', 'inscdoge.txt.v2.45') }}</div>
                            <div class="balance_title" v-else-if="type == '2'">{{ langJson('Gift current', 'inscdoge.txt.v4.7') }}</div>

                            <div class="balance">{{ saveNum(division(multiplication(amount, detail.giftRate), 100), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            <div class="balance">{{ saveNum(multiplication(detail.demandGift, symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                        </div>
                        <div class="account_balance">
                            将会获得
                            <div class="balance_title">{{ langJson('Will get', 'inscdoge.txt.v1.162') }}</div>

                            <div class="balance">{{ saveNum(plus(amount, division(multiplication(amount, detail.giftRate), 100)), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                            <div class="balance">{{ saveNum(multiplication(plus(multiplication(plus(1, division(detail.giftRate, 100)), amount), detail.demandGift), symbolQuote.rate), detail.insPrecision) | numberGrap }} {{ detail.lockCoinName }}</div>
                        </div> -->
                        <div class="account_balance">
                            <!-- 余额 -->
                            <div class="balance_title">{{ langJson('Balance', 'inscdoge.txt.v1.163') }}</div>
                            <!-- {{ detail.coinName }} -->
                            <div class="balance">{{ saveNum(detail.meBalance || 0, detail.usdtPrecision) | numberGrap }} {{ detail.coinName }}</div>
                        </div>
                    </div>
                    <div class="model_btns">
                        <div class="model_btn">
                            <BTN class="btn_item" type="line" @click="close">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
                        </div>
                        <div class="btn_grap"></div>
                        <div class="model_btn">
                            <BTN class="btn_item" type="orange" @click="next">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
                        </div>
                    </div>
                </div>
                <ApproveToken slot="step2" :token="inToken" @close="close" @finash="Invest"></ApproveToken>
                <LoadModel slot="step3"></LoadModel>
                <Hash slot="step4" @close="close" :hash="hash" :chainId="detail.chainId" :goHint="true"></Hash>
            </StepRun>
        </div>
    </Model>
</template>
<script>
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            feeRate: 0,
            tradeObj: null,
            hash: '',
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: [Number, String],
            default: 1,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
        amount: {
            type: [String, Number],
            default: 0,
        },
        usdtAmount: {
            type: [String, Number],
            default: 0,
        },
    },
    created() {},
    watch: {
        show(n) {
            if (n) {
            }
        },
    },
    methods: {
        async Invest() {
            this.next()
            try {
                // let { invest }
                let tx = await this.buyProject(this.inToken)

                console.log('tx', tx)
                if (tx) {
                    this.hash = tx

                    let { chainId } = this.detail
                    let { dispatch, commit } = this.$store
                    await dispatch('pushHash', {
                        chainId,
                        txHash: this.hash,
                    })
                    // 支付成功
                    this.pushMsg('success', this.langJson('Payment success', 'inscdoge.txt.v1.164'))
                    this.next()
                } else {
                    this.failed()
                }
                // let { id, buyNumber, chainId } = this.detail
                // console.log('id, buyNumber, chainId', id, buyNumber, chainId)
                // let pid
                // switch (this.type) {
                //     case 1:
                //         pid = `early_${id}`
                //         break
                //     case 2:
                //         pid = `current_${id}`
                //         break
                //     case 3:
                //         pid = `identity_${id}`
                //         break
                // }

                // let result = await invest.buy({
                //     pid,
                //     num: buyNumber,
                // })

                // if (result && result.transactionHash) {
                //     this.hash = result.transactionHash
                //     let { dispatch, commit } = this.$store
                //     await dispatch('pushHash', {
                //         chainId,
                //         hash: this.hash,
                //     })
                //     await this.$parent.balanceOf()

                //     // 使用this.$options.filters[]方式获取（本地/全局）过滤器
                //     let temp = this.$options.filters['numberGrap']

                //     let time = new Date()
                //         .toLocaleString('zh', {
                //             hour12: false,
                //             timeZone: 'Asia/Shanghai',
                //             year: 'numeric',
                //             month: '2-digit',
                //             day: '2-digit',
                //             hour: '2-digit',
                //             minute: '2-digit',
                //             second: '2-digit',
                //         })
                //         .replace(/\//g, '-')
                //     let obj = {
                //         name: this.detail.title,
                //         amount: temp(this.amount) || this.amount,
                //         symbolVo: this.symbolVo.name,
                //         hash: this.hash,
                //         time: time,
                //         type: this.type,
                //         chainId: this.detail.chainId,
                //     }
                //     await commit('shop_back_info', obj)

                //     this.pushMsg('success', this.langJson('支付成功','inscdoge.txt.v1.165'))
                //     this.next()
                // } else {
                //     this.failed()
                // }
            } catch (err) {
                console.log('支付失败', err)
                this.failed()
            }
        },
        failed() {
            this.close()
            // 支付失败
            this.pushMsg('error', this.langJson('Payment failure', 'inscdoge.txt.v1.166'))
        },
        close() {
            this.$emit('change', false)
            this.$emit('completeStaking')
        },
        next() {
            if (this.detail.meBalance == 0 || !this.detail.meBalance) {
                // 余额不足
                this.pushMsg('error', this.langJson('Not sufficient funds', 'inscdoge.txt.v1.167'))
                return
            }
            this.$refs.step.next()
        },
    },
    computed: {
        yieldMonth() {
            return (rate) => {
                let result = multiplication(rate, 30)
                result = saveNum(result, 2)
                result = isNaN(result) ? 0 : result
                return result
            }
        },

        earningsSymbolVo() {
            try {
                return this.detail.earningsSymbolVo
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        symbolVo() {
            try {
                let { coinId } = this.detail
                return this.findSymbol(coinId) ? this.findSymbol(coinId) : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        usdAmount() {
            let { coinId } = this.detail
            return saveNum(this.usdRate(this.amount, coinId), 2)
        },
        inToken() {
            let { coinContractAddress, coinName, id } = this.detail
            let contract = coinContractAddress

            return {
                id,
                contractType: 'ERC20',
                contract,
                amount: this.usdtAmount,
                token: coinName,
                checkBalance: true,
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .play_cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: rgba(6, 6, 6, 0.4);

        backdrop-filter: blur(10px);
        border-radius: 4px;
        margin-top: 20px;
        box-sizing: border-box;
    }

    .play_cell:last-child {
        border-bottom: none;
    }

    .buy_detail {
        .model_btns {
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .btn_grap {
                width: 20px;
            }

            .model_btn {
                flex: 1;
            }
        }

        .account_balance {
            .balance {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--font1);

                .type_tag {
                    font-size: 12px;
                    line-height: 14px;

                    padding: 4px 10px;
                    border-radius: 4px;
                    border: 1px solid #2ca631;
                    color: #2ca631;
                    background-color: rgba($color: #2ca631, $alpha: 0.2);
                }
            }

            .down {
                color: var(--danger_color);
            }

            .up {
                color: var(--success_color);
            }

            .balance_title {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: var(--font1);
            }

            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid var(--border2);
            padding: 0 16px;
            box-sizing: border-box;
        }

        .info_item {
            .price_item {
                .price_amount {
                    display: flex;
                    align-items: flex-end;

                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 24px;
                    color: var(--font1);
                    margin-top: 12px;
                    word-break: break-all;
                    flex-wrap: wrap;
                    color: #ff6821;

                    .symbol_icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }

                    .price_usdt {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: var(--font1);
                        margin-left: 10px;
                    }
                }

                .price_title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--font1);
                }

                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .price_left {
                flex: 1;
            }

            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 0px;
            padding: 24px 16px;
            box-sizing: border-box;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .model_top {
        .top_right {
            cursor: pointer;
        }

        .top_left {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font1);
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    padding: 24px;
    box-sizing: border-box;
    width: 400px;
    // width: calc(100% - 30px);
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>
