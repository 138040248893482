<template>
    <div id="app">
        <div class="main_bg">
            <div class="position_bg"></div>

            <div id="main_frame" class="main_frame">
                <transition :duration="200" mode="out-in" appear enter-active-class="animated animate__fadeInDown" leave-active-class="animated animate__fadeOutDown">
                    <router-view />
                </transition>

                <!-- 全局提示 -->
                <GT></GT>
                <!-- 菜单侧边栏 -->
                <SideMenu></SideMenu>
                <!-- 链接钱包 -->
                <WalletLink></WalletLink>
                <!-- 语言切换 -->
                <LangSet></LangSet>
                <!-- 进取类型 -->
                <!-- <YieldType></YieldType>
                <YieldType2></YieldType2> -->

                <!--  logo / 账号名称 -->
                <!-- <EditNameOrIcon v-model="editModel" @editAccName="nameHand" /> -->
                <!--   修改账号名称 -->
                <!-- <EditName v-model="editNameModel" /> -->
                <!--   填写邀请码 -->
                <AddInviter v-model="addInviterModel" />
                <!--   提现弹窗 -->
                <Withdraw v-model="withdrawModel" />
                <!--   提现确认弹窗 -->
                <Withdraw_ins_usdt_tip v-model="withdrawModel_ins_usdt_tip" />
                <!--   活期划转至提现 弹窗 -->
                <WithdrawAdd v-model="withdrawAddModel" />
                <!--   内部转账 -->
                <InternalTransfer v-model="internalTransferModel" />
                <!-- <TicketBuy v-model="tipShow"></TicketBuy> -->
                <Theme v-show="false" />
            </div>
        </div>
    </div>
</template>

<script>
import tools from '@/mixin/tools'
export default {
    name: 'App',
    data() {
        return {
            headShow: true,
            headView: {
                menu: false,
                logo: true,
                search: false,
                link: true,
                create: true,
                portrait: true,
                wallet: true,
                theme: true,
            },
            footShow: true,
            code: 0,
        }
    },
    provide() {
        return {
            showHead: this.showHead,
            showFoot: this.showFoot,
            headViewSet: this.headViewSet,
            resetHeadView: this.resetHeadView,
            regCode: this.regCode,
        }
    },
    created() {
        // 自动连接

        window.logout = () => {
            let { name } = this.$route
            // 如果是 含 personal 的路由则回到首页 team
            if (name.indexOf('personal') != -1 || name.indexOf('invest') != -1 || name.indexOf('team') != -1) {
                this.jump_index()
            }
        }

        window.globalInterTime()
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.autoConnect()
            }, 2000)
            this.initAbi()
            this.getInvitedCode()
        })
    },
    methods: {
        getInvitedCode() {
            setTimeout(() => {
                if (this.$route.params.invitedCode) {
                    // if (!tools.getCookie('invitedCode') && this.$route.query.invitedCode) {
                    tools.setCookie('invitedCode', this.$route.params.invitedCode)
                }
            }, 2000)
        },
        async nameHand() {
            await this.loginOf(() => {
                this.$store.commit('editNameModel', true)
            }, false)
        },
        async initAbi() {
            this.lock_btn = true
            try {
                let paths = ['/market/index', '/h5_setting']
                let pathname = window.location.pathname
                let isAsync =
                    paths.some((item) => {
                        return pathname.indexOf(item) != -1
                    }) || pathname == '/'
                // 加载合约ABI
                if (isAsync) {
                    abiGet()
                } else {
                    await abiGet()
                }
            } catch (err) {
                console.log(err)
                this.pushMsg('error', err)
            }
            this.lock_btn = false
        },
        showHead(status) {
            this.headShow = status
        },
        headViewSet(obj) {
            this.headView = Object.assign(this.headView, obj)
        },
        resetHeadView() {
            this.headView = Object.assign(this.headView, {
                menu: false,
                logo: true,
                search: true,
                link: true,
                create: true,
                portrait: true,
                wallet: true,
                theme: true,
            })
        },
        showFoot(status) {
            this.footShow = status
        },
        regCode() {
            this.code += 1
            return this.code
        },
    },
    beforeDestroy() {
        // 重置 定时器 数据
        window.globalCloseTime()
    },
}
</script>

<style lang="scss">
.main_bg {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    color: #fff;

    .position_bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        z-index: 10;
        // background-color: #000;
    }

    .main_frame {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        overflow: auto;
        z-index: 20;

        background-color: #000;
    }
}
</style>
