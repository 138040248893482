<template>
    <div class="play_cell">
        <div class="cell_head">
            <div class="cell_title success">
                {{ item.projectName }}
                <div class="val">{{ saveNum(item.coinAmount, usdtPrecision) | numberGrap }} &nbsp;{{ item.coinName }}</div>
            </div>
        </div>
        <div class="cell_main">
            <div class="cell_item">
                <div class="key">{{ langJson('Amount', 'inscdoge.txt.v3.13') }}</div>
                <!-- <div class="val">{{ saveNum(plus(item.amount, division(multiplication(item.amount, item.giftRate), 100)), insPrecision) | numberGrap }} &nbsp;{{ item.releaseCoinName }}</div> -->
                <div class="val">{{ saveNum(plus(item.lockAmount, item.giftCurrentAmount), insPrecision) | numberGrap }} &nbsp;{{ item.releaseCoinName }}</div>
            </div>
            <!-- <div class="cell_item">
                <div class="key">{{ langJson('Expense', 'inscdoge.txt.v4.8') }}</div>
                <div class="val">{{ item.coinAmount | numberGrap }} &nbsp;{{ item.coinName }}</div>
            </div> -->
            <div class="cell_item">
                <div class="key">{{ langJson('Time', 'inscdoge.txt.v3.15') }}</div>
                <div class="val">{{ changeTime(item.createTime, 'minute') }}</div>
            </div>
            <div class="cell_item">
                <div class="key">{{ langJson('State', 'inscdoge.txt.v3.21') }}</div>
                <div v-if="item.status == 0" class="val fail">{{ langJson('Lose', 'inscdoge.txt.v3.22') }}</div>
                <div v-if="item.status == 1" class="val success">{{ langJson('Success', 'inscdoge.txt.v3.23') }}</div>
            </div>
            <div class="cell_item">
                <div class="key">{{ langJson('Hash', 'inscdoge.txt.v3.24') }}</div>
                <div class="val1 address" @click="openTx(item.txHash, item.chainId)">
                    <div class="text">{{ item.txHash }}</div>
                    <GI class="copy" name="copy" type="svg" ws="14" hs="14" @click="copy(item.txHash)"></GI>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['item', 'usdtPrecision'],
    data() {
        return {
            expirationTime: '',
            insPrecision: '2',
        }
    },
    computed: {
        accountType() {
            return (item) => {
                try {
                    if (item.accountType == 1) {
                        return this.langJson('Normal', 'inscdoge.txt.v3.16')
                    }
                    if (item.accountType == 2) {
                        return this.langJson('Lock', 'inscdoge.txt.v3.17')
                    }
                    if (item.accountType == 3) {
                        return this.langJson('Income', 'inscdoge.txt.v3.18')
                    }
                    return '--'
                } catch (error) {
                    return ''
                }
            }
        },
        typeName() {
            return (item) => {
                try {
                    if (item.type == 1) {
                        return this.langJson('Entry', 'inscdoge.txt.v3.19')
                    }
                    if (item.type == 2) {
                        return this.langJson('Outgoing', 'inscdoge.txt.v3.20')
                    }
                    return '--'
                } catch (error) {
                    return ''
                }
            }
        },
        chainIcon() {
            return (item) => {
                try {
                    return this.findChain('id', item.chainId).chainIcon
                } catch (err) {
                    console.log(err)
                    return ''
                }
            }
        },
        expireTime() {
            return (item) => {
                let { expireTime } = item

                return this.timeStr(expireTime)
            }
        },
        pass() {
            let amount = parseFloat(this.item.presentAmount)
            amount = isNaN(amount) ? 0 : amount
            return amount > 0
            // return true
        },

        // 收益和本金是否全部提取
        isNotHave() {
            let temp
            if (this.item.symbolName == this.item.incomeSymbolName) {
                // 收益是显示在本金里面的
                if (this.item.currentAmount == 0) {
                    temp = true
                } else {
                    temp = false
                }
            } else {
                if (this.item.currentAmount == 0 && this.item.completeIncome == 0) {
                    temp = true
                } else {
                    temp = false
                }
            }

            return temp
        },

        // 是否可以提取
        isPeriodical() {
            let temp
            // projectType 1 活期  2定期
            if (this.item.projectType == 1) {
                temp = true
            }
            if (this.item.projectType == 2) {
                this.getExpirationTime()
                let now = new Date().getTime()
                // 定期是否到期 可以提取
                if (this.item.status == 2) {
                    temp = true
                } else {
                    temp = false
                }
            }
            return temp
        },
        changeTime() {
            try {
                // 时 分 秒
                return (date, num = 'minute') => {
                    if (!!date) {
                        if (num === 'minute') {
                            let index = date.lastIndexOf(':')
                            return date.slice(0, index) ? date.slice(0, index) : ''
                        }
                    }
                    return ''
                }
            } catch (error) {
                return ''
            }
        },
    },
    created() {},
    methods: {
        goToPage() {
            let { commit } = this.$store
            commit('get_earn_info', {})
            let obj = {
                chainId: this.item.chainId,
                projectId: this.item.projectId,
                recordId: this.item.id,
            }
            commit('get_earn_info', obj)

            this.jump_projectId_Detail(this.item.id)
        },
        jumpAsset() {
            this.jump_assets(this.item.projectId)
        },
        // 获取到期时间
        getExpirationTime() {
            this.expirationTime = this.item.expireTimestamp
        },
        extractHand() {
            this.$emit('extractHand')
        },
    },
}
</script>
<style lang="scss" scoped>
.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 50%;

    .chain {
        display: flex;
        align-items: center;
        max-width: 100%;
        border-radius: 4px;
        background-color: var(--bg11);
        padding: 0 6px;
        /* box-shadow: -0px -0px 4px 2px rgba(#2ca631, 0.08); */

        background-color: #92169900;
        border: 1px solid #2ca631bb;
        color: #2ca631;
        transform: scale(0.78);

        .belong_chain {
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            background-color: #ffffff;
            border-radius: 50%;
            margin-left: -1px;
        }

        .val {
            /* color: var(--bg11); */
            max-width: calc(100% - 16px);
            font-size: 12px;
            line-height: 22px;
            padding-left: 4px;
        }
    }
}

.play_cell {
    .cell_btn {
        padding: 16px;
        padding-top: 0;
        margin-top: 4px;
    }

    .cell_head {
        .cell_title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            line-height: 16px;

            color: var(--font1);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .success {
            color: #2ca631;
        }

        .cell_jump {
            transform: rotate(180deg);
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--border2);
        padding: 16px;

        font-weight: bold;
        background-image: -webkit-linear-gradient(left, #ffe072, #ff8024);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .cell_main {
        .cell_item {
            width: 50%;
            margin-top: 12px;

            .key {
                color: var(--font2);
                font-size: 12px;
                line-height: 12px;

                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .val {
                color: var(--font1);
                font-size: 14px;
                line-height: 20px;

                margin-top: 6px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .val1 {
                display: flex;
                align-items: center;
                // justify-content: center;
                gap: 6px;

                color: var(--font1);
                font-size: 14px;
                line-height: 20px;

                margin-top: 6px;
                width: 100%;

                .text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .copy {
                    flex-shrink: 0;
                }
            }

            .address {
                cursor: pointer !important;
                color: #ff6821 !important;
                text-decoration: underline;
            }

            .success {
                color: #2ca631;
            }

            .fail {
                color: #f65a5a;
            }
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px 16px;
        padding-top: 0px;
    }

    display: flex;
    flex-direction: column;

    background-color: var(--bg11);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-bottom: 16px;
}

.play_cell:last-child {
    border-bottom: none;
}
</style>
