<template>
    <div class="num">
        <div class="buyName">
            <div class="right_card5_btn cursor" @click="decrementHand()">-</div>
            <input type="number" class="right_card5_num" v-model="number" @blur="checkHand()" />
            <div class="right_card5_btn cursor" @click="dosageHand()">+</div>
        </div>
        <div class="right_card5_color cursor" @click="maxHand()">
            {{ langJson('Max', 'inscdoge.txt.v1.96') }}
            <!--  {{ max }} -->
        </div>
    </div>
</template>
<script>
export default {
    props: ['buyNumber', 'max', 'min'],
    data() {
        return {
            number: 1,
        }
    },
    computed: {},
    watch: {
        number() {
            this.updateBuyNum()
        },
    },
    created() {},
    mounted() {},
    methods: {
        checkHand() {
            if (this.number > this.max) {
                this.number = this.max
                this.pushMsg('error', this.langJson('The maximum quantity is', 'inscdoge.txt.v1.97') + ' ' + this.max)
            }
            if (this.number < 1) {
                this.number = 1
                this.pushMsg('error', this.langJson('The quantity should be at least', 'inscdoge.txt.v1.98') + ' ' + this.min)
            }
        },
        updateBuyNum() {
            this.$emit('update:buyNumber', this.number)
        },
        // 最大
        maxHand() {
            this.number = this.max
        },
        // 减数量
        decrementHand() {
            if (this.number > this.min) {
                this.number--
            }
        },
        // 加数量
        dosageHand() {
            if (this.number < this.max) {
                this.number++
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    .buyName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 42px;
        width: calc(100% - 80px);
        box-sizing: border-box;
        flex-wrap: wrap;
        border-radius: 4px;
        background-color: #ffffff0f;
        overflow: hidden;
        box-shadow: -0px -0px 4px 2px rgba(#000000, 0.08);
        .right_card5_btn {
            box-sizing: border-box;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            color: var(--font1);
            // color: #191b1c;
            // background: #ffffff;
            background-image: var(--bg16);
            box-shadow: -0px -0px 4px 2px rgba(#000000, 0.08);
            padding-bottom: 4px;
        }

        .right_card5_num {
            /* flex: 1; */
            font-size: 18px;
            font-weight: 500;
            // color: #333333;
            color: var(--font1);
            border: none;
            width: auto;
            max-width: 120px;
            min-width: 30px;
            text-align: center;
            background-color: #ffffff00;
            background-image: -webkit-linear-gradient(left, #200b2e, #41284f, #200b2e);
            height: 100%;
            @media screen and (max-width: 1081px) {
                max-width: calc(100% - 92px);
                min-width: 60px;
            }
        }
    }
    .right_card5_color {
        flex: 1;
        height: 42px;
        width: 42px;
        line-height: 42px;
        font-size: 14px;
        margin-left: 20px;
        text-align: center;
        // background: #ffffff;
        color: var(--font1);
        background-image: var(--bg16);
        box-shadow: -0px -0px 4px 2px rgba(#000000, 0.08);
        font-weight: 500;
        // color: #191b1c;
        border-radius: 4px;
    }
}
</style>
