<template>
    <div class="assets">
        <Back :title="langJson('HISTORY', 'inscdoge.txt.v3.10')"></Back>
        <div class="bg">
            <div class="list">
                <DivScroll ref="scroll" @get="get">
                    <template v-slot:main="{}">
                        <HisItem v-for="(item, index) in list" :key="index" :item="item" @extractHand="extractHand(item)" />
                    </template>
                    <template v-slot:bottom="{ loadMore }" v-if="!lenArray(list)">
                        <div style="display: flex; align-items: center; justify-content: center; flex: 1">
                            <div v-if="loadMore" style="height: 100px; display: flex; align-items: center; justify-content: center">
                                <LoadIcon salce="0.7">
                                    <Loading6 />
                                </LoadIcon>
                                <div style="padding-left: 6px">
                                    {{ langJson('加载中','inscdoge.txt.v5.5') }}
                                </div>
                            </div>
                            <NuData heith="200" v-else></NuData>
                        </div>
                    </template>
                </DivScroll>
                <!-- <Page v-model="pageNum" :all="total" :size="pageSize" @change="init"></Page> -->
            </div>
            <!-- <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            pageNum: 1,
            pageSize: 10,
            extractModel: false,
            extractProModel: false,
            profit: {}, // 收益
            principal: {}, // 本金
            detail: {}, // 提取预览
        }
    },
    computed: {
        isLogin() {
            try {
                return this.walletDetail && this.walletDetail.address && this.loginToken
            } catch (error) {
                return false
            }
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                console.log('isLogin', a, b)
                if (!!a && !b) {
                    await this.get(1)
                }
            },
        },
    },
    async created() {
        // await this.init()
    },
    mounted() {},
    methods: {
        // 完成提取刷新列表
        completeExtract() {
            this.init()
        },
        confirm(item) {
            this.detail = {
                ...this.detail,
                ...item,
            }
            this.extractProModel = true
        },
        async extractHand(item) {
            this.extractModel = !this.extractModel
            this.detail = {
                ...this.detail,
                ...item,
            }
        },

        async get(page) {
            const obj = {
                // chain: 'eth',
                // sort: 'vol',
                // keyWord: '搜索词',
            }

            const name = 'list'
            let pageNum = page || this.pageNum
            const pageSize = this.pageSize

            let { dispatch } = this.$store
            let res = await dispatch('get_flow_list', { pageNum, pageSize, ...obj })
            res = res.rows
            this.pageNum = this.addList({ pageNum, name, res })
            this.$refs.scroll.getEnd()
        },
        addList({ name, res = [], pageNum = 1 }) {
            if (res.length) {
                if (pageNum == 1) {
                    this[name] = res
                } else {
                    this[name].push(...res)
                }
                pageNum++
            }
            return pageNum
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            flex: 1;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            max-height: calc(100% - 87px);

            /* font-size: 20px;
            font-weight: 700;
            line-height: 21px; */
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>


