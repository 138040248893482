<template>
    <Model v-model="show" @mask="close()">
        <div class="model_main" slot="main">
            <div class="title">{{ langJson('Invitation code', 'inscdoge.txt.v1.152') }}</div>
            <div class="line"></div>
            <input v-model="form.inviteCode" type="text" class="information_item" :placeholder="langJson('Please enter the invitation code', 'inscdoge.txt.v1.153')" />

            <BTN class="model_btns" type="orange" :disable="!pass" @click="edit">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
        </div>
    </Model>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                inviteCode: '',
            },
        }
    },
    computed: {
        pass() {
            try {
                return this.checkPass(this.form.inviteCode, ['unull'], false)
            } catch (err) {
                console.log('err', err)
                return false
            }
        },
    },
    watch: {},
    created() {},
    methods: {
        close() {
            this.$store.commit('addInviterModel', false)
        },
        async edit() {
            if (this.pass) {
                try {
                    let { dispatch } = this.$store
                    this.loading = true
                    let result = await dispatch('invitePost', this.form)
                    this.loading = false
                    if (result) {
                        this.pushMsg('success', this.langJson('添加成功', 'inscdoge.txt.v1.154'))
                        await dispatch('personalGet')
                        this.close()
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.model_main {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;
    .title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        text-align: center;
    }
    .line {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        background-color: #2a2a2a;
    }
    .information_item {
        width: 100%;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;

        height: 36px;
        padding: 8px, 16px;
        border-radius: 10px;
        background-color: #000000;
        color: #ffffff;
        margin-top: 15px;

        &::placeholder {
            color: #727272;
        }
    }
    .model_btns {
        margin-top: 15px;
    }
}
@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
