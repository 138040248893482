<template>
    <div class="frame_head">
        <!-- :style="{ height: `${$nav_h}px` }" -->
        <div class="head_left" @click="!noShowIcon ? back() : ''">
            <GI class="left_ico" v-if="!noShowIcon" name="back" ws="7.78" hs="12.73" :noClickStop="true"></GI>
            <div class="head_title">{{ title }}</div>
        </div>
        <div class="head_right">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        noShowIcon: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
.frame_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 20px;
    /* padding: 29px 20px; */
    /* padding: 29px 31px; */
    position: fixed;

    backdrop-filter: blur(24px);
    top: 0;
    left: 0;
    right: 0;
    z-index: 1999;
    /* border-bottom: 1px solid #615a74; */
    box-sizing: border-box;
    .head_left {
        display: flex;
        align-items: center;
        max-width: 100%;

        .left_ico {
            margin-right: 11.22px;
        }
        .head_title {
            // margin-left: 11.22px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 16px;
            /* line-height: 18px; */
            font-family: Montserrat;
            font-weight: 800;
            background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .head_right {
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
