var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type == 'svg' && _vm.src == '' && !_vm.invariant && _vm.color)?_c('SvgBase64',{attrs:{"color":_vm.color,"name":_vm.name,"ws":_vm.ws,"hs":_vm.hs,"noClickStop":_vm.noClickStop},on:{"click":function($event){return _vm.$emit('click')}}}):_c('div',{staticClass:"conent",style:({
        width: _vm.ws ? `${_vm.ws}px` : '100%',
        height: _vm.hs ? `${_vm.hs}px` : 'auto',
        lineHeight: _vm.hs ? `${_vm.hs}px` : 'auto',
    }),on:{"click":function($event){return _vm.handSvg()}}},[(_vm.lazyload)?[(!_vm.noClickStop)?_c('img',{class:['icon lazyload', _vm.rotate ? 'rotate' : ''],style:({
                width: _vm.ws ? `${_vm.ws}px` : '100%',
                height: _vm.hs ? `${_vm.hs}px` : 'auto',
                lineHeight: _vm.hs ? `${_vm.hs}px` : 'auto',
            }),attrs:{"src":"/source/svg/default_icon.svg","data-src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name}.${_vm.type}`},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}}):_c('img',{class:['icon lazyload', _vm.rotate ? 'rotate' : ''],style:({
                width: _vm.ws ? `${_vm.ws}px` : '100%',
                height: _vm.hs ? `${_vm.hs}px` : 'auto',
            }),attrs:{"src":"/source/svg/default_icon.svg","data-src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name}.${_vm.type}`},on:{"click":function($event){return _vm.$emit('click')}}})]:[(!_vm.noClickStop)?_c('img',{class:['icon', _vm.rotate ? 'rotate' : ''],style:({
                width: _vm.ws ? `${_vm.ws}px` : '100%',
                height: _vm.hs ? `${_vm.hs}px` : 'auto',
                lineHeight: _vm.hs ? `${_vm.hs}px` : 'auto',
            }),attrs:{"src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name}.${_vm.type}`},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}}):_c('img',{class:['icon', _vm.rotate ? 'rotate' : ''],style:({
                width: _vm.ws ? `${_vm.ws}px` : '100%',
                height: _vm.hs ? `${_vm.hs}px` : 'auto',
            }),attrs:{"src":_vm.src ? _vm.src : `/source/${_vm.type}/${_vm.name}.${_vm.type}`},on:{"click":function($event){return _vm.$emit('click')}}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }