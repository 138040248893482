<template>
    <div class="assets">
        <Back :title="langJson('Notice', 'inscdoge.txt.v5.46')"></Back>
        <div class="bg">
            <div class="list">
                <DivScroll ref="scroll" @get="get">
                    <template v-slot:main="{}">
                        <noticeItem v-for="(item, index) in list" :key="index" :item="item" @change="changeItem" />
                    </template>
                    <template v-slot:bottom="{ loadMore, type }" v-if="!lenArray(list)">
                        <div style="display: flex; align-items: center; justify-content: center; flex: 1">
                            <div v-show="(loadMore || load) && type == 1">
                                <div style="height: 100px; display: flex; align-items: center; justify-content: center">
                                    <LoadIcon salce="0.7">
                                        <Loading6 />
                                    </LoadIcon>
                                    <div style="padding-left: 6px">
                                        {{ langJson('加载中', 'inscdoge.txt.v5.5') }}
                                    </div>
                                </div>
                            </div>
                            <template v-if="(loadMore || load) && type == 1"></template>
                            <NuData heith="200" v-else-if="!(loadMore || load)"></NuData>
                        </div>
                    </template>
                </DivScroll>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            load: false,

            type: '0',
            subType: '0',

            list: [],
            pageNum: 1,
            pageSize: 25,
        }
    },
    computed: {
        isLogin() {
            try {
                return this.walletDetail && this.walletDetail.address && this.loginToken
            } catch (error) {
                return false
            }
        },
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                if (!!a && !b) {
                    await this.get({ page: '1', type: 1 })
                }
            },
        },
    },
    async created() {},
    mounted() {},
    methods: {
        async get({ page = '', type = 1, top = false }) {
            if (this.load) {
                return
            }
            this.load = true
            try {
                const obj = {
                    type: 2,
                }

                const name = 'list'
                let pageNum = page || this.pageNum
                const pageSize = this.pageSize
                if (pageNum == 1) {
                    this[name] = []
                }

                let { dispatch } = this.$store
                let res = await dispatch('getArticleList', { pageNum, pageSize, ...obj })

                res = res.rows
                this.pageNum = this.addList({ pageNum, name, res })

                this.$refs.scroll.getEnd(type)
            } catch (error) {
                this.$refs.scroll.getEnd(type)
            }
            this.load = false
        },
        addList({ name, res = [], pageNum = 1 }) {
            if (res.length) {
                if (pageNum == 1) {
                    this[name] = res
                } else {
                    this[name].push(...res)
                }
                pageNum++
            }
            return pageNum
        },
        changeItem(item) {
            this.jump_notice_detail(item.articleId)
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            flex: 1;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            max-height: calc(100% - 87px);
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
