import api from '@/api/market'
const marketModule = {
    state: {
        // 市场首页最新上架列表
        newlistMarket: [],
        // 市场首页立即购买列表
        nowBuylistMarket: [],
        // 市场首页出价竞拍列表
        bidlistMarket: [],
        // 市场首页即将结束列表
        endlistMarket: [],
        // 市场nft列表
        nftlistMarket: [],
        // 市场首页集合排名
        collectRanking: [],
        // nft详情
        detail: {},
        // nft出价列表
        offers: [],
        // nft作者的其他nft列表
        authorOther: [],
        // 版税签名
        royaltySign: {},
        // ipfs地址
        ipfsUrl: '',
        //推荐列表
        marketRecommendList: {},
        historyMarket: [],
    },
    getters: {},
    mutations: {
        royaltySign(state, royaltySign) {
            state.royaltySign = royaltySign
        },
        newlistMarket(state, newlistMarket) {
            state.newlistMarket = newlistMarket
        },
        nowBuylistMarket(state, nowBuylistMarket) {
            state.nowBuylistMarket = nowBuylistMarket
        },
        bidlistMarket(state, bidlistMarket) {
            state.bidlistMarket = bidlistMarket
        },
        endlistMarket(state, endlistMarket) {
            state.endlistMarket = endlistMarket
        },
        nftlistMarket(state, nftlistMarket) {
            state.nftlistMarket = nftlistMarket
        },
        collectRanking(state, collectRanking) {
            state.collectRanking = collectRanking
        },
        detail(state, detail) {
            state.detail = detail
        },
        offers(state, offers) {
            state.offers = offers
        },
        authorOther(state, authorOther) {
            state.authorOther = authorOther
        },
        ipfsUrl(state, ipfsUrl) {
            state.ipfsUrl = ipfsUrl
        },
        setMarketRecommendList(state, marketRecommendList) {
            state.marketRecommendList = marketRecommendList
        },
        historyMarket(state, historyMarket) {
            state.historyMarket = historyMarket
        },
    },

    actions: {
        // 获取nft交易记录
        async historyMarket(context, params) {
            try {
                let result

                result = await api.nftHistory(params.id, params)
                result = this.apiResult(result)
                context.commit('historyMarket', result.data.records)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取版税签名
        async royaltySignGet(context, params) {
            try {
                let result = await api.royaltySign(params)
                result = this.apiResult(result)
                context.commit('royaltySign', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 立即购买出价
        async buyOfferPost(context, params) {
            try {
                let result = await api.buyOfferPost(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 降价
        async lowPrice(context, params) {
            try {
                let result = await api.salePriceUpdate(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 收藏
        async nftCollect(context, id) {
            try {
                let result = await api.nftCollect(id)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 推荐
        async nftWay(context, id) {
            try {
                let result = await api.nftWay(id)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 浏览
        async nftSee(context, id) {
            try {
                let result = await api.nftSee(id)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        /* 详情页 */
        // 获取nft详情
        async marketDetail(context, id) {
            try {
                // 请求接口
                let result = await api.marketDetail(id)
                // 处理结果
                result = this.apiResult(result)
                context.commit('detail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取nft出价列表
        async marketOffers(context, id) {
            try {
                let result = await api.detailOffers(id)
                result = this.apiResult(result)
                context.commit('offers', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取nft作者的其他作品
        async marketAuthorOther(context, params) {
            try {
                let result = await api.marketAuthorOther(params)
                result = this.apiResult(result)
                context.commit('authorOther', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        /* 首页 */
        // 获取市场首页最新产品
        async marketNewGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'new',
                }
                // 请求接口
                let result = await api.marketSales(params)
                // 处理结果
                result = this.apiResult(result)
                // 缓存数据
                this.apiCache('market', 'newlistMarket', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取市场首页立即购买产品
        async marketNowBuyGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    status: 1,
                }
                let result = await api.marketSales(params)
                result = this.apiResult(result)
                this.apiCache('market', 'nowBuylistMarket', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取市场首页出价竞拍产品
        async marketBidGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    status: 2,
                }
                let result = await api.marketSales(params)
                result = this.apiResult(result)
                this.apiCache('market', 'bidlistMarket', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取市场开结束的产品
        async marketEndGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 9,
                    orderByColumn: 'expire',
                }
                let result = await api.marketSales(params)
                result = this.apiResult(result)
                this.apiCache('market', 'endlistMarket', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取市场首页展示集合
        async marketCollect() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'chainCoinPrice',
                    isAsc: 'desc',
                }
                let result = await api.marketCollectranking(params)
                result = this.apiResult(result)
                this.apiCache('market', 'collectRanking', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        /* 列表页 */
        // 获取市场产品列表
        async marketProject(context, params = {}) {
            try {
                let result = await api.marketSales(params)
                result = this.apiResult(result)
                context.commit('nftlistMarket', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 市场轮播图 */
        async getMarketRecommendList(context) {
            try {
                // 请求接口
                let result = await api.getRecommendList({ type: 1 })

                // 处理结果
                result = this.apiResult(result)
                // 设置
                context.commit('setMarketRecommendList', result.data)
                this.apiCache('market', 'setMarketRecommendList', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default marketModule
