<template>
    <div v-if="!noClickStop" :style="{ width: ws + 'px', height: hs + 'px', 'background-image': 'url(' + svgImg + ')' }" class="icon" @click.stop="$emit('click')"></div>

    <div v-else :style="{ width: ws + 'px', height: hs + 'px', 'background-image': 'url(' + svgImg + ')' }" class="icon" @click="$emit('click')"></div>
</template>

<script>
import svgData from '/public/source/svgjs/index.js'
// 教程
// 1.public\static\img\svg 下载svg到这
// 2.https://jakearchibald.github.io/svgomg/ 压缩svg
// 3.public\static\img\svg\js 在新建同名.js文件 放入 压缩后的svg
// 4.压缩后的色值必须是 #6位 才能被成功改色
// 5.<GI color="#442b73" name="edit" ws="12" hs="12"></GI>// 使用
export default {
    name: 'SvgName',
    props: {
        name: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        hs: {
            type: [String, Number],
            default: '12',
        },
        ws: {
            type: [String, Number],
            default: '12',
        },
        noClickStop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            svgImg: '',
        }
    },
    watch: {
        color() {
            try {
                this.colorChange()
            } catch (error) {
                console.log('svgImg', error)
            }
        },
        name() {
            try {
                this.nameChange()
                this.colorChange()
            } catch (error) {
                console.log('svgImg', error)
            }
        },
    },
    created() {
        try {
            this.nameChange()
            this.colorChange()
        } catch (error) {
            console.log(this.name + '.svg .js fial is not defind', error)
        }
    },
    methods: {
        nameChange() {
            if (this.name && svgData[this.name]) {
                this.svgImg = this.svgToUrl(svgData[this.name])
            }
        },
        colorChange() {
            if (this.color) {
                this.svgImg = this.changeColor(this.svgImg, this.color)
            }
        },
        // 动态替换 base64 中的 # 色值
        changeColor(url, color) {
            let res = url.replace(/%23[a-zA-Z0-9]{6}/g, color.replace('#', '%23'))
            return res
        },
        // svg压缩后转 base64
        svgToUrl(url) {
            var encoded = url
                .replace(/<!--(.*)-->/g, '')
                .replace(/[\r\n]/g, ' ')
                .replace(/"/g, `'`)
                .replace(/%/g, '%25')
                .replace(/&/g, '%26')
                .replace(/#/g, '%23')
                .replace(/{/g, '%7B')
                .replace(/}/g, '%7D')
                .replace(/</g, '%3C')
                .replace(/>/g, '%3E')

            let res = '"' + `data:image/svg+xml,${encoded}` + '"'
            return res
        },
    },
}
</script>
<style lang="scss" scoped>
.icon {
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>
