<template>
    <!-- 选择列表输入框 -->
    <div class="NFTSortBtn" :class="{ open }" @click="open = !open" @mouseleave="closeHand" :style="{ height }">
        <div class="NFTSortBtnOpen" :style="{ background: bgc, height }">
            <div class="selectName" :style="{ color: handSelect ? '#ffffff' : '#ffffff' }">{{ selectvalue }}</div>
            <GI class="downNFTSortBtn" name="new_down" type="svg" ws="10" hs="10" :noClickStop="true"></GI>
            <GI class="upNFTSortBtn" name="new_down" type="svg" ws="10" hs="10" :noClickStop="true"></GI>
        </div>
        <div class="NFTSortBtnList">
            <div class="listItem1"></div>
            <div class="NFTSortBtnList2">
                <div
                    class="listItem"
                    :class="{ active: key == item.key }"
                    v-for="(item, index) in list"
                    :key="index"
                    @click.stop="
                        select(item, true)
                        open = false
                    "
                    :style="{ height, 'line-height': height }"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SelectionListInput',
    props: ['orderType', 'list', 'noSelect', 'previewText', 'bgc', 'height'],
    data() {
        return {
            // list_l: [],
            key: '',
            open: false,
            handSelect: false, //是否手动选中
            selectvalue: '',
            id: '',
        }
    },
    watch: {
        list() {
            if (this.previewText) {
                this.selectvalue = this.list[0].title
            }
        },
    },
    created() {
        if (this.previewText) {
            this.selectvalue = this.list[0].title
        }
    },
    mounted() {
        if (!this.noSelect) {
            this.select(this.list[0])
        }
    },
    destroyed() {},
    beforeDestroy() {},
    methods: {
        select(item, selectTextColor = false) {
            this.handSelect = selectTextColor
            this.selectvalue = item.name
            this.$emit('select', item.key)
            this.$emit('select_detail', item)
            this.key = item.key
        },
        openHand() {
            this.open = true
        },
        closeHand() {
            this.open = false
        },
    },
}
</script>
<style lang="scss" scoped>
.NFTSortBtn {
    position: relative;

    &.open .NFTSortBtnList {
        max-height: 382px;
        /* padding: 12px 0 !important; */
        padding-top: 4px;
    }
    .downNFTSortBtn {
        display: inline-block;
        transition: all 0.25s;
    }
    .upNFTSortBtn {
        display: none;
        transition: all 0.25s;
    }

    &.open .NFTSortBtnOpen .downNFTSortBtn {
        display: none;
        transition: all 0.25s;
        transform: rotate(180deg);
    }

    &.open .NFTSortBtnOpen .upNFTSortBtn {
        display: inline-block;
        transition: all 0.25s;
        transform: rotate(180deg);
    }

    &.open .NFTSortBtnOpen {
        /* border-color: #2da2d9; */
    }

    .NFTSortBtnOpen {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        font-size: 14px;
        width: 100%;
        border-radius: 8px;
        /* background: #313843; */
        background: #000000;
        border: 1px solid transparent;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        cursor: pointer;
        .selectName {
            white-space: nowrap;
            overflow: hidden;
            width: calc(100% - 20px);
        }
        .downNFTSortBtn {
            transition: all 0.25s;
            transform: rotate(0deg);
        }
        .upNFTSortBtn {
            transition: all 0.25s;
            transform: rotate(0deg);
        }
    }

    .NFTSortBtnList {
        position: absolute;
        width: 100%;
        background: transparent;
        max-height: 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: all 0.25s;
        z-index: 999;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        .NFTSortBtnList2 {
            width: 100%;
            max-height: 382px;
            border-radius: 8px;
            /* 黑色主题模块颜色 */
            background: #000000;
            /* background: #313843; */
            box-sizing: border-box;

            /* 1 */
            /* border: 1px solid #2da2d9; */
            transition: all 0.25s;
            z-index: 999;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 5px 0;
        }
        /* &:hover {
            max-height: 382px;
        } */

        .listItem1 {
            width: 100%;
            height: 4px !important;
            background: transparent;
            flex-shrink: 0;
        }
        .listItem {
            width: calc(100% - 20px);
            margin: 5px auto;
            line-height: 1;
            box-sizing: border-box;
            font-size: 14px;
            font-size: 14px;
            cursor: pointer;
            border-radius: 8px;
            color: #fff;
            padding-left: 10px;

            &.active {
                color: #ff7724;
                background: #202020;
                box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.13);
            }
            &:hover {
                color: #ff7724;
                background: #202020;
                box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.13);
            }
        }
    }
}
</style>
