import http from "./base";


/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * 正在售卖的商品
 */
let marketSales = (params) => {
  return http.get("/product", params);
};
/**
 * @param pageNum 第几页
 * @param pageSize 每页数量
 * @param orderByColumn chainCoinPrice
 * @param isAsc desc
 * 获取集合排名数据
 */
let marketCollectranking = (params) => {
  return http.get("/index/groupStatistics", params);
};
/**
 * @param id NFT ID
 * 获取nft详情
 */
let marketDetail = (id) => {
  return http.get(`/product/detail/${id}`);
};
/**
 * 商品的出价列表
 */
let detailOffers = (id) => {
  return http.get(`/maker/productMakerList/${id}`);
};

/**
 * @param pageNum   第几页
 * @param pageSize  每页数量
 * @param creator   地址
 * @param chainName  链名
 * 获取作者者的其他作品
 */
let marketAuthorOther = params => {
  return http.get("/sellProduct/user/list", params);
};
/**
 *浏览
 * @param address    地址
 * @param chainName  链名
 */
let nftSee = (id, params = {}) => {
  return http.post(`/view/${id}`, params);
};

/**
 *收藏
 * @param address    地址
 * @param chainName  链名
 */
 let nftCollect = (id, params = {}) => {
  return http.post(`/collect/${id}`, params);
};
/**
 *推荐
 * @param address    地址
 * @param chainName  链名
 */
let nftWay = (id, params = {}) => {
  return http.post(`/way/${id}`, params);
};

/**
 * @param tradeType  0转账 1-立即购买 2-竞拍 3-立即购买出价
 *交易记录
 */
let nftHistory = (id,params = {}) => {
  return http.get(`/trade/list/${id}`, params);
};

/**
 * @param address
 * @param contractAddress
 * @param tokenId
 * @param chainId 
 * @param buyNum
 * @param productId
 * 获取版税签名
 */
let royaltySign = (params = {}) => {
  return http.get(`/sign/sell`, params);
};
/**
 * 
 * @param productId 
 * @param price
 * @param startTime
 * @param gooder
 * @param endTime
 * @param isSpecific
 * @param specificAddr
 * @param coinId
 * @param sign
 * @param chainId
 * 降价出售
 */

let salePriceUpdate = params => {
  return http.postJson(`/product/updatePrice`, params);
};

/**
 * @param productId
 * @param gooder
 * @param price
 * @param coinId
 * @param type
 * @param chainId
 * @param expireTime
 * @param sign
 * @param buyNum
 * 对上架商品进行出价邀约
 */
 let buyOfferPost = params => {
  return http.postJson(`/maker/makerOffer`, params);
};
// 获取页面轮播图
let getRecommendList= (params={}) => { 
  return http.get('/index/recommend/list',params)
}


/**
 *冻结数据
 */
let frozenMeta = id => {
  return http.post(`/product/frozen/${id}`);
};

export default { 
    marketSales,
    marketCollectranking,
    marketDetail,
    detailOffers,
    marketAuthorOther,
    nftSee,
    nftCollect,
    nftWay,
    nftHistory,
    royaltySign,
    salePriceUpdate,
    buyOfferPost,
    getRecommendList,
    frozenMeta
 };
