<template>
    <div class="frame_out">
        <Back :title="langJson('Team details', 'inscdoge.txt.v1.57')"></Back>
        <div class="bg">
            <div class="top">
                <input v-model="filterCode" type="text" class="filterCode" :placeholder="langJson('Please enter your account ID', 'inscdoge.txt.v5.49')" @keyup.enter="$event.target.blur()" @blur="blurInput" />
            </div>

            <div class="play_item">
                <div class="bg1">
                    <TreeList :list="list" class="bgColor" />
                    <NuData heith="200" :loading="loading" v-if="!lenArray(list) || loading"></NuData>
                </div>
                <pageBottom class="pageBottom"></pageBottom>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            filterCode: '',
            parentId: '',
            list: [],
            props: {
                label: 'name',
                children: 'zones',
                isLeaf: 'leaf',
            },
        }
    },
    created() {
        // this.init()
        this.loading = false
        this.personInfo()
    },
    methods: {
        async personInfo() {
            let { dispatch } = this.$store
            await dispatch('yourself')
            // await dispatch('personalGet')
            // await dispatch('agentInfo')

            let obj = JSON.parse(JSON.stringify(this.yourself))
            this.$set(this.list, 0, {
                ...obj,
            })
        },

        async init() {
            try {
                let { dispatch } = this.$store
                let res = await dispatch('inviteTree', { parentId: this.parentId })
                this.list = res.data
            } catch (err) {
                console.log(err)
            }
        },
        async blurInput() {
            try {
                this.loading = true

                if (!this.filterCode.trim()) {
                    let obj = JSON.parse(JSON.stringify(this.yourself))
                    this.$set(this.list, 0, {
                        ...obj,
                    })

                    this.loading = false
                } else {
                    let { dispatch } = this.$store
                    let res = await dispatch('inviteTreeChild', { username: this.filterCode })

                    if (res.data) {
                        let obj = JSON.parse(JSON.stringify(res.data))

                        this.$set(this.list, 0, {
                            ...obj,
                            show: false,
                        })
                    } else {
                        this.list = []
                    }
                    this.loading = false
                }
            } catch (err) {
                console.log(err)
                this.loading = false
            }
        },
        searchSuggest() {
            console.log(1)
        },
    },
}
</script>
<style lang="scss" scoped>
.filterCode {
    box-sizing: border-box;
    padding: 2px 10px;
    width: 100%;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    // text-align: center;

    height: 36px;
    padding: 8px, 16px;
    border-radius: 7px;
    background-color: #615a7465;
    color: #ffffff;
    margin-bottom: 15px;

    &::placeholder {
        color: #727272;
    }
}
.frame_out {
    width: 100%;
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;

        .top {
            min-width: calc(100vw - 32px);

            // background-color: var(--bg11);
            // width: auto;
            // overflow-y: auto;
            // height: calc(100% - 87px);
            padding-top: 87px;
            margin: 0px 16px 16px 16px;
        }

        .play_item {
            display: flex;
            flex-direction: column;
            min-width: calc(100vw - 32px);

            // background-color: var(--bg11);
            // width: auto;
            overflow-y: auto;
            height: calc(100% - 87px);
            // padding-top: 87px;
            margin: 0px 16px 16px 16px;
            .bg1 {
                // backdrop-filter: blur(10px);
            }
            .pageBottom {
                flex: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
            .bgColor {
            }
            /* background-color: rgba($color: #ffffff, $alpha: 0.1); */
            /* border: 1px solid var(--border2);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0); */
        }
    }
}
</style>
