<template>
    <Model v-model="show" @mask="close()">
        <div class="model_main" slot="main">
            <div class="title">
                <template v-if="pledgeAssets.incomeAccount > 0">{{ langJson('Transfer', 'inscdoge.txt.v5.2') }}</template>
                <template v-else>{{ langJson('Transfer Reminder', 'inscdoge.txt.v5.30') }}</template>
            </div>
            <div class="line"></div>
            <template v-if="pledgeAssets.incomeAccount > 0">
                <!-- balance -->
                <!-- current -->
                <!-- ins -->

                <div class="list">
                    <div class="item">
                        <div class="key">
                            <GI class="current" name="current" type="svg" ws="20" hs="20"></GI>
                            <div class="t">{{ langJson('从', 'inscdoge.txt.v5.38') }}</div>
                            <div class="t2">{{ langJson('活期资金', 'inscdoge.txt.v5.36') }}</div>
                        </div>

                        <GI class="new_right" name="new_right" type="svg" ws="6.48" hs="10.61"></GI>
                    </div>
                    <div class="item">
                        <div class="key">
                            <GI class="balance" name="balance" type="svg" ws="20" hs="20"></GI>
                            <div class="t">{{ langJson('到', 'inscdoge.txt.v5.39') }}</div>
                            <div class="t2">{{ langJson('提现资金', 'inscdoge.txt.v5.37') }}</div>
                        </div>

                        <GI class="new_right" name="new_right" type="svg" ws="6.48" hs="10.61"></GI>
                    </div>

                    <div class="item">
                        <div class="key">
                            <GI class="ins" name="ins" type="svg" ws="20" hs="20"></GI>
                            <div class="t">INS</div>
                        </div>

                        <GI class="new_right" name="new_right" type="svg" ws="6.48" hs="10.61"></GI>
                    </div>
                </div>

                <div class="information_item_title">{{ langJson('数量', 'inscdoge.txt.v3.13') }}</div>
                <div class="information_item">
                    <input v-model="form.amount" type="number" :placeholder="langJson('最小', 'inscdoge.txt.v5.35') + ' ' + (withdrawFee.transferMin || '')" />
                    <div class="sub">
                        <div class="INS">INS</div>
                        <div class="Max" @click="maxHand()">{{ langJson('最大', 'inscdoge.txt.v1.124') }}</div>
                    </div>
                </div>
                <div class="bar">
                    {{ langJson('Available', 'inscdoge.txt.v5.31') }}：
                    <div class=" ">{{ saveNum(pledgeAssets.incomeAccount || '0', 2) | numberGrap }}&nbsp;INS</div>
                </div>
            </template>
            <div class="desc">
                <template v-if="pledgeAssets.incomeAccount > 0"></template>
                <template v-else>{{ langJson('当前没有可划转资金', 'inscdoge.txt.v5.34') }}</template>
            </div>

            <BTN class="model_btns" type="orange" :disable="!pass" @click="edit" :load="loading">
                <template v-if="pledgeAssets.incomeAccount > 0">{{ langJson('确认划转', 'inscdoge.txt.v5.40') }}</template>
                <template v-else>{{ langJson('Transfer', 'inscdoge.txt.v5.2') }}</template>
            </BTN>
        </div>
    </Model>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                coinId: '',
                amount: '',
            },
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        pass() {
            try {
                return this.checkPass(this.form.amount, ['unull'], false) && this.pledgeAssets.incomeAccount > 0
            } catch (err) {
                console.log('err', err)
                return false
            }
        },
    },
    watch: {},
    created() {},
    methods: {
        maxHand() {
            if (this.pledgeAssets.incomeAccount) {
                this.$set(this.form, 'amount', this.pledgeAssets.incomeAccount)
            }
        },
        close() {
            this.$set(this.form, 'coinId', '')
            this.$set(this.form, 'amount', '')
            this.$store.commit('withdrawAddModel', false)
        },
        async edit() {
            if (this.form.amount * 1 > this.pledgeAssets.incomeAccount * 1) {
                this.pushMsg('warning', this.langJson('Insufficient quantity', 'inscdoge.txt.v5.29'))
                return
            }
            // /api/withdraw/transfer

            console.log('this.pass', this.pass)
            if (this.pass) {
                // coinId amount address

                try {
                    console.log('this.walletDetail.chainDetail.id', this.walletDetail.chainDetail.id)
                    console.log('chainSymbol', this.chainSymbol)
                    let INS = this.chainSymbol.find((item) => {
                        return item.name == 'INS'
                    })
                    console.log('INS', INS)
                    this.form.coinId = INS.id

                    let { dispatch } = this.$store
                    this.loading = true
                    let result = await dispatch('withdraw_transfer', this.form)
                    this.loading = false
                    if (result) {
                        this.pushMsg('success', this.langJson('Current transfer successful with normal withdrawal balance', 'inscdoge.txt.v5.33'))
                        this.close()
                        await dispatch('pledgeAssets')
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.model_main {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;
    .title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        text-align: center;
    }
    .line {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        background-color: #2a2a2a;
    }
    .bar {
        display: flex;
        font-size: 12px;
        margin-top: 8px;
        color: #cccccc;
        .active {
            color: #56ffa4;
        }
    }
    .list {
        margin-top: 15px;
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #000;
            height: 36px;
            border-radius: 0 0 10px 10px;
            padding: 0 13px;
            box-sizing: border-box;
            &:first-child {
                border-radius: 10px 10px 0 0;
            }
            &:last-child {
                border-radius: 10px;
                margin-top: 16px;
            }
            .key {
                display: flex;
                align-items: center;
                font-family: Montserrat;
                font-size: 12px;
                color: #cccccc;
                .t {
                    //styleName: M 12;

                    margin-left: 11px;
                }
                .t2 {
                    margin-left: 20px;
                }
            }
            .new_right {
                margin-right: 6.67px;
            }
        }
    }
    .information_item_title {
        margin-top: 26px;
        font-size: 12px;
        color: #cccccc;
    }
    .information_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;
        /* padding: 0 16px; */
        padding-left: 13px;
        padding-right: 19px;
        width: 100%;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        height: 36px;
        /* padding: 8px, 16px; */
        border-radius: 10px;
        background-color: #000000;
        color: #ffffff;
        margin-top: 6px;

        &::placeholder {
            color: #cccccc;
        }

        input {
            flex: 1;
            color: #ffffff;
            height: 100%;
            padding-right: 16px;
        }
        .sub {
            display: flex;
            .INS {
                font-family: Montserrat;
                font-size: 12px;
                line-height: 20px;
                color: #cccccc;
            }
            .Max {
                font-family: Montserrat;
                font-size: 12px;
                line-height: 20px;
                color: #ff6821;
                margin-left: 8px;
            }
        }
    }
    .desc {
        margin: 0 auto;
        margin-top: 15px;
        width: 68%;
        font-family: Montserrat;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #727272;
    }
    .model_btns {
        margin-top: 15px;
    }
}
@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
