<template>
    <div class="preSale">
        <div class="header">
            <FrameHead></FrameHead>

            <div class="head_box">
                <div class="btn">
                    <!-- 0-待上架 1-已上架 2-已下架 -->
                    <span v-if="project_info.status == 0">{{ langJson('Pending listing', 'inscdoge.txt.v4.4') }}</span>
                    <span v-if="project_info.status == 1">{{ langJson('NOW LIVE', 'inscdoge.txt.v2.1') }}</span>
                    <span v-if="project_info.status == 2">{{ langJson('Delisted', 'inscdoge.txt.v4.5') }}</span>
                </div>
            </div>

            <div class="title">
                <span class="title_1">${{ project_info.lockCoinName }}</span>
                <span class="title_2">{{ langJson('Pre-Sale', 'inscdoge.txt.v2.2') }}</span>
            </div>
            <div class="time">
                <div v-if="isChina">{{ showChinaTimeMeth(project_info.startTime, 1) }} - {{ showChinaTimeMeth(project_info.endTime, 2) }}</div>
                <div v-else class="text">{{ showTimeMeth(project_info.startTime, 1) }} - {{ showTimeMeth(project_info.endTime, 2) }}</div>
            </div>

            <div class="info_title">{{ langJson('Limited to ', 'inscdoge.txt.v2.3') }}{{ project_info.limitDailyAmount | numberGrap }}{{ langJson(' pieces/day', 'inscdoge.txt.v2.4') }}</div>
            <div v-show="isLogin" class="info_title title2 fontOver">{{ langJson('Total amount：', 'inscdoge.txt.v2.5') }} {{ project_info.totalAmount | numberGrap }}</div>
            <div :class="['info_text', !isLogin ? 'noLogin' : '']">{{ langJson('Sale start at 00:00 every day', 'inscdoge.txt.v2.6') }}</div>

            <GI class="icon1 absolute" name="icon1" type="svg" ws="30" hs=""></GI>
            <GI class="icon2 absolute" name="icon2" type="svg" ws="66" hs=""></GI>
            <GI class="icon3 absolute" name="icon3" type="svg" ws="30" hs=""></GI>
            <GI class="icon4 absolute" name="icon4" type="svg" ws="30" hs=""></GI>
        </div>

        <div class="page_main">
            <div class="bg_hd">
                <div class="left">
                    <div class="title1">${{ project_info.lockCoinName }}</div>
                    <div class="title2">{{ langJson('Pre-Sale', 'inscdoge.txt.v2.2') }}</div>
                </div>
                <div class="right">
                    <div class="title">{{ langJson('End in', 'inscdoge.txt.v2.7') }}</div>
                    <div class="main_time">
                        <div class="time_item">
                            <div class="text">{{ timeObj.days }}</div>
                            <div class="unit">{{ langJson('DAY', 'inscdoge.txt.v2.8') }}</div>
                        </div>
                        <div class="time_line">:</div>
                        <div class="time_item">
                            <div class="text">{{ timeObj.hours }}</div>
                            <div class="unit">{{ langJson('HRS', 'inscdoge.txt.v2.9') }}</div>
                        </div>
                        <div class="time_line">:</div>
                        <div class="time_item">
                            <div class="text">{{ timeObj.minutes }}</div>
                            <div class="unit">{{ langJson('MIN', 'inscdoge.txt.v2.10') }}</div>
                        </div>
                        <div class="time_line">:</div>
                        <div class="time_item">
                            <div class="text">{{ timeObj.seconds }}</div>
                            <div class="unit">{{ langJson('SEC', 'inscdoge.txt.v2.11') }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!isLogin" class="price">
                <div class="progress">
                    <div class="total">
                        <div class="title spaceBetween">
                            <div class="left">{{ langJson('Pre-Sale Amount', 'inscdoge.txt.v2.12') }}</div>
                            <div class="right">
                                <span>{{ scliceLen(project_info.totalAmount - project_info.surplusAmount) | numberGrap }} {{ project_info.lockCoinName }}</span>
                                <span>&nbsp;/&nbsp;{{ project_info.totalAmount | numberGrap }}{{ project_info.lockCoinName }}</span>
                            </div>
                        </div>
                        <div class="chart_box" :style="{ '--totalRate': rateMeth(2) }">
                            <div class="chart">
                                <div class="orange"></div>
                            </div>
                            <span>{{ rateMeth(2) }}</span>
                        </div>
                    </div>
                </div>

                <div class="market_price">
                    <div class="left">{{ langJson('Real-time Market Price', 'inscdoge.txt.v2.13') }}</div>
                    <div class="right">
                        <!-- {{ scliceLen(symbolQuote.currentPrice) | numberGrap }} {{ project_info.lockCoinName }} -->
                        <div>{{ setAccuracy(division(1, symbolQuote.rate), 'USDT') | numberGrap }}&nbsp;{{ project_info.coinName }}</div>
                        <div>
                            <span>
                                <GI v-if="showReplace(symbolQuote.rateChange)" class="" name="up" type="svg" ws="16" hs="16"></GI>
                                <GI v-else class="" name="down" type="svg" ws="16" hs="16"></GI>
                                <!-- {{ scliceLen(symbolQuote.yesterdayPrice) | numberGrap }} -->
                                <!-- {{ setAccuracy(reduce(division(1, symbolQuote.rate), division(1, symbolQuote.yesterdayRate)), 'USDT') | numberGrap }}&nbsp;{{ project_info.coinName }} -->
                                {{ setAccuracy(division(1, symbolQuote.yesterdayRate), 'USDT') | numberGrap }}&nbsp;{{ project_info.coinName }}
                            </span>
                            <span>
                                <GI v-if="showReplace(symbolQuote.rateChange)" class="" name="up" type="svg" ws="16" hs="16"></GI>
                                <GI v-else class="" name="down" type="svg" ws="16" hs="16"></GI>
                                {{ replace(symbolQuote.rateChange) }}
                            </span>
                        </div>
                    </div>
                </div>

                <BTN class="wallet" type="orange" @click="loginOf">{{ langJson('Connect Wallet', 'inscdoge.txt.v2.14') }}</BTN>
            </div>

            <div v-if="isLogin" class="price">
                <div class="title spaceBetween">${{ project_info.lockCoinName }} &nbsp;{{ langJson('Price', 'inscdoge.txt.v2.15') }}</div>
                <div class="price_title">{{ setAccuracy(division(1, symbolQuote.rate), 'USDT') | numberGrap }}{{ project_info.coinName }}</div>
                <div class="box_btn">
                    <div class="btn orange">
                        <div class="text">{{ project_info.lockCoinName }}</div>
                        <GI class="" name="swap" type="svg" ws="20" hs="20"></GI>
                        <div class="text">{{ project_info.coinName }}</div>
                    </div>
                    <div class="btn line">
                        <div class="text">{{ langJson('24H increase', 'inscdoge.txt.v2.16') }}</div>
                        <GI v-if="showReplace(symbolQuote.rateChange)" class="" name="up" type="svg" ws="20" hs="20"></GI>
                        <GI v-else class="" name="down" type="svg" ws="20" hs="20"></GI>
                        <div class="text">{{ replace(symbolQuote.rateChange) }}</div>
                    </div>
                </div>

                <div class="progress">
                    <div class="day">
                        <div class="title">{{ langJson('Pre-Sale progress of the day', 'inscdoge.txt.v2.17') }}</div>
                        <div class="chart_box">
                            <div class="chart" :style="{ '--dayRate': rateMeth(1) }">
                                <div class="orange"></div>
                            </div>
                            <span>{{ rateMeth(1) }}</span>
                        </div>
                    </div>
                    <div class="total">
                        <div class="title">{{ langJson('Total Pre-sale progress', 'inscdoge.txt.v2.18') }}</div>
                        <div class="chart_box" :style="{ '--totalRate': rateMeth(2) }">
                            <div class="chart">
                                <div class="orange"></div>
                            </div>
                            <span>{{ rateMeth(2) }}</span>
                        </div>
                    </div>
                </div>

                <div class="buy">
                    <div class="title_buy">
                        {{ langJson('Subscription', 'inscdoge.txt.v2.19') }}
                        <GI @click="jump_purchase(project_info.chainId, project_info.id)" class="his" name="his" ws="24" hs="24" :noClickStop="true"></GI>
                    </div>

                    <div class="box">
                        <div class="left">
                            <IN class="entrance" v-model="text1" :placeholder="langJson('INS:', 'inscdoge.txt.v2.20')" @input="keyInput('to')" :disabled="isNoClick" :isTip="true" :maxValue="project_info.maxAmount"></IN>
                            <IN class="exit" v-model="text2" :placeholder="langJson('USDT:', 'inscdoge.txt.v2.21')" @input="keyInput('from')" :disabled="isNoClick" :isTip="true" :minValue="project_info.coinAmount"></IN>
                        </div>
                        <div @click="openModel" :class="['right', loading || (!this.text1 && !this.text2) || isNoClick ? 'lucency' : '']">
                            <div class="text">
                                {{ langJson('Buy', 'inscdoge.txt.v2.22') }}
                            </div>
                            <div v-show="loading" class="bxo">
                                <img class="laoding rotate" src="/source/png/backup_loading.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="assets">
                <div v-if="!isLogin" class="boxNoLink">
                    <div class="title">
                        {{ langJson('My Assets', 'inscdoge.txt.v2.23') }}
                        <div class="right">
                            <GI class="" name="logo" type="svg" ws="21" hs="21"></GI>
                            {{ langJson('INS', 'inscdoge.txt.v2.24') }}
                        </div>
                    </div>
                    <div class="cont">{{ langJson('Connect Wallet to continue', 'inscdoge.txt.v2.25') }}</div>
                </div>

                <div v-if="isLogin" class="boxLink">
                    <div class="title">{{ langJson('My Assets | Locked ', 'inscdoge.txt.v2.26') }}{{ saveNum(accountList && accountList.balance ? accountList.balance : 0, insPrecision) }} {{ langJson('INS', 'inscdoge.txt.v2.24') }}</div>
                </div>
            </div>

            <div v-if="isLogin" class="team">
                <div class="title">{{ langJson('My Team', 'inscdoge.txt.v2.27') }}</div>

                <div class="border"></div>

                <div class="invitation">
                    <div class="title">{{ langJson('Invitation Link', 'inscdoge.txt.v2.28') }}</div>
                    <IN class="link" :keywork="urlNow" :placeholder="langJson('link', 'inscdoge.txt.v2.29')" :disabled="true">
                        <template #append>
                            <GI class="" name="copy" type="svg" ws="18" hs="18" @click="copy(urlNow)"></GI>
                        </template>
                    </IN>
                    <div class="title3" :class="{ underline: !information.parentUserName }" @click="inviterHand">
                        <div class="left">{{ langJson('Inviter', 'inscdoge.txt.v2.30') }}：</div>
                        <div class="right">{{ information.parentUserName || langJson('Add', 'inscdoge.txt.v2.31') }}</div>
                    </div>

                    <div class="title2">
                        <div class="left">{{ langJson('Total number of team INS', 'inscdoge.txt.v1.70') }}</div>
                        <!-- insPrecision -->
                        <div class="right active">{{ saveNum(teamInfo.teamTotalIns || 0, insPrecision) | numberGrap }} INS</div>
                    </div>
                    <div class="title2">
                        <div class="left">{{ langJson('Team Members', 'inscdoge.txt.v2.32') }}</div>
                        <div class="right">{{ teamInfo.teamNum | numberGrap }}</div>
                    </div>

                    <div class="footer">
                        <div class="left">
                            {{ langJson('Team details', 'inscdoge.txt.v2.33') }}
                        </div>
                        <div class="right">
                            <BTN class="Check" type="orange" @click="jump_teamTree">{{ langJson('Check', 'inscdoge.txt.v2.34') }}</BTN>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <pageBottom></pageBottom>
        <!-- 支付 totalPay -->
        <PayToken type="1" v-model="payModel" :amount="text1" :usdtAmount="text2" :detail="{ ...project_info, insPrecision, usdtPrecision }"></PayToken>
    </div>
</template>

<script>
export default {
    data() {
        return {
            insPrecision: '2',
            usdtPrecision: '0',
            urlNow: '',
            // dayRate: '10%',
            // totalRate: '5%',

            text1: '',
            text2: '',

            refer: null,
            timer: null,
            payModel: false,

            inputTime: null,
            fromTime: null,
            stateType: 'to',
            loading: false,
        }
    },
    async created() {
        // 项目
        // await this.gainInfo()
        // 用户账户列表
        // await this.gainAssets()
        // await this.teamGet()

        this.timer = setInterval(() => {
            this.refer = new Date().getTime()
        }, 1000)
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                this.loading = false
                // console.log('----------', 'a:', a, 'b:', b)
                if (!this.project_info.lockCoinId) {
                    await this.gainInfo()
                }
                if (!!a && !b) {
                    if (this.project_info.lockCoinId) {
                        await this.gainAssets()
                    }
                    await this.teamGet()
                }
            },
            immediate: true,
        },
    },
    computed: {
        isChina() {
            try {
                let lang = localStorage.getItem('Language') == 'zh_CN' || false
                return lang
            } catch (error) {
                return false
            }
        },
        information() {
            try {
                let result = this.globalPersonal.information
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        timeObj() {
            try {
                return this.refer && this.remainTime(this.project_info.endTime)
            } catch (error) {
                return {}
            }
        },
        address() {
            return this.walletDetail.address
        },
        isLogin() {
            try {
                let state = this.walletDetail && this.walletDetail.address && this.loginToken
                return this.address && state
            } catch (error) {
                return false
            }
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        isNoClick() {
            let state = this.timeObj.days == '00' && this.timeObj.hours == '00' && this.timeObj.minutes == '00' && this.timeObj.seconds == '00'

            if (state) {
                return true
            }

            return false
        },
    },
    methods: {
        // 添加邀请人
        async inviterHand() {
            if (this.information.parentUserName) {
                //  邀请关系已绑
                this.pushMsg('warning', this.langJson('The invitation relationship is bound.', 'inscdoge.txt.v2.35'))
                return
            }
            await this.loginOf(() => {
                this.$store.commit('addInviterModel', true)
            }, false)
        },
        async teamGet() {
            let { dispatch } = this.$store
            await dispatch('teamInfo')
            await dispatch('personalGet')

            let protocol = window.location.protocol //http / https
            let host = window.location.host //主域名 + 端口

            if (!!this.$route.query && this.$route.query.page) {
                this.urlNow = `${protocol}//${host}/${this.$route.query.page}/${this.information.inviteCode}`
            } else {
                this.urlNow = `${protocol}//${host}/preSale/${this.information.inviteCode}`
            }

            // let INS = this.chainSymbol.find((item) => {
            //     return item.name == 'INS'
            // })
            // this.insPrecision = INS.precision || 6
            let USDT = this.chainSymbol.find((item) => {
                return item.name == 'USDT'
            })
            this.usdtPrecision = USDT.precision || 6
        },
        async openModel() {
            if ((!this.text1.trim() && !this.text2.trim()) || this.loading) return

            if (this.project_info.status == 0) {
                // 项目未上架
                this.pushMsg('error', this.langJson('Item not listed', 'inscdoge.txt.v2.38'))
                this.loading = false
                return
            } else if (this.project_info.status == 2) {
                this.loading = false
                // 项目已下架
                this.pushMsg('error', this.langJson('Item on the shelf', 'inscdoge.txt.v2.39'))
                return
            }

            // let state = this.timeObj.days == '00' && this.timeObj.hours == '00' && this.timeObj.minutes == '00' && this.timeObj.seconds == '00'
            if (this.isNoClick) {
                this.pushMsg('error', this.langJson('Project completed', 'inscdoge.txt.v2.36'))
                return
            }

            this.loading = true
            await this.loginOf(async () => {
                // if (!this.information.parentUserName) {
                //     this.loading = false
                //     this.$store.commit('addInviterModel', true)
                //     // 请先绑定上级
                //     this.pushMsg('error', this.langJson('Bind the upper level first', 'inscdoge.txt.v2.37'))

                //     return
                // }

                if (isNaN(this.text1) || isNaN(this.text2)) {
                    this.loading = false
                    // 输入的格式不正确
                    this.pushMsg('error', this.langJson('The format of the input is incorrect', 'inscdoge.txt.v2.40'))
                    return
                }

                // 实时获取 限额足够吗
                await this.gainVerifyParam()
                console.log('实时获取 限额足够吗', this.verifyParam)

                // 最小购买数量
                // console.log('最小购买数量', this.project_info.minAmount * 1 <= this.text1 * 1)
                // 最大购买数量
                // console.log('最大购买数量', this.project_info.maxAmount * 1 >= this.text1 * 1)
                // 小于 每日剩余数量
                // console.log('小于每日剩余数量', this.project_info.surplusDailyAmount >= this.text1 * 1)
                if (this.project_info.minAmount * 1 <= this.text1 * 1 && this.project_info.maxAmount * 1 >= this.text1 * 1 && this.project_info.surplusDailyAmount >= this.text1 * 1 && this.verifyParam) {
                    let contract = JSON.parse(JSON.stringify(this.project_info.coinContractAddress))
                    let { balance } = await this.getBalance({
                        contract,
                    })
                    console.log(balance, '=========balance========')
                    this.project_info.meBalance = balance

                    this.payModel = true
                    this.loading = false
                    return
                }

                if (this.project_info.maxAmount * 1 < this.text1 * 1) {
                    // 超出最大购买数量
                    this.pushMsg('error', this.langJson('Exceed the maximum purchase quantity', 'inscdoge.txt.v2.41'))
                } else if (this.project_info.minAmount * 1 > this.text1 * 1) {
                    // 小于最小购买数量
                    this.pushMsg('error', this.langJson('Less than the minimum purchase quantity', 'inscdoge.txt.v2.42'))
                } else if (this.project_info.surplusDailyAmount < this.text1 * 1) {
                    // 大于每日剩余数量
                    this.pushMsg('error', this.langJson('Greater than the remaining daily quantity', 'inscdoge.txt.v2.43'))
                }
                this.loading = false
            })
        },
        closeLoading() {
            this.loading = false
        },
        async gainInfo() {
            try {
                let { dispatch } = this.$store
                let id = 1
                await dispatch('projectInfo', id)
            } catch (error) {
                console.log(error)
            }
        },
        async gainAssets() {
            try {
                let { dispatch } = this.$store
                // 用户账户列表，1-正常账户，2-锁仓账户，3-收益账户
                await dispatch('accountList', {
                    type: 2,
                    symbolId: this.project_info.lockCoinId,
                })

                // console.log('accountList', this.accountList)
            } catch (error) {
                console.log(error)
            }
        },
        // 实时汇率
        // async gainSymbolQuote() {
        //     try {
        //         let { dispatch } = this.$store
        //         await dispatch('symbolQuote')
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        async gainVerifyParam() {
            try {
                let { dispatch } = this.$store
                await dispatch('verifyParam', {
                    projectId: this.project_info.id,
                    amount: this.text2,
                })

                // console.log('verifyParam', this.verifyParam)
            } catch (error) {
                console.log(error)
            }
        },

        keyInput(val) {
            this.loading = true
            if (this.inputTime) {
                clearTimeout(this.inputTime)
                this.inputTime = null
            }

            this.inputTime = setTimeout(async () => {
                await this.matrixing(val)

                this.loading = false
            }, 500)
        },

        showTimeMeth(t, type) {
            let date = new Date(t)
            // // 获取年、月、日、小时、分钟、秒
            const year = date.getFullYear()
            const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
            const day = date.getDate()
            // const hour = date.getHours()
            // const minute = date.getMinutes()
            // const second = date.getSeconds()

            // // 输出英文日期格式
            // const englishDate = `${month} ${day}, ${year} ${hour}:${minute}:${second}`
            // console.log('englishDate', englishDate)
            // var dns

            // var m = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December')
            // var w = new Array('Monday', 'Tuseday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday')
            // var d = new Array('st', 'nd', 'rd', 'th')

            if (type === 1) {
                // if (day % 10 < 1 || day % 10 > 3) {
                //     dns = d[3]
                // } else {
                //     dns = d[(dn % 10) - 1]
                //     if (day == 11 || day == 12) {
                //         dns = d[3]
                //     }
                // }

                if (day > 3 && day < 21) return `${month} ${day}th`
                switch (day % 10) {
                    case 1:
                        return `${month} ${day}st`
                    case 2:
                        return `${month} ${day}nd`
                    case 3:
                        return `${month} ${day}rd`
                    default:
                        return `${month} ${day}th`
                }

                // return `${month} ${day}`
            } else if (type === 2) {
                if (day > 3 && day < 21) return `${month} ${day}th ${year}`
                switch (day % 10) {
                    case 1:
                        return `${month} ${day}st ${year}`
                    case 2:
                        return `${month} ${day}nd ${year}`
                    case 3:
                        return `${month} ${day}rd ${year}`
                    default:
                        return `${month} ${day}th ${year}`
                }

                // return `${month} ${day} ${year}`
            }
        },
        showChinaTimeMeth(t, type) {
            let dateObj = new Date(t)
            let y = dateObj.getFullYear()
            let m = dateObj.getMonth() + 1
            let d = dateObj.getDate()

            if (type === 1) {
                // 年月日
                return `${y}年${m}月${d}日`
            } else if (type === 2) {
                // 月日
                return `${m}月${d}日`
            }
            return
        },
        rateMeth(type, length = 2) {
            try {
                let value
                let len

                // 计算当天剩余百分百
                if (type === 1) {
                    // 已购买 数量 = 总数 - 剩余量
                    let buyNumber = this.project_info.limitDailyAmount * 1 - this.project_info.surplusDailyAmount * 1
                    if (buyNumber * 1 <= this.project_info.limitDailyAmount * 1) {
                        value = ((buyNumber * 1) / (this.project_info.limitDailyAmount * 1)) * 100

                        if (value == '0') return '0%'

                        len = String(value).split('.')
                        if (length != 0) {
                            let text
                            if (len.length != 0 && len.length == 2) {
                                text = len[1]
                                len[1] = text.slice(0, length)

                                text = len.join('.')
                                return text + '%'
                            }

                            return value + '%'
                        } else {
                            return len[0] + '%'
                        }
                    } else {
                        return '100%'
                    }
                } else if (type === 2) {
                    // 计算总剩余百分百

                    // 已购买 数量 = 总数 - 剩余量
                    let buyNumber = this.project_info.totalAmount * 1 - this.project_info.surplusAmount * 1
                    if (buyNumber * 1 <= this.project_info.totalAmount * 1) {
                        value = ((buyNumber * 1) / (this.project_info.totalAmount * 1)) * 100

                        if (value === '0' || value === 0) return '0%'

                        len = String(value).split('.')
                        if (length != 0) {
                            let text
                            if (len.length != 0 && len.length == 2) {
                                text = len[1]
                                len[1] = text.slice(0, length)

                                text = len.join('.')
                                return text + '%'
                            }

                            return value + '%'
                        } else {
                            return len[0] + '%'
                        }
                    } else {
                        return '100%'
                    }
                }
            } catch (error) {
                return 0
            }
        },
        scliceLen(str, length = 2) {
            let len = String(str).split('.')
            let text
            if (len.length != 0 && len.length == 2) {
                text = len[1]
                len[1] = text.slice(0, length)

                text = len.join('.')

                return text
            }
            return str
        },
        replace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return str.slice(1)
            }
            return str
        },
        showReplace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return false
            }

            return true
        },
        isGainProject(item, length = 4) {
            if (item) {
                let len = String(item.balance).split('.')
                let text
                if (len.length != 0 && len.length == 2) {
                    text = len[1]
                    len[1] = text.slice(0, length)
                    text = len.join('.')

                    return text
                }
                return item.balance
            }
            return 0
        },
        matrixing(val, length = 6) {
            if (val === 'to') {
                // Number(this.text1) / Number(this.symbolQuote.rate)
                let value = this.division(this.text1, this.symbolQuote.rate)
                let len = String(value).split('.')
                let text
                if (len.length != 0 && len.length == 2) {
                    text = len[1]
                    len[1] = text.slice(0, length)
                    text = len.join('.')
                }
                this.text2 = this.text1 ? String(text) : ''
            } else {
                // Number(this.symbolQuote.rate) * Number(this.text2)
                this.text1 = this.multiplication(this.symbolQuote.rate, this.text2)
                this.text1 = this.text2 ? String(this.text1) : ''
            }
        },
        setAccuracy(value, name, long = 4) {
            let len = String(value).split('.')
            let text

            if (len.length != 0 && len.length == 2) {
                text = len[1]

                let item = this.chainSymbol.find((item) => {
                    return item.name == name
                })
                let length = item && item.precision ? item.precision : long

                len[1] = text.slice(0, length)
                text = len.join('.')
                return text
            }
            return value
        },
    },
    beforeDestroy() {
        clearTimeout(this.inputTime)
        clearInterval(this.timer) //销毁
        this.timer = null
        this.inputTime = null
    },
}
</script>

<style lang="scss" scoped>
.preSale {
    box-sizing: border-box;
    // padding-top: 70px; // 116px
    color: #fff;
    text-align: center;

    .header {
        z-index: 9;
        position: relative;
        padding-top: 70px;
        max-height: 361px;
        background-image: url('/public/source/newfi/live_bg.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .head_box {
            display: flex;

            .btn {
                box-sizing: border-box;
                color: #fff;
                justify-content: center;

                max-width: 264px;
                height: 37px;
                margin: auto;
                margin-top: 46px;

                display: flex;
                padding: 10px 20px 12px 20px;
                justify-content: center;
                align-items: center;

                border-radius: 50px;
                border: 1px solid var(--Linear, #ff6201);
            }
        }

        .title {
            margin-top: 5px;

            span {
                max-width: 100%;
                word-wrap: break-word;
                word-break: break-all;
                overflow: hidden;
            }
            .title_1 {
                margin-right: 15px;
                font-family: Montserrat;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 54px;
            }

            .title_2 {
                font-family: Inconsolata;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;

                background: var(--Linear, linear-gradient(324deg, #ff6201 17.29%, #ffa401 100.12%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .time {
            max-width: 264px;
            margin: 5px auto 13px;
            display: flex;
            padding: 10px 20px 12px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 50px;
            // background: var(--Gradient-Purple, linear-gradient(90deg, #140f6e 66.41%, #4b0ac9 96.41%));
            background: linear-gradient(90deg, #b8a048 66.41%, #796938 96.41%);
            .text {
                font-family: Inconsolata;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .info_title {
            z-index: 9;
            position: relative;
            padding: 0 5px;
            // margin-top: 13px;
            /* XB 16 */
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 28px; /* 175% */
        }

        .title2 {
            margin-top: 10px;
            font-size: 12px;
            line-height: 20px;
        }

        .info_text {
            margin-top: 5px;
            margin-bottom: 5px;

            /* M 12 */
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
        }

        .noLogin {
            margin-bottom: 41px;
        }

        .absolute {
            z-index: -1;
            position: absolute;
        }
        .icon1 {
            top: 50px;
            left: 0;
            flex-shrink: 0;

            opacity: 0.6;
        }
        .icon2 {
            top: 185px;
            left: 0;
            flex-shrink: 0;
        }
        .icon3 {
            top: 250px;
            right: 0;
            flex-shrink: 0;
        }
        .icon4 {
            top: 60px;
            right: 0;
            flex-shrink: 0;
        }
    }

    .page_main {
        .bg_hd {
            box-sizing: border-box;

            display: flex;
            width: 100%;
            padding: 10px 20px;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            // background: var(--Gradient-Purple, linear-gradient(90deg, #140f6e 66.41%, #4b0ac9 96.41%));
            background: linear-gradient(90deg, #b8a048 66.41%, #b6a25b 96.41%);

            .left {
                max-width: 85px;
                .title1 {
                    color: #fff;
                    font-family: Montserrat;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px; /* 150% */
                }
                .title2 {
                    font-family: Inconsolata;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px; /* 160% */

                    background: linear-gradient(324deg, #000 17.29%, #ffa401 100.12%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .right {
                box-sizing: border-box;
                flex: 1;
                display: flex;
                padding: 5px 20px;
                flex-direction: column;
                align-items: center;

                border-radius: 20px;
                background: #766a39; // #040041

                .title {
                    /* M 12 */
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }

                .main_time {
                    .time_item {
                        .text {
                            font-family: Source Sans Pro;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32px; /* 160% */

                            // background: var(--gradient-orange, linear-gradient(324deg, #ff6201 17.29%, #ffa401 100.12%));
                            // background-clip: text;
                            // -webkit-background-clip: text;
                            // -webkit-text-fill-color: transparent;
                            color: #000;
                        }

                        .unit {
                            color: var(--White, var(--Text-Title, #fff));

                            /* P - Source Sans Pro/S - R 14 */
                            font-family: Source Sans Pro;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                        }
                    }
                    .time_line {
                        margin: 0 10px;
                        color: var(--Text-Title, #fff);

                        /* P - Source Sans Pro/S - SB 20 */
                        font-family: Source Sans Pro;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px; /* 160% */
                    }
                    display: flex;
                    align-items: center;
                }
            }
        }

        .price {
            padding: 15px;
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                /* XB 16 */
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 28px; /* 175% */
            }

            //
            .market_price {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    color: #ccc;

                    /* M 12 */
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }

                .right {
                    & > div {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 5px;

                        &:first-child {
                            /* XB 16 */
                            font-family: Montserrat;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 28px; /* 175% */
                        }

                        &:last-child {
                            color: var(--Orange, #ff6821);

                            /* XB 12 */
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 20px; /* 166.667% */
                        }

                        span {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            .wallet {
                margin-top: 30px;
                margin-bottom: 24px;
                // display: block;
            }
            //

            .price_title {
                word-wrap: break-word;
                margin-top: 12px;
                text-align: center;
                font-family: Montserrat;
                font-size: 36px;
                font-style: normal;
                font-weight: 800;
                line-height: 28px; /* 77.778% */

                background: var(--PurpleOrange-Shade, linear-gradient(90deg, #ffb14b 0%, #af3300 49.48%, #690058 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .box_btn {
                margin-top: 36px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;
                gap: 9px;

                .btn {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;

                    width: calc((100% - 9px) / 2);
                    height: 37px; // 37px 56px

                    padding: 10px 20px 12px 20px;

                    .text {
                        /* XB 12 */
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 20px; /* 166.667% */
                    }
                }

                .orange {
                    gap: 10px;
                    border-radius: 50px;
                    background: var(--Orange, #ff6821);
                }
                .line {
                    gap: 4px;

                    border-radius: 50px;
                    border: 1px solid var(--Orange, #ff6821);

                    .text {
                        line-height: 15px;
                    }
                }
            }

            .progress {
                .title {
                    margin-top: 25px;
                    margin-bottom: 17px;
                    text-align: center;

                    /* XB 16 */
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 28px; /* 175% */

                    .left {
                        /* M 12 */
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 166.667% */
                    }
                    .right {
                        /* XB 12 */
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 166.667% */

                        & > span:first-child {
                            font-weight: 800;
                            color: #fff;
                        }
                        & > span:last-child {
                            background: var(--gradient-orange, linear-gradient(324deg, #ff6201 17.29%, #ffa401 100.12%));
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }

                .spaceBetween {
                    justify-content: space-between;
                }

                .chart_box {
                    display: flex;
                    align-items: center;
                    gap: 13px;
                    height: 22px;
                    .chart {
                        box-sizing: border-box;
                        flex: 1;
                        flex-shrink: 0;
                        display: flex;
                        // width: 308px;
                        height: 100%;
                        padding: 5px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 10px;

                        border-radius: 20px;
                        background: var(--Dark-Grey, #363636);

                        .orange {
                            height: 12px;
                            border-radius: 20px;
                            background: var(--Orange, #ff6821);
                        }
                    }

                    span {
                        word-wrap: break-word;
                        width: 52px;
                        text-align: center;

                        /* XB 12 */
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 20px; /* 166.667% */
                    }
                }

                .day {
                    .chart {
                        .orange {
                            width: var(--dayRate);
                        }
                    }
                }
                .total {
                    .chart {
                        .orange {
                            width: var(--totalRate);
                        }
                    }
                }
            }

            .buy {
                .title_buy {
                    width: 100%;
                    margin: 25px 0;
                    position: relative;

                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 28px;
                    .his {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(0, -50%);
                    }
                }

                .box {
                    box-sizing: border-box;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 22px;
                    flex-shrink: 0;

                    width: 100%;
                    max-width: calc(100vw - 30px);
                    overflow: hidden;
                    min-height: 118px;
                    padding-left: 20px;

                    border-radius: 15px;
                    border: 1px solid var(--Background-color-Card-Outline, #525252);
                    background: var(--Background-color-Card-BG, linear-gradient(135deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%));
                    box-shadow: 0px 4px 10px 0px #00000066;
                    backdrop-filter: blur(2.5px);
                    .left {
                        flex: 1;
                        height: 118px;

                        margin-top: 5px;
                        margin-bottom: 5px;
                        .entrance {
                            margin-top: 15px;
                        }
                        .exit {
                            margin-top: 25px;
                            // margin-bottom: 15px;
                        }

                        input::placeholder {
                            color: #9a9a9a;
                        }
                    }

                    .right {
                        // cursor: pointer !important;
                        position: relative;

                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 148px;
                        height: 148px;
                        padding: 15px 20px;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;
                        flex-shrink: 0;

                        border-radius: 15px;
                        border: 1px solid var(--Background-color-Card-Outline, #525252);
                        background: var(--Linear, linear-gradient(324deg, #ff6201 17.29%, #ffa401 100.12%));
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
                        backdrop-filter: blur(2.5px);

                        .text {
                            cursor: pointer !important;
                        }

                        .bxo {
                            cursor: pointer !important;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .laoding {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .lucency {
                        opacity: 0.5;
                    }
                }
            }
        }
        .assets {
            box-sizing: border-box;
            margin: 0 15px;
            margin-top: 6px;
            display: flex;
            width: calc(100% - 30px);

            padding: 15px 20px;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;

            border-radius: 15px;
            border: 1px solid var(--Background-color-Card-Outline, #525252);
            background: var(--Background-color-Card-BG, linear-gradient(135deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%));
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2.5px);

            .boxNoLink {
                box-sizing: border-box;
                width: 100%;
                .title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /* XB 16 */
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 28px; /* 175% */

                    .right {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;

                        /* XB 12 */
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 20px; /* 166.667% */
                    }
                }

                .cont {
                    width: 100%;
                    display: flex;
                    height: 84px;
                    flex-direction: column;
                    justify-content: center;
                    flex-shrink: 0;

                    color: var(--Light-Grey, #ccc);

                    /* M 12 */
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }
            }

            .boxLink {
                width: 100%;

                .title {
                    color: var(--white, var(--Text-Title, #fff));
                    font-family: Inconsolata;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }

        .team {
            box-sizing: border-box;
            margin: 0 15px;
            margin-top: 29px;
            // display: flex;
            width: calc(100% - 30px);

            // display: flex;
            min-height: 254px;
            padding: 15px 20px;
            // flex-direction: column;
            // align-items: center;
            flex-shrink: 0;

            border-radius: 15px;
            border: 1px solid var(--Background-color-Card-Outline, #525252);
            background: var(--Background-color-Card-BG, linear-gradient(135deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%));
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2.5px);

            .title {
                /* XB 16 */
                text-align: left;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 28px; /* 175% */
            }

            .border {
                margin: 15px 0;
                width: 100%;
                height: 1px;
                background: var(--Element-Line, #2a2a2a);
            }

            .invitation {
                .title {
                    color: var(--Monochrome-White, var(--Text-Title, #fff));
                    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

                    /* M 12 */
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }

                .title2 {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /* M 12 */
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */

                    .left {
                        color: var(--Light-Grey, #ccc);
                    }
                    .right {
                        font-weight: 800;
                        &.active {
                            color: #ff6821;
                        }
                    }
                }

                .title3 {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    &.underline {
                        justify-content: space-between;
                        .right {
                            text-decoration: underline;
                            color: #ffffff;
                        }
                    }
                    .left {
                        font-family: Inconsolata;
                        font-size: 16px;
                        line-height: 17px;
                        color: #ffffff;
                    }
                    .right {
                        font-family: Inconsolata;
                        font-size: 16px;
                        line-height: 17px;
                        color: #ff6821;
                    }
                }

                .footer {
                    /* display: flex;
                    align-items: center; */
                    margin-top: 17px;

                    .left {
                        /* margin-right: 21px; */
                        color: var(--Text-Title, #fff);
                        font-family: Inconsolata;
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                        text-align: center;
                    }

                    .right {
                        display: flex;
                        justify-content: center;
                        margin-top: 12px;
                        .Check {
                            min-width: 194px;
                            // display: block;
                        }
                    }
                }

                .link {
                    margin-top: 5px;
                }
            }
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
</style>
