<template>
    <div class="approve_token">
        <!-- 批准您的Token -->

        <div class="title">{{ langJson('Authorize your token', 'inscdoge.txt.v1.155') }} ({{ token && token.token }})</div>
        <div class="tip">
            {{ langJson('To set up the first project, you must approve its sales, which requires a one-time gas bill.', 'inscdoge.txt.v1.156') }}
        </div>
        <div class="model_btns">
            <div class="model_btn">
                <BTN class="btn_item" type="line" :disable="load_btn" @click="close">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
            </div>
            <div class="btn_grap"></div>
            <div class="model_btn">
                <BTN class="btn_item" type="orange" :load="load_btn" @click="approveTokenRun">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        token: {
            type: Object,
            default: () => {
                // { contractType,contract,tokenId,amount,token,checkBalance }
                return {}
            },
        },
        type: {
            type: String,
            default: 'collection_contract',
        },
    },
    methods: {
        async approveTokenRun() {
            this.load_btn = true
            try {
                let enough
                let checkBalance
                if (this.token.checkBalance === undefined) {
                    checkBalance = true
                } else {
                    checkBalance = this.token.checkBalance
                }
                if (checkBalance) {
                    // let { balance } = await this.getTokenBalance(this.token)
                    let { balance } = await this.getBalance(this.token)
                    enough = parseFloat(balance) >= parseFloat(this.token.amount)
                }

                console.log('=====================', this.token)
                if (enough || !checkBalance) {
                    let supportContract = await this.supportContract()

                    let target = supportContract[this.type]
                    let isApprove = await this.symbolTokenisApprove({
                        ...this.token,
                        target,
                    })
                    console.log('isApprove', isApprove)

                    // let isApprove = await this.getTokenApprove({
                    //     ...this.token,
                    //     target,
                    // })
                    if (isApprove) {
                        this.$emit('finash')
                    } else {
                        let result = await this.symbolTokenApprove({
                            ...this.token,
                            target,
                        })

                        console.log('result', result)
                        // let result = await this.approvePlay({
                        //     ...this.token,
                        //     target,
                        // })
                        if (result) {
                            this.$emit('finash')
                        } else {
                            this.failed()
                        }
                    }
                } else {
                    this.pushMsg('warning', this.langJson('抱歉，您的信用额度不足', 'inscdoge.txt.v1.157'))
                    this.failed()
                }
            } catch (err) {
                console.log(err)
                this.failed()
            }
            this.load_btn = false
        },
        failed() {
            this.close()
            this.pushMsg('error', this.langJson('Approval failed', 'inscdoge.txt.v1.158'))
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>
<style lang="scss" scoped>
.approve_token {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 170px;
    .model_btns {
        .btn_grap {
            width: 20px;
        }
        .model_btn {
            flex: 1;
        }
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .tip {
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        color: var(--font2);
        margin: auto;
        margin-top: 10px;
        text-align: center;
        width: 80%;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font1);
        text-align: center;
        margin: auto;
        margin-top: 10px;
        width: 80%;
    }
}
</style>
