import http from "./base";
/**
 * 获取集合下nft
 * @param pageNum   第几页
 * @param pageSize  每页数量
 * @param groupId 组ID
*/
let nftGroup = (parmas) => {
    return http.get("/product/group", parmas);
};

/**
 * @param pageNum   第几页
 * @param pageSize  每页数量
 * @param address   钱包地址
 * 获取所属于钱包地址下的所有集合
 */
let belongGroup = (parmas) => {
    return http.get("/group/list", parmas);
}

/**
 * @param id 集合ID
 * 获取集合详情
 */
let groupDetail = (parmas) => {
    return http.get("/group/detail", parmas);
}

/**
 * @param pageNum   第几页
 * @param pageSize  每页数量
 * @param groupId 集合ID
 * @param orderByColumn  按什么排序
 * @param isAsc 排序方式
 * 获取集合详情
 */
let groupHistory = (parmas) => {
    return http.get("/group/detail", parmas);
}





/**
* @param pageNum   第几页
* @param pageSize  每页数量
* @param groupId 组ID
* 分组下的交易记录
*/
let getGroupTrades = params => {
    return http.get(`/trade/group`, params);
};
export default {
    nftGroup,
    belongGroup,
    groupDetail,
    groupHistory,
    getGroupTrades
};
