<template>
    <Model v-model="show">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{ langJson('资产提现', 'inscdoge.txt.v1.114') }}
                </div>
                <div class="top_right">
                    <GI name="model_close_1" ws="24" hs="24" @click="close" :color="svg1"></GI>
                </div>
            </div>
            <StepRun ref="step" :stepNum="true">
                <div class="buy_detail" slot="step1">
                    <div class="available_btn">{{ langJson('提现币种', 'inscdoge.txt.v1.115') }}</div>
                    <div class="rates_btn">
                        <div :class="['btn_item', symbolType == '1' ? 'active' : '']" @click="switchType('1')">{{ langJson('UI', 'inscdoge.txt.v1.116') }}</div>
                        <div :class="['btn_item', symbolType == '2' ? 'active' : '']" @click="switchType('2')">{{ langJson('USDT', 'inscdoge.txt.v1.117') }}</div>
                    </div>
                    <!-- <div class="type_main">
                        <div @click="typeChange(4)" :class="['type_item',withdrawType == 4 && 'type_active']">{{ langJson('本金','inscdoge.txt.v1.118') }}</div>
                        <div @click="typeChange(5)" :class="['type_item',withdrawType == 5 && 'type_active']">{{ langJson('收益','inscdoge.txt.v1.119') }}</div>
                    </div> -->
                    <div class="play_cell">
                        <div class="info_item">
                            <div class="price_left price_item">
                                <div class="price_title">{{ langJson('你将获得', 'inscdoge.txt.v1.120') }}</div>
                                <div class="price_amount bg">
                                    <img :src="symbolVo.icon" alt="" class="symbol_icon" />
                                    {{ getAmount ? getAmount : '0' }} {{ symbolVo.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="play_main">
                        <div class="available">
                            <span>{{ langJson('可用余额', 'inscdoge.txt.v1.121') }}</span>
                            <span class="bg">{{ balance | numberGrap }} {{ symbolVo.name }}</span>
                        </div>
                        <div class="filter_key">
                            <div class="play_title">{{ langJson('提取', 'inscdoge.txt.v1.122') }}</div>
                            <input type="text" v-model="value" @blur="inputWatch" :placeholder="langJson('提取数量', 'inscdoge.txt.v1.123')" class="search_key" />
                            <div class="max bg" @click="inputAmount(1)">{{ langJson('MAX', 'inscdoge.txt.v1.124') }}</div>
                        </div>

                        <div class="rates">
                            <div class="rate_item" @click="inputAmount(0.25)">{{ langJson('25%', 'inscdoge.txt.v1.125') }}</div>
                            <div class="rate_item" @click="inputAmount(0.5)">{{ langJson('50%', 'inscdoge.txt.v1.126') }}</div>
                            <div class="rate_item" @click="inputAmount(0.75)">{{ langJson('75%', 'inscdoge.txt.v1.127') }}</div>
                            <div class="rate_item" @click="inputAmount(1)">{{ langJson('100%', 'inscdoge.txt.v1.128') }}</div>
                        </div>
                    </div>

                    <div class="limit_item mt20">
                        <div class="limit_key">{{ langJson('提现手续费', 'inscdoge.txt.v1.129') }}</div>
                        <!-- <div class="limit_val keynote">{{ platformSymbol ? configParams.withdrawPlatformFeeRate : plus(configParams.withdrawFeeRate, information.withdrawFeeIncrement || 0) }}%</div> -->
                        <div class="limit_val keynote bg">{{ plus(configParams.withdrawFeeRate, information.withdrawFeeIncrement || 0) }}%</div>
                    </div>

                    <div class="model_btns">
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackCancle" @click="close">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
                        </div>
                        <div class="btn_grap"></div>
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackConfirm" @click="confirm" :disable="!isAmount(value)">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
                        </div>
                    </div>
                </div>
                <LoadModel slot="step2"></LoadModel>
                <Hash slot="step3" @close="close" :hash="hash" :chainId="detail.chainId"></Hash>
            </StepRun>
        </div>
    </Model>
</template>
<script>
// import { Polyzkp } from 'h-ployzkp'
// import { HERC20 } from 'h-token-staking'
// let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            value: '',
            feeRate: 0,
            tradeObj: null,
            hash: '',

            withdrawType: 4,
            balance: 0,
            symbolType: '1',
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: [Number, String],
            default: 1,
        },

        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
        form: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    created() {},
    watch: {
        show(n) {
            if (n) {
                this.feeGet()
            }
        },
    },
    computed: {
        information() {
            try {
                let result = this.globalPersonal.information
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        cycleInfo() {
            try {
                let { cycleInfo } = this.detail
                return cycleInfo ? cycleInfo : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        expired() {
            try {
                let now = new Date().getTime()
                let expire = this.detail.expireTime < now
                return expire
            } catch (err) {
                console.log(err)
                return false
            }
        },
        platformSymbol() {
            return this.symbolVo.name == 'UI'
        },
        symbolVo() {
            try {
                let { uiId, usdtId, uiEarningsAmount, usdtEarningsAmount } = this.detail
                // this.balance = earningsAmount
                this.balance = this.symbolType == '1' ? uiEarningsAmount : usdtEarningsAmount
                let symbolId = this.symbolType == '1' ? uiId : usdtId
                let result = this.findSymbol(symbolId)
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },

        isAmount() {
            return (amount) => {
                if (!amount) return false
                amount = saveNum(amount, 6)
                amount = parseFloat(amount)
                amount = isNaN(amount) ? 0 : amount
                return amount > 0 && amount <= parseFloat(this.balance)
            }
        },
        minAmount() {
            let { uiId, usdtId } = this.detail
            let symbolId = this.symbolType == '1' ? uiId : usdtId

            let symbol = this.findSymbol(symbolId)
            let { usdRate } = symbol
            let { withdrawMinAmount } = this.withdrawFee
            let amount = division(withdrawMinAmount, usdRate)

            let result = saveNum(amount, 4)
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 5)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 6)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 7)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 8)
            }

            return result
        },
        getAmount() {
            let { withdrawFeeRate, withdrawPlatformFeeRate } = this.configParams
            // if (this.platformSymbol) {
            //     withdrawFeeRate = withdrawPlatformFeeRate
            // } else {
            //     withdrawFeeRate = this.plus(this.configParams.withdrawFeeRate || 0, this.information.withdrawFeeIncrement || 0)
            // }
            withdrawFeeRate = this.plus(this.configParams.withdrawFeeRate || 0, this.information.withdrawFeeIncrement || 0)

            withdrawFeeRate = withdrawFeeRate ? withdrawFeeRate : 0
            let rate = reduce(100, withdrawFeeRate)
            let amount = multiplication(this.value, rate)
            amount = division(amount, 100)
            amount = isNaN(amount) ? 0 : amount
            return saveNum(amount)
        },
    },
    methods: {
        switchType(val) {
            if (this.symbolType == val) return

            this.symbolType = val
        },
        typeChange(type) {
            this.withdrawType = type
        },
        async totalCompute(id, token, amount) {
            console.log(id, token, amount)
            let { newfi } = await this.stakedState()
            let pool = {}
            let liquidity = {}
            if (id) {
                pool = await newfi.pool(id)
                liquidity = await newfi.Challenge(id)
            }
            let { tokens } = liquidity
            let targetToken = new HERC20({ ...newfi.network, contract: token })
            let token0 = tokens && new HERC20({ ...newfi.network, contract: tokens.token0 })
            let token1 = tokens && new HERC20({ ...newfi.network, contract: tokens.token1 })
            let amount0 = token0 && (await token0.fromAmount(tokens.amount0))
            let amount1 = token1 && (await token1.fromAmount(tokens.amount1))
            let balance = await newfi.balanceOf(token)
            // console.log(balance)
            // balance = await targetToken.fromAmount(balance)
            // console.log(balance)
            let sum = 0
            if (tokens && charCompare(tokens.token0, token)) {
                let { outAmount } = await newfi.quoteCompute({
                    amount: amount1,
                    path: [
                        {
                            tokenIn: tokens.token1,
                            tokenOut: tokens.token0,
                            fee: division(pool.fee, 10000),
                        },
                    ],
                    side: 0,
                })
                outAmount = await targetToken.fromAmount(outAmount)
                sum = plus(balance, amount0)
                sum = plus(outAmount, sum)
                sum = multiplication(sum, 0.99)
                console.log(sum, amount, outAmount)
                return {
                    result: parseFloat(sum) >= parseFloat(amount),
                    total: saveNum(sum, 6),
                }
            } else if (tokens && charCompare(tokens.token1, token)) {
                let { outAmount } = await newfi.quoteCompute({
                    amount: amount0,
                    path: [
                        {
                            tokenIn: tokens.token0,
                            tokenOut: tokens.token1,
                            fee: division(pool.fee, 10000),
                        },
                    ],
                    side: 0,
                })
                outAmount = await targetToken.fromAmount(outAmount)
                sum = plus(balance, amount1)
                sum = plus(outAmount, sum)
                sum = multiplication(sum, 0.99)
                console.log(sum, amount, outAmount)
                return {
                    result: parseFloat(sum) >= parseFloat(amount),
                    total: saveNum(sum, 6),
                }
            } else {
                return {
                    result: parseFloat(balance) >= parseFloat(amount),
                    total: saveNum(balance, 6),
                }
            }
        },
        inputWatch(event) {
            let amount = event.target.value
            let isNum = /^\d+(\.\d*)?$/.test(amount)
            let val = ''
            if (isNum) {
                let amounts = amount.split('.')
                if (amounts.length == 2 && parseFloat(amounts[1])) {
                    val = saveNum(amount, 6)
                } else {
                    val = amount
                }
            }
            this.value = val
        },
        inputAmount(rate) {
            let value = multiplication(this.balance, rate)
            this.value = saveNum(value, 6)
        },
        async feeGet() {
            await this.targetChain(this.detail.chainId, async () => {
                let { withdraw } = await this.stakedState()
                this.feeRate = await withdraw.fee()
            })
        },
        async confirm() {
            try {
                this.next()
                let { dispatch } = this.$store
                let { id } = this.detail
                let { withdraw, withdraw_pay_out_address, withdraw_contract } = await this.stakedState()
                let tokenObj = new HERC20({
                    ...withdraw,
                    contract: this.symbolVo.contractAddr,
                })

                let balance = await tokenObj.balanceOf(withdraw_pay_out_address)
                balance = await tokenObj.fromAmount(balance)
                let approveAmount = await tokenObj.allowance(withdraw_pay_out_address, withdraw_contract)
                approveAmount = await tokenObj.fromAmount(approveAmount)
                let pass = parseFloat(balance) >= parseFloat(this.value)
                pass = pass && parseFloat(approveAmount) >= parseFloat(this.value)
                if (!pass) {
                    this.pushMsg('error', this.langJson('系统繁忙,请稍后操作', 'inscdoge.txt.v1.131'))
                    this.close()
                    return
                }

                let signTx = await dispatch('signGet', {
                    coinId: this.symbolVo.id,
                    amount: this.value,
                    type: 5,
                })
                console.log(signTx)
                if (signTx && signTx.code == 200) {
                    let { data } = signTx
                    let params = {
                        pledgeId: data.pledgeId,
                        signType: data.type,
                        token: data.contractAddress,
                        amount: data.amount,
                        signature: data.sign,
                        deadline: data.timeStr,
                    }
                    let result = await withdraw.Withdrawal(params)
                    if (result && result.transactionHash) {
                        this.hash = result.transactionHash
                        let { dispatch } = this.$store
                        await dispatch('pushHash', {
                            chainId: this.symbolVo.chainId,
                            hash: this.hash,
                        })

                        this.pushMsg('success', this.langJson('提取成功', 'inscdoge.txt.v1.132'))
                        this.next()
                    } else {
                        this.failed()
                    }
                } else {
                    this.failed()
                }
            } catch (err) {
                console.log(err)
                this.failed()
            }
        },

        async msgSign({ id, token, amount, deadline }) {
            await loadScript('web3Utils')
            let privateKey = ''
            let { address, chainDetail, plugin } = this.walletDetail
            let platformContract = this.findContractObj(chainDetail.id)
            let { pledge_contract } = platformContract
            if (address && plugin && pledge_contract) {
                let { provider, accounts } = await this.isProvider(plugin)
                let newfi = new NewFiV3({
                    provider,
                    account: accounts[0],
                    contract: pledge_contract,
                })
                newfi.privateKey = privateKey
                let user = newfi.web3.eth.defaultAccount
                let chainId = await newfi.web3.eth.getChainId()

                let inToken = new HERC20({ ...newfi, contract: token })
                amount = await inToken.toAmount(amount)

                let parmas = [id, chainId, user, token, amount, deadline]
                console.log(parmas)
                parmas = web3Utils.soliditySha3(...parmas)
                let { signature } = await newfi.msgSign(parmas)
                console.log(signature)
                newfi.privateKey = ''
                return signature
            }
        },
        failed() {
            this.close()
            this.pushMsg('error', this.langJson('提取失败', 'inscdoge.txt.v1.133'))
        },
        close() {
            this.$emit('change', false)
            this.$emit('completeExtract')
        },
        next() {
            this.$refs.step.next()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .limit_item {
        .limit_key {
            color: var(--font1);
        }

        .limit_val {
        }

        .keynote {
            color: #f65a5a;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1b1b2c;
        font-size: 12.5px;

        line-height: 14px;
        width: 100%;
        margin-top: 15px;
    }

    .mt20 {
        margin-top: 20px;
    }

    .play_cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--bg11);
        backdrop-filter: blur(10px);
        border-radius: 4px;
        padding: 16px;
        margin-top: 20px;
        box-sizing: border-box;
    }

    .play_cell:last-child {
        border-bottom: none;
    }

    .play_main {
        width: 100%;
        margin-top: 20px;

        .available {
            color: var(--font1);
            font-size: 12px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
        }

        .rates {
            .rate_item {
                font-size: 12px;
                line-height: 12px;

                color: var(--font1);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg11);
                backdrop-filter: blur(6px);
                border-radius: 4px;
                box-sizing: border-box;
                // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
                width: 22%;
                height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
        }

        .filter_key {
            .max {
                font-size: 12.5px;
                line-height: 16px;

                color: #2ca631;
                padding: 0 16px;
                flex-shrink: 0;
            }

            .play_title {
                font-size: 14px;
                line-height: 16px;

                color: var(--font1);
                padding: 0 16px;
                flex-shrink: 0;
            }

            .search_key {
                background: transparent;
                border: none;
                outline: none;
                flex: 1;
                color: var(--font1);
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
            }

            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--bg11);
            backdrop-filter: blur(6px);
            border-radius: 4px;
            box-sizing: border-box;
            // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
        }
    }

    .buy_detail {
        .type_main {
            .type_item {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: var(--font1);

                display: flex;
                align-items: center;
                justify-content: center;

                height: 100%;
                flex: 1;
                border-radius: 4px;
            }

            .type_active {
                background-color: #5d0561;
            }

            height: 42px;
            width: 100%;
            border-radius: 4px;
            background-color: var(--bg11);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px;
            box-sizing: border-box;
            gap: 6px;
        }

        .model_btns {
            .btn_grap {
                width: 20px;
            }

            .model_btn {
                flex: 1;
            }

            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .account_balance {
            .balance {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }

            .down {
                color: var(--danger_color);
            }

            .up {
                color: var(--success_color);
            }

            .balance_title {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }

            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .info_item {
            .price_item {
                .price_amount {
                    display: flex;
                    align-items: flex-end;

                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--font1);
                    margin-top: 12px;
                    word-break: break-all;

                    .symbol_icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }

                    .price_usdt {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: var(--font1);
                        margin-left: 10px;
                    }
                }

                .price_title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--font1);
                }

                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .price_left {
                flex: 1;
            }

            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 0px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .model_top {
        .top_right {
            cursor: pointer;
        }

        .top_left {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font1);
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .available_btn {
        width: 100%;
        color: var(--font1);
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .rates_btn {
        width: 100%;
        .btn_item {
            font-size: 12px;
            line-height: 12px;

            color: var(--font1);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--bg11);
            backdrop-filter: blur(6px);
            border-radius: 4px;
            box-sizing: border-box;
            // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
            width: 22%;
            height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .active {
            font-family: 'MicrosoftYaHei';
            // font-weight: bold;
            z-index: 100;
            background-image: -webkit-linear-gradient(top, #38b9c4, #278bc3, #1968c2);
        }

        display: flex;
        align-items: center;
        gap: 10px;
        // justify-content: space-between;
        margin-top: 5px;
    }

    padding: 24px;
    box-sizing: border-box;
    width: 400px;
}
.bg {
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #ff6e02, #ffae00, #ff6e02);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>
