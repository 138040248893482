<template>
    <Model v-model="show">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{ langJson('资产提现', 'inscdoge.txt.v1.114') }}
                </div>
                <div class="top_right">
                    <GI name="model_close_1" ws="24" hs="24" @click="close"></GI>
                </div>
            </div>
            <StepRun ref="step" :stepNum="true">
                <div class="buy_detail" slot="step1">
                    <!-- <div class="type_main">
                        <div @click="typeChange(4)" :class="['type_item',withdrawType == 4 && 'type_active']">{{ langJson('本金','inscdoge.txt.v1.118') }}</div>
                        <div @click="typeChange(5)" :class="['type_item',withdrawType == 5 && 'type_active']">{{ langJson('收益','inscdoge.txt.v1.119') }}</div>
                    </div> -->
                    <div class="play_cell">
                        <div class="info_item">
                            <div class="price_left price_item">
                                <div class="price_title">{{ langJson('你将获得', 'inscdoge.txt.v1.120') }}</div>
                                <div class="price_amount">
                                    <img :src="symbolVo.icon" alt="" class="symbol_icon" />
                                    {{ getAmount ? getAmount : '0' }} {{ symbolVo.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="play_main">
                        <div class="available">
                            <span>{{ langJson('可用余额', 'inscdoge.txt.v1.121') }}</span>
                            <span>{{ balance | numberGrap }} {{ symbolVo.name }}</span>
                        </div>
                    </div>

                    <div v-if="detail.status == 0" class="limit_item mt10">
                        <div class="limit_key">{{ langJson('提前提取', 'inscdoge.txt.v1.140') }}</div>
                        <div class="limit_val keynote">{{ cycleInfo.breakRate }}%</div>
                    </div>

                    <div class="model_btns">
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackCancle" @click="close">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
                        </div>
                        <div class="btn_grap"></div>
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackConfirm" @click="detail.status == 0 ? closeHint(true) : confirm()" :disable="!isAmount(value)">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
                        </div>
                    </div>
                </div>
                <LoadModel slot="step2"></LoadModel>
                <Hash slot="step3" @close="close" :hash="hash" :chainId="detail.chainId"></Hash>
            </StepRun>
            <div class="confirm" v-show="confirmShow">
                <div class="content" @click.stop>
                    <div class="top">
                        {{ langJson('提示', 'inscdoge.txt.v1.141') }}
                        <GI name="model_close_1" ws="24" hs="24" @click="closeHint"></GI>
                    </div>
                    <div class="details">
                        {{ langJson('如果您确定要提前提现，系统将扣除您20%的本金作为违约金', 'inscdoge.txt.v1.142') }}
                    </div>
                    <div class="footer">
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackCancle" @click="closeHint">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
                        </div>
                        <div class="model_btn">
                            <BTN class="btn_item" type="blackConfirm" @click="confirm">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
// import { Polyzkp } from 'h-ployzkp'
// import { HERC20 } from 'h-token-staking'
// let { saveNum, multiplication, division, reduce, charCompare, plus } = require('h-token-staking/src/utils')
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    data() {
        return {
            value: '',
            feeRate: 0,
            tradeObj: null,
            hash: '',

            withdrawType: 4,
            balance: 0,
            confirmShow: false,
            confirmState: 0,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: [Number, String],
            default: 1,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
        form: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    created() {},
    watch: {
        show(n) {
            if (n) {
                // this.confirmShow = false
                // if (this.detail.status == 0) {
                //     this.confirmState = 0
                // }else{
                //     this.confirmState = 1
                // }
            }
        },
    },
    computed: {
        cycleInfo() {
            try {
                let { cycleInfo } = this.detail
                return cycleInfo ? cycleInfo : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        expired() {
            try {
                let now = new Date().getTime()

                let expire = this.detail.expireTime < now
                return expire
            } catch (err) {
                console.log(err)
                return false
            }
        },
        symbolVo() {
            try {
                let symbolId
                let { earningsCoinId, coinId, presentAmount, confirmAmount } = this.detail
                symbolId = coinId
                this.balance = presentAmount
                this.value = this.balance
                let result = this.findSymbol(symbolId)
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },

        isAmount() {
            return (amount) => {
                if (!amount) return false
                amount = saveNum(amount, 6)
                amount = parseFloat(amount)
                amount = isNaN(amount) ? 0 : amount
                return amount > 0 && amount <= parseFloat(this.balance)
            }
        },
        minAmount() {
            let { symbolId } = this.detail
            let symbol = this.findSymbol(symbolId)
            let { usdRate } = symbol
            let { withdrawMinAmount } = this.withdrawFee
            let amount = division(withdrawMinAmount, usdRate)

            let result = saveNum(amount, 4)
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 5)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 6)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 7)
            }
            if (parseFloat(result) == 0) {
                result = saveNum(amount, 8)
            }

            return result
        },
        getAmount() {
            if (this.detail.status != 0) {
                return saveNum(this.balance)
            } else {
                let { breakRate } = this.cycleInfo
                let rate = reduce(100, breakRate)
                let amount = multiplication(this.value, rate)
                amount = division(amount, 100)
                amount = isNaN(amount) ? 0 : amount
                return saveNum(amount)
            }
        },
    },
    methods: {
        closeHint(val = false) {
            this.confirmShow = val
            // this.confirmState = 0
            // if (!!val) {
            //     this.confirmState = 1
            // }
        },
        typeChange(type) {
            this.withdrawType = type
        },
        async totalCompute(id, token, amount) {
            console.log(id, token, amount)
            let { newfi } = await this.stakedState()
            let pool = {}
            let liquidity = {}
            if (id) {
                pool = await newfi.pool(id)
                liquidity = await newfi.Challenge(id)
            }
            let { tokens } = liquidity
            let targetToken = new HERC20({ ...newfi.network, contract: token })
            let token0 = tokens && new HERC20({ ...newfi.network, contract: tokens.token0 })
            let token1 = tokens && new HERC20({ ...newfi.network, contract: tokens.token1 })
            let amount0 = token0 && (await token0.fromAmount(tokens.amount0))
            let amount1 = token1 && (await token1.fromAmount(tokens.amount1))
            let balance = await newfi.balanceOf(token)
            // console.log(balance)
            // balance = await targetToken.fromAmount(balance)
            // console.log(balance)
            let sum = 0
            if (tokens && charCompare(tokens.token0, token)) {
                let { outAmount } = await newfi.quoteCompute({
                    amount: amount1,
                    path: [
                        {
                            tokenIn: tokens.token1,
                            tokenOut: tokens.token0,
                            fee: division(pool.fee, 10000),
                        },
                    ],
                    side: 0,
                })
                outAmount = await targetToken.fromAmount(outAmount)
                sum = plus(balance, amount0)
                sum = plus(outAmount, sum)
                sum = multiplication(sum, 0.99)
                console.log(sum, amount, outAmount)
                return {
                    result: parseFloat(sum) >= parseFloat(amount),
                    total: saveNum(sum, 6),
                }
            } else if (tokens && charCompare(tokens.token1, token)) {
                let { outAmount } = await newfi.quoteCompute({
                    amount: amount0,
                    path: [
                        {
                            tokenIn: tokens.token0,
                            tokenOut: tokens.token1,
                            fee: division(pool.fee, 10000),
                        },
                    ],
                    side: 0,
                })
                outAmount = await targetToken.fromAmount(outAmount)
                sum = plus(balance, amount1)
                sum = plus(outAmount, sum)
                sum = multiplication(sum, 0.99)
                console.log(sum, amount, outAmount)
                return {
                    result: parseFloat(sum) >= parseFloat(amount),
                    total: saveNum(sum, 6),
                }
            } else {
                return {
                    result: parseFloat(balance) >= parseFloat(amount),
                    total: saveNum(balance, 6),
                }
            }
        },
        inputWatch(event) {
            let amount = event.target.value
            let isNum = /^\d+(\.\d*)?$/.test(amount)
            let val = ''
            if (isNum) {
                let amounts = amount.split('.')
                if (amounts.length == 2 && parseFloat(amounts[1])) {
                    val = saveNum(amount, 6)
                } else {
                    val = amount
                }
            }
            this.value = val
        },
        inputAmount(rate) {
            let value = multiplication(this.balance, rate)
            this.value = saveNum(value, 6)
        },
        async confirm() {
            try {
                this.closeHint()
                // if (!this.confirmState) {
                //     this.confirmShow = true
                //     return
                // }

                this.next()
                let { dispatch } = this.$store
                let { id } = this.detail

                let { address, chainDetail, plugin } = this.walletDetail
                let platformContract = chainDetail && this.findContractObj(chainDetail.id)
                let pledge_contract = platformContract && platformContract.pledge_contract
                let withdraw_pay_out_address = platformContract && platformContract.withdraw_pay_out_address
                if (address && plugin && pledge_contract && withdraw_pay_out_address) {
                    let { provider, accounts } = await this.isProvider(plugin)
                    let newfi = new Polyzkp({
                        provider,
                        account: accounts[0],
                        contract: pledge_contract,
                    })

                    let tokenObj = new HERC20({
                        ...newfi,
                        contract: this.symbolVo.contractAddr,
                    })

                    let balance = await tokenObj.balanceOf(withdraw_pay_out_address)
                    balance = await tokenObj.fromAmount(balance)
                    let approveAmount = await tokenObj.allowance(withdraw_pay_out_address, pledge_contract)
                    approveAmount = await tokenObj.fromAmount(approveAmount)
                    let pass = parseFloat(balance) >= parseFloat(this.value)
                    pass = pass && parseFloat(approveAmount) >= parseFloat(this.value)
                    if (!pass) {
                        this.pushMsg('error', this.langJson('系统繁忙,请稍后重试', 'inscdoge.txt.v1.143'))
                        this.failed()
                        return
                    }

                    // tx.sign = await this.msgSign({
                    //     id:tx.projectId,
                    //     token:tx.tokenAddress,
                    //     amount:tx.amount,
                    //     deadline:tx.timeStr
                    // })

                    let signTx = await dispatch('signGet', {
                        pledgeId: id,
                        coinId: this.symbolVo.id,
                        amount: this.value,
                        type: 4,
                    })
                    console.log(signTx)
                    if (signTx && signTx.code == 200) {
                        let { data } = signTx
                        // let realAmount = await tokenObj.fromAmount(data.amount)
                        let params = {
                            pledgeId: data.pledgeId,
                            signType: data.type,
                            token: data.contractAddress,
                            amount: data.amount,
                            signature: data.sign,
                            // signature:"",
                            deadline: data.timeStr,
                        }
                        let result = await newfi.Withdrawal(params)
                        if (result && result.transactionHash) {
                            this.hash = result.transactionHash
                            let { dispatch } = this.$store
                            await dispatch('pushHash', {
                                chainId: chainDetail.id,
                                hash: this.hash,
                            })

                            this.pushMsg('success', this.langJson('提取成功', 'inscdoge.txt.v1.132'))
                            this.next()
                        } else {
                            this.failed()
                        }
                    } else {
                        this.failed()
                    }
                }
            } catch (err) {
                console.log(err)
                this.failed()
            }
        },

        async msgSign({ id, token, amount, deadline }) {
            await loadScript('web3Utils')
            let privateKey = ''
            let { address, chainDetail, plugin } = this.walletDetail
            let platformContract = this.findContractObj(chainDetail.id)
            let { pledge_contract } = platformContract
            if (address && plugin && pledge_contract) {
                let { provider, accounts } = await this.isProvider(plugin)
                let newfi = new NewFiV3({
                    provider,
                    account: accounts[0],
                    contract: pledge_contract,
                })
                newfi.privateKey = privateKey
                let user = newfi.web3.eth.defaultAccount
                let chainId = await newfi.web3.eth.getChainId()

                let inToken = new HERC20({ ...newfi, contract: token })
                amount = await inToken.toAmount(amount)

                let parmas = [id, chainId, user, token, amount, deadline]
                console.log(parmas)
                parmas = web3Utils.soliditySha3(...parmas)
                let { signature } = await newfi.msgSign(parmas)
                console.log(signature)
                newfi.privateKey = ''
                return signature
            }
        },
        failed() {
            this.close()
            this.pushMsg('error', this.langJson('提取失败', 'inscdoge.txt.v1.133'))
        },
        close() {
            this.$emit('change', false)
            this.$emit('completeExtract')
        },
        next() {
            this.$refs.step.next()
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    .limit_item {
        .limit_key {
            color: var(--font1);
        }
        .limit_val {
        }
        .keynote {
            color: #f65a5a;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1b1b2c;
        font-size: 14px;

        line-height: 14px;
        width: 100%;
        margin-top: 15px;
    }
    .mt10 {
        margin-top: 10px;
    }
    .mt20 {
        margin-top: 20px;
    }
    .play_cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: rgba(43, 49, 57, 0.5);
        backdrop-filter: blur(10px);
        border-radius: 4px;
        padding: 16px;
        margin-top: 20px;
        box-sizing: border-box;
    }
    .play_cell:last-child {
        border-bottom: none;
    }
    .play_main {
        width: 100%;
        margin-top: 20px;
        .available {
            color: var(--font1);
            font-size: 12px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
        }
        .rates {
            .rate_item {
                font-size: 12px;
                line-height: 12px;

                color: var(--font1);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(43, 49, 57, 0.5);
                backdrop-filter: blur(6px);
                border-radius: 4px;
                box-sizing: border-box;
                // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
                width: 22%;
                height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
        }
        .filter_key {
            .max {
                font-size: 16px;
                line-height: 16px;

                color: #2ca631;
                padding: 0 16px;
                flex-shrink: 0;
            }
            .play_title {
                font-size: 16px;
                line-height: 16px;

                color: var(--font1);
                padding: 0 16px;
                flex-shrink: 0;
            }
            .search_key {
                background: transparent;
                border: none;
                outline: none;
                flex: 1;
                color: var(--font1);
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
            }

            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(43, 49, 57, 0.5);
            backdrop-filter: blur(6px);
            border-radius: 4px;
            box-sizing: border-box;
            // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
        }
    }
    .buy_detail {
        .type_main {
            .type_item {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: var(--font1);

                display: flex;
                align-items: center;
                justify-content: center;

                height: 100%;
                flex: 1;
                border-radius: 4px;
            }
            .type_active {
                background-color: var(--bg11);
            }
            height: 42px;
            width: 100%;
            border-radius: 4px;
            background-color: rgba(43, 49, 57, 0.5);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px;
            box-sizing: border-box;
            gap: 6px;
        }
        .model_btns {
            .btn_grap {
                width: 20px;
            }
            .model_btn {
                flex: 1;
            }
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .account_balance {
            .balance {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }
            .down {
                color: var(--danger_color);
            }
            .up {
                color: var(--success_color);
            }
            .balance_title {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #1b1b2c;
            }
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .info_item {
            .price_item {
                .price_amount {
                    display: flex;
                    align-items: flex-end;

                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 24px;
                    color: var(--font1);
                    margin-top: 12px;
                    word-break: break-all;
                    .symbol_icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }
                    .price_usdt {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: var(--font1);
                        margin-left: 10px;
                    }
                }
                .price_title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--font1);
                }
                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .price_left {
                flex: 1;
            }
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 0px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font1);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .confirm {
        z-index: 9999999999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 100vw;
        height: 100%;
        min-height: 100vh;
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .content {
            width: 340px;
            border-radius: 20px;
            background-color: rgbA(24, 26, 32, 95%);
            border: 1px solid rgbA(255, 255, 255, 30%);
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                font-size: 14px;

                color: var(--font1);
            }
            .details {
                padding: 0 15px;
                font-size: 14px;

                color: var(--font1);
            }
            .footer {
                display: flex;
                gap: 18px;
                padding: 20px 15px;
                .model_btn {
                    flex: 1;
                }
            }
        }
    }
    padding: 24px;
    box-sizing: border-box;
    width: 400px;
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
}
</style>
