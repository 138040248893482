import api from "@/api/foot"
const footModule = {
    state: {
        //网站脚部联系方式列表
        links: [],
        types: [],
        article: {},
    },
    getters: {},
    mutations: {
        links(state, links) {
            state.links = links
        },
        types(state, types) {
            state.types = types
        },
        article(state, article) {
            state.article = article
        },
    },
    actions: {
        // 获取网站脚部联系方式
        async getLinkList(context) {
            try {
                let result = await api.getLinkList()
                result = this.apiResult(result)
                result.data && context.commit('links', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取网站脚部文章类型
        async typeGet(context) {
            try {
                let parmas = {
                    type: 1,
                }
                let result = await api.getTypes(parmas)
                result = this.apiResult(result)
                result.data && context.commit('types', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async articleGet(context, id) {
            try {
                let result = await api.getArticleDetail(id)
                result = this.apiResult(result)
                result.data && context.commit('article', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default footModule;
