import { render, staticRenderFns } from "./SvgBase64.vue?vue&type=template&id=8c464c26&scoped=true"
import script from "./SvgBase64.vue?vue&type=script&lang=js"
export * from "./SvgBase64.vue?vue&type=script&lang=js"
import style0 from "./SvgBase64.vue?vue&type=style&index=0&id=8c464c26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c464c26",
  null
  
)

export default component.exports