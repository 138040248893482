import http from './base'

/**
 * @param bio   描述
 * @param userName  用户名
 * @param email 邮箱
 * @param imageUrl 图片地址
 * @param bannerUrl 背景地址
 * @param lang 语言
 * 修改用户信息
 */
let setlUpdate = (params) => {
    return http.postJson('/user/update', params)
}

/**
 * @param chainName  通知类型
 * */
let setNoticeType = (params) => {
    return http.get('/content/noticeType', params)
}
/**
 * @param noticeKeys  打开这些通知
 *
 * */
let setOnOff = (params) => {
    return http.post('/content/noticeSwitch', params)
}

/**
 * 支持类型
 */
let setSupports = () => {
    return http.get('/content/helpList')
}
/**
 * @param affairId
 * @param title  标题
 * @param email 邮箱
 * @param address 地址
 * @param chainName 主链
 * @param helpType 类型
 * @param description 描述
 * @param file 图片
 * 支持提交
 */
let supportPlay = (params) => {
    return http.postJson('/content/helpAdd', params)
}

/**
 * @param pageNum 页数
 * @param pageSize  每页条数
 * 支持提交过的记录
 */
let setSupportedList = (params) => {
    return http.get('/content/helpRecordUser', params)
}
// /content/helpRecordUser?pageNum=1&pageSize=30&orderByColumn=createTime&isAsc=desc

/**
 *  获取所有提交过的项目
 */
let setSubProjecList = (params) => {
    return http.get('/contract/list', params)
}

/**
 * @param contractName 合约名称
 * @param email 邮箱
 * @param address 合约地址
 * @param startBlockNum 区块高度
 * @param bio 简介
 * @param bannerUrl 形象图
 * @param imageUrl logo图
 * @param contractType 代币标准
 * 提交项目
 */
let setProjecInfo = (params) => {
    return http.postJson('/contract/apply', params)
}

/**
 * @param id  项目id
 * 获取项目详情
 */

let setGetProjecInfo = (id) => {
    return http.get('/contract/detail/' + id)
}

/**
 * @param contractName  合约名称
 * @param email  邮箱
 * @param address  合约地址
 * @param startBlockNum  区块高度
 * @param bio  简介
 * @param bannerUrl  形象图
 * @param imageUrl  logo图
 * @param contractType  代币标准
 * 修改项目
 */
let setProjecUpdate = (params) => {
    return http.postJson('/contract/update', params)
}

/**
 * @param id  项目id
 * 删除项目
 */
let setProjecDeleteId = (id) => {
    return http.postJson('/contract/remove?id=' + id)
}

// 获取 kyc 信息
let setGetKycInfo = (parmas = {}) => {
    return http.get('/userAuth/info', parmas)
}

// 修改 kyc 信息
/**
 * @param userName  用户姓名
 * @param identityCard  证件号
 * @param identityType  证件类型
 * @param identityPositiveUrl  证件正面照片
 * @param identityNegativeUrl  证件反面照片
 * @param identityUserUrl  手执证件照片
 * @param list  社交账号 传递数组 [socialType:社交账号类型, socialValue:社交账号]
 * @param id  项目id
 */
let setKycPut = (parmas = {}) => {
    return http.postJson('/userAuth/updateKYC', parmas)
}

// 获取 KYC 社交类型
let setGetKycSocialAccountTypeList = (parmas = {}) => {
    return http.get('/userAuth/getKycSocialAccountTypeList', parmas)
}

export default { setProjecDeleteId, setProjecUpdate, setGetProjecInfo, setlUpdate, setKycPut, setGetKycSocialAccountTypeList, setGetKycInfo, setProjecInfo, setSubProjecList, setNoticeType, setSupports, supportPlay, setOnOff, setSupportedList }
