<template>
    <div class="footer">{{ langJson('© Inscribe 2024.','inscdoge.txt.v3.25') }}</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer {
    padding: 10px 0 20px;
    color: #9a9a9a;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
</style>


