import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import config from '@/store/data/config'
import market from '@/store/data/market'
import foot from '@/store/data/foot'
import collection from '@/store/data/collection'
import nft from '@/store/data/nft'
import personal from '@/store/data/personal'
import auction from '@/store/data/auction'
import myster from '@/store/data/myster'
import login from '@/store/data/login'
import mint from '@/store/data/mint'
import setting from '@/store/data/setting'
import staking from '@/store/data/staking'
import status from '@/store/data/status'
import currency from '@/store/data/currency'
import newFi from '@/store/data/newFi'
import tools from '@/mixin/tools'

import preSale from '@/store/data/preSale'

Vue.prototype.langJson = (str) => {
    return str
}

const store = new Vuex.Store({
    state: {
        tips: [],
        // 提示时长
        timelen: 5000,
        // 接口数据缓存
        apiCache: {},
        // 记录总条数，当页面只有一个记录接口是可以使用
        total: 0,
        //是否显示资产侧边栏
        SideMenu: false,
        //是否显示操作侧边栏
        OperationStatus: false,

        // 是否显示登录弹框
        walletShow: false,
        // 是否显示语言弹框
        langShow: false,
        YieldShow: false,
        YieldShow2: false,
        YieldList: [],
        YieldId: 0,
        YieldId2: 0,
        // 退出账号时锁定标识
        lockRun: 0,
        // 启动进度
        launchProgress: 0,
        // staked
        staked: {},

        editModel: false,
        editNameModel: false,
        addInviterModel: false,
        withdrawModel: false,
        withdrawModel_ins_usdt_tip: false,
        withdrawAddModel: false,
        internalTransferModel: false,
        filterModel: false,

        tipShow: false,
    },
    mutations: {
        tipShow(state, tipShow) {
            state.tipShow = tipShow
        },
        editModel(state, editModel) {
            state.editModel = editModel
        },
        editNameModel(state, editNameModel) {
            state.editNameModel = editNameModel
        },
        addInviterModel(state, addInviterModel) {
            state.addInviterModel = addInviterModel
        },
        withdrawModel(state, withdrawModel) {
            state.withdrawModel = withdrawModel
        },
        withdrawModel_ins_usdt_tip(state, withdrawModel_ins_usdt_tip) {
            state.withdrawModel_ins_usdt_tip = withdrawModel_ins_usdt_tip
        },
        withdrawAddModel(state, withdrawAddModel) {
            state.withdrawAddModel = withdrawAddModel
        },
        internalTransferModel(state, internalTransferModel) {
            state.internalTransferModel = internalTransferModel
        },
        filterModel(state, filterModel) {
            state.filterModel = filterModel
        },
        staked(state, staked) {
            state.staked = staked
        },
        launchProgress(state, launchProgress) {
            state.launchProgress = launchProgress
        },
        lockRun(state, lockRun) {
            state.lockRun = lockRun
        },
        walletShow(state, walletShow) {
            state.walletShow = walletShow
        },
        langShow(state, langShow) {
            state.langShow = langShow
        },
        YieldShow(state, YieldShow) {
            state.YieldShow = YieldShow
        },
        YieldShow2(state, YieldShow2) {
            state.YieldShow2 = YieldShow2
        },
        YieldList(state, YieldList) {
            state.YieldList = YieldList
        },
        YieldId(state, YieldId) {
            state.YieldId = YieldId
        },
        YieldId2(state, YieldId2) {
            state.YieldId2 = YieldId2
        },
        tips(state, tips) {
            state.tips = tips
        },
        SideMenu(state, SideMenu) {
            state.SideMenu = SideMenu
        },
        OperationStatus(state, OperationStatus) {
            state.OperationStatus = OperationStatus
        },
        total(state, total) {
            state.total = total
        },
        apiCache(state, apiCache) {
            state.apiCache = apiCache
        },
        warning(state, message) {
            let result = [
                {
                    type: 'warning',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ].concat(state.tips)

            if (result.length >= 1) {
                result = result.slice(0, 1)
            }

            state.tips = result
        },
        success(state, message) {
            let result = [
                {
                    type: 'success',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ].concat(state.tips)
            if (result.length >= 1) {
                result = result.slice(0, 1)
            }
            state.tips = result
        },
        error(state, message) {
            let result = [
                {
                    type: 'error',
                    message,
                    expire: new Date().getTime() + state.timelen,
                },
            ].concat(state.tips)
            if (result.length >= 1) {
                result = result.slice(0, 1)
            }
            state.tips = result
        },
    },
    modules: {
        config,
        market,
        foot,
        collection,
        nft,
        personal,
        auction,
        myster,
        login,
        mint,
        setting,
        staking,
        status,
        currency,
        newFi,
        preSale,
    },
})

store.apiResult = (result, defaultValue = false) => {
    try {
        if (result && result.code == 200) {
            return result
        } else if ((result && result.code == 403) || (result && result.code == 401) || (result && result.code == 1007)) {
            /* if (store.state.login.token) {
                store.dispatch('logout')
            } else {
                store.commit('walletShow', true)
            } */
            store.dispatch('logout')
            store.commit('walletShow', true)
        } else if (result && result.code == 1022) {
            console.log('不能绑定自己的下级')
            store.commit('warning', result.msg)
            tools.setCookie('invitedCode', '')
        } else {
            store.commit('warning', result.msg)
            return defaultValue
        }
    } catch (err) {
        console.log(err)
        return defaultValue
    }
}

store.apiCache = (model, mutation, data) => {
    try {
        let expirelen = 1000 * 600
        store.commit(mutation, data)
        let allCache = store.state.apiCache
        allCache[`${model}.${mutation}`] = {
            expire: new Date().getTime() + expirelen,
            data,
        }
        store.commit('apiCache', allCache)
    } catch (err) {
        console.log(err)
    }
}

store.isGet = (model, mutation) => {
    let allCache = store.state.apiCache
    let cacheData = allCache[`${model}.${mutation}`]
    let nowTime = new Date().getTime()
    if (cacheData && cacheData.expire > nowTime) {
        return false
    } else {
        return true
    }
}

export default store
