<template>
    <div :id="components_id" class="select" @click="openHand">
        <div
            :class="['select_btn']"
            :style="{
                width: size ? `${size}px` : `100%`,
            }"
            @mouseleave="leave"
        >
            <!-- <div class="chainBg" v-if="current.chainIcon != '/source/svg/exit.svg'" :style="{ backgroundImage: `url(${iconSrc})` }"></div>
            <div class="chainBg" v-else :style="{ backgroundImage: `url(/source/svg/color_error.svg)` }"></div> -->

            <div class="btn_text">
                {{ address }}
                <GI class="iconDown" name="link_down" ws="12" hs="12" :noClickStop="true"></GI>
            </div>
            <!--  -->
        </div>
        <div
            class="select_popup"
            :id="popup_id"
            :style="{
                top: `${top}px`,
                width: ws ? `${ws}px` : `auto`,
                right: ws ? `` : `0px`,
            }"
        >
            <slot name="top"></slot>
            <div class="popup_list">
                <div class="popup_item" v-for="(item, index) in list" :key="index" @click="selectItem(item)">
                    <div class="chainBg" v-if="item.chainIcon && !item.isIconSrc" :style="{ backgroundImage: `url(${iconSrc})` }"></div>
                    <div class="chainBg" v-if="item.chainIcon && item.isIconSrc" :style="{ backgroundImage: `url(${item.chainIcon})` }"></div>
                    {{ item.chainName }}
                </div>
            </div>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
<script>
import move from 'move-js'
export default {
    data() {
        return { chainId: '', top: 0, offset: 10, popup_id: '', open: false, iconSrc: '/source/uic/gold.png' }
    },
    props: {
        address: {
            type: [Number, String],
            default: '',
        },

        size: {
            type: [Number, String],
            default: 200,
        },
        ws: {
            type: [Number, String],
            default: 0,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: 'bg',
        },
    },
    inject: ['regCode'],
    created() {
        let code = this.regCode()
        this.components_id = 'select_' + code
        code = this.regCode()
        this.popup_id = 'select_popup_' + code
    },
    mounted() {
        let { height } = document.getElementById(this.components_id).getBoundingClientRect()
        this.top = this.offset + height

        move.defaults = {
            duration: 500,
        }
    },
    computed: {
        getStyle() {
            return this.ws && this.top
                ? {
                      top: `${this.top}px`,
                      width: `${this.ws}px`,
                  }
                : {
                      top: `${this.top}px`,
                      right: `0px`,
                  }
        },
        current() {
            let result = this.list.find((item) => {
                return item.chainId == this.walletDetail.chainDetail.chainId
            })
            return result
                ? result
                : {
                      chainIcon: '',
                  }
        },
    },
    methods: {
        openHand() {
            if (this.open) {
                this.closePopup()
            } else {
                this.openPopup()
            }
        },
        async loginBefore() {
            await this.loginOf(() => {
                sessionStorage.setItem('scrollTopPersonal', 0)
                this.jump_personal()
            }, false)
        },
        selectItem(item) {
            this.chainId = item.chainId
            this.$emit('change', item)
            this.closePopup()
        },
        openPopup() {
            clearTimeout(this.timer)
            console.log('this.popup_id', this.popup_id)
            let node = document.getElementById(this.popup_id)
            console.log('node', node)
            let result = this.SubTH(node)
            result = result > 300 ? 300 : result
            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(180).end()

                // 打开选项
                move(`#${this.components_id} .select_popup`).set('height', `${result}px`).set('opacity', 1).end()
                this.open = true
            }
        },
        leave() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.closePopup()
                clearTimeout(this.timer)
            }, 1000)
        },
        closePopup() {
            if (document.getElementById(this.components_id)) {
                // 图标旋转
                move(`#${this.components_id} .icon`).rotate(0).end()
                // 收起选项
                move(`#${this.components_id} .select_popup`).set('height', `0px`).set('opacity', 0).end()
                this.open = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.iconDown {
    margin-left: 3px;
    padding-bottom: 3.1px;
}

.chainBg {
    width: 14px;
    height: 14px;
    border-radius: 24px;
    margin-right: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}
.select {
    position: relative;
    z-index: 2001;
    .select_popup {
        height: 0px;
        overflow: auto;
        opacity: 0;
        left: 0;
        position: absolute;
        z-index: 2000000;
        // background-color: rgba($color: #eff4f5, $alpha: 1);

        backdrop-filter: blur(10px);

        // border: 1px solid rgba($color: #eff4f5, $alpha: 1);
        .popup_list {
            .popup_item {
                font-size: 13px;
                // height: 36px;
                // line-height: 36px;
                padding: 0 20px;
                height: 37px;
                /* height: 28px; */
                line-height: 37px;
                /* border-radius: 4px; */
                border-radius: 40px;
                // color: #1b1b2c;
                color: #fff;
                cursor: pointer;
                // padding: 0 12px;
                margin-bottom: 10px;
                background-color: #ff6821;
                // border-top: 1px solid rgba($color: #ffffff, $alpha: 0.8);
                display: flex;
                align-items: center;
                /* 2a2f3b */
                // &:first-child {
                //     border-radius: 6px 6px 0 0;
                //     border-top: 1px solid rgba($color: #000000, $alpha: 0);
                // }
                // &:last-child {
                //     border-radius: 0 0 6px 6px;
                // }
                .chainIcon {
                    margin-right: 5px;
                }
            }
        }
    }
    .select_btn {
        // padding: 6px 10px;
        height: 28px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 4px;

        // .btn_text {
        //     font-size: 12px;
        //     color: #b1b1b1;
        //     margin-right: 5px;
        //     flex: 1;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        // }

        .btn_text {
            box-sizing: border-box;
            height: 37px;
            display: flex;
            // padding: 10px 10px 12px 10px;
            padding: 10px 20px;

            justify-content: center;
            align-items: center;

            border-radius: 50px;
            background: #ff6821;

            color: #fff;
            font-family: Inconsolata;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .bg {
        background-color: #615b74; // #eff4f5
    }
    .white {
    }
}
</style>
