export default {
    back() {
        this.$router.go(-1)
    },
    jump_page_path(path, query = {}, backTop = true) {
        this.$store.commit('SideMenu', false)
        this.$router.push({ path, query })
        // if (backTop) {
        //     document.getElementById('view_main').scrollTop = 0
        // }
    },
    jump_tokens() {
        this.jump_page_path('/tokens')
    },
    jump_tokenDetail(id = '') {
        this.jump_page_path(`/tokenDetail/${id}`)
    },
    jump_root() {
        this.jump_page_path('/')
    },
    jump_his() {
        this.jump_page_path('/assetsHistory')
    },
    jump_operationIntroduction() {
        this.jump_page_path('/operationIntroduction')
    },
    jump_perkenalanvideo() {
        this.jump_page_path('/perkenalanvideo')
    },
    jump_purchase(chainId = '', id = '') {
        if (!!chainId && !!id) {
            this.jump_page_path(`/purchaseHistory/${chainId}/${id}`)
        } else if (!chainId && !id) {
            this.jump_page_path(`/purchaseHistory`)
        }
    },
    jump_teamTree() {
        this.jump_page_path('/teamTree')
    },
    jump_personal() {
        this.jump_page_path('/personal')
    },
    jump_invite_rewards() {
        this.jump_page_path('/personal/invite/rewards')
    },
    jump_invite_relation() {
        this.jump_page_path('/personal/invite/relation')
    },
    jump_invite(page) {
        if (page) {
            this.jump_page_path(`/personal/invite/index?page=${page}`)
        } else {
            this.jump_page_path('/personal/invite/index')
        }
    },
    jump_team() {
        this.jump_page_path('/team')
    },
    jump_notice() {
        this.jump_page_path('/notice')
    },
    jump_connect_us(id = 1) {
        this.jump_page_path(`/about/${id}`)
    },
    jump_notice_detail(id) {
        this.jump_page_path(`/noticeDetail/${id}`)
    },
    jump_introduction(id = 3) {
        this.jump_page_path(`/introduction/${id}`)
    },
    jump_plan(id = 2) {
        this.jump_page_path(`/plan/${id}`)
    },
    jump_deposit() {
        this.jump_page_path('/depositPage')
    },
    jump_pay(query) {
        this.jump_page_path('/pay', query)
    },
    jump_assets(ids) {
        if (ids) {
            this.jump_page_path(`/assets/${ids}`)
        } else {
            this.jump_page_path(`/assets`)
        }
    },
    jump_assets_old(ids) {
        if (ids) {
            this.jump_page_path(`/assetsOld/${ids}`)
        } else {
            this.jump_page_path(`/assetsOld`)
        }
    },
    jump_assets_extract() {
        this.jump_page_path(`/extract`)
    },
    jump_assets_extract_old() {
        this.jump_page_path(`/extractOld`)
    },
    jump_assets_freed(id) {
        this.jump_page_path(`/freed/${id}`)
    },
    jump_pool() {
        this.jump_page_path('/pool')
    },
    jump_invest(id) {
        if (id) {
            this.jump_page_path(`/invest/${id}`)
        } else {
            this.jump_page_path('/invest')
        }
    },
    jump_investMore(id) {
        if (id) {
            this.jump_page_path(`/investMore/${id}`)
        } else {
            this.jump_page_path(`/investMore`)
        }
    },
    jump_identityMore(id) {
        if (id) {
            this.jump_page_path(`/identityMore/${id}`)
        } else {
            this.jump_page_path(`/identityMore`)
        }
    },
    jump_investSelect() {
        this.jump_page_path('/investSelect')
    },
    jump_pledgePage() {
        this.jump_page_path('/pledgePage')
    },
    jump_dashboard() {
        this.jump_page_path('/dashboard')
    },
    jump_compensationScheme() {
        this.jump_page_path('/compensationScheme')
    },
    jump_set() {
        this.jump_page_path('/set')
    },
    jump_about() {
        this.jump_page_path('/about')
    },
    jump_shop() {
        this.jump_page_path('/shop')
    },
    jump_history_staked(id) {
        this.jump_page_path(`/personal/history/staked/${id}`)
    },
    jump_history_income() {
        this.jump_page_path(`/personal/history/income`)
    },
    jump_history_extract() {
        this.jump_page_path('/personal/history/extract')
    },
    jump_personal_about() {
        this.jump_page_path('/personal/about')
    },

    jump_personal_language() {
        this.jump_page_path('/personal/language')
    },
    jump_index() {
        this.jump_page_path('/')
    },
    jump_text(id) {
        this.jump_page_path(`/text/${id}`)
    },
    jump_support() {
        this.jump_page_path('/support')
    },
    jump_detail(id = false, cycle = false) {
        if (!id) {
            return
        }
        if (!cycle) {
            return
        }
        this.jump_page_path(`/detail/${id}/${cycle}`)
    },
    jump_nftDetail(id = false) {
        if (!id) {
            return
        }
        this.jump_page_path(`/nftDetail/${id} `)
    },
    jump_coinDetail(id = false) {
        if (!id) {
            return
        }
        this.jump_page_path(`/coinDetail/${id} `)
    },
    // 跳转活期
    jump_market(id) {
        if (!id) {
            return
        }
        this.jump_page_path(`/market/${id}`)
    },
    // 跳转身份卡
    jump_identityDetail(id) {
        if (!id) {
            return
        }
        this.jump_page_path(`/identityDetail/${id}`)
    },
    jump_projectId_Detail(id) {
        this.jump_page_path(`/earning/projectIdDetail/${id} `)
    },
    jump_projectId_Detail2(id) {
        this.jump_page_path(`/earning/projectIdDetail2/${id} `)
    },
    jump_hintPage() {
        this.jump_page_path('/hintPage')
    },
    jump_signScoreLogPage({ type, amount }) {
        this.jump_page_path(`/signRecord/signScoreLog/${type}/${amount}`)
    },
    jump_signIn() {
        this.jump_page_path('/signPage')
    },

    jump_preSale() {
        this.jump_page_path('/preSale')
    },
}
