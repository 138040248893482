<template>
    <div class="noticeItem" @click="changeItem(item)">
        <div class="title">{{ item.title }}</div>
        <div class="val">{{ changeTime(item.updateTime, 'minute') }}</div>
        <div class="cont" v-html="item.content"></div>
    </div>
</template>

<script>
export default {
    props: ['item'],
    computed: {
        changeTime() {
            try {
                // 时 分 秒
                return (date, num = 'minute') => {
                    if (!!date) {
                        if (num === 'minute') {
                            let index = date.lastIndexOf(':')
                            return date.slice(0, index) ? date.slice(0, index) : ''
                        }
                    }
                    return ''
                }
            } catch (error) {
                return ''
            }
        },
    },
    methods: {
        changeItem() {
            this.$emit('change', this.item)
        },
    },
}
</script>

<style lang="scss" scoped>
.noticeItem {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 8px 12px;
    // padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: var(--bg11);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-bottom: 16px;

    .title {
        font-size: 16px;
        font-weight: 700;

        color: var(--font1);
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .val {
        font-size: 14px;
        font-weight: 400;
        color: var(--font2);
        margin-top: 2px;
        font-size: 12px;
        line-height: 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .cont {
        font-size: 14px;
        font-weight: 400;
        margin-top: 4px;
        color: var(--font2);
        font-size: 12px;
        line-height: 12px;
        width: 100%;
        height: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        /deep/ p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; // 保留几行
            -webkit-box-orient: vertical;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; // 保留几行
        -webkit-box-orient: vertical;
    }
}
</style>
