<template>
    <div class="assets">
        <Back :title="langJson('Operation process', 'inscdoge.txt.v5.48')"></Back>
        <div class="bg">
            <div class="list">
                <div class="item" v-for="(item, index) in list" :key="index + 'P'" @click="item.fun()">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    computed: {
        list() {
            try {
                return [
                    {
                        name: this.langJson('TP钱包教学指南', 'inscdoge.txt.v5.64'),
                        fun: () => {
                            try {
                                let lang = localStorage.getItem('Language')
                                if (lang.indexOf('zh') != -1) {
                                    this.openUrl('/ppt/tp/TP-CN.pdf')
                                } else if (lang.indexOf('id') != -1) {
                                    this.openUrl('/ppt/tp/TP-ID.pdf')
                                } else if (lang.indexOf('ko') != -1) {
                                    this.openUrl('/ppt/tp/TP-KO.pdf')
                                } else {
                                    this.openUrl('/ppt/tp/TP-EN.pdf')
                                }
                            } catch (err) {
                                this.openUrl('/ppt/tp/TP-EN.pdf')
                            }
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('INSDOGE操作指南', 'inscdoge.txt.v5.65'),
                        fun: () => {
                            try {
                                let lang = localStorage.getItem('Language')
                                if (lang.indexOf('zh') != -1) {
                                    this.openUrl('/ppt/inscDoge/INSCDOGE-CN.pdf')
                                } else if (lang.indexOf('id') != -1) {
                                    this.openUrl('/ppt/inscDoge/INSCDOGE-ID.pdf')
                                } else if (lang.indexOf('ko') != -1) {
                                    this.openUrl('/ppt/inscDoge/INSCDOGE-KO.pdf')
                                } else {
                                    this.openUrl('/ppt/inscDoge/INSCDOGE-EN.pdf')
                                }
                            } catch (err) {
                                this.openUrl('/ppt/inscDoge/INSCDOGE-EN.pdf')
                            }
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('UNIELON教学指南', 'inscdoge.txt.v5.66'),
                        fun: () => {
                            try {
                                let lang = localStorage.getItem('Language')
                                if (lang.indexOf('zh') != -1) {
                                    this.openUrl('/ppt/unielon/UNIELON-CN.pdf')
                                } else if (lang.indexOf('id') != -1) {
                                    this.openUrl('/ppt/unielon/UNIELON-ID.pdf')
                                } else if (lang.indexOf('ko') != -1) {
                                    this.openUrl('/ppt/unielon/UNIELON-KO.pdf')
                                } else {
                                    this.openUrl('/ppt/unielon/UNIELON-EN.pdf')
                                }
                            } catch (err) {
                                this.openUrl('/ppt/unielon/UNIELON-EN.pdf')
                            }
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('USDT提现指南', 'inscdoge.txt.v5.67'),
                        fun: () => {
                            try {
                                let lang = localStorage.getItem('Language')
                                if (lang.indexOf('zh') != -1) {
                                    this.openUrl('/ppt/usdt/USDT-CN.pdf')
                                } else if (lang.indexOf('id') != -1) {
                                    this.openUrl('/ppt/usdt/USDT-ID.pdf')
                                } else if (lang.indexOf('ko') != -1) {
                                    this.openUrl('/ppt/usdt/USDT-KO.pdf')
                                } else {
                                    this.openUrl('/ppt/usdt/USDT-EN.pdf')
                                }
                            } catch (err) {
                                this.openUrl('/ppt/usdt/USDT-EN.pdf')
                            }
                        },
                        show: true,
                    },
                    {
                        name: this.langJson('Inscribe提现指南', 'inscdoge.txt.v5.68'),
                        fun: () => {
                            try {
                                let lang = localStorage.getItem('Language')
                                if (lang.indexOf('zh') != -1) {
                                    this.openUrl('/ppt/inscribe/Inscribe-CN.pdf')
                                } else if (lang.indexOf('id') != -1) {
                                    this.openUrl('/ppt/inscribe/Inscribe-ID.pdf')
                                } else if (lang.indexOf('ko') != -1) {
                                    this.openUrl('/ppt/inscribe/Inscribe-KO.pdf')
                                } else {
                                    this.openUrl('/ppt/inscribe/Inscribe-EN.pdf')
                                }
                            } catch (err) {
                                this.openUrl('/ppt/inscribe/Inscribe-EN.pdf')
                            }
                        },
                        show: true,
                    },
                ]
            } catch (error) {
                return []
            }
        },
    },
    watch: {},
    async created() {},
    mounted() {},
    methods: {
        // changeItem(item) {
        //     this.jump_notice_detail(item.articleId)
        // },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            flex: 1;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            max-height: calc(100% - 87px);

            .item {
                width: 100%;
                box-sizing: border-box;
                padding: 10px 16px;
                // padding: 5px;
                display: flex;
                font-size: 14px;
                flex-direction: column;
                background-color: var(--bg11);
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                border-radius: 4px;
                margin-bottom: 12px;
            }
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
