<template>
    <div ref="bsWrapper" class="scroll">
        <div class="scroll2">
            <!-- top -->
            <div class="slotTop" ref="top">
                <slot name="top">
                    <div class="top">
                        <div v-show="!loading" class="icon1" :style="icon1Style">
                            <svg class="svg" t="1693728950063" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2477" width="31" height="31" :fill="textObj.iconColor"><path d="M358 127h308v385h231L512 897 127 512h231z" p-id="2478"></path></svg>
                        </div>
                        <LoadIcon v-show="loading" salce="0.45" ws="45">
                            <Loading1 :color="textObj.iconColor" />
                        </LoadIcon>
                        <div class="text">
                            <!-- 是否可以触发下拉 -->
                            <div v-show="text1">
                                {{ langJson('正在刷新...', 'inscdoge.txt.v5.24') }}
                            </div>
                            <div v-show="text2">
                                {{ langJson('下拉可以刷新', 'inscdoge.txt.v5.25') }}
                            </div>
                            <div v-show="text3">
                                {{ langJson('释放立即刷新', 'inscdoge.txt.v5.26') }}
                            </div>
                            <div v-show="text4">
                                {{ langJson('已完成刷新', 'inscdoge.txt.v5.27') }}
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
            <!-- scroll -->
            <div class="slotMain">
                <slot name="main"></slot>
            </div>
            <!-- bottom -->
            <div ref="bottom" class="slotMore">
                <slot name="bottom" :loadMore="loadMore" :type="type">
                    <div class="bottom">
                        <div v-show="loadMore" class="load" :class="{ fadein: loadMore, fadeOut: !loadMore }">
                            <LoadIcon salce="0.7">
                                <Loading6 :color="textObj.iconColor" />
                            </LoadIcon>
                            <div class="loadingText">
                                <!-- {{ textObj.text5 }} -->
                                {{ langJson('加载中', 'inscdoge.txt.v5.5') }}
                                <!-- 加载中 -->
                            </div>
                        </div>
                        <div v-show="!loadMore" :class="{ fadein: !loadMore, fadeOut: loadMore }">
                            {{ langJson('没有更多数据了', 'inscdoge.txt.v5.28') }}
                        </div>
                    </div>
                </slot>
            </div>

            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
// import BScroll from '@better-scroll/core'
// import PullDown from '@better-scroll/pull-down'
// import PullUp from '@better-scroll/pull-up'
// import MouseWheel from '@better-scroll/mouse-wheel'
// BScroll.use(PullDown)
// BScroll.use(PullUp)
// BScroll.use(MouseWheel)

export default {
    name: 'DivScroll',
    props: {
        textObj: {
            type: Object,
            default: () => {
                return {
                    iconColor: '#00adb5',
                    fontColor: '#000000',

                    text1: '正在刷新...',
                    text2: '下拉可以刷新',
                    text3: '释放立即刷新',
                    text4: '已完成刷新',
                    text5: '加载中',
                    text6: '没有更多数据了',

                    // text1: 'Refreshing',
                    // text2: 'Dropdown can refresh',
                    // text3: 'Release immediate refresh',
                    // text4: 'Refresh completed',
                    // text5: 'LOADING',
                    // text6: 'No more data',
                }
            },
        },
    },
    data() {
        return {
            // 自动获取自css
            h1: '', // 最大下拉间距 顶部div高度   生效为 2/3
            h2: '', // 上拉阈值 底部div高度

            startInit: false,
            touchEnd: false,
            rotate: false,
            initISEnd: false, //  是否下拉加载中
            loading: false, //  是否下拉加载中
            loadMore: false, //  是否上拉加载中
        }
    },
    computed: {
        icon1Style() {
            try {
                let obj = {}
                if (this.touchEnd || this.rotate) {
                    obj = { transform: `rotate(${180}deg)` }
                }
                if (!this.touchEnd && !this.rotate) {
                    obj = { transform: `rotate(${0}deg)` }
                }
                return obj ? obj : {}
            } catch (error) {
                console.log('error', error)
                return {}
            }
        },
        text1() {
            try {
                return this.loading
            } catch (error) {
                console.log('error', error)
                return false
            }
        },
        text2() {
            try {
                return !this.loading && !this.rotate && !this.touchEnd
            } catch (error) {
                console.log('error', error)
                return false
            }
        },
        text3() {
            try {
                return !this.loading && this.rotate && !this.touchEnd && !this.initISEnd && !this.startInit
            } catch (error) {
                console.log('error', error)
                return false
            }
        },
        text4() {
            try {
                return !this.loading && this.touchEnd && !this.initISEnd && !this.startInit
            } catch (error) {
                console.log('error', error)
                return false
            }
        },
    },
    created() {
        this.bscroll = null
        this.type = 1
    },

    mounted() {
        window.addEventListener('resize', () => {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.bscroll && this.bscroll.refresh()
                clearTimeout(this.timer)
            }, 100)
        })
        this.initBscroll()
    },
    methods: {
        scrollTo(x, y, time = 300) {
            this.bscroll && this.bscroll.scrollTo(x, y, time)
        },
        async initBscroll() {
            if (this.$refs.top) {
                this.h1 = this.$refs.top.offsetHeight
            }
            if (this.$refs.bottom) {
                this.h2 = this.$refs.bottom.offsetHeight
            }
            await loadScript('better-scroll')
            this.bscroll = new BetterScroll.createBScroll(this.$refs.bsWrapper, {
                click: true,
                scrollY: true,
                mouseWheel: true, // 滚轮生效
                // bounceTime: 800 ,// 动画惯性
                pullDownRefresh: {
                    threshold: this.h1 * 0.67, // 下拉生效
                    stop: this.h1,
                },
                pullUpLoad: {
                    threshold: this.h2,
                },
            })
            this.bscroll.on('pullingDown', () => {
                this.loading = true
                this.initISEnd = true
                this.touchEnd = true
                this.startInit = true
                this.type = 0
                this.$emit('get', { page: 1, type: 0 })

                // 下拉没有松手时 不允许下拉
            })
            this.bscroll.on('pullingUp', () => {
                this.loadMore = true
                this.type = 1
                this.$emit('get', { page: '', type: 1 })
            })
            this.bscroll.on('scroll', (pos) => {
                if (pos.y >= this.h1 * 0.67) {
                    this.rotate = true
                } else {
                    this.rotate = false
                }
            })
            this.bscroll.on('scrollStart', () => {
                this.touchEnd = false
                this.initISEnd = false
            })
            this.bscroll.on('mousewheelStart', () => {
                this.touchEnd = false
                this.initISEnd = false
            })

            // 第一页
            this.loadMore = true
            this.type = 1
            this.$emit('get', { page: 1, type: 1 })
        },

        getEnd(type) {
            // if (type) {
            //上拉
            this.loadMore = false
            this.$nextTick(() => {
                this.bscroll.finishPullUp()
            })
            // } else {
            // 下拉
            this.initISEnd = false
            this.loading = false
            this.startInit = false
            this.$nextTick(() => {
                this.bscroll.finishPullDown()
                // this.type = 1 // 下拉完后默认上拉
            })
            // }
            this.$nextTick(() => {
                this.bscroll.refresh()
            })
        },
        async lowSpeed(obj = {}) {
            let res = { fn: async () => {}, ms: 0, speed: 100, speedCount: 0 }
            Object.assign(res, obj)
            let { fn, ms, speed, speedCount } = res
            await new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    let res2 = setTimeout(() => {
                        resolve(true)
                        clearTimeout(res2)
                    }, ms)
                })
            })

            if (speedCount < speed) {
                speedCount++
                await this.lowSpeed({ fn, ms, speed, speedCount })
            } else if (speedCount == speed) {
                await fn()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
/* cubic-bezier(0.23, 1, 0.32, 1) */
/* cubic-bezier(0.25, 0.46, 0.45, 0.94) */
/* cubic-bezier(0.165, 0.84, 0.44, 1) */
.fadein {
    animation: fadein 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.fadeout {
    animation: fadein 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
* {
    transition: all 0s;
}
.scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .scroll2 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100% - 1px);

        .slotTop {
            position: absolute;
            transform: translateY(-100%) translateZ(0);
            width: 100%;
            .top {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100px;
                /* height: 60px; */
                width: 100%;
                .icon1 {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: transform 0.3s;
                }
                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .slotMain {
            /* * {
                animation: fadein 0.2s cubic-bezier(0.23, 1, 0.32, 1);
            } */
            * {
                transition: all 0s;
            }
        }
        .slotMore {
            flex: 1;
            display: flex;
            flex-direction: column;

            .bottom {
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #777777;

                .load {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .loadingText {
                        padding-left: 6px;
                    }
                }
            }
        }
    }
}
</style>
