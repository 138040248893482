<template>
    <Model v-model="walletShow" zIndex="2001">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{ langJson('Choose a wallet', 'inscdoge.txt.v1.144') }}
                </div>
                <div class="top_right">
                    <GI name="model_close_1" ws="24" hs="24" @click="close" :color="svg1"></GI>
                </div>
            </div>
            <StepRun ref="step" :stepNum="true">
                <div class="play_item" slot="step1">
                    <div class="play_cell" v-for="(item, index) in wallets" :key="index + 'P'" @click="selectPlugin(item)">
                        <div class="item_left">
                            <GI :src="item.icon" ws="24" hs="24"></GI>
                            <div class="cell_title">{{ item.showName }}</div>
                        </div>

                        <GI v-if="selectName == item.showName" :noClickStop="true" name="lang_select" ws="20" hs="20" :color="'#ff6821'"></GI>
                    </div>
                </div>
                <LoadModel slot="step2" :msg="langJson('Please connect your wallet', 'inscdoge.txt.v1.145')"></LoadModel>
                <LoadModel slot="step3" :msg="langJson('Please use your wallet for signature', 'inscdoge.txt.v1.146')"></LoadModel>
                <LoadModel slot="step4"></LoadModel>
            </StepRun>

            <div class="model_btns" v-if="runStep === 0">
                <div class="model_btn">
                    <BTN type="orangeCancle" @click="close">
                        {{ langJson('Cancel', 'inscdoge.txt.v1.130') }}
                    </BTN>
                </div>
                <div class="btn_grap"></div>
                <div class="model_btn">
                    <BTN type="orange" :disable="!plugin || !selectName" @click="waitConnect">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
                </div>
            </div>
        </div>
    </Model>
</template>
<script>
import tools from '@/mixin/tools'
export default {
    data() {
        return {
            form: {
                key: '',
                value: '',
            },
            wallets: [],
            runStep: 0,
        }
    },
    computed: {
        plugin() {
            return this.walletDetail.plugin
        },
        selectName() {
            return this.walletDetail.showName
        },
    },
    props: {},
    created() {
        this.init()
    },
    watch: {
        walletShow(a) {
            console.log(this.wallets)
        },
    },
    methods: {
        // 退出walletConent
        disWalletConent() {
            try {
                for (const [key, val] of Object.entries(localStorage)) {
                    if (key.indexOf('wc@2') != -1) {
                        localStorage.removeItem(key)
                    }
                }
                localStorage.removeItem('wagmi.store')
            } catch (error) {}
        },
        async init() {
            let wallets = await this.supportWallets()
            console.log(wallets)

            let metamask = wallets[0]
            metamask.showName = metamask.name
            let walletConnect = wallets[1]
            walletConnect.showName = walletConnect.name

            let tron_wallet = {
                // name:'TronLink'
                ...metamask,
                showName: 'Tron Link',
                icon: '/source/svg/tron_link.svg',
                ios: {
                    schemes: 'dapp://${domain}',
                    download: 'https://www.tronlink.org/',
                },
                android: {
                    schemes: 'dapp://${domain}',
                    download: 'https://www.tronlink.org/',
                },
                defaultDownload: 'https://www.tronlink.org/',
            }

            let tp_wallet = {
                ...metamask,
                showName: 'TokenPocket',
                icon: '/source/svg/tokenpocket.svg',
                ios: {
                    schemes: 'dapp://${domain}',
                    download: 'https://www.tokenpocket.pro/zh/download/app',
                },
                android: {
                    schemes: 'dapp://${domain}',
                    download: 'https://www.tokenpocket.pro/zh/download/app',
                },
                defaultDownload: 'https://www.tokenpocket.pro/zh/download/app',
            }

            this.wallets = [tron_wallet, tp_wallet] // , metamask
            // console.log(this.wallets)
        },
        async waitConnect() {
            try {
                this.nextStep()
                if (window.provider && window.provider.disconnect) {
                    window.provider.disconnect()
                    this.disWalletConent()
                }
                // Connect wallet
                console.log(this.plugin)
                let connected = await this.connect()
                if (!connected) {
                    this.close()
                    return
                }
                this.nextStep()

                // 获取nonce 并签名
                let { dispatch } = this.$store
                let { address } = this.walletDetail
                await dispatch('nonceGet', { address })
                let sign = await this.walletSign(this.loginAccout.nonce, this.plugin)
                if (!sign) {
                    this.close()
                    return
                }
                this.nextStep()

                let inviteCode = ''
                // 提交登录
                if (tools.getCookie('invitedCode')) {
                    inviteCode = tools.getCookie('invitedCode')
                }

                let login = await dispatch('tokenGet', { sign, address, inviteCode })
                if (login) {
                    await dispatch('personalGet')
                }

                window.logoSuccess && window.logoSuccess()

                this.nextStep()
                setTimeout(() => {
                    this.close()
                }, 2000)
            } catch (err) {
                console.log(err)
                this.close()
            }
            this.globalEvent.emit('login')
            // this.ticketCheck()
        },
        nextStep() {
            this.$refs.step.next()
            this.runStep = this.$refs.step.step
        },
        async selectPlugin(item) {
            await this.linkUpdate({ plugin: item.name, showName: item.showName })
        },
        close() {
            this.runStep = 0
            this.$store.commit('walletShow', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.model_main {
    padding: 24px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;

    .link_wallet {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 200px;

        .link_tip {
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: blue;
            margin-top: 40px;
        }
    }

    .model_btns {
        .btn_grap {
            width: 20px;
        }

        .model_btn {
            flex: 1;
        }

        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .model_top {
        .top_right {
            cursor: pointer;
        }

        .top_left {
            font-size: 18px;
            line-height: 28px;
            color: var(--font1);
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .play_item {
        background-color: var(--bg11);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        margin-top: 20px;
        transition: all 0s !important;

        .play_cell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 22px 16px;
            border-bottom: 1px solid var(--border2);

            .cell_info {
                width: 80%;

                .title {
                    font-size: 16px;
                    line-height: 16px;

                    width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .desc {
                    font-size: 12px;
                    line-height: 14px;

                    width: 100%;
                    margin-top: 6px;
                }
            }

            .item_left {
                display: flex;
                align-items: center;

                .cell_title {
                    font-size: 16px;
                    line-height: 16px;

                    color: var(--font1);
                    margin-left: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .cell_jump {
                transform: rotate(180deg);
            }
        }

        .play_cell:last-child {
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
