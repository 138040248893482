<template>
    <div class="team">
        <Back :title="langJson('TEAM', 'inscdoge.txt.v1.68')"></Back>
        <div class="bg">
            <div class="list">
                <div class="item">
                    <div class="key">{{ langJson('Level', 'inscdoge.txt.v5.45') }}：</div>
                    <div class="val active fontOver">{{ information.userName }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Level', 'inscdoge.txt.v1.15') }}：</div>
                    <div class="val active fontOver">{{ information.roleName }}</div>
                </div>
                <div class="item" @click="inviterHand">
                    <div class="key">{{ langJson('Inviter', 'inscdoge.txt.v2.30') }}：</div>
                    <div class="val active" :class="{ underline: !information.parentUserName }">
                        <div class="fontOver">{{ information.parentUserName || langJson('Add', 'inscdoge.txt.v1.55') }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Invitation', 'inscdoge.txt.v1.69') }}：</div>
                    <div class="val active">
                        <div class="fontOver">{{ urlNow }}</div>
                        <GI @click="copy(urlNow)" name="new_copy" class="copy" ws="18" hs="18"></GI>
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('邀请id', 'inscdoge.txt.v5.44') }}：</div>
                    <div class="val active">
                        <div class="fontOver">{{ information.inviteCode }}</div>
                        <GI @click="copy(information.inviteCode)" name="new_copy" class="copy" ws="18" hs="18"></GI>
                    </div>
                </div>
            </div>

            <div class="total">
                <div class="item">
                    <div class="key">{{ langJson('Total number of team INS', 'inscdoge.txt.v1.70') }}：</div>
                    <div class="val">{{ saveNum(teamInfo.teamTotalIns || 0, insPrecision) | numberGrap }} INS</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Total team size', 'inscdoge.txt.v1.71') }}：</div>
                    <div class="val">{{ teamInfo.teamNum | numberGrap }}</div>
                </div>
            </div>

            <div class="desc">{{ langJson('Team details', 'inscdoge.txt.v2.33') }}</div>
            <div class="btnCheck">
                <BTN @click="jump_teamTree" class="Check" type="orange">
                    {{ langJson('Check', 'inscdoge.txt.v2.34') }}
                </BTN>
            </div>
            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            insPrecision: '2',
            urlNow: '',
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        information() {
            try {
                let result = this.globalPersonal.information
                // let result = { ...this.globalPersonal.information, parentUserName: '' }
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
    },
    async created() {
        await this.init()
    },
    mounted() {},
    methods: {
        // 添加邀请人
        async inviterHand() {
            if (this.information.parentUserName) {
                this.pushMsg('warning', this.langJson('Invitation relationship bound', 'inscdoge.txt.v1.72'))
                return
            }
            await this.loginOf(() => {
                this.$store.commit('addInviterModel', true)
            }, false)
        },
        async init() {
            let { dispatch } = this.$store
            // await dispatch('agentInfo')
            await dispatch('teamInfo')
            // console.log('teamInfo', this.teamInfo)
            await dispatch('personalGet')
            // console.log('this.globalPersonal.information', this.globalPersonal.information)

            let protocol = window.location.protocol //http / https
            let host = window.location.host //主域名 + 端口
            if (!!this.$route.query && this.$route.query.page) {
                this.urlNow = `${protocol}//${host}/${this.$route.query.page}/${this.information.inviteCode}`
            } else {
                // this.urlNow = `${protocol}//${host}/preSale/${this.information.inviteCode}`
                this.urlNow = `${protocol}//${host}/index/${this.information.inviteCode}`
            }

            // let INS = this.chainSymbol.find((item) => {
            //     return item.name == 'INS'
            // })
            // this.insPrecision = INS.precision
        },
    },
}
</script>
<style lang="scss" scoped>
.team {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        .list {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            font-size: 20px;
            font-weight: 700;
            line-height: 21px;

            .item {
                display: flex;
                align-items: flex-end;

                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0px;
                }
                .key {
                    color: #fff;
                }
                .val {
                    display: flex;
                    align-items: flex-end;
                    color: #fff;
                    font-size: 14px;
                    line-height: 17px;
                    height: 17px;
                    .fontOver {
                        max-width: calc(100vw - 180px);
                        &:first-letter {
                            text-transform: none;
                        }
                    }
                    .add {
                        margin-left: 9px;
                    }

                    &.active {
                        color: #ff6821;
                    }
                    &.underline {
                        text-decoration: underline;
                        color: #ffffff;
                    }

                    .copy {
                        margin-left: 9px;
                    }
                }
            }
        }
        .total {
            width: calc(100% - 40px);
            margin: 0 auto;
            min-height: 70px;
            border-radius: 6px;
            // background: linear-gradient(90deg, #140f6e 66.41%, #4b0ac9 96.41%);
            background: linear-gradient(90deg, #bc9e49 66.41%, #88773b 96.41%);

            font-family: Montserrat;
            font-size: 10px;
            font-weight: 500;
            line-height: 20px;
            padding: 11px 17px;
            box-sizing: border-box;
            margin-top: 44px;
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0px;
                }
                .key {
                    // color: #ff6821;
                    color: #000;
                }
                .val {
                    color: #fff;
                }
            }
        }
        .desc {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 800;
            line-height: 28px;
            text-align: center;
            margin-top: 22px;
        }
        .btnCheck {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 17px;
            .Check {
                min-width: 194px;
                .text {
                    font-family: Inconsolata;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
