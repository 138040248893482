<template>
    <div class="assets">
        <Back :title="langJson('ASSETS', 'inscdoge.txt.v1.5')">
            <div class="right">
                <GI @click="jump_his" name="his" ws="24" hs="24" :noClickStop="true"></GI>
            </div>
        </Back>
        <div class="bg">
            <div class="list">
                <div class="item">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('Total assets', 'inscdoge.txt.v1.6') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insTotalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('Buy Amount', 'inscdoge.txt.v2.44') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.purchaseAmount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('活期收益', 'inscdoge.txt.v5.17') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insEarning || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item">
                    <!-- 锁仓 -->
                    <div class="key">{{ langJson('Total amount locked', 'inscdoge.txt.v1.7') }}：</div>
                    <div class="val active">
                        <div class="fontOver v2">{{ saveNum(pledgeAssets.lockAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item">
                    <!-- 收益账户 -->
                    <div class="key">{{ langJson('INS活期数量', 'inscdoge.txt.v1.8') }}：</div>
                    <div class="val active">
                        <div class="fontOver v3">{{ saveNum(pledgeAssets.incomeAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('已释放数量', 'inscdoge.txt.v5.81') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insReleased || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('活期收益', 'inscdoge.txt.v5.17') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insEarning || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <!-- <div class="item">
                    <div class="key">{{ langJson('USDT总资产', 'inscdoge.txt.v5.62') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtTotalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('USDT冻结资产', 'inscdoge.txt.v5.63') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtLockAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div> -->
                <div class="item">
                    <div class="key">{{ langJson('奖励活期数量', 'inscdoge.txt.v5.60') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div>

                <!-- <div class="item ins_usdt">
                    <div class="key">{{ langJson('奖励活期数量', 'inscdoge.txt.v5.60') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">≈{{ saveNum(pledgeAssets.usdtAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div> -->
                <!-- 可提取的 -->
                <!-- <div class="item">
                    <div class="key">{{ langJson('Extractable', 'inscdoge.txt.v1.9') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.normalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div> -->
                <!-- 现在有两种手续费 -->
                <!-- <div class="desc">({{ langJson('Handlng fee', 'inscdoge.txt.v1.10') }}：{{ withdrawFee.withdrawFeeRate || 0 }}%)</div> -->

                <div class="btnWithdraw">
                    <!-- {{ langJson('Increase withdrawal','inscdoge.txt.v5.1') }} -->
                    <!-- <BTN class="add" type="line" :disable="!withdrawFee.withdrawFlag" @click="withdrawAdd" height="26px">
                        {{ langJson('划转', 'inscdoge.txt.v5.2') }}
                    </BTN> -->
                    <!-- ins 注释掉 提现/购买/内部转账 -->
                    <!-- <BTN class="add" type="line" :disable="!withdrawFee.transferFlag" @click="internalTransfer" height="26px">
                        {{ langJson('内部转账', 'inscdoge.txt.v5.82') }}
                    </BTN>
                    <BTN class="withdraw" type="orange" :disable="!withdrawFee.withdrawFlag" @click="withdraw" height="26px">{{ langJson('Withdraw', 'inscdoge.txt.v3.1') }}</BTN> -->

                    <!-- <div class="add" @click="withdrawAdd">{{ langJson('Add', 'inscdoge.txt.v2.31') }}</div> -->
                </div>

                <div class="textDesc">
                    1、{{ langJson('打开unielon，https://unielon.com/', 'inscdoge.txt.v3.2') }}
                    <br />
                    <br />
                    2、{{ langJson('点击右上角Connect Wallet 连接钱包；', 'inscdoge.txt.v3.3') }}
                    <br />
                    <br />
                    3、{{ langJson('跳出Install Unielon Wallet提示，点击前往应用商店安装unielon；', 'inscdoge.txt.v3.4') }}
                    <br />
                    <br />
                    4、{{ langJson('点击添加至浏览器，添加扩展程序即完成安装；', 'inscdoge.txt.v3.5') }}
                    <br />
                    <br />
                    5、{{ langJson('返回unielon页面再次点击连接钱包，提示Create new wallet，点击后即开始新钱包的创建；', 'inscdoge.txt.v3.6') }}
                    <br />
                    <br />
                    6、{{ langJson('需输入两次同样的密码，作为钱包登陆密码；输入完成后，会跳出12个钱包助记词（请按顺序手抄记录，且不要泄露），记录完成后点击继续，钱包就创建完成啦～', 'inscdoge.txt.v3.7') }}
                    <br />
                    <br />
                    7、{{ langJson('最后，在钱包的最上面，可复制的长串字符即个人的钱包地址。', 'inscdoge.txt.v3.8') }}
                </div>
                <div class="descTime">{{ langJson('Withdrawals will arrive within 24-48 hours', 'inscdoge.txt.v3.9') }}</div>
            </div>

            <!-- <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div> -->
            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            insPrecision: '2',
            normal: '0', // 正常账户
            lock: '0', // 冻结账户
            income: '0', // 收益账户
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        isLogin() {
            try {
                return this.walletDetail && this.walletDetail.address && this.loginToken
            } catch (error) {
                return false
            }
        },
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                console.log('isLogin', a, b)
                if (!!a && !b) {
                    await this.init()
                }
            },
        },
    },
    async created() {
        await this.init()
    },
    mounted() {},
    methods: {
        async withdraw() {
            await this.loginOf(() => {
                if (this.pledgeAssets.usdtAccount > 0 || this.pledgeAssets.incomeAccount > 0) {
                    this.$store.commit('withdrawModel', true)
                } else {
                    this.pushMsg('warning', this.langJson('Your normal balance is insufficient to withdraw funds', 'inscdoge.txt.v5.3'))
                }
            }, false)
        },
        async withdrawAdd() {
            await this.loginOf(() => {
                // if (this.pledgeAssets.incomeAccount > 0) {
                this.$store.commit('withdrawAddModel', true)
                // } else {
                //     this.pushMsg('warning', this.langJson('Your current balance is insufficient to transfer', 'inscdoge.txt.v5.4'))
                // }
            }, false)
        },
        async internalTransfer() {
            await this.loginOf(() => {
                if (this.pledgeAssets.usdtAccount > 0 || this.pledgeAssets.incomeAccount > 0) {
                    this.$store.commit('internalTransferModel', true)
                } else {
                    this.pushMsg('warning', this.langJson('Your current balance is insufficient to transfer', 'inscdoge.txt.v5.4'))
                }
            }, false)
        },
        async init() {
            let { dispatch } = this.$store
            await dispatch('pledgeAssets')

            // this.$store.commit('pledgeAssets', { totalAccount: '72808012.3481682688', lockAccount: '1026543.0246', incomeAccount: '0', normalAccount: '0' })

            // console.log('pledgeAssets', this.pledgeAssets)

            // let INS = this.chainSymbol.find((item) => {
            //     return item.name == 'INS'
            // })
            // this.insPrecision = INS.precision
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;

            font-size: 20px;
            font-weight: 700;
            line-height: 21px;

            .item {
                display: flex;
                align-items: flex-end;
                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0px;
                }
                &.ins_usdt {
                    margin-top: -10px;
                    .key {
                        color: #00000000;
                    }
                    .val {
                        color: #ff6821bf !important;
                    }
                }
                &.item2 {
                    padding-left: 12px;
                    .key {
                        color: #7a7a7a;
                        flex-shrink: 0;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
                .key {
                    color: #fff;
                    flex-shrink: 0;
                }
                .val {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    color: #ff6821;
                    font-size: 14px;
                    line-height: 14px;
                    height: 14px;
                    /* background-color: red; */
                    .fontOver {
                        /* background-color: blue; */
                        max-width: calc(100% - 25px);
                        text-transform: none;
                        /* max-width: calc(100vw - 209px);
                        &.v2 {
                            max-width: calc(100vw - 285px);
                            text-transform: none;
                        }
                        &.v3 {
                            max-width: calc(100vw - 227px);
                            text-transform: none;
                        }
                        &.v4 {
                            max-width: calc(100vw - 206px);
                            text-transform: none;
                        }
                        &:first-letter {
                            text-transform: none;
                        } */
                    }
                    .up {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .add {
                        margin-left: 9px;
                    }

                    &.active {
                        color: #ff6821;
                    }
                    &.underline {
                        text-decoration: underline;
                        color: #ffffff;
                    }

                    .copy {
                        margin-left: 9px;
                    }
                }
            }
            .desc {
                font-family: Inconsolata;
                font-size: 16px;
                font-weight: 500;
                line-height: 17px;
                color: #a99388;
            }
        }

        .btnWithdraw {
            display: flex;
            /* align-items: flex-end; */
            /* justify-content: space-between; */
            margin-top: 30px;
            .withdraw {
                min-width: 105px;

                ::v-deep .btnText {
                    font-size: 12px;
                }
            }
            .add {
                /* font-size: 16px;
                text-decoration: underline;
                color: #ffffff; */
                margin-right: 10px;
                min-width: 105px;
                ::v-deep .btnText {
                    font-weight: 400;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                }
            }
        }
        .textDesc {
            box-sizing: border-box;
            margin-top: 32px;
            width: 100%;
            /* height: 337px; */
            padding: 21px;
            border-radius: 12px;
            border: 1px solid #292829;

            font-family: Montserrat;
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            color: #eeeeee;
        }
        .descTime {
            font-family: Inconsolata;
            font-size: 12px;
            font-weight: 500;
            line-height: 13px;
            margin-top: 18px;
            color: #a99388;
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }

        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
