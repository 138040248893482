// import Web3 from 'web3'
import 'h-connect-wallet'
// import { Uincubator, DistributeToken } from 'h-uincubator'
import supportConnect from 'h-connect-wallet/src/walletSupport'
export default {
    async supportWallets() {
        // await loadScript("h-connect-wallet")
        return supportConnect
    },
    async buildConnect(walletPlugin) {
        // await loadScript("h-connect-wallet")
        if (!walletPlugin) {
            walletPlugin = this.walletDetail && this.walletDetail.plugin
        }
        let connect

        if (walletPlugin) {
            connect = new Connect(walletPlugin, '8d9464a6591a4cddcc508538ddede892', this.walletDetail.chainId || 1)
        } else {
            await this.$store.dispatch('logout')
            window.location.reload()
        }

        return connect
    },
    async supportProvider(walletPlugin) {
        let connect = await this.buildConnect(walletPlugin)

        let chainMapping = {}
        this.chainMapping.forEach((item) => {
            chainMapping[item.chainId] = item.chainRpc
        })
        let inited = await connect.init(chainMapping)
        let accounts = []
        if (inited) {
            accounts = await connect.enable()
        }
        return { provider: connect.provider, accounts, connect }
    },
    async isProvider(walletPlugin) {
        let { provider, accounts } = await this.supportProvider(walletPlugin)
        return { provider, accounts }
    },

    async linkUpdate(obj) {
        let chainDetail
        if ('chainId' in obj) {
            chainDetail = this.findChain('chainId', obj.chainId)
            obj.chainDetail = chainDetail
        }
        let { plugin } = this.walletDetail

        if (plugin || 'plugin' in obj) {
            this.$store.commit('walletDetail', {
                ...this.walletDetail,
                ...obj,
            })
            // obj.chainDetail && (await this.stakedState())
        } else {
            await this.$store.dispatch('logout')
            window.location.reload()
        }

        return this.walletDetail
    },

    async stakedState() {
        try {
            await loadScript('h-uincubator')
            await this.connect()
            let { chainDetail, plugin, address } = this.walletDetail
            let platformContract = this.findContractObj(chainDetail.id)
            let { withdraw_contract, invest_contract, withdraw_pay_out_address } = platformContract
            let { provider, accounts } = await this.isProvider(plugin)
            if (!withdraw_contract && !invest_contract && address) {
                return {}
            }
            let invest = new Uincubator({
                provider,
                account: accounts[0],
                contract: invest_contract,
            })
            let withdraw = new DistributeToken({
                provider,
                account: accounts[0],
                contract: withdraw_contract,
            })

            return {
                withdraw_contract,
                invest_contract,
                withdraw_pay_out_address,
                invest,
                withdraw,
            }
        } catch (err) {
            console.log(err)
            return {}
        }
    },
    async getBalance(tokenObj) {
        try {
            let { contract } = tokenObj
            await loadScript('h-unielon-ins')
            let res = new UnielonIns()
            await res.connect()

            let result = await res.balance(contract)
            return {
                balance: result.data,
            }
        } catch (error) {
            console.log(error)
            return {}
        }
    },
    async symbolTokenisApprove(tokenObj) {
        let { contract, target, amount } = tokenObj
        await loadScript('h-unielon-ins')
        let res = new UnielonIns(target)
        await res.connect()

        // 第一次 设置限制额度

        // isApprove
        let result = await res.isApprove({
            token: contract,
            amount,
        })
        return result.data
    },
    async symbolTokenApprove(tokenObj) {
        try {
            let { contract, target } = tokenObj
            await loadScript('h-unielon-ins')
            let res = new UnielonIns(target)
            await res.connect()

            let result = await res.approve({
                token: contract,
                amount: '9999999999999999999999999999999999',
            })
            return result.data
        } catch (error) {
            return false
        }
    },
    async buyProject(tokenObj) {
        try {
            let { contract, id, amount } = tokenObj
            await loadScript('h-unielon-ins')
            let supportContract = await this.supportContract()

            let target = supportContract['collection_contract']
            let res = new UnielonIns(target)
            await res.connect()

            console.log('购买', {
                token: contract,
                id,
                amount,
            })
            let result = await res.pay_token({
                token: contract,
                id,
                amount,
            })

            return result.data
        } catch (error) {
            console.log(error)
            return
        }
    },
    async useChainId() {
        try {
            await loadScript('web3')
            let { provider, accounts } = await this.isProvider()
            if (!provider) {
                return false
            } else {
                window.web3 = new Web3(provider)
                let chainID = await web3.eth.getChainId()
                return chainID
            }
        } catch (err) {
            console.log(err)
        }
    },
    async switchNetwork(chain) {
        let chainId = await this.useChainId()

        if (chain.chainId == chainId) {
            return true
        }

        if (!chainId || !chain) {
            this.pushMsg('error', this.langJson('Main chain abnormality', 'inscdoge.txt.v1.74'))
            return false
        }
        try {
            if (chain.chainId != chainId) {
                const data = [{ chainId: `0x${chain.chainId.toString(16)}` }]
                await window.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: data,
                })
            }
        } catch (err) {
            if (err && err.code == 4902) {
                const data = [
                    {
                        chainId: `0x${chain.chainId.toString(16)}`,
                        chainName: chain.networkName,
                        rpcUrls: [chain.chainRpc],
                    },
                ]
                await window.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: data,
                })
            }
        }
        // chainId = await this.useChainId()
        // if (chainId == chain.chainId) {
        //     await this.connect(this.walletDetail.plugin)
        //     return true
        // } else {
        //     return false
        // }

        // 在wallet connent下 这里由于是监听chainChanged 和 await 同时返回所以比的是 两个接口的快慢问题
        if (chain.chainId != chainId) {
            let res = await this.wait(chain)
            return res
        } else {
            return true
        }
        // chainId = await this.useChainId()
        // console.log('useChainId', chainId) //  当前的链
        // console.log('chain.chainId', chain.chainId) // 要切的链

        // if (chainId == chain.chainId) {
        //     await this.connect(this.walletDetail.plugin)
        //     return true
        // } else {
        //     return false
        // }
    },
    //wait数据加载
    async wait(chain) {
        // console.log('开始监听')
        return new Promise((resolve) => {
            let res

            let time
            clearTimeout(time)
            time = setTimeout(() => {
                clearTimeout(time)
                resolve(false) // 5秒后算做超时失败
            }, 5000)
        })
    },
    async checkChangeChainId(chain) {
        let chainId
        chainId = await this.useChainId()
        // console.log('useChainId', chainId) //  当前的链
        // console.log('chain.chainId', chain.chainId) // 要切的链

        if (chainId == chain.chainId) {
            await this.connect(this.walletDetail.plugin)
            return true
        } else {
            return false
        }
    },
}
