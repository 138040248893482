import api from '@/api/auction'

const auctionModule = {
    state: {
        //网站nft最新拍卖列表
        newlistAuction: [],
        //网站nft即将结束拍卖列表
        endlistAuction: [],
        // 网站nft拍卖列表
        nftlistAuction: [],
        // nft拍卖详情
        detail: {},
        // nft拍卖出价记录
        auctionOffers: [],
        // 推荐列表
        auctionRecommendList:[]
    },
    getters: {},
    mutations: {
        newlistAuction(state, newlistAuction) {
            state.newlistAuction = newlistAuction
        },
        endlistAuction(state, endlistAuction) {
            state.endlistAuction = endlistAuction
        },
        nftlistAuction(state, nftlistAuction) {
            state.nftlistAuction = nftlistAuction
        },
        detail(state, detail) {
            state.detail = detail
        },
        auctionOffers(state, auctionOffers) {
            state.auctionOffers = auctionOffers
        },
        setAuctionRecommendList(state,auctionRecommendList){
         state.auctionRecommendList = auctionRecommendList
        }
    },
    actions: {
        /* 拍卖详情页 */
        // nft拍卖详情
        async auctionDetail(context, id) {
            try {
                // 请求接口
                let result = await api.auctionDetail(id)
                // 处理结果
                result = this.apiResult(result)
                context.commit('detail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async auctionOffers(context, id, params) {
            try {
                let result = await api.auctionOffers(id, params)
                result = this.apiResult(result)
                context.commit('auctionOffers', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 参加拍卖
        async auctionJoin(context,params) {
          try{
            let result = await api.joinAuction(params);
            result = this.apiResult(result)
            return result
          }catch(err){
            console.log(err)
            return false
          }
        },

        /* 拍卖主页 */
        // 获取网站nft最新拍卖列表
        async auctionNewGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'createTime',
                    isAsc: 'desc',
                }
                let result = await api.auctionProject(params)
                result = this.apiResult(result)
                this.apiCache('auction', 'newlistAuction', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取网站nft即将结束拍卖列表
        async auctionEndGet() {
            try {
                let params = {
                    pageNum: 1,
                    pageSize: 20,
                    orderByColumn: 'endTime',
                    isAsc: 'asc',
                }
                let result = await api.auctionProject(params)
                result = this.apiResult(result)
                this.apiCache('auction', 'endlistAuction', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        /* 拍卖列表页 */
        // 获取网站nft拍卖列表
        async auctionProject(context, params) {
            try {
                let result = await api.auctionProject(params)
                result = this.apiResult(result)
                context.commit('nftlistAuction', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        /* 拍卖轮播图 */
        async getAuctionRecommendList(context) {
            try {
                 // 请求接口
                 let result = await api.getRecommendList({ type: 2 })
                
                 // 处理结果
                 result = this.apiResult(result)
                 // 设置
                 context.commit('setAuctionRecommendList',result.data)
                 this.apiCache('auction', 'setAuctionRecommendList', result.data)
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default auctionModule
