import api from '@/api/config'
const configModule = {
    state: {
        // 网站所有支持的币种
        globalToken: [],
        // 网站支持的主链
        chainMapping: [],
        // 网站支持的平台nft
        platformSupport: [],
        // 网站默认配置
        defaultConfig: {},
        // kyc配置
        kycConfig: {},
        // 网站主题
        theme: '',
        // 网站支持的nft类型
        nftCategory: [],
        // 平台合约列表
        platformContract: [],
        // 语种列表
        langTypeList: [],
        // 用户部署的nft合约
        personalContract: [],
        // isPhone
        isPhone: false, // 是否是手机
        muen_h5_open: 1, // 1 2 3 4 5 5个菜单
        nav_obj: {
            left: {
                icon: 'global_back', //返回的图标
                path: '', //-1 返回上一级 不写HomePage
            },
            center: {
                title: '7788', //标题
            },
            right: {
                icon: '',
                path: '',
            },
        }, //控制顶部 导航栏数据/功能/图标
        // title为 空时不显示

        mintSupport: false,
        buyCoinSupport: false,
        stakeSupport: false,
        mysterSupport: false,
        auctionSupport: false,
    },
    getters: {
        symbolOf(state) {
            return (key, value) => {
                return state.globalToken.find((item) => {
                    return item[key] == value
                })
            }
        },
        chainOf(state) {
            return (key, value) => {
                return state.chainMapping.find((item) => {
                    return item[key] == value
                })
            }
        },
        // 平台nft合约
        nftContract(state) {
            return () => {
                return state.platformContract.filter((item) => {
                    return item.name == 'platform_contract_721' || item.name == 'platform_contract_1155'
                })
            }
        },
    },
    mutations: {
        theme(state, theme) {
            state.theme = theme
        },
        globalToken(state, globalToken) {
            state.globalToken = globalToken
        },
        chainMapping(state, chainMapping) {
            state.chainMapping = chainMapping
        },
        platformContract(state, platformContract) {
            state.platformContract = platformContract
        },
        platformSupport(state, platformSupport) {
            state.platformSupport = platformSupport
        },
        defaultConfig(state, defaultConfig) {
            state.defaultConfig = defaultConfig
        },
        kycConfig(state, kycConfig) {
            state.kycConfig = kycConfig
        },
        nftCategory(state, nftCategory) {
            state.nftCategory = nftCategory
        },
        langConfig(state, langTypeList) {
            state.langTypeList = langTypeList
        },
        personalContract(state, personalContract) {
            state.personalContract = personalContract
        },
        saveIsPhone(state, isPhone) {
            state.isPhone = isPhone
        },
        updateMuen_h5_open(state, muen_h5_open) {
            state.muen_h5_open = muen_h5_open
        },
        updateNav_obj(state, nav_obj) {
            state.nav_obj = nav_obj
        },
        mintSupport(state, mintSupport) {
            state.mintSupport = mintSupport
        },
        buyCoinSupport(state, buyCoinSupport) {
            state.buyCoinSupport = buyCoinSupport
        },
        stakeSupport(state, stakeSupport) {
            state.stakeSupport = stakeSupport
        },
        mysterSupport(state, mysterSupport) {
            state.mysterSupport = mysterSupport
        },
        auctionSupport(state, auctionSupport) {
            state.auctionSupport = auctionSupport
        },
    },
    actions: {
        // 获取是否支持Mint
        async mintGet(context) {
            try {
                let result = await api.switchSupport()
                result = this.apiResult(result)
                if (result && result.data) {
                    let { showMysteryBox, showOfficialAuction, showStaking, mintSwitch, showBuyCoin } = result.data
                    context.commit('buyCoinSupport', showBuyCoin == 1 ? true : false)
                    context.commit('mintSupport', mintSwitch == 1 ? true : false)
                    context.commit('stakeSupport', showStaking == 1 ? true : false)
                    context.commit('mysterSupport', showMysteryBox == 1 ? true : false)
                    context.commit('auctionSupport', showOfficialAuction == 1 ? true : false)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取用户部署的nft合约
        async personalContractGet(context, params) {
            try {
                let result = await api.platformSupport(params)
                result = this.apiResult(result)
                result.data && context.commit('personalContract', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 上传文件
        async uploadFile(context, params) {
            try {
                let result = await api.uploadFile(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async contractGet(context) {
            try {
                let result = await api.platformContract()
                result = this.apiResult(result)
                context.commit('platformContract', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取nft的支持类型
        async categoryGet(context) {
            try {
                let result = await api.nftCategory()
                result = this.apiResult(result)
                context.commit('nftCategory', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的币种
        async symbolGet(context) {
            try {
                let result = await api.allCoins()
                result = this.apiResult(result)
                context.commit('globalToken', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的主链
        async chainGet(context) {
            try {
                let result = await api.getChainMapping()
                result = this.apiResult(result)
                context.commit('chainMapping', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的平台NFT
        async platformGet(context) {
            try {
                let result = await api.getPlatformContractList()
                result = this.apiResult(result)
                context.commit('platformSupport', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取网站默认配置
        async configGet(context) {
            try {
                let result = await api.getWebConfig()
                result = this.apiResult(result)
                context.commit('defaultConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取Kyc配置
        async kycGet(context) {
            try {
                let result = await api.getKycConfigGuide()
                result = this.apiResult(result)
                context.commit('kycConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 语种列表
        async getLangType(context, params) {
            try {
                let result = await api.getLangType(params)
                result = this.apiResult(result)
                result.data && context.commit('langConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // /ipfs 主域名
        async getIpfsDomain(context, params) {
            try {
                let result = await api.getIpfsDomain(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default configModule
