<template>
    <div :class="['frame_head', isFixed]" :style="{ height: `${$nav_h}px` }">
        <div class="left" @click="jump_index">
            <template v-if="locationType != '0'">
                <slot name="header_left"></slot>
            </template>

            <template v-else>
                <div class="logo_bg" :style="logoStyle"></div>
                <GI class="" name="logo_text" type="svg" ws="89" hs="15" :noClickStop="true"></GI>
            </template>
        </div>

        <div class="right">
            <div class="link">
                <SelChain v-if="isLogin() && address" :size="0" :list="chainList" :address="address | hideAddress" @change="chainHand"></SelChain>

                <div v-else @click="showAssets()" class="address">
                    {{ langJson('Connect Wallet', 'inscdoge.txt.v1.13') }}
                    <!-- {{ langJson('Connect wallet','inscdoge.txt.v1.14') }} -->
                </div>
            </div>
            <template v-if="locationType != '0'">
                <slot name="header_right"></slot>
            </template>

            <div v-else class="menu" @click="$store.commit('SideMenu', !SideMenu)">
                <GI name="menu" v-show="!SideMenu" ws="27.5" hs="27.5" :noClickStop="true"></GI>
                <GI name="close" v-show="SideMenu" ws="27.5" hs="27.5" :noClickStop="true"></GI>
            </div>
        </div>
    </div>
</template>
<script>
// let { saveNum, multiplication, division, charCompare, reduce } = require('h-token-staking/src/utils')
export default {
    data() {
        return { scrollTop: 0 }
    },
    props: {
        locationType: {
            // 0 没有 1-3【左中右】
            type: String,
            default: '0',
        },
        text: {},
    },
    computed: {
        priceCompute() {
            try {
                let zkp = this.chainSymbol.find((item) => {
                    return item.name == 'ZKP'
                })
                let matic = this.chainSymbol.find((item) => {
                    return item.name == 'MATIC'
                })
                console.log(zkp, matic)
                if (zkp && matic) {
                    let result = saveNum(division(zkp.usdRate, matic.usdRate), 4)
                    result = isNaN(result) ? 0 : result
                    return result
                } else {
                    return 0
                }
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        address() {
            return this.walletDetail.address
        },
        isFixed() {
            return this.scrollTop > 0 ? 'fixed_head' : ''
        },
        chainList() {
            // ...this.chainMapping,
            let temp = [{ isIconSrc: true, chainIcon: '/source/svg/exit.svg', chainName: this.langJson('Disconnect', 'inscdoge.txt.v1.24') }]
            return temp
        },
        logoStyle() {
            let { webLogo } = this.globalImage
            return {
                backgroundImage: `url(${webLogo})`,
                width: `40px`,
                borderRadius: `0px`,
                height: `40px`,
            }
        },
    },
    watch: {},
    created() {
        window.onbeforeunload = () => {
            sessionStorage.setItem('scrollTopPersonal', 0)
        }
        // console.log(this.chainSymbol)
        // this.init()
    },
    mounted() {
        if (sessionStorage.getItem('scrollTopIndex')) {
            this.scrollTop = sessionStorage.getItem('scrollTopIndex')
            let div = document.querySelector('#main_frame')
            div.scrollTo({ top: this.scrollTop, left: 0 })
        }
        this.scrollOf()
    },
    beforeDestroy() {
        window.onbeforeunload = () => {}
        this.saveTop()
    },
    methods: {
        showLang() {
            this.$store.commit('langShow', true)
        },
        saveTop() {
            sessionStorage.setItem('scrollTopIndex', this.scrollTop)
        },
        async init() {
            try {
                let { dispatch } = this.$store

                await dispatch('personalGet')
            } catch (err) {
                console.log(err)
            }
        },
        // async loginBefore() {
        //     await this.loginOf(() => {
        //         sessionStorage.setItem('scrollTopPersonal', 0)
        //         this.jump_personal()
        //     }, false)
        // },
        scrollOf() {
            let main_frame = document.getElementById('main_frame')
            main_frame.addEventListener('scroll', (event) => {
                this.scrollTop = event.target.scrollTop
            })
        },
        // 主链选择
        chainHand(item) {
            if (item.chainName == this.langJson('Disconnect', 'inscdoge.txt.v1.24')) {
                this.$store.dispatch('logout')
            } else {
                console.log('item', item)
                this.targetChain(item.id)
            }
        },

        async showAssets() {
            await this.loginOf(() => {})
        },
        isLogin() {
            return this.walletDetail && this.walletDetail.address && this.loginToken
        },
    },
}
</script>
<style lang="scss" scoped>
.frame_head {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    backdrop-filter: blur(24px);
    border-bottom: 1px solid #363636;
    // background: var(--bg13);
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: calc(100% - 100px);
        .link {
            color: var(--font1);
            align-items: center;
            display: flex;
            justify-content: center;
            max-width: calc(100% - 34px);
            .link_icon {
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
            // .address {
            //     margin: 0 5px;
            //     padding: 0px 10px;
            //     height: 24px;
            //     line-height: 24px;
            //     box-sizing: border-box;

            //     font-size: 12px;
            //     max-width: 100%;
            //     min-width: 70px;
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            //     white-space: nowrap;
            //     text-align: center;
            //     background: var(--bg7);
            //     color: #ffffff;
            //     border-radius: 6px;
            // }

            .address {
                box-sizing: border-box;
                height: 37px;
                display: flex;
                // padding: 10px 10px 12px 10px;
                padding: 10px 20px;

                justify-content: center;
                align-items: center;

                border-radius: 50px;
                background: #ff6821;

                color: #fff;
                font-family: Inconsolata;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.2px;
            }
        }
        .lang {
            margin-right: 12px;
            display: flex;
            align-items: center;
        }
        .menu {
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
        .proinfo {
            .person_icon {
                margin-left: 12px;
                width: 26px;
                height: 26px;
                background-color: #000;
                border-radius: 50%;
            }
        }
    }
    .logo {
        font-family: Alkatra;
        font-size: 22px;
        line-height: 24px;
        color: var(--font1);
    }
    .left {
        .price {
            font-size: 16px;
            color: var(--font1);
            width: calc(100% - 50px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .logo_bg {
            background-repeat: no-repeat;
            background-position: left center;
            background-size: auto 100%;
            cursor: pointer;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
        }
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 15px;
        // flex: 1;
    }
}
</style>
