<template>
    <div>
        <span :style="{ 'background-color': color }"></span>
        <span :style="{ 'background-color': color }"></span>
        <span :style="{ 'background-color': color }"></span>
        <span :style="{ 'background-color': color }"></span>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '#3cefff',
        },
    },
}
</script>

<style lang="scss" scoped>
div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 30px;
}

span {
    width: 4.5px;
    height: 15px;
}

span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
}

span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
}

span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
}

span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
    0%,
    100% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(2);
    }
}
</style>
