<template>
    <div class="play_cell">
        <div class="cell_head">
            <!-- type1入账 2出账 -->
            <div class="cell_title">
                <div class="type">{{ typeName(item) }}</div>
                <div class="time">{{ changeTime(item.createTime, 'minute') }}</div>
            </div>
            <!-- /api/flow/list 
             accountType 1正常 2锁仓 3收益 -->

            <!-- fontOver -->
            <div class="amountAdd" :class="{ active: item.type == 1, zero: item.amount == 0 }">
                <template v-if="item.amount == 0"></template>
                <template v-else-if="item.type == 1">+</template>
                <template v-else>-</template>
                {{ saveNum(replace(item.amount), 2) | numberGrap }}&nbsp;{{ item.symbol }}
            </div>
        </div>
        <!-- <div class="cell_main">
            <div class="cell_item">
                <div class="key">{{ langJson('Type', 'inscdoge.txt.v3.12') }}</div>
                <div class="val">{{ accountType(item) }}</div>
            </div>
            <div class="cell_item">
                <div class="key">{{ langJson('Amount', 'inscdoge.txt.v3.13') }}</div>
                <div class="val">{{ item.amount | numberGrap }} &nbsp;{{ item.symbol }}</div>
            </div>
            <div class="cell_item">
                <div class="key">{{ langJson('Balance', 'inscdoge.txt.v3.14') }}</div>
                <div class="val">{{ item.toBalance | numberGrap }}&nbsp;{{ item.symbol }}</div>
            </div>
            <div class="cell_item">
                <div class="key">{{ langJson('Time', 'inscdoge.txt.v3.15') }}</div>
                <div class="val">{{ changeTime(item.createTime, 'minute') }}</div>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    props: ['item'],
    data() {
        return {
            expirationTime: '',
        }
    },
    computed: {
        // typeBlance() {
        //     return (item) => {
        //         try {
        //             if (item.type == 1) {
        //                 return item.toBalance
        //             }
        //             if (item.type == 2) {
        //                 return item.fromBalance
        //             }
        //             return '0'
        //         } catch (error) {
        //             return ''
        //         }
        //     }
        // },
        accountType() {
            return (item) => {
                try {
                    if (item.accountType == 1) {
                        return this.langJson('Normal', 'inscdoge.txt.v3.16')
                    }
                    if (item.accountType == 2) {
                        return this.langJson('Lock', 'inscdoge.txt.v3.17')
                    }
                    if (item.accountType == 3) {
                        return this.langJson('Income', 'inscdoge.txt.v3.18')
                    }
                    return '--'
                } catch (error) {
                    return ''
                }
            }
        },
        typeName() {
            return (item) => {
                try {
                    if (item.type == 1) {
                        return this.langJson('Entry', 'inscdoge.txt.v3.19')
                    }
                    if (item.type == 2) {
                        return this.langJson('Outgoing', 'inscdoge.txt.v3.20')
                    }
                    return '--'
                } catch (error) {
                    return ''
                }
            }
        },
        chainIcon() {
            return (item) => {
                try {
                    return this.findChain('id', item.chainId).chainIcon
                } catch (err) {
                    console.log(err)
                    return ''
                }
            }
        },
        expireTime() {
            return (item) => {
                let { expireTime } = item

                return this.timeStr(expireTime)
            }
        },
        pass() {
            let amount = parseFloat(this.item.presentAmount)
            amount = isNaN(amount) ? 0 : amount
            return amount > 0
            // return true
        },

        // 收益和本金是否全部提取
        isNotHave() {
            let temp
            if (this.item.symbolName == this.item.incomeSymbolName) {
                // 收益是显示在本金里面的
                if (this.item.currentAmount == 0) {
                    temp = true
                } else {
                    temp = false
                }
            } else {
                if (this.item.currentAmount == 0 && this.item.completeIncome == 0) {
                    temp = true
                } else {
                    temp = false
                }
            }

            return temp
        },

        // 是否可以提取
        isPeriodical() {
            let temp
            // projectType 1 活期  2定期
            if (this.item.projectType == 1) {
                temp = true
            }
            if (this.item.projectType == 2) {
                this.getExpirationTime()
                let now = new Date().getTime()
                // 定期是否到期 可以提取
                if (this.item.status == 2) {
                    temp = true
                } else {
                    temp = false
                }
            }
            return temp
        },
        changeTime() {
            try {
                // 时 分 秒
                return (date, num = 'minute') => {
                    if (!!date) {
                        if (num === 'minute') {
                            let index = date.lastIndexOf(':')
                            return date.slice(0, index) ? date.slice(0, index) : ''
                        }
                    }
                    return ''
                }
            } catch (error) {
                return ''
            }
        },
    },
    created() {},
    methods: {
        goToPage() {
            let { commit } = this.$store
            commit('get_earn_info', {})
            let obj = {
                chainId: this.item.chainId,
                projectId: this.item.projectId,
                recordId: this.item.id,
            }
            commit('get_earn_info', obj)

            this.jump_projectId_Detail(this.item.id)
        },
        jumpAsset() {
            this.jump_assets(this.item.projectId)
        },
        // 获取到期时间
        getExpirationTime() {
            // let before = this.timestampGet(this.item.createTime)
            // let cycle = this.item.cycle * 1000 * 60 * 60 * 24
            // this.expirationTime = before + cycle
            this.expirationTime = this.item.expireTimestamp
        },
        extractHand() {
            this.$emit('extractHand')
        },
        replace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return str.slice(1)
            }
            return str
        },
    },
}
</script>
<style lang="scss" scoped>
.amountAdd {
    display: flex;
    align-items: center;
    /* width: 50%; */
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #c44e21;
    &.active {
        color: #56ffa4;
    }
    &.zero {
        color: #ffffff !important;
    }
}

.play_cell {
    .cell_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        .cell_title {
            .type {
                font-size: 16px;
                line-height: 16px;

                color: var(--font1);

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-weight: bold;
                background-image: -webkit-linear-gradient(left, #ffe072, #ff8024);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .time {
                margin-top: 5px;
                font-size: 12px;
                line-height: 12px;
                color: #ffffff;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .cell_btn {
        padding: 16px;
        padding-top: 0;
        margin-top: 4px;
    }
    .cell_main {
        .cell_item {
            width: 50%;
            margin-top: 12px;

            .key {
                color: var(--font2);
                font-size: 12px;
                line-height: 12px;

                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .val {
                color: var(--font1);
                font-size: 14px;
                line-height: 20px;

                margin-top: 6px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .success {
                color: #2ca631;
            }

            .fail {
                color: #f65a5a;
            }
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px 16px;
        padding-top: 0px;
    }

    display: flex;
    flex-direction: column;

    background-color: var(--bg11);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-bottom: 16px;
}

.play_cell:last-child {
    border-bottom: none;
}
</style>
