var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select",attrs:{"id":_vm.components_id}},[_c('div',{class:['select_btn', _vm.bgColor],style:({
            width: _vm.size ? `${_vm.size}px` : `100%`,
        }),on:{"click":_vm.openPopup,"mouseleave":_vm.leave,"mouseenter":_vm.openPopup}},[_c('div',{staticClass:"btn_text"},[_vm._v(_vm._s(_vm.current.lable))]),_c('GI',{staticClass:"icon",attrs:{"name":"swap_down_1","ws":"24","hs":"24"},on:{"click":_vm.openPopup}})],1),_c('div',{staticClass:"select_popup",style:({
            top: `${_vm.top}px`,
            width: _vm.ws ? `${_vm.ws}px` : `auto`,
            right: _vm.ws ? `` : `0px`,
        }),attrs:{"id":_vm.popup_id},on:{"mouseenter":_vm.openPopup,"mouseleave":_vm.leave}},[_vm._t("top"),_c('div',{staticClass:"popup_list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"popup_item",on:{"click":function($event){return _vm.selectItem(item.value)}}},[_vm._v(" "+_vm._s(item.lable)+" ")])}),0),_vm._t("bottom")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }