import color from '@/mixin/color/index.js'

export default {
    // --- theme Start ----
    globalTheme() {
        // this.$store.commit('theme', theme)
        // return this.globalConfig.theme // 'light'/'dark'
        return 'dark' // 'light'/'dark'
    },
    ...color,
    // --- theme End ----
    // store state config mode
    globalConfig() {
        return this.$store.state.config
    },
    // store state status mode
    globalStatus() {
        return this.$store.state.status
    },
    // 全局主链列表
    chainMapping() {
        return this.globalConfig.chainMapping
    },
    // 全局币种列表
    globalToken() {
        return this.globalConfig.globalToken
    },
    globalImage() {
        return this.globalConfig.defaultConfig
    },
    // globalTheme() {
    //     return this.globalConfig.theme
    // },
    nftCategory() {
        return this.globalConfig.nftCategory
    },
    platformContract() {
        return this.globalConfig.platformContract
    },
    isPhone() {
        return this.globalConfig.isPhone
    },

    muen_h5_open() {
        return this.globalConfig.muen_h5_open
    },

    nav_obj() {
        return this.globalConfig.nav_obj
    },
    stakeSupport() {
        return this.globalConfig.stakeSupport
    },
    buyCoinSupport() {
        return this.globalConfig.buyCoinSupport
    },
    mysterSupport() {
        return this.globalConfig.mysterSupport
    },
    auctionSupport() {
        return this.globalConfig.auctionSupport
    },

    mintSupport() {
        try {
            if (this.globalConfig.mintSupport) {
                return true
            } else {
                let { information } = this.globalPersonal
                if (information && information.canMint) {
                    return true
                } else {
                    return false
                }
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },
    logined() {
        try {
            return this.walletDetail && this.walletDetail.address && this.loginToken
        } catch (err) {
            console.log(err)
            return false
        }
    },
    deploySupport() {
        try {
            let { information } = this.globalPersonal
            if (this.mintSupport && information && information.canDeploy) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },

    // newfi
    preSale() {
        return this.$store.state.preSale
    },
    project_info() {
        return this.preSale.projectInfo
    },
    verifyParam() {
        return this.preSale.verifyParam
    },
    accountList() {
        return this.preSale.accountList
    },
    symbolQuote() {
        return this.preSale.symbolQuote
    },

    //

    SideMenu() {
        return this.$store.state.SideMenu
    },
    filterModel() {
        return this.$store.state.filterModel
    },
    addInviterModel() {
        return this.$store.state.addInviterModel
    },
    withdrawModel() {
        return this.$store.state.withdrawModel
    },
    withdrawModel_ins_usdt_tip() {
        return this.$store.state.withdrawModel_ins_usdt_tip
    },
    withdrawAddModel() {
        return this.$store.state.withdrawAddModel
    },
    internalTransferModel() {
        return this.$store.state.internalTransferModel
    },
    editNameModel() {
        return this.$store.state.editNameModel
    },
    editModel() {
        return this.$store.state.editModel
    },
    OperationStatus() {
        return this.$store.state.OperationStatus
    },
    walletShow() {
        return this.$store.state.walletShow
    },
    langShow() {
        return this.$store.state.langShow
    },
    YieldShow() {
        return this.$store.state.YieldShow
    },
    YieldShow2() {
        return this.$store.state.YieldShow2
    },
    YieldList() {
        return this.$store.state.YieldList
    },
    YieldId() {
        return this.$store.state.YieldId
    },
    YieldId2() {
        return this.$store.state.YieldId2
    },
    tipShow() {
        return this.$store.state.tipShow
    },

    staked() {
        return this.$store.state.staked
    },

    // store state tips
    globalTip() {
        return this.$store.state.tips
    },
    globalTotal() {
        return this.$store.state.total
    },
    // store state market mode
    globalMarket() {
        return this.$store.state.market
    },
    // store state collection mode
    globalCollection() {
        return this.$store.state.collection
    },
    // store state personal mode
    globalPersonal() {
        return this.$store.state.personal
    },
    // store state foot mode
    globalFoot() {
        return this.$store.state.foot
    },
    globalNewFi() {
        return this.$store.state.newFi
    },
    powerRecord() {
        return this.globalNewFi.powerRecord
    },
    allCoin() {
        return this.globalNewFi.coinList
    },
    yourself() {
        return this.globalNewFi.yourself
    },
    withdrawFee() {
        return this.globalNewFi.withdrawConfig
    },
    allProject() {
        return this.globalNewFi.proList
    },
    checkTicket() {
        return this.globalNewFi.checkTicket
    },
    pledgeAssets() {
        return this.globalNewFi.pledgeAssets
    },
    checkNode() {
        return this.globalNewFi.checkNode
    },
    checkPledge() {
        return this.globalNewFi.checkPledge
    },
    pledgelist() {
        return this.globalNewFi.pledgelist
    },

    configParams() {
        return this.globalNewFi.configParams
    },
    get_chargeUp_account() {
        return this.globalNewFi.get_chargeUp_account
    },
    get_earlyProject_list() {
        return this.globalNewFi.get_earlyProject_list
    },
    get_shop_back_info() {
        return this.globalNewFi.shop_back_info
    },
    get_earlyProject_info() {
        return this.globalNewFi.get_earlyProject_info
    },
    get_early_earnings_record() {
        return this.globalNewFi.get_early_earnings_record
    },
    get_early_record() {
        return this.globalNewFi.get_early_record
    },
    get_flow_list() {
        return this.globalNewFi.get_flow_list
    },
    get_record_list() {
        return this.globalNewFi.get_record_list
    },
    get_generalProject_info() {
        return this.globalNewFi.get_generalProject_info
    },
    get_generalProject_list() {
        return this.globalNewFi.get_generalProject_list
    },
    get_release_record() {
        return this.globalNewFi.get_release_record
    },
    get_earnings() {
        return this.globalNewFi.get_earnings
    },
    get_earn_info() {
        return this.globalNewFi.get_earn_info
    },
    get_general_earnings() {
        return this.globalNewFi.get_general_earnings
    },
    get_general_earnings_record() {
        return this.globalNewFi.get_general_earnings_record
    },
    get_general_record() {
        return this.globalNewFi.get_general_record
    },
    get_card_record() {
        return this.globalNewFi.get_card_record
    },
    projectEarning() {
        return this.globalNewFi.projectEarning
    },
    listTotal() {
        return this.globalNewFi.listTotal
    },
    get_deposit_list() {
        return this.globalNewFi.deposit_list
    },
    get_record_sign() {
        return this.globalNewFi.record_sign
    },
    get_config_sign() {
        return this.globalNewFi.config_sign
    },
    get_checkIn_list_sign() {
        return this.globalNewFi.checkIn_list_sign
    },
    get_checkIn_log_sign() {
        return this.globalNewFi.checkIn_log_sign
    },
    get_financeInfo() {
        return this.globalNewFi.financeInfo
    },
    getArticleList() {
        return this.globalNewFi.getArticleList
    },
    releaseEarning() {
        return this.globalNewFi.releaseEarning
    },
    get_identityCard_list() {
        return this.globalNewFi.get_identityCard_list
    },
    get_ientityCard_info() {
        return this.globalNewFi.get_ientityCard_info
    },
    teamInfo() {
        try {
            return this.globalNewFi.teamInfo
        } catch (err) {
            console.log(err)
            return {}
        }
    },

    agentInfo() {
        try {
            return this.globalNewFi.agentInfo
        } catch (err) {
            console.log(err)
            return {}
        }
    },

    cyclelist() {
        try {
            return this.globalNewFi.cyclelist
        } catch (err) {
            console.log(err)
            return {}
        }
    },

    // store state auction mode
    globalAuction() {
        return this.$store.state.auction
    },
    // store state auction mode
    globalStaking() {
        return this.$store.state.staking
    },
    // store state myster mode
    globalMyster() {
        return this.$store.state.myster
    },
    // store state mint mode
    globalMint() {
        return this.$store.state.mint
    },
    // store state login mode
    loginAccout() {
        return this.$store.state.login
    },
    // store state currency mode
    globalCurrency() {
        return this.$store.state.currency
    },
    // 连接的钱包详情
    walletDetail() {
        return this.loginAccout.walletDetail
    },

    // 连接的钱包详情
    loginToken() {
        return this.loginAccout.token
    },

    // global computed
    lenArray() {
        return (list = []) => {
            try {
                return list.length
            } catch (err) {
                console.log(err)
                return 0
            }
        }
    },
    tokenStandard() {
        return (type) => {
            let result
            switch (type) {
                case 'ERC721':
                    result = 721
                    break
                case 'ERC1155':
                    result = 1155
                    break
            }
            return result
        }
    },
    standard() {
        return (type) => {
            let result = ''
            if (type == 721) {
                result = 'ERC721'
            } else if (type == 1155) {
                result = 'ERC1155'
            }
            return result
        }
    },
    // 通过id查找币种详情
    findSymbol() {
        return (id) => {
            let result = this.$store.getters.coinOf('id', id)
            return result ? result : {}
        }
    },
    // 查找某个主链详情
    findChain() {
        return (key, value) => {
            let result = this.$store.getters.chainOf(key, value)
            return result ? result : {}
        }
    },
    nftContract() {
        return (chainId) => {
            let result = this.$store.getters.nftContract()
            return result.filter((item) => {
                return item.chainId == chainId
            })
        }
    },
    nftSupport() {
        try {
            return (chainId, contract) => {
                let tmp = this.nftContract(chainId).concat(
                    this.globalConfig.personalContract.map((item) => {
                        return {
                            address: item,
                        }
                    })
                )
                return tmp.some((item) => {
                    return item.address.toLocaleLowerCase() == contract.toLocaleLowerCase()
                })
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },
    // 汇率换算
    usdRate() {
        return (amount, symbolId) => {
            try {
                let usdRate = this.findSymbol(symbolId).usdRate
                return this.multiplication(amount, usdRate)
            } catch (err) {
                console.log(err)
                return 0
            }
        }
    },
    // 精确为正常大小
    toMain() {
        return (amount, symbolId) => {
            let precision = this.findSymbol(symbolId).precision
            return this.division(amount, Math.pow(10, precision))
        }
    },
    //
    toWei() {
        return (amount, symbolId) => {
            let precision = this.findSymbol(symbolId).precision
            return this.multiplication(amount, Math.pow(10, precision))
        }
    },
    isOwner() {
        try {
            return (owner) => {
                let { address } = this.walletDetail
                if (address && owner) {
                    return owner.toLocaleLowerCase() == address.toLocaleLowerCase()
                } else {
                    return false
                }
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },
    addressCompare() {
        try {
            return (address_one, address_two) => {
                if (address_one && address_two) {
                    return address_one.toLocaleLowerCase() == address_two.toLocaleLowerCase()
                } else {
                    return false
                }
            }
        } catch (err) {
            console.log(err)
            return false
        }
    },

    tabNav() {
        try {
            return this.$route.params.tab
        } catch (err) {
            return ''
        }
    },
    addressNav() {
        try {
            return this.$route.params.address
        } catch (err) {
            return ''
        }
    },
    kycConfig() {
        try {
            return this.globalConfig.kycConfig
        } catch (err) {
            return {}
        }
    },
    langTypeList() {
        try {
            return this.globalConfig.langTypeList
        } catch (err) {
            return {}
        }
    },

    findContractObj() {
        return (chainId) => {
            let result = this.platformContract.filter((item) => {
                return item.chainId == chainId
            })
            let obj = {}
            result.forEach((item) => {
                obj[item.name] = item.address
            })
            return obj
        }
    },
    // staking合约地址
    contractStaking() {
        return (chainId) => {
            let { staking_contract } = this.findContractObj(chainId)
            return staking_contract
        }
    },
    // 盲盒合约地址
    contractMyster() {
        return (chainId) => {
            let { mystery_box_contract } = this.findContractObj(chainId)
            return mystery_box_contract
        }
    },
    routePath() {
        return this.$route.path
    },
}
