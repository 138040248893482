<template>
    <Model v-model="show" @mask="close()">
        <div class="model_main" slot="main">
            <div class="title">{{ langJson('内部转账', 'inscdoge.txt.v5.82') }}</div>
            <div class="line"></div>

            <div class="bar">
                <div class="key">
                    {{ langJson('可用余额', 'inscdoge.txt.v1.121') }}
                </div>
                <div class="val" @click="maxHand()">
                    {{ saveNum(max || '0', insPrecision) | numberGrap }}
                </div>
            </div>
            <div class="information_item SelectDown">
                <input v-model="form.amount" type="number" :placeholder="langJson('Amount', 'inscdoge.txt.v3.13')" />

                <div class="select">
                    <!-- <div class="selectBg"></div> -->
                    <SelectDown bgc="#000000" ref="SelectCoin" :list="trx_coin_l" @select_detail="select_detail" height="36px" />
                </div>
                <!-- <div class="INS">{{ currency_details.name }}</div> -->
            </div>
            <div class="red" v-show="this.form.amount * 1 > this.max * 1">{{ langJson('余额不足', 'inscdoge.txt.v5.61') }}</div>

            <div class="list_rate">
                <div class="item" v-if="this.currency_details.transferFeeType == 0">
                    <div class="key">{{ langJson('Handlng fee', 'inscdoge.txt.v1.10') }}</div>
                    <div class="val rate">{{ saveNum(currency_details.transferFee) }}%</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Fee Amount', 'inscdoge.txt.v5.51') }}</div>
                    <div class="val">{{ feeAmount | numberGrap }} {{ currency_details.name }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Receive Amount', 'inscdoge.txt.v5.52') }}</div>
                    <div class="val">{{ getAmount | numberGrap }} {{ currency_details.name }}</div>
                </div>
            </div>

            <input v-model="form.key" type="text" class="information_item" :placeholder="langJson('请输入用户名或者用户钱包地址', 'inscdoge.txt.v5.91')" />

            <div class="desc_t">
                {{ langJson('请确认收款方和代币数量', 'inscdoge.txt.v5.92') }}
            </div>

            <BTN class="model_btns" type="orange" :disable="!pass" @click="withdrawHand" :load="loading">{{ langJson('确认', 'inscdoge.txt.v5.93') }}</BTN>
        </div>
    </Model>
</template>

<script>
import tools from '@/mixin/tools'
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currency_details: {},
            insPrecision: '6',
            loading: false,
            form: {
                coinId: '',
                amount: '',
                key: '',
            },
        }
    },
    computed: {
        getAmount() {
            try {
                let num = this.reduce(this.form.amount || 0, this.feeAmount || 0)

                if (num * 1 < 0) {
                    num = 0
                }
                return this.saveNum(num, this.insPrecision)
            } catch (err) {
                console.log(err)
                return 0
            }
        },
        feeAmount() {
            try {
                let num = ''
                if (this.currency_details.transferFeeType == 1) {
                    num = this.currency_details.transferFee
                }
                if (this.currency_details.transferFeeType == 0) {
                    let temp = this.division(this.currency_details.transferFee || 0, 100)
                    // console.log('temp', temp, this.currency_details.transferFee, this.currency_details)
                    num = this.multiplication(this.form.amount || 0, temp)
                }

                return this.saveNum(num, this.insPrecision)
            } catch (err) {
                console.log(err)
                return 0
            }
        },

        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        trx_coin_l() {
            try {
                let res = this.chainSymbol.map((item) => {
                    return { ...item, name: item.name, key: item.id }
                })
                res = res.filter((item) => {
                    return item.enableTransfer == 1 // 币种接口返回是否 支持转账
                })

                res = res.sort((item, item2) => {
                    if (this.withdrawFee.withdrawInsUsdtSwitch && this.withdrawFee.withdrawUsdtSwitch) {
                        return item.name[0].localeCompare(item2.name[0])
                    } else if (this.withdrawFee.withdrawInsUsdtSwitch) {
                        return item.name == 'INS'
                    } else if (this.withdrawFee.withdrawUsdtSwitch) {
                        return item.name == 'USDT'
                    }
                })
                return res
            } catch (err) {
                console.log(err)
                return []
            }
        },
        max() {
            try {
                let max
                if (this.currency_details.name == 'USDT') {
                    max = this.pledgeAssets.usdtAccount
                }
                if (this.currency_details.name == 'INS') {
                    max = this.pledgeAssets.incomeAccount
                }

                return max
            } catch (err) {
                console.log('err', err)
                return '0'
            }
        },
        pass() {
            try {
                console.log("checkPass(this.form.key, ['unull'], false)", this.checkPass(this.form.key, ['unull'], false))
                console.log("checkPass(this.form.amount, ['unull'], false)", this.checkPass(this.form.amount, ['unull'], false))

                return this.checkPass(this.form.amount, ['unull'], false) && this.checkPass(this.form.key, ['unull'], false) && this.getAmount > 0 && this.form.amount * 1 <= this.max * 1
            } catch (err) {
                console.log('err', err)
                return false
            }
        },
    },
    watch: {
        'form.amount'() {
            //
            try {
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    if (this.form.amount != '') {
                        this.$set(this.form, 'amount', this.saveNum(this.form.amount, 6))
                    }
                    clearTimeout(this.timer)
                }, 300)
            } catch (err) {
                console.log('err', err)
                // return false
            }
        },
        show(a) {
            if (a) {
                // 提交登录
                let key = tools.getCookie('temp_key_user')
                this.$set(this.form, 'key', key || '')
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        maxHand() {
            this.$set(this.form, 'amount', this.max)
        },
        select_detail(select) {
            if (select.key != this.form.coinId) {
                this.currency_details = select

                this.$set(this.form, 'coinId', select.key)
            }
        },
        setAccuracy(value, name, long = 4) {
            let len = String(value).split('.')
            let text

            if (len.length != 0 && len.length == 2) {
                text = len[1]

                let item = this.chainSymbol.find((item) => {
                    return item.name == name
                })
                let length = item && item.precision ? item.precision : long

                len[1] = text.slice(0, length)
                text = len.join('.')
                return text
            }
            return value
        },

        close() {
            this.$set(this.form, 'coinId', '')
            this.$set(this.form, 'amount', '')
            this.$set(this.form, 'key', '')
            this.$store.commit('internalTransferModel', false)
        },
        async withdrawHand() {
            if (this.pass) {
                try {
                    await this.sureHand()
                } catch (err) {
                    console.log(err)
                }
            }
        },
        async sureHand() {
            try {
                this.loading = true
                let { dispatch } = this.$store

                let trimStr = this.form.key.trim()
                this.$set(this.form, 'key', trimStr)

                this.$set(this.form, 'amount', this.saveNum(this.form.amount, 6))

                if (this.form.amount * 1 < this.currency_details.transferMin * 1) {
                    this.pushMsg('warning', this.langJson('内部转账不能低于', 'inscdoge.txt.v5.94') + this.currency_details.transferMin + ' ' + this.currency_details.name)
                    this.loading = false
                    return
                }
                let result = await dispatch('transfer_apply', { ...this.form, key: trimStr.toLowerCase() })
                this.loading = false
                if (result) {
                    this.pushMsg('success', this.langJson('内部转账成功', 'inscdoge.txt.v5.95'))

                    tools.setCookie('temp_key_user', this.form.key)

                    this.close()
                    await dispatch('pledgeAssets')
                }
            } catch (error) {
                console.log('ins0001', error)
            }
        },
        numberGrapNow(val) {
            if (val) {
                val = val + ''
            } else {
                return 0
            }
            // val = this.goodNumber(val)

            let float = val.split('.')[1]
            if (float && float.length > 18) {
                float = float.slice(0, 18)
            }
            val = val.split('.')[0]
            val = val.split('')
            val.reverse()
            val = val.join('')
            if (val.length > 3) {
                let last = ''
                if (val.length % 3 != 0) {
                    last = val.slice(val.length - (val.length % 3))
                    last = last.split('')
                    last.reverse()
                    last = last.join('')
                }
                let temp = val
                    .match(/\w{3}/g)
                    .map((item) => {
                        item = item.split('')
                        item.reverse()
                        item = item.join('')
                        return item
                    })
                    .reverse()
                    .join(',')
                if (last) {
                    return float ? `${last + ',' + temp}.${float}` : last + ',' + temp
                } else {
                    return float ? `${temp}.${float}` : temp
                }
            } else {
                val = val.split('')
                val.reverse()
                return float ? `${val.join('')}.${float}` : val.join('')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.select {
    /* position: relative; */
    /* margin: 0 auto; */
    width: 90px;
    height: 100%;
    height: 36px;
    /* margin-top: 27px; */

    .selectBg {
        left: 4px;
        top: -2.5px;
        position: absolute;
        width: 20.81px;
        height: 16.51px;
        /* background-image: url('/public/source/svg/discord_black.svg'); */
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    /deep/ {
        .NFTSortBtn {
            .NFTSortBtnOpen {
                .selectName {
                    text-align: right;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 800;
                    line-height: 20px;
                    background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .NFTSortBtnList {
                .NFTSortBtnList2 {
                    .listItem {
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 800;
                        /* background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent; */
                    }
                }
            }
        }
    }
}

.model_main {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;

    .title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        text-align: center;
    }

    .line {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        background-color: #2a2a2a;
    }

    .bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        /* margin-bottom: 11px; */
        .key {
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #ffffffcc;
            /* color: #ffffff90; */
        }

        .val {
            text-align: right;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #878e95ee;
            /* color: #ffffff90; */
        }
    }

    .information_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;
        padding: 0 16px;
        width: 100%;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        height: 36px;
        /* padding: 8px, 16px; */
        border-radius: 10px;
        background-color: #000000;
        color: #ffffff;
        /* margin-top: 6px; */
        margin-top: 15px;
        transition: all 0s !important;

        &.SelectDown {
            padding-right: 0px;
            margin-top: 8px;
        }

        &::placeholder {
            color: #727272;
        }

        input {
            flex: 1;
            color: #ffffff;
            height: 100%;
            padding-right: 16px;
        }

        .INS {
            /* position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0%, -50%); */

            //styleName: XB 12;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 800;
            line-height: 20px;
            background: linear-gradient(323.92deg, #ff6201 17.29%, #ffa401 100.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .tab {
        margin-top: 15px;
        .t {
            font-family: Montserrat;
            font-size: 12px;
            line-height: 20px;
            color: #ffffffcc;
            margin-bottom: 15px;
            /* color: #ffffff90; */
        }
    }

    .list_rate {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;

            .key {
                font-size: 12px;
                color: #878e95;
            }

            .val {
                font-size: 12px;
                color: #878e95;

                &.rate {
                    color: #24ae8f;
                }
            }
        }
    }

    .red {
        margin: 0 auto;
        margin-top: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        /* color: #cccccc; */
        color: red;
    }

    .desc_t {
        margin: 0 auto;
        margin-top: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        /* color: #cccccc; */
        color: red;
    }

    .desc {
        margin: 0 auto;
        margin-top: 15px;
        width: 68%;
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;

        color: #cccccc;
    }

    .model_btns {
        margin-top: 15px;
    }
}

@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
