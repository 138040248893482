import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=28f05e3e&scoped=true"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"
import style0 from "./Loading.vue?vue&type=style&index=0&id=28f05e3e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f05e3e",
  null
  
)

export default component.exports