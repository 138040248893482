import http from "./base";
/**
 * 获取集合下nft
 * @param contractType   合约类型
 * @param chainId  主链
 * @param address  用户钱包
*/
let belongGroup = (parmas) => {
   return http.get("/group/myslef",parmas);
};

/**
 * 创建集合
 * @param contractType   合约类型
 * @param chainId  主链
 * @param logo  logo
 * @param name  集合名
*/
let groupCreate = (params) => {
    return http.postJson('/group/insert',params)
}
/**
 * 集合详情
 * @param id
 * @param address   地址
 * @param chainName  链名
*/
let getGroupDetail = params => {
    return http.get(`/group/detail`, params);
  };
/**
 * 修改合集
 * @param type 分类
 * @param name 祖名
 * @param logo logo
 * @param featured
 * @param banner banner
 * @param contractAddress 合约地址
 * @param theme 主题
 * @param description 介绍
 * @param isSensitive
 * @param coinIds 币种
 * @param id 组ID
 * @param chainName 链名
 */
 let editGroup = params => {
    return http.postJson("/group/edit", params);
  };

/**
 * 创建nft
 * @param data              资源链接
 * @param dataType          资源类型
 * @param linkUrl           拓展链接
 * @param name              nft名称
 * @param description       描述
 * @param groupId           分组
 * @param type              分类
 * @param chainId           链id
 * @param attributes        属性
 * @param level             等级
 * @param statisticalData   ?
 * @param lockContent       锁定为所有者才能看到的内容
 * @param isLock            是否锁定内容
 * @param isSensitive       是否为敏感内容
*/
let nftMint = (params) => {
    return http.postJson('/product/mint',params)
}

/**
 * 创建创建签名
 * @param tokenId   tokenid
 * @param ctime     创建时间戳
 * @param sign      签名
 * @param chainId   主链id
 * @param contractType   合约类型
*/
let mintSign = (params) => {
    return http.post('/sign/addCreateSign',params)
}
/**
 * @param id NFT ID
 * 获取产品详情
 */
 let getArtDetail = id => {
    return http.get(`/product/detail/${id}`);
  };

/**
 * @param data              资源链接
 * @param dataType          资源类型
 * @param linkUrl           拓展链接
 * @param name              nft名称
 * @param description       描述
 * @param groupId           分组
 * @param type              分类
 * @param chainName         链名
 * @param attributes        属性
 * @param level             等级
 * @param statisticalData   ?
 * @param lockContent       锁定为所有者才能看到的内容
 * @param isLock            是否锁定内容
 * @param isSensitive       是否为敏感内容
 * @param id
 */
 let editProject = (params) => {
    return http.postJson('/product/update',params)
}
/** 
 * @param productId  产品ID
 */
 let delProject = (params) => {
    return http.post('/product/remove',params)
}
export default {
    belongGroup,
    groupCreate,
    nftMint,
    mintSign,
    getGroupDetail,
    editGroup,
    getArtDetail,
    editProject,
    delProject
};
