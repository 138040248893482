var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select",attrs:{"id":_vm.components_id},on:{"click":_vm.openHand}},[_c('div',{class:['select_btn'],style:({
            width: _vm.size ? `${_vm.size}px` : `100%`,
        }),on:{"mouseleave":_vm.leave}},[_c('div',{staticClass:"btn_text"},[_vm._v(" "+_vm._s(_vm.address)+" "),_c('GI',{staticClass:"iconDown",attrs:{"name":"link_down","ws":"12","hs":"12","noClickStop":true}})],1)]),_c('div',{staticClass:"select_popup",style:({
            top: `${_vm.top}px`,
            width: _vm.ws ? `${_vm.ws}px` : `auto`,
            right: _vm.ws ? `` : `0px`,
        }),attrs:{"id":_vm.popup_id}},[_vm._t("top"),_c('div',{staticClass:"popup_list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"popup_item",on:{"click":function($event){return _vm.selectItem(item)}}},[(item.chainIcon && !item.isIconSrc)?_c('div',{staticClass:"chainBg",style:({ backgroundImage: `url(${_vm.iconSrc})` })}):_vm._e(),(item.chainIcon && item.isIconSrc)?_c('div',{staticClass:"chainBg",style:({ backgroundImage: `url(${item.chainIcon})` })}):_vm._e(),_vm._v(" "+_vm._s(item.chainName)+" ")])}),0),_vm._t("bottom")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }