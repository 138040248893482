import api from '@/api/setting'

const settingModule = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // 获取用户信息
        async setlUpdate(context, params) {
            try {
                let result = await api.setlUpdate(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取通知开关
        async setNoticeType(context, params) {
            try {
                let result = await api.setNoticeType(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 修改通知开关
        async setOnOff(context, params) {
            try {
                let result = await api.setOnOff(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取支持的问题类型
        async setSupports(context, params) {
            try {
                let result = await api.setSupports(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 支持提交
        async setSupportPlay(context, params) {
            try {
                let result = await api.supportPlay(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 支持提交过的记录
        async setSupportedList(context, params) {
            try {
                let result = await api.setSupportedList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取所有提交过的项目
        async setSubProjecList(context, params) {
            try {
                let result = await api.setSubProjecList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提交项目
        async setProjecInfo(context, params) {
            try {
                let result = await api.setProjecInfo(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 项目详情
        async setGetProjecInfo(context, params) {
            try {
                let result = await api.setGetProjecInfo(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 更新项目详情
        async setProjecUpdate(context, params) {
            try {
                let result = await api.setProjecUpdate(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 项目删除
        async setProjecDeleteId(context, id) {
            try {
                let result = await api.setProjecDeleteId(id)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取 kyc 信息
        async setGetKycInfo(context, params) {
            try {
                let result = await api.setGetKycInfo(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取 KYC 社交类型
        async setGetKycSocialAccountTypeList(context, params) {
            try {
                let result = await api.setGetKycSocialAccountTypeList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 修改 KYC 信息
        async setKycPut(context, params) {
            try {
                let result = await api.setKycPut(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default settingModule
