import http from "./base";

/**
 *@param pageSize
 *@param pageNum
 *
 * ino列表 */
let mysterlist = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/list`, params);
};
// ino 详情
let mysterDetail = (id, params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/detail/${id}`, params);
};
/** 
 *购买记录

*/
let mysterTrade = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/tradeListBy`, params);
};
/**
 * @param id
 * @param pageSize
 * @param pageNum
 * 盲盒项目 资产列表
 */
let mysterAssets = (params = {}) => {
  return http.get(`/mysteryBoxAccount/account`, params);
};

/**
 * @param mysteryBoxId
 * 盲盒下系列列表
 */
 let mysterSeries = (params = {}) => {
  return http.get(`/mysteryBox/mysteryBox/seriesList`, params);
};
// 获取页面轮播图
let getRecommendList=(params={})=>{
  return http.get('/index/recommend/list',params)
}
export default { mysterlist, mysterDetail, mysterTrade, mysterAssets,mysterSeries,getRecommendList};
