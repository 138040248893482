import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import tools from '@/mixin/tools'

// 导入api 后台配置
import api from '@/api/config'

if (!localStorage.getItem('Language')) {
    if (!tools.getCookie('Language')) {
        localStorage.setItem('Language', 'en_US')
    } else {
        localStorage.setItem('Language', tools.getCookie('Language'))
    }
}

//路由跳转后，页面回到顶部
router.afterEach((n) => {
    if (n.name == 'personal') {
        // 页面单独储存了下滑高度
        return
    }
    if (n.name == 'index') {
        // console.log(n.name, '页面单独储存了下滑高度')
        // 页面单独储存了下滑高度
        return
    }
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    try {
        let div = document.querySelector('#main_frame')
        setTimeout(() => {
            div && div.scrollTo({ top: 0, left: 0 })
        }, 401)
    } catch (error) {}
})

// 动画
// import 'animate.css'
// import './element/element'
// loadScript("animate.css")

Vue.config.productionTip = false

window.globalTimer = null
window.globalInterTime = (time = 10000) => {
    // console.log('第一次进来');
    if (!window.globalTimer) {
        store.dispatch('symbolQuote')
    }
    window.globalTime = setInterval(() => {
        store.dispatch('symbolQuote')
    }, time)
}

window.globalCloseTime = () => {
    // console.log('window 销毁') // destroy
    window.globalTime = null
    window.globalTimer = null
    clearInterval(window.globalTime) //销毁
}

// mixin引入
import mixin from './mixin'
Vue.mixin(mixin)

// 屏幕适应
let getSize = () => {
    let w = document.documentElement.clientWidth || document.body.clientWidth
    if (w > 1081) {
        store.commit('saveIsPhone', false)
        document.documentElement.style.setProperty('--max_width', '1280px')
    } else if (w <= 1081) {
        store.commit('saveIsPhone', true)
        document.documentElement.style.setProperty('--max_width', 'calc(100vw - 24px)')
    }
    return w
}
getSize()
window.addEventListener('resize', () => {
    getSize()
})

// 设置语言

// 自动加载自定义组件
let map = require.context('./components', true, /\.vue$/)
map.keys().map((item) => {
    let name = item.split('/').pop().split('.')[0]
    Vue.component(name, map(item).default)
})

// 多语言源
Vue.prototype.langSource = {}
// 全局状态
Vue.prototype.$store = {}
// 启动app
Vue.prototype.launchApp = null

Vue.prototype.globalEvent = new HEvent()
Vue.prototype.$bus = new Vue()
Vue.prototype.$nav_h = 70

// 挂载app
let mountApp = (root) => {
    // 全局状态挂载
    Vue.prototype.$store = store
    // 退出加载状态
    let app = document.getElementById('app')
    app.className = 'app_loading_status app_loading_exit'
    // 页面加载完成,网站内容挂载)
    new Vue({
        render: (h) => h(root),
        router,
    }).$mount('#app')

    window.requestload = new Date().getTime()
    // console.log("requestEnd", requestload)
    // console.log("request time", requestload - endload)
}

// 替换网站标签内容
let webTab = (title = '', icon = '') => {
    document.title = title
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.rel = 'shortcut icon'
    link.type = 'image/x-icon'
    link.href = icon
    document.getElementsByTagName('head')[0].appendChild(link)
}

let run = async (arrIndex) => {
    let failed = false
    try {
        // console.log(arrIndex)
        if (arrIndex != 3) return

        // mountApp(App)

        // 获取网站默认图片配置
        let { companyName, webIcon } = store.state.config.defaultConfig
        webTab(companyName, webIcon)

        // 获取网站平台合约
        store.dispatch('contractGet')

        // 获取连接的钱包
        if (localStorage.getItem('walletDetail')) {
            store.commit('walletDetail', JSON.parse(localStorage.getItem('walletDetail')))
        }
        if (localStorage.getItem('information')) {
            store.commit('information', JSON.parse(localStorage.getItem('information')))
        }

        if (tools.getCookie('token')) {
            store.commit('token', tools.getCookie('token'))
        }

        // 获取主题色
        let theme = localStorage.getItem('theme')
        if (theme) {
            store.commit('theme', theme)
            if (theme == 'dark') {
                document.documentElement.style.setProperty('--color_white', '#151625')
            } else if (theme == 'light') {
                document.documentElement.style.setProperty('--color_white', '#ffffff')
            }
        } else {
            // store.commit('theme', 'light')
            store.commit('theme', 'dark')
            document.documentElement.style.setProperty('--color_white', '#ffffff')
        }

        mountApp(App)

        // 获取支持币种
        store.dispatch('getCoinList')

        // 获取提现条件配置
        store.dispatch('withdrawFee')
    } catch (err) {
        console.log(err)
        failed = true
    }

    if (failed) {
        router.replace('/403')
    }
}

let langGet = async () => {
    let lang
    await api.langVersion().then(async (lang_version) => {
        let reGet = localStorage.getItem('lang_version') && localStorage.getItem('lang_sourse') && localStorage.getItem('lang_version') == lang_version.data
        if (reGet) {
            lang = {
                data: JSON.parse(localStorage.getItem('lang_sourse')),
            }
            Vue.prototype.langSource = lang.data
        } else {
            localStorage.setItem('lang_version', lang_version.data)
            lang = await api.getLanguage()
            localStorage.setItem('lang_sourse', JSON.stringify(lang.data))
            Vue.prototype.langSource = lang.data
        }
    })
}

let runBefore = async () => {
    window.endload = new Date().getTime()
    let failed = false
    try {
        let arrIndex = 0
        // 获取网站默认图片配置
        store.dispatch('configGet').then(() => {
            ++arrIndex
            run(arrIndex)
        })

        // 获取支持主链
        store.dispatch('chainGet').then(() => {
            ++arrIndex
            run(arrIndex)
        })

        // 获取多语言
        await langGet().then(() => {
            // console.log('获取多语言')
            ++arrIndex
            run(arrIndex)
        })
    } catch (err) {
        console.log(err)
        failed = true
    }

    if (failed) {
        router.replace('/403')
    }
}
runBefore()
