import http from "./base";
/**
 * 获取登录验证信息
 * @param address 钱包地址
 */
let loginNonce = params => {
  return http.post("/user/generateNonce", params);
};

/**
 * 登录
 * @param address 钱包地址
 * @param sign 钱包签名
 */
let loginPost = params => {
  return http.post(`/user/login`, params);
};

export default {
    loginNonce,
    loginPost
}