<template>
    <div class="assets">
        <Back :title="langJson('币种详情')">
            <div class="right">
                <!-- <GI @click="jump_his" name="his" ws="24" hs="24" :noClickStop="true"></GI> -->
            </div>
        </Back>
        <div class="bg">
            <div class="list">
                <div class="coin">
                    <div class="icon" :style="{ backgroundImage: `url(${'https://oss.inscdoge.com/inscdoge/2024/01/11/c3365324c40e416cb067947cf4af2290.jpg'})` }"></div>
                    <div class="t">
                        <div class="key">{{ '20222110011' }}</div>

                        <div class="val">&nbsp;KDA</div>
                    </div>
                    <div class="t u">
                        <div class="key">≈$ {{ '9981' }}</div>
                        <div class="val">&nbsp;USD</div>
                    </div>
                </div>
                <div class="btnWithdraw">
                    <BTN class="add" type="orangeCancle" :disable="!withdrawFee.transferFlag" @click="internalTransfer">
                        <!-- height="32px" -->
                        {{ langJson('内部转账', 'inscdoge.txt.v5.82') }}
                    </BTN>
                    <BTN class="withdraw" type="orange" :disable="!withdrawFee.withdrawFlag" @click="withdraw">
                        <!-- height="32px" -->
                        {{ langJson('Withdraw', 'inscdoge.txt.v3.1') }}
                    </BTN>
                </div>
                <div class="item">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('Total assets', 'inscdoge.txt.v1.6') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insTotalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('Buy Amount', 'inscdoge.txt.v2.44') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.purchaseAmount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('活期收益', 'inscdoge.txt.v5.17') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insEarning || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item">
                    <!-- 锁仓 -->
                    <div class="key">{{ langJson('Total amount locked', 'inscdoge.txt.v1.7') }}：</div>
                    <div class="val active">
                        <div class="fontOver v2">{{ saveNum(pledgeAssets.lockAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <div class="item">
                    <div class="key">{{ langJson('Real time price', 'inscdoge.txt.v1.17') }}：</div>
                    <div class="val active v2">
                        <div class="num">{{ saveNum(division(1, realRate), 8) | numberGrap }} &nbsp;{{ 'INS' }}</div>
                        <div class="upDown">
                            <GI v-if="showReplace(realRateChange)" class="" name="up" type="svg" ws="18" hs="18"></GI>
                            <GI v-else class="" name="down" type="svg" ws="18" hs="18"></GI>
                            <div class="up">{{ replace(realRateChange) }}</div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <!-- 收益账户 -->
                    <div class="key">{{ langJson('INS活期数量', 'inscdoge.txt.v1.8') }}：</div>
                    <div class="val active">
                        <div class="fontOver v3">{{ saveNum(pledgeAssets.incomeAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('已释放数量', 'inscdoge.txt.v5.81') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insReleased || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>
                <div class="item item2">
                    <!-- 总资产 -->
                    <div class="key">{{ langJson('活期收益', 'inscdoge.txt.v5.17') }}：</div>
                    <div class="val active">
                        <div class="fontOver v1">{{ saveNum(pledgeAssets.insEarning || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div>

                <!-- <div class="item">
                    <div class="key">{{ langJson('USDT总资产', 'inscdoge.txt.v5.62') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtTotalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('USDT冻结资产', 'inscdoge.txt.v5.63') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtLockAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div> -->
                <div class="item">
                    <div class="key">{{ langJson('奖励活期数量', 'inscdoge.txt.v5.60') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.usdtAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>

                    <!-- <div class="item ins_usdt">
                    <div class="key">{{ langJson('奖励活期数量', 'inscdoge.txt.v5.60') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">≈{{ saveNum(pledgeAssets.usdtAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;USDT
                    </div>
                </div> -->
                    <!-- 可提取的 -->
                    <!-- <div class="item">
                    <div class="key">{{ langJson('Extractable', 'inscdoge.txt.v1.9') }}：</div>
                    <div class="val active">
                        <div class="fontOver v4">{{ saveNum(pledgeAssets.normalAccount || '0', insPrecision) | numberGrap }}</div>
                        &nbsp;INS
                    </div>
                </div> -->
                    <!-- 现在有两种手续费 -->
                    <!-- <div class="desc">({{ langJson('Handlng fee', 'inscdoge.txt.v1.10') }}：{{ withdrawFee.withdrawFeeRate || 0 }}%)</div> -->
                </div>
            </div>

            <!-- <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div> -->
            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            insPrecision: '2',
            normal: '0', // 正常账户
            lock: '0', // 冻结账户
            income: '0', // 收益账户
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        isLogin() {
            try {
                return this.walletDetail && this.walletDetail.address && this.loginToken
            } catch (error) {
                return false
            }
        },
        realRate() {
            try {
                // console.log('rate 变化')
                return this.symbolQuote.rate
            } catch (error) {
                return 0
            }
        },
        realRateChange() {
            try {
                // console.log('rateChange 变化')
                return this.symbolQuote.rateChange
            } catch (error) {
                return 0
            }
        },
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                console.log('isLogin', a, b)
                if (!!a && !b) {
                    await this.init()
                }
            },
        },
    },
    async created() {
        await this.init()
    },
    mounted() {},
    methods: {
        async withdraw() {
            await this.loginOf(() => {
                if (this.pledgeAssets.usdtAccount > 0 || this.pledgeAssets.incomeAccount > 0) {
                    this.$store.commit('withdrawModel', true)
                } else {
                    this.pushMsg('warning', this.langJson('Your normal balance is insufficient to withdraw funds', 'inscdoge.txt.v5.3'))
                }
            }, false)
        },
        async withdrawAdd() {
            await this.loginOf(() => {
                // if (this.pledgeAssets.incomeAccount > 0) {
                this.$store.commit('withdrawAddModel', true)
                // } else {
                //     this.pushMsg('warning', this.langJson('Your current balance is insufficient to transfer', 'inscdoge.txt.v5.4'))
                // }
            }, false)
        },
        async internalTransfer() {
            await this.loginOf(() => {
                if (this.pledgeAssets.usdtAccount > 0 || this.pledgeAssets.incomeAccount > 0) {
                    this.$store.commit('internalTransferModel', true)
                } else {
                    this.pushMsg('warning', this.langJson('Your current balance is insufficient to transfer', 'inscdoge.txt.v5.4'))
                }
            }, false)
        },
        async init() {
            let { dispatch } = this.$store
            await dispatch('pledgeAssets')

            // this.$store.commit('pledgeAssets', { totalAccount: '72808012.3481682688', lockAccount: '1026543.0246', incomeAccount: '0', normalAccount: '0' })

            // console.log('pledgeAssets', this.pledgeAssets)

            // let INS = this.chainSymbol.find((item) => {
            //     return item.name == 'INS'
            // })
            // this.insPrecision = INS.precision
        },
        replace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return str.slice(1)
            }
            return str
        },
        showReplace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return false
            }
            return true
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;

            font-size: 20px;
            font-weight: 700;
            line-height: 21px;

            .coin {
                margin-top: 0px;
                margin-bottom: 16px;

                .icon {
                    margin: 0 auto;
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    /* margin-right: 8px; */
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    margin-bottom: 14px;
                }
                .t {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 80%;
                    margin: 0 auto;

                    .key {
                        font-size: 20px;
                        color: #ffffffef;
                        word-break: break-all;
                        line-height: 24px;
                    }
                    .val {
                        word-break: break-all;
                        font-size: 20px;
                        color: #999999;
                        line-height: 24px;
                    }

                    &.u {
                        margin-top: 8px;
                        .key {
                            font-size: 14px;
                            color: #999999;
                        }
                        .val {
                            font-size: 14px;
                        }
                    }
                }
            }

            .item {
                display: flex;
                align-items: flex-end;
                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0px;
                }
                &.ins_usdt {
                    margin-top: -10px;
                    .key {
                        color: #00000000;
                    }
                    .val {
                        color: #ff6821bf !important;
                    }
                }
                &.item2 {
                    padding-left: 12px;
                    .key {
                        color: #7a7a7a;
                        flex-shrink: 0;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
                .key {
                    color: #fff;
                    flex-shrink: 0;
                }
                .val {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    color: #ff6821;
                    font-size: 14px;
                    line-height: 14px;
                    height: 14px;
                    /* background-color: red; */
                    .fontOver {
                        /* background-color: blue; */
                        max-width: calc(100% - 25px);
                        text-transform: none;
                        /* max-width: calc(100vw - 209px);
                        &.v2 {
                            max-width: calc(100vw - 285px);
                            text-transform: none;
                        }
                        &.v3 {
                            max-width: calc(100vw - 227px);
                            text-transform: none;
                        }
                        &.v4 {
                            max-width: calc(100vw - 206px);
                            text-transform: none;
                        }
                        &:first-letter {
                            text-transform: none;
                        } */
                    }
                    .up {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .add {
                        margin-left: 9px;
                    }
                    .upDown {
                        display: flex;
                        align-items: center;
                        .add {
                            color: #fff;
                        }
                        &.underline {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                    &.active {
                        color: #ff6821;
                    }
                    &.underline {
                        text-decoration: underline;
                        color: #ffffff;
                    }

                    .copy {
                        margin-left: 9px;
                    }
                }
            }
            .desc {
                font-family: Inconsolata;
                font-size: 16px;
                font-weight: 500;
                line-height: 17px;
                color: #a99388;
            }
        }

        .btnWithdraw {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 18px;
            margin-bottom: 38px;
            .withdraw {
                /* height: 40px; */
                min-width: 105px;
                /* max-width: 125px; */
                width: 45%;
                ::v-deep .btnText {
                    /* font-size: 12px; */
                }
            }
            .add {
                /* height: 40px; */
                /* font-size: 16px;
                text-decoration: underline;
                color: #ffffff; */
                /* margin-right: 26px; */
                min-width: 105px;
                /* max-width: 125px; */
                width: 45%;
                ::v-deep .btnText {
                    /* font-weight: 400;
                    font-size: 12px;
                    letter-spacing: 0.5px; */
                }
            }
        }
        .textDesc {
            box-sizing: border-box;
            margin-top: 32px;
            width: 100%;
            /* height: 337px; */
            padding: 21px;
            border-radius: 12px;
            border: 1px solid #292829;

            font-family: Montserrat;
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            color: #eeeeee;
        }
        .descTime {
            font-family: Inconsolata;
            font-size: 12px;
            font-weight: 500;
            line-height: 13px;
            margin-top: 18px;
            color: #a99388;
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }

        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
