<template>
    <div class="tokenItem" @click="jump_tokenDetail(item.id)">
        <div class="symbolLine">
            <div class="symbol_inner">
                <div class="symbol_icon">
                    <div class="image" :style="{ backgroundImage: `url(${item.icon})` }"></div>
                    <div class="chain_icon" :style="{ backgroundImage: `url(${item.chainIcon})` }"></div>
                </div>
                <div class="symbol_names">
                    <div class="name_big">{{ item.symbol }}</div>
                    <div class="name_small" :style="{ color: status.color }">
                        <div class="rate">{{ '$' }}&nbsp;{{ saveNum(item.usdtRate) | numberGrap }}</div>
                        <GI class="icon" :name="status.icon" ws="14" hs="14"></GI>
                        {{ status.value }}
                    </div>
                </div>
                <div class="symbol_balance">
                    <div class="balance textOver">{{ saveNum(item.balance) | numberGrap }}</div>
                    <div :class="['usd_balance']">≈&nbsp;{{ '$' }}&nbsp;{{ saveNum(item.currencyRate) | numberGrap }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['item'],
    data() {
        return {}
    },
    computed: {
        status() {
            let low = this.item.change.indexOf('-') != -1
            let change = this.item.change.replace('-', '')
            if (low) {
                return {
                    value: ` ${change}`,
                    color: '#ff6821',
                    icon: 'down',
                }
            } else {
                return {
                    value: ` ${change}`,
                    color: '#56ffa4',
                    icon: 'up',
                }
            }
        },
    },
    created() {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.tokenItem {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    background-color: var(--bg11);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-bottom: 16px;
    flex-direction: column;
    &:first-child {
        margin-top: 20px;
    }
    .symbol_inner {
        .play_btns {
            .play_btn {
                .btn_icon {
                    margin-right: 4px;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #fff;
                margin-right: 22px;
            }
            height: 70px;
            width: 100px;
            transform: translateX(100px);
            position: absolute;
            top: 0;
            bottom: 0;
            right: -36px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .balance_loading {
            flex: 1;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .symbol_balance {
            .balance {
                font-size: 14px;
                text-align: right;
            }
            .usd_balance {
                // margin-top: 4px;
                font-size: 12px;
                display: flex;
                justify-content: flex-end;
                color: #999;
            }

            flex: 1;
            margin-left: 16px;
        }
        .symbol_names {
            margin-left: 16px;
            .name_big {
                font-size: 14px;
            }
            .name_small {
                // margin-top: 4px;
                font-size: 12px;
                display: flex;
                align-items: center;
                color: #999;
                .rate {
                    font-size: 12px;
                    margin-right: 8px;
                }
            }
        }
        .symbol_icon {
            .image {
                /* width: 28px;
                height: 28px;
                border-radius: 50%;
                object-fit: cover; */

                width: 28px;
                height: 28px;
                border-radius: 28px;
                /* margin-right: 8px; */
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 28px 28px;
            }
            .chain_icon {
                /* object-fit: cover;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0; */
                position: absolute;
                bottom: 0;
                right: 0;
                width: 12px;
                height: 12px;
                border-radius: 24px;
                /* margin-right: 8px; */
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 12px 12px;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }
}
</style>
