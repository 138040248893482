<template>
    <div
        :class="['tag', type]"
        :style="{
            borderColor: color,
            color: color,
        }"
    >
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '',
        },
        color: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '',
        },
        type: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '',
        },
    },
    data() {
        return {}
    },
    watch: {},
    computed: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
.tag {
    border-radius: 3px;
    border: 1px solid;
    font-size: 12px;
    height: 15px;
    padding: 0px 2.5px;
    /* padding: 0px 5.5px; */
    /* padding: 2.5px 5px; */
    display: flex;
    align-items: center;

    flex-shrink: 0;
    &.warp {
        height: auto;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px;
        padding: 2px 3px;
        text-align: center;
        flex-shrink: 1;
    }
}
</style>
