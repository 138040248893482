import tools from '@/mixin/tools'
import computed from '@/mixin/computed'
import jumpPage from '@/mixin/jumpPage'
import filters from '@/mixin/filters'
import methods from '@/mixin/methods'
// import connect from "@/mixin/connect"
// import contract from "@/mixin/contract"
// let { charCompare } = require('h-token-staking/src/utils')
export default {
    data() {
        return {

            support_video_extensions: ['mp4', 'ogg'],
            support_image_extensions: ['jpeg', 'jpg', 'png', 'gif', 'svg', 'svg+xml', 'x-icon', 'webp'],
            support_audio_extensions: ['mp3', 'mpeg'],
            support_model_extensions: ['glb'],
            support_html_extensions: ['html'],

            lock_btn: false,
            load_btn: false,

            saleMaxTime: '2099/12/31 00:00:00',

            pageSize: 24,
            pageNum: 1,
            apiParams: {},
            total: 0,

            // model status
            previewShow: false,

            components_id: 0,
            mainToken: '0x0000000000000000000000000000000000000000',
            maxVal: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',

            rules: {
                // 整数
                int: {
                    match: /^\d+$/,
                    tip: this.langJson('Must be an integer', 'inscdoge.txt.v1.78'),
                },
                // 整数或小数
                float: {
                    match: /^\d+(\.\d*)?$/,
                    tip: this.langJson('Must be an integer或小数', 'inscdoge.txt.v1.79'),
                },
                // 不为空
                unull: {
                    match: /^.+$/,
                    tip: this.langJson('Cannot be empty', 'inscdoge.txt.v1.80'),
                },
                // 限制字符长度
                overflow: {
                    match: /^.{6,20}$/,
                    tip: this.langJson('Character length is 6 to 20 characters', 'inscdoge.txt.v1.81'),
                },
                // 含字母
                haveword: {
                    match: /[a-zA-Z]+/,
                    tip: this.langJson('Must contain letters', 'inscdoge.txt.v1.82'),
                },
                // 含大写字母
                haveupword: {
                    match: /[A-Z]+/,
                    tip: this.langJson('Must contain uppercase letters', 'inscdoge.txt.v1.83'),
                },
                // 含小写字母
                havelowword: {
                    match: /[a-z]+/,
                    tip: this.langJson('Must contain lowercase letters', 'inscdoge.txt.v1.84'),
                },
                // 含数字
                havenum: {
                    match: /[0-9]+/,
                    tip: this.langJson('Must contain numbers', 'inscdoge.txt.v1.85'),
                },
                // 邮箱
                email: {
                    match: /^.+@.+$/,
                    tip: this.langJson('Email format error', 'inscdoge.txt.v1.86'),
                },
            },

        }
    },
    filters,
    computed,



    methods: {
        ...tools,
        ...jumpPage,
        ...methods,

        async ticketCheck() {
            let { chainDetail } = this.walletDetail
            if (!chainDetail) return
            let { dispatch } = this.$store
            let token = this.getCookie("token")
            if (chainDetail && chainDetail.id && token) {
                await dispatch('checkTicket', {
                    chainId: chainDetail.id
                })
                console.log(this.checkTicket)
            }
        },

        // 前提登录
        async loginOf(fn = () => { }, isConnect = true) {
            if (this.walletDetail && this.walletDetail.address && this.getCookie('token')) {
                isConnect && (await this.connect(this.walletDetail.plugin))
                fn()
            } else {
                this.$store.commit('walletShow', true)
            }
        },

        // 登录并切换至对应主链
        async targetChain(chainId, fn = () => { }) {
            if (!chainId) {
                return false
            }
            // 登录
            await this.loginOf(async () => {
                // 切换至对应主链
                let chainDetail = this.findChain('id', chainId)
                let result = await this.switchNetwork(chainDetail)
                // 判断切换主链是否成功
                if (result) {
                    fn()
                } else {
                    this.pushMsg('error', this.langJson('Please switch to work network ', 'inscdoge.txt.v1.87') + `(${chainDetail.networkName})`)
                }
            })
        },

        // 如果之前登录过自动连接
        async autoConnect() {
            // console.log(this.walletDetail)

            if (this.walletDetail && this.walletDetail.address) {
                let wallet = await this.connect(this.walletDetail.plugin)
            }
            let token = this.getCookie("token")
            // console.log(token)
            if (token) {
                let { dispatch } = this.$store
                await dispatch('personalGet')

                let result = this.globalPersonal.information
                // console.log(result,this.walletDetail.address)
                if (result) {
                    try {
                        let pass = charCompare(result.address, this.walletDetail.address)
                        if (!pass) {
                            await dispatch("logout")
                        }
                    } catch (error) {

                    }
                    // console.log(pass)

                }
            }
        },

        // ...connect,
        // ...contract
    },
}

