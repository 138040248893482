<template>
    <div :style="{ transform: `scale(${salce})`, width: `${ws}px`, height: `${ws}px` }" class="div">
        <svg viewBox="25 25 50 50" :class="{ animation: start }">
            <circle :class="{ animation: start }" cx="50" cy="50" r="20" :stroke="color"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        start: {
            type: [Boolean], // String, String, Number, Boolean, Array, Object
            default: true,
        },
        ws: {
            type: [String, Number], // String, String, Number, Boolean, Array, Object
            default: '26',
        },
        salce: {
            type: [String, Number], // String, String, Number, Boolean, Array, Object
            default: '1',
        },
        color: {
            type: String, // String, String, Number, Boolean, Array, Object
            default: '#000000',
        },
    },
}
</script>

<style lang="scss" scoped>
.div {
    display: flex;
    align-items: center;
    justify-items: center;
}

svg {
    width: 3.75em;
    transform-origin: center;

    &.animation {
        animation: rotate 2s linear infinite;
    }
}

circle {
    fill: none;
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    &.animation {
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}
</style>
