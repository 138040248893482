<template>
    <div class="assets">
        <Back :title="langJson('HISTORY', 'inscdoge.txt.v3.10')">
            <div class="select">
                <div class="selectBg"></div>
            </div>
        </Back>
        <div class="bg">
            <div class="list">
                <div class="bar">
                    <div class="letf">
                        <SelectDown bgc="#000000" ref="SelectDown" :list="listSelect" @select="select" height="40px" />
                    </div>
                    <div class="right">
                        <SelectDown bgc="#000000" ref="SelectDownSub" :list="listSelectSub" @select="select2" :noSelect="true" height="40px" />
                    </div>
                </div>

                <DivScroll ref="scroll" @get="get">
                    <template v-slot:main="{}">
                        <HisItem v-for="(item, index) in list" :key="index" :item="item" @extractHand="extractHand(item)" />
                    </template>
                    <template v-slot:bottom="{ loadMore, type }" v-if="!lenArray(list)">
                        <!-- type 0 下拉 1上拉-->
                        <div style="display: flex; align-items: center; justify-content: center; flex: 1">
                            <div v-show="(loadMore || load) && type == 1">
                                <div class="center" style="height: 100px">
                                    <LoadIcon salce="0.7">
                                        <Loading6 />
                                    </LoadIcon>
                                    <div style="padding-left: 6px">{{ langJson('加载中', 'inscdoge.txt.v5.5') }}</div>
                                </div>
                            </div>
                            <template v-if="(loadMore || load) && type == 1"></template>
                            <NuData heith="200" v-else-if="!(loadMore || load)"></NuData>
                        </div>
                    </template>
                </DivScroll>
                <!-- <Page v-model="pageNum" :all="total" :size="pageSize" @change="init"></Page> -->
            </div>
            <!-- <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div> -->
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            link_count_arr: [], //请求次数记号
            load: false,
            type: '0',
            subType: '0',
            list: [],
            listSelectSub: [],
            pageNum: 1,
            pageSize: 25,
            extractModel: false,
            extractProModel: false,
            profit: {}, // 收益
            principal: {}, // 本金
            detail: {}, // 提取预览
        }
    },
    computed: {
        listSelect() {
            try {
                return [
                    { name: this.langJson('锁仓', 'inscdoge.txt.v5.6'), key: '0' },
                    { name: this.langJson('奖励', 'inscdoge.txt.v5.7'), key: '1' },
                    { name: this.langJson('活期', 'inscdoge.txt.v5.8'), key: '2' },
                    { name: this.langJson('释放', 'inscdoge.txt.v5.9'), key: '3' },
                    { name: this.langJson('提现', 'inscdoge.txt.v5.10'), key: '4' },
                    { name: this.langJson('转账', 'inscdoge.txt.v5.83'), key: '5' },
                ]
            } catch (error) {
                return []
            }
        },
        listSelectSub0() {
            try {
                return [
                    { name: this.langJson('购买', 'inscdoge.txt.v5.11'), key: '0' },
                    { name: this.langJson('新人推广奖励', 'inscdoge.txt.v5.12'), key: '1' },
                ]
            } catch (error) {
                return []
            }
        },
        listSelectSub1() {
            try {
                return [
                    { name: this.langJson('全部', 'inscdoge.txt.v5.13'), key: '' },
                    { name: this.langJson('直推奖励', 'inscdoge.txt.v5.14'), key: '1' },
                    { name: this.langJson('团队奖励', 'inscdoge.txt.v5.15'), key: '2' },
                    { name: this.langJson('收益奖励', 'inscdoge.txt.v5.16'), key: '3' },
                ]
            } catch (error) {
                return []
            }
        },
        listSelectSub2() {
            try {
                return [
                    // { name: this.langJson('全部', 'inscdoge.txt.v5.13'), key: '0' },
                    { name: this.langJson('活期收益', 'inscdoge.txt.v5.17'), key: '1' },
                    { name: this.langJson('购买赠送', 'inscdoge.txt.v5.18'), key: '2' },
                    { name: this.langJson('划转', 'inscdoge.txt.v5.19'), key: '3' },
                ]
            } catch (error) {
                return []
            }
        },
        listSelectSub3() {
            try {
                return [{ name: this.langJson('释放明细', 'inscdoge.txt.v5.20'), key: '' }]
            } catch (error) {
                return []
            }
        },
        listSelectSub4() {
            try {
                return [
                    { name: this.langJson('全部', 'inscdoge.txt.v5.13'), key: '' },
                    { name: this.langJson('申请中', 'inscdoge.txt.v5.21'), key: '0' },
                    { name: this.langJson('审核通过', 'inscdoge.txt.v5.22'), key: '1' },
                    { name: this.langJson('审核拒绝', 'inscdoge.txt.v5.23'), key: '2' },
                    { name: this.langJson('已发放', 'inscdoge.txt.v5.41'), key: '3' },
                    { name: this.langJson('发放失败', 'inscdoge.txt.v5.42'), key: '4' },
                ]
            } catch (error) {
                return []
            }
        },
        listSelectSub5() {
            try {
                return [{ name: this.langJson('内部转账', 'inscdoge.txt.v5.82'), key: '' }]
            } catch (error) {
                return []
            }
        },
        isLogin() {
            try {
                return this.walletDetail && this.walletDetail.address && this.loginToken
            } catch (error) {
                return false
            }
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    watch: {
        isLogin: {
            async handler(a, b) {
                // console.log('isLogin', a, b)
                if (!!a && !b) {
                    await this.get({ page: '1', type: 1 })
                }
            },
        },
    },
    async created() {
        let { dispatch } = this.$store
        await dispatch('personalGet')
    },
    mounted() {},
    methods: {
        select(type) {
            this.type = type
            this.listSelectSub = JSON.parse(JSON.stringify(this['listSelectSub' + this.type]))
            this.$nextTick(() => {
                this.$refs['SelectDownSub'] && this.$refs['SelectDownSub'].select(this.listSelectSub[0])
            })
        },
        select2(type) {
            this.subType = type
            this.get({ page: 1, type: 1, top: true })
        },
        // 完成提取刷新列表
        completeExtract() {
            this.init()
        },
        confirm(item) {
            this.detail = {
                ...this.detail,
                ...item,
            }
            this.extractProModel = true
        },
        async extractHand(item) {
            this.extractModel = !this.extractModel
            this.detail = {
                ...this.detail,
                ...item,
            }
        },
        cancelLast() {
            if (this.link_count_arr.length > 0) {
                //console.log("取消请求");
                this.link_count_arr[this.link_count_arr.length - 1].cancel('cancel this Axios') //取消请求
            }
        },
        async get({ page = '', type = 1, top = false }) {
            // console.log("{ page = '', type = 1 }", page, type)
            // 取消已发送但未返回数据的请求
            this.cancelLast()
            this.link_count_arr = []
            const source = axios.CancelToken.source()
            this.link_count_arr.push(source)

            // if (this.load) {
            // return
            // }
            this.load = true

            try {
                // type subtype
                // 挖矿
                // 0    0  自己买的   + lockAmount type 购买
                // 0    1  新人推广奖励  往上反三代的类型奖励

                // 奖励
                // 1    '' 全部
                // 1    1 直推奖励
                // 1    2 团队奖励
                // 1    3 收益奖励  下级活期数量的利息 返过来的

                // 活期
                // 2    0 全部 --有可能有负号
                // 2    1  活期收益 活期数量的利息 一样的 从流水
                // 2    2  赠送收益 购买预售项目赠送活期余额
                // 2    3  划转

                // 释放
                // 3    '' 释放明细

                // 提现
                // 4    ''  全部
                // 4    0  申请中
                // 4    1  审核通过
                // 4    2  审核拒绝
                // 4    3  已发放
                // 4    4  发放失败

                // 提现
                // 5    ''  转账

                const obj = {
                    type: this.type, // 0 1 2 3
                    subType: this.subType, // 0 1
                    // chain: 'eth',
                    // sort: 'vol',
                    // keyWord: '搜索词',
                }

                const name = 'list'
                let pageNum = page || this.pageNum
                const pageSize = this.pageSize
                if (pageNum == 1) {
                    if (top) {
                        //下拉时不置顶
                        this.$refs.scroll.scrollTo(0, 0, 0)
                    }
                    this[name] = []
                }

                let { dispatch } = this.$store
                let res = await dispatch('get_record_list', { pageNum, pageSize, ...obj, cancelToken: this.link_count_arr[this.link_count_arr.length - 1].token })
                res = res.rows
                this.pageNum = this.addList({ pageNum, name, res })
                this.$refs.scroll.getEnd(type)
            } catch (error) {}
            this.load = false
        },
        addList({ name, res = [], pageNum = 1 }) {
            if (res.length) {
                res = res.map((item) => {
                    // console.log('this.type == 0 && this.subtype == 0', this.type == 0 && this.subType == 0)
                    // console.log('this.type == 0 && this.subtype == 0', this.type, this.subType)

                    let type = this.listSelectSub.find((item) => {
                        return item.key == this.subType
                    })

                    // 购买
                    if (this.type == 0 && this.subType == 0) {
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.lockAmount,
                            symbol: 'INS', // 挖矿/ins锁仓 只有INS
                        }
                    }

                    // 新人推广奖励
                    if (this.type == 0 && this.subType == 1) {
                        return {
                            time: item.date,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                            // 新人推广奖励, 应该是指 推荐奖励
                            // 推荐奖励中的ins 按照当时的ins价格则算成usdt 属于活期USDT账户
                            // 要问后台怎么返回的
                        }
                    }

                    // 全部
                    if (this.type == 1 && this.subType == '') {
                        let type = this.listSelectSub.find((itemSub) => {
                            return itemSub.key == item.type
                        })
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                            price: item.price,

                            // 奖励

                            // 全部
                            // 直推奖励 :一级返佣?
                            // 团队奖励 :团队返佣?
                            // 收益奖励 :锁仓的ins  (月化10%)   每天释放(锁仓的ins)都去到活期ins中(即可提现)

                            // (不用 ,在活期收益里面)此处应该多一个 奖励类型:活期usdt   (月化10%)   每天释放都去到活期usdt中(即可提现)

                            // type 1 2 3  对应 subType 1 2 3
                        }
                    }

                    // 全部
                    if (this.type == 1 && this.subType == 1) {
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                            price: item.price,
                        }
                    }

                    if (this.type == 1 && this.subType == 2) {
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                            price: item.price,
                        }
                    }

                    if (this.type == 1 && this.subType == 3) {
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                        }
                    }

                    // if (this.type == 2 && this.subType == 0) {
                    //     let temp
                    //     if (item.description.includes('收益')) {
                    //         temp = 1
                    //     } else if (item.description == '划转') {
                    //         temp = 3
                    //     } else if (item.description == '购买预售项目赠送活期余额') {
                    //         temp = 2
                    //     }
                    //     let type = this.listSelectSub.find((itemSub) => {
                    //         return itemSub.key == temp
                    //     })

                    //     // 活期

                    //     // 全部
                    //     // 活期收益 此处应该就是USDT和INS 每天月化
                    //     // 购买赠送 一定是INS
                    //     // 划转:    一定是INS 只是旧记录

                    //     return {
                    //         time: item.createTime,
                    //         type: type.name,
                    //         num: item.amount,
                    //         symbol: item.symbol,
                    //     }
                    // }
                    if (this.type == 2 && this.subType == 1) {
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                        }
                    }
                    if (this.type == 2 && this.subType == 2) {
                        // description:"购买预售项目赠送活期余额"
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                        }
                    }
                    if (this.type == 2 && this.subType == 3) {
                        // description:"购买预售项目赠送活期余额"
                        return {
                            time: item.createTime,
                            type: type.name,
                            num: item.amount,
                            symbol: item.symbol,
                        }
                    }
                    if (this.type == 3 && this.subType == '') {
                        // 释放: 释放明细
                        // 原来的释放明细 是指 INS锁仓释放  但这不是活期收益吗

                        return {
                            time: item.date,
                            type: type.name,
                            num: item.amount,
                            symbol: item.coinName,
                        }
                    }
                    if (this.type == 4) {
                        // status 0-申请中 1-审核通过 2-审核拒绝

                        // 提现

                        // 全部
                        // 审核中
                        // 审核通过
                        // 审核拒绝
                        // 已发放
                        // 发放失败

                        // 这个好解决: 增加 USDT 返回币种名字

                        let statusTxt = ''
                        let color = ''
                        if (item.status == 0) {
                            statusTxt = this.langJson('申请中', 'inscdoge.txt.v5.21')
                            color = '#ffffff'
                        }
                        if (item.status == 1) {
                            statusTxt = this.langJson('审核通过', 'inscdoge.txt.v5.22')
                            color = '#56ffa4'
                        }
                        if (item.status == 2) {
                            statusTxt = this.langJson('审核拒绝', 'inscdoge.txt.v5.23')
                            color = '#c44e21'
                        }
                        if (item.status == 3) {
                            statusTxt = this.langJson('已发放', 'inscdoge.txt.v5.41')
                            color = '#56ffa4'
                        }
                        if (item.status == 4) {
                            statusTxt = this.langJson('发放失败', 'inscdoge.txt.v5.42')
                            color = '#c44e21'
                        }
                        return {
                            time: item.createTime,
                            type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                            num: '-' + item.amount,
                            statusTxt,
                            color,
                            symbol: item.coinName,
                        }
                        // if (this.subType == '') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                        // if (this.subType == '0') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                        // if (this.subType == '1') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                        // if (this.subType == '2') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                        // if (this.subType == '3') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                        // if (this.subType == '4') {
                        //     return {
                        //         time: item.createTime,
                        //         type: this.langJson('提现', 'inscdoge.txt.v5.10'),
                        //         num: '-' + item.amount,
                        //         statusTxt,
                        //         color,
                        //     }
                        // }
                    }

                    if (this.type == 5) {
                        // status 0-申请中 1-审核通过 2-审核拒绝
                        // 转账
                        // 内部转账

                        let type = ''
                        let num = ''
                        let obj = { userName_: '', userName: '', address_: '', address: '', fee_: '', fee: '', amount_: '', amount: '' }
                        let nowId = this.globalPersonal.information.id
                        if (nowId == item.fromUid) {
                            type = this.langJson('转账', 'inscdoge.txt.v5.83')
                            num = '-' + item.amount
                            obj.userName = item.toUserName
                            obj.address = item.toAddress
                            obj.userName_ = this.langJson('接收者', 'inscdoge.txt.v5.84')
                            obj.address_ = this.langJson('接收地址', 'inscdoge.txt.v5.85')

                            obj.fee_ = this.langJson('手续费', 'inscdoge.txt.v5.86')
                            obj.fee = item.feeAmount + ' ' + item.symbolName
                            obj.amount_ = this.langJson('到账金额', 'inscdoge.txt.v5.87')
                            obj.amount = item.receiveAmount + ' ' + item.symbolName
                        }
                        if (nowId == item.toUid) {
                            type = this.langJson('收款', 'inscdoge.txt.v5.88')
                            num = item.receiveAmount
                            obj.userName = item.fromUserName
                            obj.address = item.fromAddress
                            obj.userName_ = this.langJson('发送者', 'inscdoge.txt.v5.89')
                            obj.address_ = this.langJson('发送地址', 'inscdoge.txt.v5.90')
                        }

                        return {
                            time: item.createTime,
                            type,
                            num,
                            symbol: item.symbolName,
                            obj,
                        }
                    }
                })
                // console.log('res', res)
                if (pageNum == 1) {
                    this[name] = res
                } else {
                    this[name].push(...res)
                }
                pageNum++
            }
            return pageNum
        },
    },
}
</script>
<style lang="scss" scoped>
.center {
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
    white-space: nowrap;
}

.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        overflow: hidden;
        .list {
            overflow: hidden;
            flex: 1;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            max-height: calc(100% - 87px);

            /* font-size: 20px;
            font-weight: 700;
            line-height: 21px; */
            .bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .letf {
                    width: 48%;
                }
                .right {
                    width: 48%;
                }
            }
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
