<template>
    <div class="com">
        <div class="bar">
            <div class="left">{{ langJson('已售', 'inscdoge.txt.v1.103') }}：{{ (totalNum - nowNum) | numberGrap }}</div>
            <div class="right">{{ langJson('总数', 'inscdoge.txt.v1.104') }}：{{ totalNum | numberGrap }}</div>
        </div>
        <div class="progressBox">
            <div class="progress"></div>
            <div class="progress_bar" :style="{ width: `${((totalNum - nowNum) / totalNum) * 100}%` }"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['totalNum', 'nowNum'],
    data() {
        return {
            number: 1,
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.com {
    margin-top: 15px;
    .bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            font-size: 12px;
            line-height: 16px;

            color: #a7a0bb;
        }
        .right {
            font-size: 12px;
            line-height: 16px;
            color: #a7a0bb;
        }
    }
    .progressBox {
        margin-top: 6px;
        position: relative;
        height: 20px;
        .progress {
            height: 20px;
            overflow: hidden;
            background: -webkit-repeating-linear-gradient(0deg, transparent, transparent, #ffffff 8px, #ffffff 10px);

            // background: -webkit-repeating-linear-gradient(0deg, #e9e9e9 0, #e9e9e9 5px, #ffffff 5px, #ffffff 10px);
        }
        .progress_bar {
            height: 20px;
            top: 0px;
            left: 0;
            background: -webkit-repeating-linear-gradient(0deg, transparent 0, transparent, #5d0667 8px, #1e0f6a 10px);
            // background: -webkit-repeating-linear-gradient(0deg, #3071fd 0, #3071fd 5px, #ffffff 5px, #ffffff 10px);
            position: absolute;
        }
    }
}
</style>
