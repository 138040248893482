const statusModule = {
    state: {
        sendSelect: [],
        saleSelect: [],
        cartSelect: [],
        mintSelect: [],
        cancelSelect: [],
        typeOperation: "sendSelect",

    },
    getters: {

    },
    mutations: {
        cancelSelect(state, cancelSelect) {
            state.cancelSelect = cancelSelect
        },
        sendSelect(state, sendSelect) {
            state.sendSelect = sendSelect
        },
        saleSelect(state, saleSelect) {
            state.saleSelect = saleSelect
        },
        cartSelect(state, cartSelect) {
            state.cartSelect = cartSelect
        },
        mintSelect(state, mintSelect) {
            state.mintSelect = mintSelect
        },
        typeOperation(state, typeOperation) {
            state.typeOperation = typeOperation
        },
    }

};
export default statusModule;
