<template>
    <div class="page_main" v-if="all != 0 || noAll">
        <div class="page_prev" @click="prev">
            <!-- <GI name="global_back_white" ws="24" hs="24" @click="prev"></GI> -->
            <GI class="left_arrows" name="right_arrows" ws="24" hs="24" @click="prev" :color="svg1"></GI>
        </div>

        <div v-show="isBtn" v-if="current != 1 && current > 2" :class="['page_item']" @click="goJump(1)">1</div>

        <div v-show="isBtn" v-if="current > 3" :class="['page_item', 'ellipsis']">...</div>

        <!-- <div v-if="current - 2 > 0" :class="['page_item']" @click="goJump(current - 2)">
            {{current - 2}}
        </div> -->

        <div v-show="isBtn" v-if="current - 1 > 0" :class="['page_item']" @click="goJump(current - 1)">
            {{ current - 1 }}
        </div>

        <!-- @click="goJump(current)" -->
        <div v-show="isBtn" :class="['page_item', 'page_active']">
            {{ current }}
        </div>

        <div v-show="isBtn" v-if="current < page - 1" :class="['page_item']" @click="goJump(current + 1)">
            {{ current + 1 }}
        </div>
        <!-- <div v-if="current < page - 2" :class="['page_item']" @click="goJump(current + 2)">
            {{current + 2}}
        </div> -->

        <div v-show="isBtn" v-if="current <= page - 3" :class="['page_item', 'ellipsis']">...</div>

        <div v-show="isBtn" v-if="current != page" :class="['page_item']" @click="goJump(page, true)">
            {{ page }}
        </div>
        <div v-show="!isBtn" class="page_num">{{ current }} / {{ page }}</div>

        <div class="page_next" @click="next">
            <!-- updata_right_1 -->
            <GI name="right_arrows" ws="24" hs="24" @click="next" :color="svg1"></GI>
            <!-- <GI  name="updata_right_1" ws="24" hs="24" @click="next" :color="svg1"></GI> -->
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'current',
        event: 'update',
    },
    props: {
        all: {
            type: [Number, String],
            default: 0,
        },
        size: {
            type: [Number, String],
            default: 24,
        },
        current: {
            type: [Number, String],
            default: 1,
        },
        isBtn: {
            type: Boolean,
            default: true,
        },
        noAll: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        page() {
            try {
                if (this.noAll) {
                    return 'More'
                } else {
                    return Math.ceil(this.all / this.size)
                }
            } catch (err) {
                console.log(err)
                return 1
            }
        },
    },
    methods: {
        prev() {
            if (this.current > 1) {
                this.goJump(this.current - 1)
            }
        },
        next() {
            if (this.current < this.page) {
                this.goJump(this.current + 1)
            }
        },
        goJump(val, jupm = false) {
            if (this.noAll && jupm) {
                return
            }
            this.$emit('update', val)
            this.$emit('change', val)
        },
    },
}
</script>
<style lang="scss" scoped>
.page_main {
    .page_next {
        margin-left: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page_prev {
        margin-right: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left_arrows {
            transform: rotate(180deg);
        }
    }

    .page_item {
        min-width: 36px;
        height: 36px;
        margin: 0 4px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 8px;

        border-radius: 8px;
        box-sizing: border-box;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2px;

        background: rgba($color: #ffffff, $alpha: 1);
        color: #72768b;
        cursor: pointer;
        transition: all ease-out 0.25s !important;
        backdrop-filter: blur(10px);

        /* &:hover {
            color: #f6f6f6;
        } */
        &.ellipsis {
            /* &:hover {
                color: #72768b;
            } */
        }
    }

    .page_active {
        background: #ff8d01;
        color: #ffffff;
    }

    .page_num {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        color: #72768b;
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
