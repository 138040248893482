// 特殊颜色
export default {
    determineColor() {
        // 入参 白天颜色 黑夜颜色
        return (dark, light) => {
            if (this.globalTheme == 'dark') {
                return dark
            } else {
                return light
            }
        }
    },
}
