import http from "./base";
/**
 * 拍卖列表
 * @param pageNum   第几页
 * @param pageSize  每页数量
 * @param orderByColumn 按什么排序
 * @param isAsc 顺序
*/
let auctionProject = (parmas) => {
   return http.get("/auction/productList",parmas);
};


/**
 * 拍卖详情
*/
let auctionDetail = (id) => {
   return http.get(`/auction/detail/${id}`);
};

/**
 * 拍卖列表
 * @param pageNum   第几页
 * @param pageSize  每页数量
*/
let auctionOffers = (id,parmas = {}) => {
   return http.get(`/auction/makerList/${id}`,parmas);
};

/**
 * @param recordId
 * @param tokenAmount
 * @param sign
 * @param chainId
 * @param signTime
 *参加拍卖
 */
let joinAuction = params => {
  return http.postJson(`/auction/join`, params);
};
// 获取页面轮播图
let getRecommendList=(params={})=>{
   return http.get('/index/recommend/list',params)
}

export default {
    auctionProject,
    auctionDetail,
    auctionOffers,
    joinAuction,
    getRecommendList
};
