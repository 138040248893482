<template>
    <div :class="['btn', type, disable || load ? 'disable' : '', size]" @click="emitEvent" :style="Object.assign(styleCustom, { height })">
        <GI v-if="icon_left" class="icon_left" :name="icon_left" :ws="iconSize ? iconSize : ws" :hs="iconSize ? iconSize : hs" @click="emitEvent"></GI>
        <div class="btnText">
            <slot></slot>
        </div>

        <GI v-if="icon_right" class="icon_right" :name="icon_right" :ws="iconSize ? iconSize : ws" :hs="iconSize ? iconSize : hs" @click="emitEvent"></GI>
        <div class="mask" v-if="load">
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="24" hs="24"></GI>
            <!-- <GI class="loading" :rotate="true" type="png" name="backup_loading"></GI> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'BTN',
    data() {
        return {
            lockStatus: false,
            lockTime: 500,
        }
    },
    props: {
        icon_left: {
            type: String,
            default: '',
        },
        icon_right: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'default',
        },
        disable: {
            type: Boolean,
            default: false,
        },
        load: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: [String, Number],
            default: '',
        },
        ws: {
            type: [String, Number],
            default: '20',
        },
        hs: {
            type: [String, Number],
            default: '20',
        },
        height: {
            type: String,
            default: '37px',
        },
        styleCustom: {
            type: Object,
            default: () => {
                return {}
            },
        },
        size: {
            type: String,
            default: 'medium',
        },
    },

    methods: {
        emitEvent() {
            if (!this.disable && !this.load && !this.lockStatus) {
                this.lockStatus = true
                setTimeout(() => {
                    this.lockStatus = false
                }, this.lockTime)
                this.$emit('click')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.btn {
    user-select: none;
    .icon_left {
        margin-right: 10px;
    }
    .icon_right {
        margin-left: 10px;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 1px 10px 1px 1px;
    gap: 4px;
    box-sizing: border-box;
    color: var(--font_deep_color);

    font-style: normal;
    cursor: pointer;
    letter-spacing: 2px;
    position: relative;
    flex-shrink: 0;
    max-width: 100%;
    .btnText {
        text-transform: capitalize;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-color: #00000053;
        border-radius: 6px; */
    }
}

.mini {
    font-size: 14px !important;
    border-width: 1px !important;
    border-radius: 4px !important;
}
.medium {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 12px 22px !important;
}
.medium_h5 {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 12px 22px !important;
}
.big {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 16px 28px !important;
}

.default {
    border: 1px solid var(--border_color);
    // background: var(--color_white);
    color: var(--font_deep_color) !important;
    border-radius: 6px !important;
}

.main {
    color: #2ca631;
    border: 1.2px solid #2ca631;
    border-radius: 6px;
    font-size: 16px;
    padding: 0 10px;
}

.cancle {
    background: var(--bg_color);
    color: var(--font_modif_color) !important;
    border-radius: 6px !important;
}

.blackCancle {
    font-weight: 400;
    font-family: 'Inter' !important;
    font-size: 14px;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 0.5px;
    padding: 0px !important;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #610366;
    position: relative;
    // border-image-source: linear-gradient(to left, #7d087a, #2d2071) !important;
    // border-image-slice: 1 !important;
}

.blackConfirm {
    font-weight: 400;
    font-family: 'Inter' !important;
    font-size: 14px;
    background: var(--bg7);
    border: 1px solid var(--bg7);
    color: #ffffff;
    border-radius: 5px;
    line-height: 40px;
    letter-spacing: 0.5px;
    padding: 0px !important;
    border-radius: 6px;
    &.disable {
        opacity: 1;
        color: #aeafb0;
        background: #f1f3f4;
        border: 1px solid #f1f3f4;
    }
}

.active {
    background: var(--main_color);
    color: #fff !important;
    border-radius: 6px !important;
    border: 1px solid var(--main_color) !important;
}
.rejected {
    border: 1px solid var(--danger_color);
    color: var(--danger_color) !important;
    border-radius: 6px !important;
}
.yes {
    background: linear-gradient(90deg, #c589f9 27.34%, #ec52d4 100%) !important;
    box-shadow: 0px 20px 25px -5px rgba(213, 115, 234, 0.1), 0px 8px 10px -6px rgba(186, 0, 251, 0.1) !important;
    border-radius: 6px !important;
    color: #fff !important;
}
.skip {
    background: #dcd7e5 !important;
    box-shadow: 0px 0px 0px rgba(134, 134, 134, 0) !important;
    border-radius: 6px !important;
    color: #fff !important;
}

.line {
    box-sizing: border-box;
    display: flex;
    padding: 10px 20px 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    max-width: 100%;

    border-radius: 50px;
    border: 1px solid var(--Linear, #ff6201);

    color: var(--White, var(--Text-Title, #fff));
    font-family: Inconsolata;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    .font {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.orange {
    box-sizing: border-box;
    display: flex;
    padding: 10px 20px 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    max-width: 100%;

    border-radius: 50px;
    background: #ff6821;

    color: var(--White, var(--Text-Title, #fff));
    font-family: Inconsolata;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;

    .font {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.orangeCancle {
    box-sizing: border-box;
    display: flex;
    padding: 10px 20px 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    max-width: 100%;

    border-radius: 50px;
    /* background: #ff6821; */
    border: 1px solid #ff6821;

    color: var(--White, var(--Text-Title, #fff));
    font-family: Inconsolata;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    .font {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.disable {
    opacity: 0.5;
    pointer-events: none;
    /* border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px !important; */
}
.loading {
    // display: inline-flex !important;
    // height: 68% !important;
    // width: auto !important;
}

@media screen and (max-width: 450px) {
    .medium_h5 {
        height: 33px !important;
        font-size: 12px !important;
        padding: 0px 14px !important;
        ::v-deep .icon_left {
            margin-right: 4px !important;
        }
    }
}
</style>
