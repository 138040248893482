import contract from './contract'
import tronLink from './tronLink'
export default {
    ...contract,
    ...tronLink,

    // Opensea 完成订单
    async fulfillBasicOrder(params, contract, value = 0) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result
            result = await this.Send('fulfillBasicOrder', params, 'SEAPORT', value, contract)
            return result
        } catch (err) {
            console.log(err)
        }
    },

    // 批量发送nft
    async nftBatchSend(params) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result
            result = await this.Send('allToken', params, 'BATCH')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 批量铸造
    async mintBatch(params, contract, contractType) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result
            if (contractType == 721) {
                result = await this.Send('BatchMint', params, 'ERC721', 0, contract)
            } else if (contractType == 1155) {
                result = await this.Send('BatchMint', params, 'ERC1155', 0, contract)
            }

            return result
        } catch (err) {
            console.log(err)
        }
    },

    // staking 提取
    async extractStaked(params) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('extractProfit', params, 'STAKING')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // nft staking
    async nftStaked(params) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('nftDeposit', params, 'STAKING')
            return result
        } catch (err) {
            console.log(err)
        }
    },

    // 获取盲盒开箱时间
    async openMysteTime({ tokenId, contractAddr }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('openTime', [tokenId], 'MYSTERERC721', contractAddr)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 盲盒是否开启
    async isOpenMyste({ tokenId, contractAddr }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('isOpen', [tokenId], 'MYSTERERC721', contractAddr)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 打开盲盒
    async openMyste({ tokenId, contractAddr }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('setURI', [tokenId], 'MYSTERERC721', 0, contractAddr)
            return result
        } catch (err) {
            console.log(err)
        }
    },

    // 查询项目已售盲盒数量
    async soldMyster(address) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('projectSold', [address], 'MYSTER')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 购买盲盒
    async buyMyster(params, value) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('purchase', params, 'MYSTER', value)
            return result
        } catch (err) {
            console.log(err)
        }
    },

    // 获取uri
    async uriGet({ tokenId, contract, contractType }) {
        let result
        if (contractType == 1155) {
            result = await this.Call('uri', [tokenId], 'ERC1155', contract)
        } else if (contractType == 721) {
            result = await this.Call('tokenURI', [tokenId], 'ERC721', contract)
        }
        return result
    },
    // 冻结元数据
    async uriSet({ tokenId, ipfs, contract }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('setURI', [tokenId, ipfs], 'ERC1155', 0, contract)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 获取合约所有者
    async ownerGet(contract) {
        let result = ''
        try {
            result = await this.Call('owner', [], 'ERC1155', contract)
        } catch (err) {
            console.log(err)
        }
        return result
    },

    // 市场交易
    async marketTrade(params, value = 0) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('trade', params, 'MARKET', value)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 签名是否已失效
    async alreadyCancel(sign) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('forbidSignature', [sign], 'REGISTER')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 注销售卖签名
    async signCancel(params) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('cancelOffer', params, 'MARKET')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 查找平台nft是否已存在 非平台默认已存在
    async nftExist({ nft, id }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('exist', [id], 'ERC1155', nft)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 获取某个nft在合约中的交易信息
    async nftTraded({ nft, id, seller, index }) {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('getTrade', [nft, id, seller, index], 'REGISTER')
            return result
        } catch (err) {
            console.log(err)
        }
    },

    // 获取市场交易手续费比率
    async tradeFeeGet() {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Call('fee')
            result = await this.toSmall(result, 18)
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 查询钱包是否初始化
    async isInitWallet() {
        try {
            let { address } = await this.connect(this.walletDetail.plugin)
            let result = await this.Call('userPower', [address], 'REGISTER')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 初始化钱包
    async InitWallet() {
        try {
            await this.connect(this.walletDetail.plugin)
            let result = await this.Send('registerProxy', [], 'REGISTER')
            return result
        } catch (err) {
            console.log(err)
        }
    },
    // 获取nft创建者 以及 售卖者与nft的nonce
    async tradeNonce(contract, tokenId, seller = '') {
        try {
            let { address } = await this.connect(this.walletDetail.plugin)
            seller = seller ? seller : address
            let result = await this.Call('TradeRecord', [contract, tokenId], 'REGISTER')
            let nonce = await this.Call('Nonce', [contract, tokenId, seller], 'REGISTER')
            result.nonce = nonce
            return result
        } catch (err) {
            console.log(err)
            return false
        }
    },

    // 余额查询
    async getTokenBalance(tokenObj) {
        let result
        try {
            let { contractType, contract, tokenId, target } = tokenObj
            switch (contractType) {
                case 'ERC20':
                    result = await this.tokenBalance(contract, target)
                    break
                case 'ERC721':
                    result = await this.nftBalance(contractType, contract, tokenId, target)
                    break
                case 'ERC1155':
                    result = await this.nftBalance(contractType, contract, tokenId, target)
                    break
            }
        } catch (err) {
            console.log(err)
        }
        return result
    },
    async nftBalance(contractType, contract, tokenId, target) {
        let balance = 0
        let precision = 0
        let { address } = await this.connect(this.walletDetail.plugin)
        target = target ? target : address
        try {
            if (contractType == 'ERC721') {
                let owner = await this.Call('ownerOf', [tokenId], contractType, contract)
                balance = owner.toLowerCase() == target.toLowerCase() ? 1 : 0
            } else if (contractType == 'ERC1155') {
                balance = await this.Call('balanceOf', [target, tokenId], contractType, contract)
            }
        } catch (err) {
            console.log(err)
        }
        return {
            balance,
            precision,
        }
    },
    async tokenBalance(contract, target) {
        let balance = 0
        let precision = 18
        let { address } = await this.connect(this.walletDetail.plugin)
        target = target ? target : address
        try {
            if (!contract) {
                balance = await this.getEthBalance(target)
                balance = this.toSmall(balance, precision)
            } else {
                balance = await this.Call('balanceOf', [target], 'ERC20', contract)

                precision = await this.Call('decimals', [], 'ERC20', contract)
                balance = this.toSmall(balance, precision)
            }
        } catch (err) {
            console.log(err)
        }
        return {
            balance,
            precision,
        }
    },
    // 是否授权
    async getTokenApprove(tokenObj) {
        let result
        try {
            let { contractType, contract, amount, target } = tokenObj
            switch (contractType) {
                case 'ERC20':
                    if (contract != this.mainToken) {
                        result = await this.tokenApprove(contract, amount, target)
                    } else {
                        result = true
                    }
                    break
                case 'ERC721':
                    result = await this.nftApprove(contract, target)
                    break
                case 'ERC1155':
                    result = await this.nftApprove(contract, target)
                    break
            }
        } catch (err) {
            console.log(err)
        }
        return result
    },
    async tokenApprove(contract, amount, target) {
        let approve = 0
        let result = true
        if (!contract) {
            return result
        }

        let { address } = await this.connect(this.walletDetail.plugin)

        console.log('批准授权', address);
        try {
            approve = await this.Call('allowance', [address, target], 'ERC20', contract)
            let precision = await this.Call('decimals', [], 'ERC20', contract)
            approve = this.toSmall(approve, precision)
            if (parseFloat(amount) > parseFloat(approve)) {
                result = false
            }
        } catch (err) {
            console.log(err)
        }
        return result
    },
    async nftApprove(contract, target) {
        let approve = false
        let { address } = await this.connect(this.walletDetail.plugin)
        try {
            approve = await this.Call('isApprovedForAll', [address, target], 'ERC1155', contract)
        } catch (err) {
            console.log(err)
        }
        return approve
    },
    // 批准
    async approvePlay(tokenObj) {
        let result
        try {
            let { contractType, contract, token, target } = tokenObj
            switch (contractType) {
                case 'ERC20':
                    result = await this.approveToken(contract, target)
                    break
                case 'ERC721':
                    result = await this.approveNFT(contract, target)
                    break
                case 'ERC1155':
                    result = await this.approveNFT(contract, target)
                    break
            }
            if (result) {
                this.pushMsg('success', token + this.langJson(' Approval successful','inscdoge.txt.v1.88'))
            } else {
                this.pushMsg('error', token + this.langJson(' Approval failed','inscdoge.txt.v1.89'))
            }
        } catch (err) {
            console.log(err)
            this.pushMsg('error', token + this.langJson(' Approval failed','inscdoge.txt.v1.89'))
        }
        return result
    },
    async approveToken(contract, target) {
        try {
            let result = await this.Send('approve', [target, this.maxVal], 'ERC20', 0, contract)
            return result
        } catch (err) {
            console.log(err)
        }
        return false
    },
    async approveNFT(contract, target) {
        try {
            let result = await this.Send('setApprovalForAll', [target, true], 'ERC1155', 0, contract)
            return result
        } catch (err) {
            console.log(err)
        }
        return false
    },
}


