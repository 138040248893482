<template>
    <div class="hash">
        <div class="title">{{ langJson('交易完成', 'inscdoge.txt.v1.110') }}</div>
        <div class="tip" @click="hashDetail">
            {{ hash | hideStr(12) }}
        </div>
        <div class="model_btns">
            <div class="model_btn">
                <BTN class="btn_item" type="line" :disable="load_btn" @click="close">{{ langJson('关闭', 'inscdoge.txt.v1.111') }}</BTN>
            </div>
            <div class="btn_grap"></div>
            <div class="model_btn">
                <BTN class="btn_item" type="orange" :load="load_btn" @click="confirm">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        hash: {
            type: String,
            default: '',
        },
        chainId: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: Number,
            default: 1,
        },
        isConfirm: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        confirm() {
            this.close()
            // if (this.$attrs.goHint) {
            //     this.jump_preSale()
            // } else {
            //     this.jump_root()
            // }
        },
        hashDetail() {
            if (this.type == 1) {
                this.openTx(this.hash, this.chainId)
            } else if (this.type == 2) {
                this.openAddress(this.hash, this.chainId)
            }

            this.close()
        },
    },
}
</script>
<style lang="scss" scoped>
.hash {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 170px;
    width: 100%;
    .model_btns {
        .btn_grap {
            width: 20px;
        }
        .model_btn {
            flex: 1;
        }
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .tip {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        color: #2ca631;
        margin: auto;
        margin-top: 10px;
        text-align: center;
        width: 80%;
        cursor: pointer;
        white-space: wrap;
    }
    .title {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        color: var(--font1);
        text-align: center;
        margin: auto;
        margin-top: 10px;
        width: 80%;
    }
}
</style>
