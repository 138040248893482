<template>
    <Model v-model="show" @mask="close()">
        <div class="model_main" slot="main">
            <div class="title">{{ langJson('提现确认', 'inscdoge.txt.v5.79') }}</div>
            <div class="line"></div>

            <div class="list_rate">
                <div class="item">
                    <div class="key">{{ langJson('本次操作扣除', 'inscdoge.txt.v5.76') }}</div>
                    <div class="val red">{{ obj.amount }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('价值', 'inscdoge.txt.v5.77') }}</div>
                    <div class="val">{{ obj.usdt }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Exchange Rate', 'inscdoge.txt.v5.50') }}</div>
                    <div class="val">{{ obj.rate }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Fee Amount', 'inscdoge.txt.v5.51') }}</div>
                    <div class="val">{{ obj.fee }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Receive Amount', 'inscdoge.txt.v5.52') }}</div>
                    <div class="val green">{{ obj.getAmount }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('到账地址', 'inscdoge.txt.v5.78') }}</div>
                    <div class="val green">{{ obj.address }}</div>
                </div>
            </div>
            <div class="model_btns">
                <BTN class="b_t_n" type="orangeCancle" @click="close">{{ langJson('Cancel', 'inscdoge.txt.v1.130') }}</BTN>
                <BTN class="b_t_n" type="orange" @click="sure">{{ langJson('Confirm', 'inscdoge.txt.v1.112') }}</BTN>
            </div>
        </div>
    </Model>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            obj: {
                amount: '',
                usdt: '',
                rate: '',
                fee: '',
                getAmount: '',
                address: '',
            },
        }
    },
    computed: {},
    watch: {
        show(a) {
            if (a) {
            }
        },
    },
    created() {},
    mounted() {
        this.$bus.$off('ins_usdt_tip')
        this.$bus.$on('ins_usdt_tip', (obj) => {
            Object.assign(this.obj, obj)
        })
    },
    methods: {
        close() {
            this.$store.commit('withdrawModel_ins_usdt_tip', false)
        },
        sure() {
            this.close()
            this.$bus.$emit('ins_usdt_sure')
        },
    },
}
</script>

<style lang="scss" scoped>
.red {
    color: red !important;
}
.green {
    color: green !important;
}

.model_main {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 448px;
    max-width: 448px;

    .title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        text-align: center;
    }

    .line {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        background-color: #2a2a2a;
    }

    .list_rate {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;

            .key {
                font-size: 12px;
                color: #878e95;
            }

            .val {
                font-size: 12px;
                color: #878e95;

                &.rate {
                    color: #24ae8f;
                }
            }
        }
    }

    .desc_t {
        margin: 0 auto;
        margin-top: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        /* color: #cccccc; */
        color: red;
    }

    .desc {
        margin: 0 auto;
        margin-top: 15px;
        width: 68%;
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;

        color: #cccccc;
    }

    .model_btns {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        .b_t_n {
            width: 46%;
        }
    }
}

@media screen and (max-width: 1081px) {
    .model_main {
        width: 100%;
    }
}
</style>
