<template>
    <div class="tokens">
        <Back :title="langJson('代币列表')"></Back>
        <div class="bg">
            <div class="list">
                <DivScroll ref="scroll" @get="get">
                    <template v-slot:main="{}">
                        <TokenItem v-for="(item, index) in list" :key="index" :item="{ balance: '9966454.555555', usdtRate: '52.222222222222', symbol: '42.2522222222222', icon: 'https://oss.inscdoge.com/inscdoge/2024/01/11/c3365324c40e416cb067947cf4af2290.jpg', chainIcon: 'https://oss.inscdoge.com/inscdoge/2024/01/11/969bf101cadb40139a4feb50662d3b23.png', currencyRate: '12', change: Math.random() >= 0.5 ? '0.23' : '-0.23' } || item" />
                    </template>
                    <template v-slot:bottom="{ loadMore, type }" v-if="!lenArray(list)">
                        <!-- type 0 下拉 1上拉-->
                        <div style="display: flex; align-items: center; justify-content: center; flex: 1">
                            <div v-show="(loadMore || load) && type == 1">
                                <div class="center" style="height: 100px">
                                    <LoadIcon salce="0.7">
                                        <Loading6 />
                                    </LoadIcon>
                                    <div style="padding-left: 6px">{{ langJson('加载中', 'inscdoge.txt.v5.5') }}</div>
                                </div>
                            </div>
                            <template v-if="(loadMore || load) && type == 1"></template>
                            <NuData heith="200" v-else-if="!(loadMore || load)"></NuData>
                        </div>
                    </template>
                </DivScroll>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
        }
    },
    computed: {},
    watch: {
        isLogin: {
            async handler(a, b) {
                // console.log('isLogin', a, b)
                if (!!a && !b) {
                    await this.get({ page: '1', type: 1 })
                }
            },
        },
    },
    async created() {},
    mounted() {},
    methods: {
        async get({ page = '', type = 1, top = false }) {
            // console.log("{ page = '', type = 1 }", page, type)

            this.load = true
            try {
                const obj = {
                    type: 0, // 0 1 2 3
                    subType: 0,
                }
                const name = 'list'
                let pageNum = page || this.pageNum
                const pageSize = this.pageSize
                if (pageNum == 1) {
                    if (top) {
                        //下拉时不置顶
                        this.$refs.scroll.scrollTo(0, 0, 0)
                    }
                    this[name] = []
                }
                let { dispatch } = this.$store
                let res = await dispatch('get_record_list', { pageNum, pageSize, ...obj })
                res = res.rows
                this.pageNum = this.addList({ pageNum, name, res })
                this.$refs.scroll.getEnd(type)
            } catch (error) {}
            this.load = false
        },
        addList({ name, res = [], pageNum = 1 }) {
            if (res.length) {
                if (pageNum == 1) {
                    this[name] = res
                } else {
                    this[name].push(...res)
                }
                pageNum++
            }
            return pageNum
        },
    },
}
</script>
<style lang="scss" scoped>
.center {
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
    white-space: nowrap;
}

.tokens {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;

    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;

        .list {
            width: calc(100% - 40px);
            height: calc(100% - 87px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            font-size: 20px;
            font-weight: 700;
            line-height: 21px;
        }
    }
}
</style>
