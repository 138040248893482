<template>
    <Model v-model="langShow">
        <div class="model_main" slot="main">
            <div class="model_top">
                <div class="top_left">
                    {{ langJson('支持语种', 'inscdoge.txt.v1.113') }}
                </div>
                <div class="top_right">
                    <GI name="model_close_1" ws="24" hs="24" @click="close" :color="svg1"></GI>
                </div>
            </div>

            <!-- <div class="langList" v-if="!load_btn">
                <div v-for="(item, index) in langTypeList" :key="index + 'langList'" :class="['langListItem', langKey == item.langKey && 'active']" @click="changeLang(item)">
                    <div
                        class="lang_icon bgCover"
                        :style="{
                            backgroundImage: `url(${item.langIcon})`,
                        }"
                    ></div>
                    <div class="lang_name">{{ item.language }}</div>
                </div>
            </div> -->

            <div class="play_item" v-if="!load_btn">
                <div class="play_cell" v-for="(item, index) in langTypeList" :key="index + 'P'" @click="changeLang(item)">
                    <div class="cell_title">
                        <div class="lang_icon bgCover" :style="{ backgroundImage: `url(${item.langIcon})` }"></div>
                        {{ item.language }}
                    </div>
                    <GI v-if="langKey == item.langKey" :noClickStop="true" name="lang_select" ws="20" hs="20" :color="svg1"></GI>
                </div>
            </div>

            <div class="langNull" v-else>
                <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
            </div>
        </div>
    </Model>
</template>
<script>
import tools from '@/mixin/tools'
export default {
    data() {
        return {
            langKey: '',
        }
    },

    watch: {
        langShow(n) {
            if (n) {
                this.init()
            }
        },
    },
    created() {},
    methods: {
        // 语言选择
        changeLang(item) {
            if (item.langKey != localStorage.getItem('Language')) {
                localStorage.setItem('Language', item.langKey)
                localStorage.setItem('lang_sourse', '')
                localStorage.setItem('lang_version', '')
                window.location.reload()
            }
        },
        async init() {
            this.langKey = localStorage.getItem('Language')

            if (this.langTypeList && !!!this.langTypeList.length) {
                if (!this.langTypeList.length) {
                    this.load_btn = true
                }

                let { dispatch } = this.$store
                await dispatch('getLangType')
            }
            this.load_btn = false
        },
        close() {
            this.$store.commit('langShow', false)
        },
    },
    computed: {},
}
</script>
<style lang="scss" scoped>
.lang_icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
    /* object-fit: contain; */
}
.model_main {
    padding: 24px;
    box-sizing: border-box;
    width: 400px;
    max-width: 400px;
    .model_top {
        .top_right {
            cursor: pointer;
        }
        .top_left {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: var(--font1);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .langNull {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    /* .langList {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
        max-height: calc(100vh - 300px);
        overflow-x: hidden;
        overflow-y: auto;
        column-gap: 15px;
        min-height: 100px;
        .langListItem {
            cursor: pointer;
            height: 40px;
            margin: 10px 0;
            width: 200px;
            border-radius: 4px;
            display: flex;
            font-size: 14px;
            line-height: 18px;
            align-items: center;
            width: calc(33% - 13px);
            color: #757c8e;
            .lang_name {
                width: calc(100% - 36px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:hover {
                color: #f6f6f6;
            }
            &.active {
                color: #f6f6f6;
            }
        }
    } */
    .play_item {
        .play_cell {
            .cell_info {
                width: 80%;
                .title {
                    font-size: 16px;
                    line-height: 16px;

                    color: var(--font1);
                    width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .desc {
                    font-size: 12px;
                    line-height: 14px;

                    color: rgba($color: #1b1b2c, $alpha: 0.5);
                    width: 100%;
                    margin-top: 6px;
                }
            }
            .cell_title {
                font-size: 16px;
                line-height: 16px;

                color: var(--font1);
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .cell_jump {
                transform: rotate(180deg);
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid var(--border2);
        }
        .play_cell:last-child {
            border-bottom: none;
        }
        background-color: var(--bg11);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        // border: 1px solid rgba($color: #f8f8f8, $alpha: 1);
        margin-top: 24px;
        transition: all 0s !important;
    }
}

@media screen and (max-width: 500px) {
    .model_main {
        width: 100%;
    }
    .langList {
        /* justify-content: left; */
        column-gap: 20px;
        .langListItem {
            width: auto !important;
            padding-left: 0 !important;

            &.active {
                color: #ce4115;
            }
        }
    }
}
</style>
