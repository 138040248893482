import api from '@/api/newFi.js'
import tools from '@/mixin/tools'
import axios from 'axios'
const loginModule = {
    state: {
        link_count_arr: [], //请求次数记号
        bannerList: [], //    广告列表
        proList: [], // 项目列表

        proDetail: {}, // 项目详情
        coinList: [], // 筛选的币种列表
        proSearch: {
            pageNum: 1,
            pageSize: 10,
            isAsc: 'desc',
            // isAsc: 'asc',
            title: '',
            orderByColumn: 'yieldRate',
            'params[chainIdArr]': '',
            'params[coinIdArr]': '', // 87 质押币种
            type: '', // 1活期 2定期
            cycleId: '',
            isCurrent: '',
        },
        inviteTree: [], // 邀请关系树

        brokerRecordAll: [], // 邀请返佣记录累计
        brokerRecord: [], // 邀请返佣记录
        pledgeRecord: [], // 质押记录
        incomeRecord: [], // 收益记录
        extractRecord: [], // 提取记录

        articleDetail: {}, // 文章详情
        agentInfo: {},
        cyclelist: [],
        teamInfo: {},

        withdrawConfig: {},

        yourself: {},

        checkTicket: {},
        checkNode: {},
        checkPledge: {},
        pledgelist: [],
        pledgeAssets: {},
        withdrawlist: [],
        configParams: {},
        get_chargeUp_account: {},
        get_earlyProject_list: [],
        get_early_earnings_record: [],
        get_earlyProject_info: {},
        get_early_record: [],
        get_flow_list: [],
        get_record_list: [],
        get_release_record: [],
        get_generalProject_info: {},
        get_generalProject_list: [],
        get_earnings: {},
        get_general_earnings: {},
        get_general_earnings_record: [],
        get_general_record: [],
        get_card_record: [],
        projectEarning: [],
        releaseEarning: [],
        listTotal: 0,
        get_identityCard_list: [],
        get_ientityCard_info: {},
        get_earn_info: {},

        freedlist: [],
        freedDetail: [],
        powerRecord: [],

        deposit_list: [],
        shop_back_info: [],

        checkIn_sign: [],
        config_sign: {},
        record_sign: [],
        checkIn_list_sign: {},
        checkIn_log_sign: [],

        financeInfo: {},

        getArticleList: [],
    },
    getters: {
        coinOf(state) {
            return (key, value) => {
                return state.coinList.find((item) => {
                    return item[key] == value
                })
            }
        },
    },
    mutations: {
        getArticleList(state, getArticleList) {
            state.getArticleList = getArticleList
        },
        financeInfo(state, financeInfo) {
            state.financeInfo = financeInfo
        },
        checkIn_log_sign(state, checkIn_log_sign) {
            state.checkIn_log_sign = checkIn_log_sign
        },
        checkIn_list_sign(state, checkIn_list_sign) {
            state.checkIn_list_sign = checkIn_list_sign
        },
        checkIn_sign(state, checkIn_sign) {
            state.checkIn_sign = checkIn_sign
        },
        config_sign(state, config_sign) {
            state.config_sign = config_sign
        },
        record_sign(state, record_sign) {
            state.record_sign = record_sign
        },
        shop_back_info(state, shop_back_info) {
            state.shop_back_info = shop_back_info
        },
        deposit_list(state, deposit_list) {
            state.deposit_list = deposit_list
        },
        powerRecord(state, powerRecord) {
            state.powerRecord = powerRecord
        },
        freedlist(state, freedlist) {
            state.freedlist = freedlist
        },
        freedDetail(state, freedDetail) {
            state.freedDetail = freedDetail
        },
        configParams(state, configParams) {
            state.configParams = configParams
        },
        get_chargeUp_account(state, get_chargeUp_account) {
            state.get_chargeUp_account = get_chargeUp_account
        },
        get_earlyProject_list(state, get_earlyProject_list) {
            state.get_earlyProject_list = get_earlyProject_list
        },
        get_earlyProject_info(state, get_earlyProject_info) {
            state.get_earlyProject_info = get_earlyProject_info
        },
        get_early_earnings_record(state, get_early_earnings_record) {
            state.get_early_earnings_record = get_early_earnings_record
        },
        get_early_record(state, get_early_record) {
            state.get_early_record = get_early_record
        },
        get_flow_list(state, get_flow_list) {
            state.get_flow_list = get_flow_list
        },
        get_record_list(state, get_record_list) {
            state.get_record_list = get_record_list
        },
        get_generalProject_info(state, get_generalProject_info) {
            state.get_generalProject_info = get_generalProject_info
        },
        get_earnings(state, get_earnings) {
            state.get_earnings = get_earnings
        },
        get_general_earnings(state, get_general_earnings) {
            state.get_general_earnings = get_general_earnings
        },
        get_general_earnings_record(state, get_general_earnings_record) {
            state.get_general_earnings_record = get_general_earnings_record
        },
        get_general_record(state, get_general_record) {
            state.get_general_record = get_general_record
        },
        get_card_record(state, get_card_record) {
            state.get_card_record = get_card_record
        },
        projectEarning(state, projectEarning) {
            state.projectEarning = projectEarning
        },
        listTotal(state, listTotal) {
            state.listTotal = listTotal
        },
        releaseEarning(state, releaseEarning) {
            state.releaseEarning = releaseEarning
        },
        get_generalProject_list(state, get_generalProject_list) {
            state.get_generalProject_list = get_generalProject_list
        },
        get_release_record(state, get_release_record) {
            state.get_release_record = get_release_record
        },
        get_identityCard_list(state, get_identityCard_list) {
            state.get_identityCard_list = get_identityCard_list
        },
        get_ientityCard_info(state, get_ientityCard_info) {
            state.get_ientityCard_info = get_ientityCard_info
        },
        get_earn_info(state, get_earn_info) {
            state.get_earn_info = get_earn_info
        },
        withdrawlist(state, withdrawlist) {
            state.withdrawlist = withdrawlist
        },
        pledgeAssets(state, pledgeAssets) {
            state.pledgeAssets = pledgeAssets
        },
        pledgelist(state, pledgelist) {
            state.pledgelist = pledgelist
        },
        checkPledge(state, checkPledge) {
            state.checkPledge = checkPledge
        },
        checkNode(state, checkNode) {
            state.checkNode = checkNode
        },
        checkTicket(state, checkTicket) {
            state.checkTicket = checkTicket
        },
        yourself(state, yourself) {
            state.yourself = yourself
        },
        bannerList(state, bannerList) {
            state.bannerList = bannerList
        },
        proList(state, proList) {
            state.proList = proList
        },

        proDetail(state, proDetail) {
            state.proDetail = proDetail
        },
        coinList(state, coinList) {
            if (coinList) {
                localStorage.setItem('coinList', JSON.stringify(coinList))
            }
            state.coinList = coinList
        },
        proSearch(state, proSearch) {
            state.proSearch = proSearch
        },
        inviteTree(state, inviteTree) {
            state.inviteTree = inviteTree
        },
        brokerRecord(state, brokerRecord) {
            state.brokerRecord = brokerRecord
        },
        brokerRecordAll(state, brokerRecordAll) {
            state.brokerRecordAll = brokerRecordAll
        },
        pledgeRecord(state, pledgeRecord) {
            state.pledgeRecord = pledgeRecord
        },
        incomeRecord(state, incomeRecord) {
            state.incomeRecord = incomeRecord
        },
        extractRecord(state, extractRecord) {
            state.extractRecord = extractRecord
        },
        articleDetail(state, articleDetail) {
            state.articleDetail = articleDetail
        },
        agentInfo(state, agentInfo) {
            state.agentInfo = agentInfo
        },
        teamInfo(state, teamInfo) {
            state.teamInfo = teamInfo
        },

        cyclelist(state, cyclelist) {
            state.cyclelist = cyclelist
        },
        link_count_arr(state, link_count_arr) {
            state.link_count_arr = link_count_arr
        },
        withdrawConfig(state, withdrawConfig) {
            state.withdrawConfig = withdrawConfig
        },
    },
    actions: {
        async financeInfo(context, param = {}) {
            try {
                let result = await api.financeInfo(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('financeInfo', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async checkIn_log_sign(context, param = {}) {
            try {
                context.commit('listTotal', 0)
                let result = await api.checkInPointList(param)
                context.commit('listTotal', result.total || 0)
                result = this.apiResult(result)
                console.log(result)
                if (result && result.rows) {
                    let data = context.state.checkIn_log_sign.concat(result.rows)
                    context.commit('checkIn_log_sign', data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async checkIn_list_sign(context, param = {}) {
            try {
                let result = await api.checkInTotalPoints(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('checkIn_list_sign', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async checkIn_sign(context, param = {}) {
            try {
                let result = await api.checkInSign(param)
                result = this.apiResult(result)
                // if (result && result.rows) {
                //     context.commit('checkIn_sign', result.rows)
                // }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async config_sign(context, param = {}) {
            try {
                let result = await api.getConfig(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('config_sign', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async record_sign(context, param = {}) {
            try {
                let result = await api.getSignRecord(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('record_sign', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async deposit_list(context, param = {}) {
            try {
                let result = await api.genesisNodeRecord(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('deposit_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async freedlist(context, param = {}) {
            try {
                let result = await api.freedlist(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async freedDetail(context, param = {}) {
            try {
                let result = await api.freedDetail(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
            }
        },

        async pledgeStatistic(context, param = {}) {
            try {
                let result = await api.pledgeStatistic(param)
                result = this.apiResult(result)

                return result
            } catch (err) {
                console.log(err)
            }
        },
        async brokerlist(context, param = {}) {
            try {
                let result = await api.brokerlist(param)
                result = this.apiResult(result)

                return result
            } catch (err) {
                console.log(err)
            }
        },
        async configParams(context, param = {}) {
            try {
                let result = await api.configParams(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('configParams', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_chargeUp_account(context, param = {}) {
            try {
                let result = await api.get_chargeUp_account(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('get_chargeUp_account', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_earlyProject_list(context, param = {}) {
            try {
                let result = await api.get_earlyProject_list(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_earlyProject_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_early_earnings_record(context, param = {}) {
            try {
                let result = await api.get_early_earnings_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_early_earnings_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_early_record(context, param = {}) {
            try {
                let result = await api.get_early_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_early_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_flow_list(context, param = {}) {
            try {
                let result = await api.get_flow_list(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_flow_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_record_list(context, param = {}) {
            try {
                let { cancelToken } = param

                let result = await api.get_record_list(param, cancelToken)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_record_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_earlyProject_info(context, param = {}) {
            try {
                context.commit('get_earlyProject_info', {})
                let result = await api.get_earlyProject_info(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('get_earlyProject_info', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_generalProject_info(context, param = {}) {
            try {
                context.commit('get_generalProject_info', {})
                let result = await api.get_generalProject_info(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('get_generalProject_info', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_earnings(context, param = {}) {
            try {
                let result = await api.get_earnings(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('get_earnings', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_general_earnings(context, param = {}) {
            try {
                let result = await api.get_general_earnings(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_general_earnings', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_general_earnings_record(context, param = {}) {
            try {
                let result = await api.get_general_earnings_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_general_earnings_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_general_record(context, param = {}) {
            try {
                let result = await api.get_general_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_general_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_card_record(context, param = {}) {
            try {
                let result = await api.get_card_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_card_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async projectEarning(context, param = {}) {
            try {
                context.commit('listTotal', 0)
                let result = await api.projectEarning(param)
                context.commit('listTotal', result.total || 0)
                result = this.apiResult(result)
                if (result && result.rows) {
                    let data = context.state.projectEarning.concat(result.rows)
                    context.commit('projectEarning', data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async releaseEarning(context, param = {}) {
            try {
                context.commit('listTotal', 0)
                let result = await api.releaseEarning(param)
                context.commit('listTotal', result.total || 0)
                result = this.apiResult(result)
                if (result && result.rows) {
                    let data = context.state.releaseEarning.concat(result.rows)
                    context.commit('releaseEarning', data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },

        async get_general_update(context, param = {}) {
            try {
                let result = await api.get_general_update(param)
                result = this.apiResult(result)
                // if (result && result.rows) {
                //     context.commit('get_general_record', result.rows)
                // }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_identityCard_list(context, param = {}) {
            try {
                let result = await api.get_identityCard_list(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_identityCard_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_ientityCard_info(context, param = {}) {
            try {
                context.commit('get_ientityCard_info', {})
                let result = await api.get_ientityCard_info(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('get_ientityCard_info', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_generalProject_list(context, param = {}) {
            try {
                let result = await api.get_generalProject_list(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_generalProject_list', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async get_release_record(context, param = {}) {
            try {
                let result = await api.get_release_record(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('get_release_record', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async powerRecord(context, param = {}) {
            try {
                let result = await api.powerRecord(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('powerRecord', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },

        async earningslist(context, param = {}) {
            try {
                let result = await api.earningslist(param)
                result = this.apiResult(result)

                return result
            } catch (err) {
                console.log(err)
            }
        },
        async withdrawlist(context, param = {}) {
            try {
                let result = await api.withdrawlist(param)
                result = this.apiResult(result)

                return result
            } catch (err) {
                console.log(err)
            }
        },

        async pledgeAssets(context, param = {}) {
            try {
                let result = await api.pledgeAssets(param)
                result = this.apiResult(result)
                if (result && result.data) {
                    context.commit('pledgeAssets', result.data)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async withdraw_apply(context, param = {}) {
            try {
                let result = await api.withdraw_apply(param)
                result = this.apiResult(result)
                // if (result && result.data) {
                //     context.commit('pledgeAssets', result.data)
                // }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async transfer_apply(context, param = {}) {
            try {
                let result = await api.transfer_apply(param)
                result = this.apiResult(result)
                // if (result && result.data) {
                //     context.commit('pledgeAssets', result.data)
                // }
                return result
            } catch (err) {
                console.log(err)
            }
        },
        async withdraw_transfer(context, param = {}) {
            try {
                let result = await api.withdraw_transfer(param)
                result = this.apiResult(result)
                // if (result && result.data) {
                //     context.commit('pledgeAssets', result.data)
                // }
                return result
            } catch (err) {
                console.log(err)
            }
        },

        async pledgelist(context, param = {}) {
            try {
                let result = await api.pledgelist(param)
                result = this.apiResult(result)
                if (result && result.rows) {
                    context.commit('pledgelist', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
            }
        },

        async checkTicket(context, param = {}) {
            try {
                let result = await api.checkTicket(param)
                result = this.apiResult(result)
                result && result.data && result.data.ticketList && result.data.ticketList.length && context.commit('checkTicket', result.data.ticketList[0])

                result && result.data && result.data.nodeList && result.data.nodeList.length && context.commit('checkNode', result.data.nodeList[0])

                result && result.data && result.data.pledgeList && result.data.pledgeList.length && context.commit('checkPledge', result.data.pledgeList[0])

                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async pullSign(context, param = {}) {
            try {
                let result = await api.inSign(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提交 hash
        async pushHash(context, param = {}) {
            try {
                let result = await api.inHash(param)

                result = this.apiResult(result)
                return result
                // if (result.code == 200) {
                //     result = this.apiResult(result)
                //     return result
                // } else {
                //     this.pushMsg('warning', this.langJson('Blockchain did not confirm the transaction, please check later','inscdoge.txt.v1.95'))
                // }
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async assetsTotal(context, params = {}) {
            try {
                let result = await api.assetsTotal(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async yourself(context) {
            try {
                let result = await api.yourself()
                result = this.apiResult(result)
                result && result.data && context.commit('yourself', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async withdrawFee(context) {
            try {
                let result = await api.feeGet()
                result = this.apiResult(result)
                result && result.data && context.commit('withdrawConfig', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        async cycleGet(context) {
            try {
                let result = await api.cycleGet()
                result = this.apiResult(result)
                result && result.data && context.commit('cyclelist', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async teamInfo(context) {
            try {
                let result = await api.teamInfo()
                result = this.apiResult(result)
                result && result.data && context.commit('teamInfo', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async agentInfo(context) {
            try {
                let result = await api.agentInfo()
                result = this.apiResult(result)
                result && result.data && context.commit('agentInfo', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async rewardProject(context, param) {
            try {
                let result = await api.rewardProject(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        async extractPost(context, param) {
            try {
                let result = await api.hashExtract(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提交staked hash
        async investPost(context, param) {
            try {
                let result = await api.hashInvest(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提交佣金 hash
        async rewardPost(context, param) {
            try {
                let result = await api.rewardHash(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 奖励签名获取
        async signReward(context, param) {
            try {
                let result = await api.rewardSign(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提现签名获取
        async signGet(context, param) {
            try {
                let result = await api.withdrawSignGet(param)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 获取广告列表
        async getBannerList(context) {
            try {
                let result = await api.getBannerList()
                result = this.apiResult(result)
                context.commit('bannerList', result.data)
                this.apiCache('newFi', 'bannerList', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取项目列表
        async getProList(context, params) {
            try {
                let result = await api.getProList()
                result = this.apiResult(result)

                if (result.rows) {
                    result.rows.forEach((item) => {
                        item.rowNum = tools.multiplication(item.demandGift, 1) == 0 ? 2 : 3
                        item.isList = false
                        item.isLoading = false
                    })
                }

                result.rows && context.commit('proList', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 公告
        async getArticleList(context, params) {
            try {
                let result = await api.getArticleList(params)
                result = this.apiResult(result)

                if (result && result.rows) {
                    context.commit('getArticleList', result.rows)
                }
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取项目列表 定期/活期 前三
        async getProList1(context, params) {
            try {
                let result = await api.getProList(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 获取项目详情
        async getProDetail(context, id) {
            try {
                let result = await api.getProDetail(id)
                result = this.apiResult(result)
                result.data && context.commit('proDetail', result.data)
                // this.apiCache('newFi', 'proDetail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取筛选币种列表
        async getCoinList(context, params) {
            try {
                let result = await api.getCoinList(params)
                result = this.apiResult(result)
                context.commit('coinList', result.data)
                this.apiCache('newFi', 'coinList', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 手动绑定邀请码
        async invitePost(context, params) {
            try {
                let result = await api.invitePost(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 邀请关系树
        async inviteTree(context, params) {
            try {
                let result = await api.inviteTree(params)
                result = this.apiResult(result)
                context.commit('inviteTree', result.data)
                this.apiCache('newFi', 'inviteTree', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 查找 邀请关系树 下级
        async inviteTreeChild(context, params) {
            try {
                let result = await api.inviteTreeChild(params)
                result = this.apiResult(result)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 邀请返佣记录累计
        async brokerRecordAll(context, params) {
            try {
                let result = await api.brokerRecordAll(params)
                result = this.apiResult(result)
                context.commit('brokerRecordAll', result.data)
                this.apiCache('newFi', 'brokerRecordAll', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 邀请返佣记录
        async brokerRecord(context, params) {
            try {
                let result = await api.brokerRecord(params)
                result = this.apiResult(result)
                context.commit('brokerRecord', result.rows)
                this.apiCache('newFi', 'brokerRecord', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 投资质押记录
        async pledgeRecord(context, params) {
            try {
                let result = await api.pledgeRecord(params)
                result = this.apiResult(result)
                context.commit('pledgeRecord', result.rows)
                this.apiCache('newFi', 'pledgeRecord', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 收益记录
        async incomeRecord(context, params) {
            try {
                let result = await api.incomeRecord(params)
                result = this.apiResult(result)
                context.commit('incomeRecord', result.rows)
                this.apiCache('newFi', 'incomeRecord', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 提取记录
        async extractRecord(context, params) {
            try {
                let result = await api.extractRecord(params)
                result = this.apiResult(result)
                context.commit('extractRecord', result.rows)
                this.apiCache('newFi', 'extractRecord', result.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 文章详情
        async articleDetail(context, id) {
            try {
                let result = await api.articleDetail(id)
                result = this.apiResult(result)
                context.commit('articleDetail', result.data)
                this.apiCache('newFi', 'articleDetail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
    },
}
export default loginModule
