<template>
    <div class="assets">
        <Back :title="langJson('purchase history', 'inscdoge.txt.v3.11')"></Back>
        <div class="bg">
            <div class="list">
                <HisItem1 v-for="(item, index) in get_general_record" :key="index" :item="item" @extractHand="extractHand(item)" :usdtPrecision="usdtPrecision" />
                <NuData heith="200" v-if="!lenArray(get_general_record)"></NuData>
                <Page v-model="pageNum" :all="total" :size="pageSize" @change="init"></Page>
            </div>
            <!-- <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div> -->
            <pageBottom class="pageBottom"></pageBottom>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            total: '',
            pageNum: 1,
            pageSize: 10,
            extractModel: false,
            extractProModel: false,
            profit: {}, // 收益
            principal: {}, // 本金
            detail: {}, // 提取预览
            usdtPrecision: 0,
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    async created() {
        let USDT = this.chainSymbol.find((item) => {
            return item.name == 'USDT'
        })
        this.usdtPrecision = USDT.precision || 6

        await this.init()
    },
    mounted() {
        console.log(this.walletDetail)
        console.log(this.$route.params)
    },
    methods: {
        // 完成提取刷新列表
        completeExtract() {
            this.init()
        },
        confirm(item) {
            this.detail = {
                ...this.detail,
                ...item,
            }
            this.extractProModel = true
        },
        async extractHand(item) {
            this.extractModel = !this.extractModel
            this.detail = {
                ...this.detail,
                ...item,
            }
        },

        async init() {
            let { dispatch } = this.$store
            let { chainId, id } = this.$route.params
            if (id == 0) {
                id = ''
            }
            let { chainDetail } = this.walletDetail
            let obj = {
                chainId,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }
            if (this.$route.params && this.$route.params.id) {
                obj.projectId = this.$route.params.id
            }
            if (chainDetail && chainDetail.id) {
                let res = await dispatch('get_general_record', obj)
                this.total = res.total

                // await dispatch('personalGet')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;

            font-size: 20px;
            font-weight: 700;
            line-height: 21px;
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}
</style>
