<template>
    <div class="item_head">
        <div class="icon">
            <GI v-if="item.directNum > 0 && !item.loading" :noClickStop="true" :name="item.show ? 'reduce' : 'reduce_1_1'" ws="17" hs="17" :color="'#ff6821'"></GI>
            <GI v-if="!(item.directNum > 0) && !item.loading" name="stop" ws="15" hs="15" :color="'#ff6821'"></GI>
            <GI v-if="item.loading" :rotate="true" type="png" name="backup_loading" ws="17" hs="17"></GI>
        </div>

        <div class="content">
            <div class="left">
                <div class="title">
                    {{ item.userName }}
                    <!-- &nbsp;{{ langJson('Purchase Amount','inscdoge.txt.v3.26') }}：{{ saveNum(item.purchaseAmount, insPrecision) }} &nbsp;INS  -->
                    <!-- &nbsp;{{ langJson('Team size','inscdoge.txt.v3.27') }}：{{ item.directNum }} -->
                </div>
                <div class="desc" @click.stop="copy(item.address)">
                    <div class="address">{{ item.address | hideStr(6) }}</div>
                    <GI class="copyIcon" :noClickStop="true" name="new_copy" ws="14" hs="14"></GI>
                </div>
            </div>
            <div class="right">
                <!-- <div class="amount">
                    {{ langJson('等级:','inscdoge.txt.v1.168') }}
                    <div class="txt">{{ item.roleName }}</div>
                </div> -->

                <div class="box">
                    <div class="amount">
                        {{ langJson('Team size', 'inscdoge.txt.v3.27') }}：
                        <div class="txt">{{ item.directNum }}</div>
                    </div>
                    <div class="level">{{ item.roleName || 'A0' }}</div>
                </div>
                <div class="amount" v-if="item.purchaseAmount">
                    {{ langJson('Purchase', 'inscdoge.txt.v3.28') }}：
                    <div class="txt">
                        <span class="current">{{ saveNum(item.purchaseAmount, insPrecision) }}&nbsp;INS</span>
                    </div>
                </div>
                <div class="amount" v-show="false" v-if="item.amountStatistics">
                    {{ langJson('当前总消费', 'inscdoge.txt.v1.169') }}
                    <div class="txt">
                        <span class="current">{{ item.amountStatistics.validAmount }}</span>
                        <!-- /
                        <span class="history">{{ item.amountStatistics.totalAmount }}</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TreeItem',
    props: ['item'],
    data() {
        return {
            insPrecision: '2',
        }
    },
    computed: {
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
    },
    created() {
        // let INS = this.chainSymbol.find((item) => {
        //     return item.name == 'INS'
        // })
        // this.insPrecision = INS.precision
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.item_head {
    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;
    height: 50px;
    border-bottom: 1px solid var(--border2);

    .icon {
        width: 17px;
        height: 17px;
    }
    .content {
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        .right {
            flex: 1;
            height: 100%;
            margin-left: 15px;
            padding-right: 16px;
            .box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 28px;
                margin-bottom: 3px;
                // line-height: 16px;

                .level {
                    box-sizing: border-box;
                    padding: 2px;
                    font-size: 10px;
                    color: #ff6821;

                    border: 1px solid #ff6821;
                    border-radius: 50%;
                }
            }
            .amount {
                .txt {
                    .current {
                        color: #2ca631;
                    }
                    .history {
                        color: #ea4a18;
                    }
                    color: var(--font1);
                    /* margin-left: 4px; */
                    display: flex;
                    align-items: center;
                    gap: 2px;
                }

                color: var(--font1);
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                white-space: nowrap;
            }
        }
        .left {
            .title {
                color: var(--font1);
                font-size: 12px;
                line-height: 18px;
            }
            .desc {
                display: flex;
                align-items: center;

                .address {
                    color: #ff6821;
                    /* color: #2ca631; */
                    font-size: 12px;
                    line-height: 15px;
                    margin-right: 8px;
                }
                .copyIcon {
                    position: relative;
                    transform: translateY(-1px);
                }
            }
        }
    }
}
</style>
