<template>
    <div class="list">
        <div v-for="(item, index) in list" :key="index + 'P'" @click="setType(index)" :class="['item cursor', step == index && 'active']">
            <div class="text cursor">{{ item.name }}</div>
            <!-- <div class="line" /> -->
        </div>
    </div>
</template>

<script>
export default {
    // model: {
    //     prop: 'step',
    //     event: 'change',
    // },
    props: ['list', 'step'],
    data() {
        return {}
    },
    mounted() {},
    methods: {
        setType(type) {
            this.$emit('change', type)
        },
    },
}
</script>
<style lang="scss" scoped>
.list {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #000000;
    .item {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 10px;
        width: 50% !important;
        /* width: calc(50% - 27px) !important; */
        &:first-child {
            margin-left: 0px;
        }
        &.active {
            .line {
                background-color: #697178;
            }
            .text {
                font-size: 12px;
                color: #ffffffcc;
                background-color: #ffffff30;
            }
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 36px;
            height: 36px;
            letter-spacing: 0;
            text-align: center;
            color: #697178;
            font-size: 12px;
            width: 100%;
            border-radius: 10px;
            font-weight: 800;
        }

        .line {
            position: absolute;
            width: 24px;
            height: 2px;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
</style>
