<template>
    <div class="assets">
        <Back :title="langJson('HOME', 'inscdoge.txt.v1.12')" :showHead="true">
            <div class="right">
                <div class="link">
                    <SelChain v-if="isLogin() && address" :size="0" :list="chainList" :address="address | hideAddress" @change="chainHand"></SelChain>

                    <div v-else @click="showAssets()" class="address">
                        {{ langJson('Connect Wallet', 'inscdoge.txt.v1.13') }}
                        <!-- {{ langJson('Connect wallet','inscdoge.txt.v1.14') }} -->
                    </div>
                </div>
                <div class="menu" @click="$store.commit('SideMenu', !SideMenu)">
                    <GI name="menu" v-show="!SideMenu" ws="27.5" hs="27.5" :noClickStop="true"></GI>
                    <GI name="close" v-show="SideMenu" ws="27.5" hs="27.5" :noClickStop="true"></GI>
                </div>
            </div>
        </Back>
        <div class="bg">
            <div class="list">
                <div class="item">
                    <!-- 团队等级 -->
                    <div class="key">{{ langJson('Level', 'inscdoge.txt.v1.15') }}：</div>
                    <div class="val fontOver">{{ information.roleName || 'A0' }}</div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Total Assets', 'inscdoge.txt.v1.16') }}：</div>
                    <div class="val active v2">
                        <div class="fontOver">{{ saveNum(pledgeAssets.totalAccount || '0', insPrecision) | numberGrap }}&nbsp;{{ symbol }}</div>

                        <!-- <div class="left">{{ langJson('Inviter', 'inscdoge.txt.v2.30') }}：</div> -->
                        <!-- <div class="upDown" :class="{ underline: !information.parentUserName }" @click="inviterHand">
                            <div class="add">{{ information.parentUserName || langJson('Add', 'inscdoge.txt.v2.31') }}</div>
                        </div> -->
                    </div>
                </div>
                <div class="item">
                    <div class="key">{{ langJson('Real time price', 'inscdoge.txt.v1.17') }}：</div>
                    <div class="val active v2">
                        <!-- fontOver v2 -->
                        <div class="num">{{ saveNum(division(1, symbolQuote.rate), usdtPrecision) | numberGrap }} &nbsp;{{ toSymbol }}</div>
                        <div class="upDown">
                            <GI v-if="showReplace(symbolQuote.rateChange)" class="" name="up" type="svg" ws="18" hs="18"></GI>
                            <GI v-else class="" name="down" type="svg" ws="18" hs="18"></GI>
                            <div class="up">{{ replace(symbolQuote.rateChange) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="bar">
                <div class="bar_left">
                    <div class="item">
                        <div class="key">{{ langJson('Current', 'inscdoge.txt.v1.19') }}：</div>
                        <div class="val fontOver">{{ saveNum(get_financeInfo.customAccount || 0, insPrecision) | numberGrap }}</div>
                        &nbsp;{{ symbol }}
                    </div>
                    <div class="item">
                        <div class="key">{{ langJson('Lock position', 'inscdoge.txt.v1.18') }}：</div>
                        <div class="val fontOver">{{ saveNum(get_financeInfo.lockAccount || 0, insPrecision) | numberGrap }}</div>
                        &nbsp;{{ symbol }}
                    </div>
                    <div class="item">
                        <div class="key">{{ langJson('allReleasedAmount', 'inscdoge.txt.v1.21') }}：</div>
                        <div class="val fontOver">{{ saveNum(get_financeInfo.allReleasedAmount || 0, insPrecision) | numberGrap }}</div>
                        &nbsp;{{ symbol }}
                    </div>
                    <div class="item">
                        <div class="key">{{ langJson('releasedToday', 'inscdoge.txt.v1.20') }}：</div>
                        <div class="val fontOver">{{ saveNum(get_financeInfo.releasedToday || 0, insPrecision) | numberGrap }}</div>
                        &nbsp;{{ symbol }}
                    </div>
                </div>
                <div class="bar_right">
                    <div class="item">
                        <div class="key">{{ langJson('Team rewards', 'inscdoge.txt.v1.23') }}</div>
                        <div class="val">
                            <div class="fontOver">{{ saveNum(get_financeInfo.giftAmount || 0, insPrecision) | numberGrap }}</div>
                            &nbsp;{{ symbol }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="key">{{ langJson('Direct rewards', 'inscdoge.txt.v1.22') }}</div>
                        <div class="val">
                            <div class="fontOver">{{ saveNum(get_financeInfo.recommendReward || 0, insPrecision) | numberGrap }}</div>
                            &nbsp;{{ symbol }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="history">
                <div class="top">
                    {{ langJson('Purchase History', 'inscdoge.txt.v4.1') }}
                    <GI @click="jump_purchase" class="his" name="his" ws="24" hs="24" :noClickStop="true"></GI>
                </div>

                <template v-if="lenArray(allProject)">
                    <div class="his_title">{{ langJson('unlock position', 'inscdoge.txt.v4.2') }}</div>
                    <div class="his_cont">
                        <div class="int_item" v-for="item in allProject" :key="item.id">
                            <div class="item_head">
                                <div :class="['item_left', item.isList ? 'isRight' : '']">
                                    <div class="item_box">
                                        <div>{{ item.title }} {{ item.coinAmount }} {{ item.coinName }}</div>
                                        <div @click="item.isList = !item.isList">
                                            {{ langJson('Details', 'inscdoge.txt.v4.3') }}

                                            <GI :class="[item.isList ? 'Show' : 'noShow']" name="link_down" ws="12" hs="12" :noClickStop="true"></GI>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="isRight" class="item_right" @click="setObj(item)">
                                    {{ langJson('Buy', 'inscdoge.txt.v2.22') }}
                                    <div v-show="item.isLoading" class="bxo">
                                        <img class="laoding rotate" src="/source/png/backup_loading.png" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div v-show="item.isList" class="showList">
                                <div class="head">
                                    <div class="key">{{ langJson('锁仓/额外配送', 'inscdoge.txt.v4.9') }}</div>
                                    <div class="key">{{ langJson('以实时价格INS结算', 'inscdoge.txt.v4.10') }}</div>
                                    <div class="key">{{ langJson('赠送活期INS市值', 'inscdoge.txt.v4.11') }}</div>
                                </div>
                                <div class="cont">
                                    <div class="val">{{ item.coinAmount }} + {{ item.giftRate }}%</div>
                                    <div class="val">{{ saveNum(plus(item.coinAmount, division(multiplication(item.coinAmount, item.giftRate), 100), usdtPrecision)) | numberGrap }}{{ symbol_U }} &nbsp;{{ item.lockCoinName }}{{ langJson('市值', 'inscdoge.txt.v4.12') }}</div>
                                    <div class="val">{{ item.demandGift | numberGrap }}{{ symbol_U }}&nbsp;{{ item.lockCoinName }}{{ langJson('市值', 'inscdoge.txt.v4.12') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div> -->

            <div class="wait">{{ langJson('Coming soon', 'inscdoge.txt.v1.11') }}</div>
            <pageBottom class="pageBottom"></pageBottom>
        </div>

        <PayToken v-model="payModel" type="2" :amount="selectObj.gain_insAmount" :usdtAmount="selectObj.coinAmount" :detail="{ ...selectObj, meBalance, insPrecision, usdtPrecision }"></PayToken>
    </div>
</template>
<script>
export default {
    data() {
        return {
            toSymbol: 'USDT',
            symbol: 'INS',

            usdtPrecision: '6',
            normal: '0',
            dataList: [],
            // 是否显示右边
            isRight: true,
            payModel: false,
            selectObj: {},
            meBalance: 0,
            loading: false,

            insPrecision: '2',
            symbol_U: 'U',
        }
    },
    watch: {
        loginState: {
            async handler(a, b) {
                if (!!a && !b) {
                    // 项目列表
                    // this.getList()

                    // bar 栏
                    // this.getUserInfo()
                    // 登录了
                    await this.init()
                    await this.gainAssets()
                }
            },
            immediate: true,
        },
    },
    computed: {
        chainList() {
            // ...this.chainMapping,
            let temp = [{ isIconSrc: true, chainIcon: '/source/svg/exit.svg', chainName: this.langJson('Disconnect', 'inscdoge.txt.v1.24') }]
            return temp
        },
        address() {
            return this.walletDetail.address
        },
        chainSymbol() {
            try {
                let { chainDetail } = this.walletDetail
                let id
                if (!(chainDetail && chainDetail.id)) {
                    id = this.chainMapping[0] && this.chainMapping[0].id
                } else {
                    id = chainDetail.id
                }
                let coinlist = this.allCoin.filter((item) => {
                    return item.chainId == id
                })
                return coinlist
            } catch (err) {
                console.log(err)
                return []
            }
        },
        information() {
            try {
                let result = this.globalPersonal.information
                // let result = { ...this.globalPersonal.information, parentUserName: '' }

                console.log(this.globalPersonal)
                return result ? result : {}
            } catch (err) {
                console.log(err)
                return {}
            }
        },
        loginState() {
            return this.walletDetail && this.walletDetail.address && this.loginToken
        },
    },
    async created() {
        this.meBalance = 0
        this.selectObj = {}

        // this.getList()

        // await this.init()
        // await this.gainAssets()
    },
    mounted() {
        let USDT = this.chainSymbol.find((item) => {
            return item.name == 'USDT'
        })
        this.usdtPrecision = USDT.precision || 6
    },
    methods: {
        // 添加邀请人
        async inviterHand() {
            if (this.information.parentUserName) {
                //  邀请关系已绑
                this.pushMsg('warning', this.langJson('The invitation relationship is bound.', 'inscdoge.txt.v2.35'))
                return
            }
            await this.loginOf(() => {
                this.$store.commit('addInviterModel', true)
            }, false)
        },
        async showAssets() {
            await this.loginOf(() => {})
        },
        // 主链选择
        chainHand(item) {
            if (item.chainName == this.langJson('Disconnect', 'inscdoge.txt.v1.24')) {
                this.$store.dispatch('logout')
            } else {
                console.log('item', item)
                this.targetChain(item.id)
            }
        },
        isLogin() {
            return this.walletDetail && this.walletDetail.address && this.loginToken
        },
        replace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return str.slice(1)
            }
            return str
        },
        showReplace(str) {
            let index = str.indexOf('-')
            if (index !== -1) {
                return false
            }

            return true
        },
        async gainAssets() {
            try {
                let INS = this.allCoin.find((item) => {
                    return item.name == 'INS'
                })
                let { dispatch } = this.$store
                await dispatch('pledgeAssets')
                // 用户账户列表，1-正常账户，2-锁仓账户，3-收益账户
                // await dispatch('accountList', {
                //     type: 1,
                //     symbolId: INS.id,
                // })

                // if (this.accountList) {
                //     this.normal = this.accountList.balance
                // }
            } catch (error) {
                console.log(error)
            }
        },
        async init() {
            let { dispatch } = this.$store
            await dispatch('personalGet')
        },
        // 获取项目列表
        async getList() {
            try {
                let { dispatch, state } = this.$store
                await dispatch('getProList')
            } catch (error) {
                console.log(error)
            }
        },
        async setObj(item) {
            this.loginOf(async () => {
                if (this.loading) return
                this.loading = true
                item.isLoading = true

                this.selectObj = item
                await this.get_Balance(item)

                let USDT = this.chainSymbol.find((item) => {
                    return item.name == 'USDT'
                })
                this.usdtPrecision = USDT.precision || 6

                item.gain_insAmount = item.coinAmount

                this.payModel = true
                this.loading = false
                item.isLoading = false
            }, false)
        },
        async get_Balance(item) {
            let contract = JSON.parse(JSON.stringify(item.coinContractAddress))
            let { balance } = await this.getBalance({
                contract,
            })
            // console.log(balance, '=========balance========')
            this.meBalance = balance
        },
        async getUserInfo() {
            try {
                // let { dispatch, state } = this.$store
                // await dispatch('getProList')

                let { dispatch } = this.$store
                await dispatch('financeInfo')

                // console.log('financeInfo', this.get_financeInfo)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.assets {
    background-image: url('/public/source/newfi/live_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    height: 100%;
    .bg {
        display: flex;
        flex-direction: column;
        background-image: url('/public/source/newfi/live_bg2.png');
        background-position: center 50vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
        height: 100%;
        .list {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-top: 87px;
            font-family: Inconsolata;
            font-size: 20px;
            font-weight: 700;
            line-height: 21px;

            .item {
                display: flex;
                align-items: flex-end;

                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0px;
                }
                .key {
                    color: #fff;
                    flex-shrink: 0;
                }
                .val {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    color: #ff6821;
                    font-size: 14px;
                    line-height: 14px;
                    height: 14px;
                    &.v2 {
                        flex: 1;
                        /* max-width: calc(100vw - 320px); */
                        position: relative;
                        transform: translateY(-2px);
                        text-transform: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .num {
                            word-break: break-all;
                        }
                        .upDown {
                            display: flex;
                            align-items: center;

                            .add {
                                color: #fff;
                            }

                            &.underline {
                                color: #fff;

                                text-decoration: underline;
                            }
                        }
                    }
                    .fontOver {
                        line-height: 14px;
                        height: 14px;
                        max-width: calc(100vw - 212px);

                        &:first-letter {
                            text-transform: none;
                        }
                    }
                    .up {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffffff;
                        /* line-height: 12px;
                        height: 21px;
                        display: flex;
                        align-items: flex-end; */
                    }
                    .add {
                        margin-left: 9px;
                    }

                    &.active {
                        color: #ff6821;
                    }
                    &.underline {
                        text-decoration: underline;
                        color: #ffffff;
                    }

                    .copy {
                        margin-left: 9px;
                    }
                }
            }
        }
        .bar {
            width: calc(100% - 40px);
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 43px;
            .bar_left {
                box-sizing: border-box;
                max-width: 57%;
                width: 200px;
                border-radius: 6px;
                padding: 6px 17px;
                // background: linear-gradient(90deg, #140f6e 66.41%, #4b0ac9 96.41%);
                background: linear-gradient(90deg, #bc9e49 66.41%, #766a35 96.41%);

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 20px;
                    margin-top: 8px;
                    &:first-child {
                        margin-top: 0px;
                    }
                    .key {
                        min-width: 66px;
                        max-width: 66px;
                        // color: #ff6821;
                        color: #000;
                        white-space: nowrap;
                    }
                    .val {
                        text-align: right;
                        flex: 1;
                        color: #ffffff;
                    }
                }
            }
            .bar_right {
                max-width: 37%;
                .item {
                    max-width: 100%;
                    box-sizing: border-box;
                    width: 129px;
                    padding: 11px 5px 10px 5px;
                    border-radius: 6px;

                    // background: linear-gradient(90deg, #140f6e 66.41%, #4b0ac9 96.41%);
                    background: linear-gradient(90deg, #bc9e49 66.41%, #766a35 96.41%);

                    font-family: Montserrat;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    margin-top: 10px;
                    &:first-child {
                        margin-top: 0px;
                    }
                    .key {
                        // color: #ff6821;
                        color: #000;
                        white-space: nowrap;
                    }
                    .val {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 8px;
                        color: #ffffff;
                    }
                }
            }
        }

        .history {
            width: calc(100% - 40px);
            margin: 0 auto;
            margin-top: 27px;
            font-family: Inconsolata;
            font-size: 20px;
            font-weight: 700;
            line-height: 21px;
            .top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 9px;

                color: #ffffff;
                /* XB 12 */
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: 20px; /* 166.667% */
            }

            .his_title {
                display: flex;
                align-items: center;
                justify-content: center;

                margin-top: 23px;
                width: 100%;
                height: 50px;
                flex-shrink: 0;

                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: 20px;

                border-radius: 6px 6px 0px 0px;
                border: 1px solid #363636;
                background: #ff6821;
            }
            .his_cont {
                .int_item {
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;

                    min-height: 50px;
                    border-left: 1px solid #363636;
                    border-right: 1px solid #363636;
                    border-bottom: 1px solid #363636;

                    .item_head {
                        display: flex;
                        flex-shrink: 0;

                        .item_left {
                            padding: 15px 25px;
                            .item_box {
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                & > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    gap: 5px;
                                }

                                .Show {
                                    transform: rotate(180deg);
                                }

                                .noShow {
                                    transform: translateY(-2px);
                                }
                            }

                            flex: 1;
                            border-right: 1px solid #363636;

                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 20px;
                        }
                        .item_right {
                            position: relative;
                            box-sizing: border-box;
                            padding: 15px 25px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 96px;
                            height: 50px;
                            // line-height: 50px;

                            color: #ff6821;

                            /* XB 12 */
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 20px; /* 166.667% */

                            .bxo {
                                cursor: pointer !important;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .laoding {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    .showList {
                        box-sizing: border-box;
                        margin: 0 15px 14px;

                        font-family: Montserrat;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: center;

                        border-left: 1px solid #363636;
                        border-bottom: 1px solid #363636;
                        .head {
                            display: flex;
                            .key {
                                box-sizing: border-box;
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 6px 8px; // 18px
                                width: calc(100% / 3);
                                color: #ff7724;

                                border-top: 1px solid #363636;
                                border-bottom: 1px solid #363636;
                                border-right: 1px solid #363636;
                            }
                        }

                        .cont {
                            display: flex;
                            .val {
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-sizing: border-box;
                                padding: 6px 10px; // 18px
                                width: calc(100% / 3);

                                border-right: 1px solid #363636;
                            }
                        }
                    }

                    .isRight {
                        border-right: 1px solid #00000000 !important;
                    }
                }
            }
        }
        .wait {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #ffffff;
            font-size: 20px;
        }
        .pageBottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .link {
            color: var(--font1);
            align-items: center;
            display: flex;
            justify-content: center;
            max-width: calc(100% - 34px);
            .link_icon {
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }

            .address {
                box-sizing: border-box;
                height: 37px;
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                background: #ff6821;
                color: #fff;
                font-family: Inconsolata;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.2px;
            }
        }
        .lang {
            margin-right: 12px;
            display: flex;
            align-items: center;
        }
        .menu {
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
        .proinfo {
            .person_icon {
                margin-left: 12px;
                width: 26px;
                height: 26px;
                background-color: #000;
                border-radius: 50%;
            }
        }
    }
}
</style>
