import api from '@/api/collection'
const collectionModule = {
    state: {
        // 集合下的nft列表
        detailnfts: [],
        // 所属集合列表
        belongGroup: [],
        // 获取集合详情
        detail: {},
        // 获取集合交易记录
        historyGroup: [],
        
        // 集合的交易记录
        collectionTradelist:[]
    },
    getters: {},
    mutations: {
        detailnfts(state, detailnfts) {
            state.detailnfts = detailnfts
        },
        belongGroup(state, belongGroup) {
            state.belongGroup = belongGroup
        },
        detail(state, detail) {
            state.detail = detail
        },
        historyGroup(state, historyGroup) {
            state.historyGroup = historyGroup
        },

        setCollectionList(state,collectionTradelist){
            state.collectionTradelist=collectionTradelist
        }
    },
    actions: {
        // 获取集合下的nft列表
        async collectionItems(context, params) {
            try {
                // 请求接口
                let result = await api.nftGroup(params)
                result = this.apiResult(result)
                context.commit('detailnfts', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取所属集合列表
        async belongGroupGet(context, params) {
            try {
                let result = await api.belongGroup(params)
                result = this.apiResult(result)
                context.commit('belongGroup', result.data.rows)
                context.commit('total', result.data.total)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },

        // 获取集合详情
        async groupDetail(context, params) {
            try {
                let result = await api.groupDetail(params)
                result = this.apiResult(result)
                context.commit('detail', result.data)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        // 获取集合交易记录
        async groupHistoryGet(context, params) {
            try {
                let result = await api.groupHistory(params)
                result = this.apiResult(result)
                context.commit('historyGroup', result.data.rows)
                return result
            } catch (err) {
                console.log(err)
                return false
            }
        },
        
        async getCollectionTradeList(conext, params) {
            try {
                let result = await api.getGroupTrades(params)
                result = this.apiResult(result)
                console.log(result.data.rows);
                // conext.commit('setCollectionList',result.data.rows)
                this.apiCache('collection', 'setCollectionList', result.data.rows)
                conext.commit('total',result.data.total)
            } catch (err) {
                console.log(err)
            }
        },
    },
}
export default collectionModule
